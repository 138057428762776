import { NumberBox, Popup, Template, TextBox } from 'devextreme-react';
import React, { createRef, useEffect, useRef, useState } from 'react';
import Validator, { AsyncRule, RequiredRule } from 'devextreme-react/validator';
import { useScreenSize } from '../../../utils/media-query';
import PopupHeader from '../../../layouts/popup-header-footer/PopupHeader';
import PopupFooter from '../../../layouts/popup-header-footer/PopupFooter';
import { ConflictPopup, ShowAlert, eCRUDStatus, onDragOver, onKeyDown } from '../../../utils/common-methods';
import PopupSkeleton from '../../../layouts/templates/PopupSkeleton';
import PopupSkeletonOneLine from '../../../layouts/templates/PopupSkeletonOneLine';
import "./InteractionRound.scss"
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import { InteractionRoundServices } from '../../../api/services/InteractionRoundServices';

const interactionRoundServices = new InteractionRoundServices();
export default function InteractionRoundPopup(props) {

  const ValidationGroupName = "InteractionRoundPopupValidation"

  const { isXXSmall, isExSmall, isSmall, isXSmall, isMedium } = useScreenSize();

  const FocusedFeild = useRef(null);
  const onShown = () => FocusedFeild.current?.instance.focus();
  const [newRecord, setNewRecord] = useState({});
  const [originalRecord, setOriginalRecord] = useState({});
  let PrimaryKeyID = props.primaryKey;
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [assessmentFormattedText, setAssessmentFormattedText] = useState(null);

  const assessmentTemplateRef = useRef(null);

  const htmlEditorSecondAttributes = {
    class: "noteSecondHtml",
  };

  const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
  const fontValues = [
    'Arial',
    'Courier New',
    'Georgia',
    'Impact',
    'Lucida Console',
    'Tahoma',
    'Times New Roman',
    'Verdana',
  ];

  useEffect(() => {
    !showSkeleton && FocusedFeild.current?.instance.focus()
  }, [showSkeleton]);

  useEffect(() => {
    if (!showSkeleton && PrimaryKeyID && PrimaryKeyID !== 0) {
      assessmentFormattedText && assessmentTemplateRef.current?.instance.option("value", assessmentFormattedText);
    }
  }, [showSkeleton])

  useEffect(() => {
    if (PrimaryKeyID !== null && PrimaryKeyID !== 0) {
      GetModelRecord();
    } else {
      setTimeout(async () => {
        setNewRecord({});
        setShowSkeleton(false);
      }, 1000);
    }
  }, [PrimaryKeyID]);

  const GetModelRecord = async () => {
    setTimeout(async () => {
      const result = await interactionRoundServices.GetModelData(PrimaryKeyID);
      if (result.statusCode === 200) {
        const response = result.data;
        setNewRecord(response);
        setOriginalRecord(response);
        setAssessmentFormattedText(response?.assessmentTemplateFormattedText);
        setShowSkeleton(false);
      }
    }, 1000);
  };


  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!PrimaryKeyID) {
      InsertRecord();
    } else {
      UpdateRecord();
    }
  }


  const InsertRecord = async () => {
    setShowSkeleton(true);
    let assessmentTemplatePlainText = assessmentTemplateRef.current?.instance.getQuillInstance().getText();
    let assessmentTemplateFormattedText = assessmentTemplateRef.current?.instance.option("value");
    let dataToInsert = {
      interactionRoundName: newRecord.interactionRoundName,
      sortOrder: newRecord.sortOrder,
      assessmentTemplatePlainText,
      assessmentTemplateFormattedText,
    }
    const result = await interactionRoundServices.InsertData(dataToInsert);

    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency")
      }
      setShowSkeleton(false);
    } else {
      props.setStatus({
        eStatus: eCRUDStatus.Inserted,
        primaryKeyID: result.data.responseData
      })
      onClosePopup();
      setShowSkeleton(false);
    }
  }

  const UpdateRecord = async () => {
    if (originalRecord == newRecord || (originalRecord !== newRecord)) {
      setShowSkeleton(true);
      let assessmentTemplatePlainText = assessmentTemplateRef.current?.instance.getQuillInstance().getText();
      let assessmentTemplateFormattedText = assessmentTemplateRef.current?.instance.option("value");
      var dataToUpdate = {
        updatedOn: newRecord.updatedOn,
        interactionRoundID: newRecord.interactionRoundID,
        interactionRoundName: newRecord.interactionRoundName,
        sortOrder: newRecord.sortOrder,
        assessmentTemplatePlainText,
        assessmentTemplateFormattedText,
      }

      const result = await interactionRoundServices.UpdateData(dataToUpdate);
      if (!result.isOk) {
        if (result.statusCode === 409) {
          ConflictPopup(result.data).then((res) => {
            if (res) {
              GetModelRecord();
            } else {
              return;
            }
          });
          setShowSkeleton(false);
        } else if (result.statusCode === 400) {
          ShowAlert(result.data, "Vakency");
          setShowSkeleton(false);
        }
      } else {
        props.setStatus({
          eStatus: eCRUDStatus.Updated,
          primaryKeyID: result.data.responseData
        });
        onClosePopup();
        setShowSkeleton(false);
      }
    }
  }

  const asyncInteractionRoundNameValidation = async (e) => {
    try {
      const Name = encodeURIComponent(e?.value);
      const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
      const CheckDuplicateParams = `Name=${Name}&ExcludeID=${ExcludeID}`;

      const result = await interactionRoundServices.CheckDuplicateData(CheckDuplicateParams);

      return new Promise((resolve) => {
        resolve(result.isOk !== false);
      });
    } catch (error) {
      console.error("Error in asyncInteractionRoundNameValidation:", error);
      return new Promise((resolve) => {
        // setIsQualificationValid(false)
        resolve(false);
      });
    }

  }

  const onClosePopup = () => {
    props.setOpenPopup(false);
    setNewRecord({});
  }

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={[<span key={"header_title"} className="base-accent-text">{props.primaryKey ? "Edit" : "New"}</span>, " Interaction Round"]}
          onSubmit={handleSubmit}
          hideSaveButton={showSkeleton ? true : false}
        />
      </>
    )
  }

  return (
    <div className='Interaction_round_popup'>
      <Popup
        visible={props.openPopup}
        titleRender={PopupTitle}
        width={(isXSmall || isXXSmall || isExSmall || isSmall) ? "90%" : isMedium ? "82%" : " 55%"}
        height={'auto'}
        maxHeight={"95vh"}
        wrapperAttr={{ class: "CustomPopup" }}
        onShown={onShown}
      >
        <form onSubmit={handleSubmit} onDragStart={onDragOver}>
          <div className='row m-0'>
            <div className='col-md py-2'>
              {showSkeleton ?
                <div>
                  <PopupSkeletonOneLine />
                </div>
                :
                <TextBox
                  label='Interaction Round Name'
                  maxLength={50}
                  value={newRecord.interactionRoundName}
                  ref={FocusedFeild}
                  labelMode='floating'
                  onValueChange={(e) => setNewRecord({ ...newRecord, interactionRoundName: e })}
                >
                  <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                    <RequiredRule message='Interaction Round Name is required' />
                    {/* <CustomRule
                      ignoreEmptyValue={true}
                      message="Can't accept duplicate Interaction Round Name"
                      validationCallback={asyncInteractionRoundNameValidation}
                    /> */}
                    <AsyncRule
                      reevaluate={false}
                      message="Can't accept duplicate Interaction Round Name"
                      validationCallback={asyncInteractionRoundNameValidation}
                    />
                  </Validator>
                </TextBox>
              }
            </div>
            <div className='col-md py-2'>
              {showSkeleton ?
                <div>
                  <PopupSkeletonOneLine />
                </div>
                :
                <NumberBox
                  label='Order'
                  value={newRecord.sortOrder}
                  onValueChange={(e) => setNewRecord({ ...newRecord, sortOrder: e })}
                  maxLength={2}
                  format="#"
                  inputAttr={{ maxLength: 2 }}
                  onKeyDown={onKeyDown}
                  labelMode='floating'
                  step={0}
                >
                  <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                    <RequiredRule message='Order is required' />
                  </Validator>
                </NumberBox>
              }
            </div>
          </div>
          <div className='row m-0'>
            <div className='col py-2'>
              {showSkeleton ?
                <div>
                  <PopupSkeleton />
                  <PopupSkeleton />
                  <PopupSkeleton />
                  <PopupSkeleton />
                  <PopupSkeletonOneLine />
                </div>
                :
                <HtmlEditor
                  ref={assessmentTemplateRef}
                  height={"340px"}
                  valueType='html'
                  elementAttr={htmlEditorSecondAttributes}
                  className='CustomizeHtmlEditor'
                  tabIndex={-1}
                >
                  <HtmlEditorToolbar multiline={false}>
                    <HtmlEditorItem name="link"></HtmlEditorItem>
                    <HtmlEditorItem name="font" acceptedValues={fontValues} />
                    <HtmlEditorItem name="size" acceptedValues={sizeValues} />
                    <HtmlEditorItem name="bold" />
                    <HtmlEditorItem name="italic" />
                    <HtmlEditorItem name="underline" />
                    <HtmlEditorItem name="background" />
                    <HtmlEditorItem name="color" />
                    <HtmlEditorItem name="orderedList" />
                    <HtmlEditorItem name="bulletList" />
                    <HtmlEditorItem name="clear" />
                  </HtmlEditorToolbar>
                </HtmlEditor>
              }
            </div>
          </div>
          {showSkeleton ?
            <div className='d-flex justify-content-end pb-3 pe-2 py-5 mt-2'>
              <div className='skeleton-View Skeleton-popup-footer'></div>
            </div>
            :
            <PopupFooter
              ValidationGroupName={ValidationGroupName}
              openPopup={props.openPopup}
              setOpenPopup={props.setOpenPopup}
            />
          }
        </form>
      </Popup>
    </div>
  )
}
