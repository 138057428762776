import { Popup, TextBox, Validator } from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import PopupHeader from "../../../layouts/popup-header-footer/PopupHeader";
import PopupFooter from "../../../layouts/popup-header-footer/PopupFooter";
import { RequiredRule } from "devextreme-react/data-grid";
import { useScreenSize } from "../../../utils/media-query";
import {
  ConflictPopup,
  ShowAlert,
  eCRUDStatus,
} from "../../../utils/common-methods";
import { AsyncRule, CustomRule } from "devextreme-react/validator";
import PopupSkeleton from "../../../layouts/templates/PopupSkeleton";
import { CountryServices } from "../../../api/services/CountryServices";

const countryServices = new CountryServices();

export default function CountryPopup(props) {
  const ValidationGroupName = "countryPopupValidation";

  const { isExSmall, isXXSmall, isXSmall } = useScreenSize();
  const FocusedFeild = useRef(null);

  const onShown = () => FocusedFeild.current?.instance.focus();
  const [newRecord, setNewRecord] = useState({});
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [originalRecord, setOriginalRecord] = useState({});
  let PrimaryKeyID = props.primaryKey;

  // let [isCountryNameValid, setIsCountryNameValid] = useState(true);

  useEffect(() => {
    if (
      PrimaryKeyID !== null &&
      PrimaryKeyID !== 0 &&
      PrimaryKeyID !== undefined
    ) {
      setShowSkeleton(true);
      GetModelRecord();
    } else {
      setNewRecord({});
    }
  }, [PrimaryKeyID]);

  useEffect(() => {
    !showSkeleton && FocusedFeild.current?.instance.focus();
  }, [showSkeleton]);

  const GetModelRecord = async () => {
    setTimeout(async () => {
      const result = await countryServices.GetModelData(PrimaryKeyID);
      if (result?.statusCode === 200) {
        const response = result.data;
        setNewRecord(response);
        setOriginalRecord(response)
      }

      setShowSkeleton(false);
    }, 1000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!PrimaryKeyID) {
      InsertRecord();
    } else {
      UpdateRecord();
    }
  };

  const InsertRecord = async () => {
    setShowSkeleton(true);
    let dataToInsert = {
      countryName: newRecord.countryName,
    };

    const result = await countryServices.InsertData(dataToInsert);
    if (!result?.isOk) {
      if (result?.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
        setShowSkeleton(false);
      }
    } else {
      props.setStatus({
        eStatus: eCRUDStatus.Inserted,
        primaryKeyID: result.data.responseData,
      });
      onClosePopup();
      setShowSkeleton(false);
    }
  };

  const UpdateRecord = async () => {
    if(originalRecord == newRecord || (originalRecord !== newRecord)){

    setShowSkeleton(true);

    var dataToUpdate = {
      updatedOn: newRecord.updatedOn,
      countryID: newRecord.countryID,
      countryName: newRecord.countryName,
    };
    const result = await countryServices.UpdateData(dataToUpdate);

    if ( !result?.isOk) {
      if (result?.statusCode === 409) {
        ConflictPopup(result.data).then((res) => {
          if (res) {
            GetModelRecord();
          } else {
            return;
          }
        });
        setShowSkeleton(false);
      } else if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
        setShowSkeleton(false);
      }
    } else {
      props.setStatus({
        eStatus: eCRUDStatus.Updated,
        primaryKeyID: result.data.responseData,
      });
      onClosePopup();
      setShowSkeleton(false);
    }
  }
  };

  const onClosePopup = () => {
    // setIsCountryNameValid(true);
    props.setOpenPopup(false);
    setNewRecord({});
  };

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={[
            <span key={"header_title"} className="base-accent-text">
              {PrimaryKeyID ? "Edit" : "New"}
            </span>,
            " Country",
          ]}
          onSubmit={PrimaryKeyID ? UpdateRecord : InsertRecord}
          hideSaveButton={showSkeleton ? true : false}
        />
      </>
    );
  };

  const asyncCountryNameValidation = async (e) => {
    try {
      const Name = e?.value;
      const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
      const CheckDuplicateParams = `Name=${Name}&ExcludeID=${ExcludeID}`;
      const result = await countryServices.CheckDuplicateData(CheckDuplicateParams);
      return new Promise((resolve) => {
        resolve(result.isOk !== false);
        
      });
      
    } catch (error) {
      console.error("Error in asyncCountryNameValidation:", error);
      return new Promise((resolve) => {
        resolve(false);
      });
    }
  };
  

  return (
    <>
      {props.openPopup && (
        <Popup
          visible={props.openPopup}
          titleRender={PopupTitle}
          width={isExSmall || isXXSmall || isXSmall ? "90%" : 350}
          height={"auto"}
          wrapperAttr={{ class: "CustomPopup" }}
          onShown={onShown}
        >
          <form onSubmit={handleSubmit}>
            {showSkeleton ? (
              <div className="p-2 pb-0">
                <PopupSkeleton />
              </div>
            ) : (
              <div className="py-2 px-3">
                <TextBox
                  label="Country Name"
                  maxLength={50}
                  value={newRecord.countryName}
                  onValueChange={(e) => {
                    setNewRecord({ ...newRecord, countryName: e });
                  }}
                  labelMode="floating"
                  ref={FocusedFeild}
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Country Name is required" />
                    {/* <CustomRule
                      ignoreEmptyValue={true}
                      message="Can't accept duplicate Country Name"
                      validationCallback={asyncCountryNameValidation}
                    /> */}
                     <AsyncRule
                        reevaluate={false}
                        message="Can't accept duplicate Country Name"
                        validationCallback={asyncCountryNameValidation}
                      />
                  </Validator>
                </TextBox>
              </div>
            )}
            {showSkeleton ? (
              <div className="d-flex justify-content-end pb-2 pe-2">
                <div className="skeleton-View Skeleton-popup-footer"></div>
              </div>
            ) : (
              <PopupFooter
                ValidationGroupName={ValidationGroupName}
                openPopup={props.openPopup}
                setOpenPopup={props.setOpenPopup}
              />
            )}
          </form>
        </Popup>
      )}
    </>
  );
}
