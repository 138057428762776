import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { JobApplicationHistoryName } from "../../contexts/APIurl";

const url = JobApplicationHistoryName;
const baseURL = process.env.REACT_APP_BASEURL;

export class JobApplicationHistoryServices extends VakencyApiServices {
    constructor() {
        super(url);
    }

    GetJobApplicationHistory = async (jobApplicationID) => {
        const GetListUrl = url + `/GetJobApplicationHistory/${jobApplicationID}`;
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }
}