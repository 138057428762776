import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, CheckBox, DateBox, FileUploader, LoadPanel, NumberBox, ScrollView, SelectBox, TextBox } from 'devextreme-react';
import { Button as NumberBoxButton } from 'devextreme-react/number-box';
import './JobApplication.scss';
import { JobOpeningOpenService } from '../../api/services/JobOpeningOpenService';
import { JobApplicationOpenService } from '../../api/services/JobApplicationOpenService';
import { eCRUDStatus, minDate, onKeyDown, onKeyDown_Withpoint, ShowAlert } from '../../utils/common-methods';
import { NoImage } from '../../utils/base-64-Icons';
import { useScreenSize } from '../../utils/media-query';
import PopupSkeleton from '../../layouts/templates/PopupSkeleton';
import Validator, { EmailRule, RangeRule, RequiredRule, StringLengthRule } from 'devextreme-react/validator';
import PopupSkeletonOneLine from '../../layouts/templates/PopupSkeletonOneLine';

const ValidationGroupName = "CandidateValidationGroup"
const jobOpeningOpenServices = new JobOpeningOpenService();
const jobApplicationOpenService = new JobApplicationOpenService();
const maxResumeSizeLength = 5 * 1024 * 1024;

const currentDate = new Date();
const DOBRangeRule = new Date(
    currentDate.setFullYear(currentDate.getFullYear() - 18)
);

const CandidateDS = {
    firstName: '',
    lastName: '',
    emailID: '',
    mobileNo: null,
    genderID: null,
    dob: null,
    maritalStatusID: null,
    currentCity: '',
    homeTown: null,
    totalExperience: 0,
    relevantExperience: 0,
    currentCTC: null,
    expectedCTC: null,
    noticePeriod: null,
    readyToRelocate: null,
    reasonForLeaving: null,
    currentCompanyName: null,
    acceptTermsAndConditions: false
}

const dsMaritalStatus = [
    {
        maritalStatusID: 0,
        maritalStatus: "Single",
    },
    {
        maritalStatusID: 1,
        maritalStatus: "Married",
    },
    {
        maritalStatusID: 2,
        maritalStatus: "Divorced",
    },
    {
        maritalStatusID: 3,
        maritalStatus: "Other",
    },
];

const dsGenderStatus = [
    {
        genderID: 0,
        genderStatus: "Male",
    },
    {
        genderID: 1,
        genderStatus: "Female",
    },
    {
        genderID: 2,
        genderStatus: "Other",
    },
];

export default function JobApplicationPublicView() {
    const { openaccesscode } = useParams();
    const { state } = useLocation();
    const [showSkeleton, setShowSkeleton] = React.useState(true);
    const [dsJobApplication, setDsJobApplication] = React.useState({});
    const [companyImg, setCompanyImg] = React.useState({ fileData: null });
    const [showFooterButton, setShowFooterButton] = React.useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(1);
    const [newRecord, setNewRecord] = React.useState(CandidateDS);
    const [resume, setResume] = React.useState(null);
    const [months, setMonths] = React.useState({
        totalMonth: 0,
        relevantMonth: 0,
    });
    const [status, setStatus] = React.useState({
        eStatus: eCRUDStatus.None,
        message: null
    });
    const [currentCTCMonthlyValue, setCurrentCTCMonthlyValue] = React.useState();
    const [expectedCTCMonthlyValue, setExpectedCTCMonthlyValue] = React.useState();

    const NavigateTo = useNavigate();
    const { isExSmall, isSmall, isXSmall, isXXSmall } = useScreenSize();
    const UploadResumeRef = React.useRef();

    React.useEffect(() => {
        if (openaccesscode) {
            GetApplication();
        }
    }, [openaccesscode]);

    React.useEffect(() => {
        setShowFooterButton(false);
    }, [selectedTabIndex]);

    const GetApplication = async () => {
        const result = await jobOpeningOpenServices.GetApplicationData(openaccesscode);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                let errorMessage = null;
                try {
                    let errorRes = JSON.parse(result.data);
                    errorMessage = errorRes?.status && errorRes.status == 400 ? 'Job application key is invalid.' : 'Something went wrong.';
                } catch (err) {
                    errorMessage = result.data;
                } finally {
                    NavigateTo('/Job/Public/error-page', {
                        state: {
                            errorMessage
                        }
                    });
                }
            }
            setShowSkeleton(false);
        } else {
            const companyLogo = await GetCompanyLogo();
            setCompanyImg({
                fileData: companyLogo?.size > 0 ? window.URL.createObjectURL(companyLogo) : null,
            });
            setDsJobApplication(result.data);
            setShowSkeleton(false);
        }
    };

    const GetCompanyLogo = async () => {
        const result = await jobOpeningOpenServices.GetCompanyLogo(openaccesscode);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
            }
        }
        return result.data;
    };

    const handleScroll = (e) => {
        const scrollTop = e.scrollOffset.top;
        const scrollHeight = e.component.scrollHeight();
        const clientHeight = e.component.clientHeight();
        if (scrollTop + clientHeight >= scrollHeight - 130) {
            setShowFooterButton(true);
        } else {
            setShowFooterButton(false);
        }
    };

    const onApplyButtonClick = () => {
        setSelectedTabIndex(2);
    };

    const onUploadResumeValueChanged = (e) => {
        const file = e[0];
        if (file && file?.size > maxResumeSizeLength) {
            ShowAlert(`Resume of more than 5 MB size cannot be uploaded`, "Vakency");
            UploadResumeRef?.current?.instance.reset();
            setResume(null);
        } else if (
            file &&
            file["type"]?.split("/")[1] !== "pdf"
        ) {
            ShowAlert(`This file is not accepted`, "Vakency");
            UploadResumeRef?.current?.instance.reset();
            setResume(null);
        } else {
            if(file){
              setResume(file);
            }
        }
    };

    const goToJobOpenings = () => {
        if (selectedTabIndex == 2) {
            setSelectedTabIndex(1);
            setNewRecord(CandidateDS);
        } else {
            NavigateTo(-1);
        }

    }

    const onClickClearResume = async (e) => {
        UploadResumeRef.current?.instance.reset();
        setResume(null);
    };

    const UpdateResume = async (ID) => {
        if (resume) {
            var Datatoupload = { attachment: resume };
            await jobApplicationOpenService.UpdateCandidateDocData(
                Datatoupload,
                ID
            );
        }
    };

    const InsertRecord = async () => {
        setShowSkeleton(true);
        var formattedDate = newRecord?.dob
            ? new Date(
                new Date(newRecord?.dob + "Z").toUTCString()
            ).toLocaleDateString()
            : null;
        let dataToInsert = {
            firstName: newRecord?.firstName,
            lastName: newRecord?.lastName,
            emailID: newRecord?.emailID ? newRecord?.emailID : null,
            mobileNo: newRecord?.mobileNo,
            genderID: newRecord?.genderID,
            dob: formattedDate,
            maritalStatusID: newRecord?.maritalStatusID,
            currentCity: newRecord?.currentCity,
            homeTown: newRecord?.homeTown,
            totalExperience: parseFloat(
                (newRecord?.totalExperience ? newRecord?.totalExperience : '0') + "." + (months.totalMonth ? (months.totalMonth < 10 ? `0${months.totalMonth}` : months.totalMonth) : '0')
            ).toFixed(2),
            relevantExperience: parseFloat(
                (newRecord?.relevantExperience ? newRecord?.relevantExperience : '0') + "." + (months.relevantMonth ? (months.relevantMonth < 10 ? `0${months.relevantMonth}` : months.relevantMonth) : '0')
            ).toFixed(2),
            currentCTC: newRecord?.currentCTC,
            expectedCTC: newRecord?.expectedCTC,
            noticePeriod: newRecord?.noticePeriod,
            readyToRelocate: newRecord?.readyToRelocate || false,
            reasonForLeaving: newRecord?.reasonForLeaving || '',
            currentCompanyName: newRecord?.currentCompanyName,
        };

        const result = await jobApplicationOpenService.PostCandidateDetail(openaccesscode, dataToInsert);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
                setShowSkeleton(false);
            }
        } else {
            UpdateResume(result.data.responseData);
            setNewRecord({});
            setShowSkeleton(false);
            setStatus({
                eStatus: eCRUDStatus.Inserted,
                message: 'Applied Successfully'
            });
            setSelectedTabIndex(1);
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (!resume) {
            ShowAlert(`Resume is required`, "Vakency");
        } else {
            InsertRecord();
        }
    }

    const clearForm = (e) => {
        e.validationGroup.reset(CandidateDS);
        setNewRecord(CandidateDS);
        setResume(null);
    }

    return (
        <div className='container-fluid'>

            <div className={`${(isExSmall || isSmall || isXSmall || isXXSmall) ? 'job-application-public-section' : 'job-application-public-section'} row justify-content-center`}>
                <div className="col-12 col-md-10 col-lg-7 col-xxl-6 d-flex justify-content-center align-items-center">
                    <div className="w-100 job-application-public-card">
                        <LoadPanel
                            visible={showSkeleton}
                            shading={true}
                            shadingColor="rgba(0,0,0,0.4)"
                        />
                        <div className="row px-0 mx-0 d-flex align-items-center"
                            style={{
                                position: 'sticky',
                                top: 0,
                                left: 0,
                                width: '100%',
                                background: 'white',
                                zIndex: 1000,
                                justifyContent: 'space-between',
                                padding: '10px 20px',
                                borderBottom: '1px solid #ddd'
                            }}
                        >
                            <div className='col-auto p-2'>
                                <img
                                    src={companyImg.fileData || NoImage}
                                    alt="candidate"
                                    width={'100%'}
                                    height={'40px'}
                                />
                            </div>
                            <div className='col ps-1 font-semiBold-24'>
                                {dsJobApplication.companyName || '-'}
                            </div>
                            <div className="col-auto">
                                {
                                    selectedTabIndex == 2  ? (
                                        <Button
                                            text={"Back"}
                                            icon="spinprev"
                                            type="default"
                                            hint="Back"
                                            width={100}
                                            stylingMode="text"
                                            className="border-0 Back__button"
                                            onClick={goToJobOpenings}
                                        />
                                    ) : (
                                        selectedTabIndex == 1 && state?.showBackButton && (
                                            <Button
                                                text={"Back"}
                                                icon="spinprev"
                                                type="default"
                                                hint="Back"
                                                width={100}
                                                stylingMode="text"
                                                className="border-0 Back__button"
                                                onClick={goToJobOpenings}
                                            />
                                        )
                                    )
                                    
                                }
                            </div>
                        </div>
                        <div className='content-section pb-3'>
                            {
                                selectedTabIndex === 1 && (
                                    <ScrollView height={'100%'} width={'100%'} onScroll={handleScroll}>
                                        <div className="row py-4 px-0 mx-0">
                                            <div className='col-auto ps-2'>
                                                <div className='font-semiBold-24'>{dsJobApplication.jobProfileName}</div>
                                            </div>
                                        </div>
                                        <div className="row py-2 px-0 mx-0">
                                            <div className='col-auto ps-2'>
                                                {
                                                    status.eStatus === 1 ? (
                                                        <>
                                                            <i className="dx-icon-successIcon dx-custom-icon mb-1"></i>{" "}
                                                            <span className='font-semiBold-16 text-success'>{status.message}</span>
                                                        </>
                                                    ) : (
                                                        <Button
                                                            text='Apply'
                                                            hint='Apply for job'
                                                            className='rounded-2'
                                                            stylingMode='contained '
                                                            type='default'
                                                            onClick={onApplyButtonClick}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="row py-3 px-0 mx-0">
                                            <div className='col-auto ps-2'>
                                                <div className='font-semiBold-18'>About the Job</div>
                                                <div className='mt-2' dangerouslySetInnerHTML={{ __html: dsJobApplication.jobDescrFormattedText || '-' }} />
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-center">
                                            <div className='col-auto ps-2 text-center '>
                                                {
                                                    showFooterButton && status.eStatus === 0 && (
                                                        <Button
                                                            text='Apply'
                                                            hint='Apply for job'
                                                            className='rounded-2'
                                                            stylingMode='contained '
                                                            type='default'
                                                            onClick={onApplyButtonClick}
                                                        />

                                                    )
                                                }
                                            </div>
                                        </div>
                                    </ScrollView>
                                )
                            }
                            {
                                selectedTabIndex === 2 && (
                                    <ScrollView height={'100%'} width={'100%'}>
                                        <form className='' onSubmit={handleSubmit}>

                                            <div className="px-3 py-2">

                                                <div className="row pt-3">
                                                    <div className='col font-semiBold-20'>
                                                        <span className='job-profile-caption'>Job Profile:</span> {dsJobApplication.jobProfileName}
                                                    </div>
                                                    <div className='col-xs-12 col-md pt-md-0 pt-3'>
                                                        <div className="resumeUpload d-flex border rounded align-items-center">
                                                            <label htmlFor="upload-resume" className='ms-1'>
                                                                <span className='text-danger'>*</span>
                                                            </label>
                                                            <FileUploader
                                                                ref={UploadResumeRef}
                                                                name="candidate resume"
                                                                multiple={false}
                                                                uploadMode={"useForm"}
                                                                selectButtonText="Browse"
                                                                allowCanceling={false}
                                                                labelText=""
                                                                hoverStateEnabled={false}
                                                                activeStateEnabled={false}
                                                                focusStateEnabled={false}
                                                                uploadedMessage="Resume Uploaded"
                                                                readyToUploadMessage=""
                                                                className=""
                                                                inputAttr={{ 'aria-label': 'Browse' }}
                                                                onValueChange={onUploadResumeValueChanged}
                                                                value={resume ? [resume] : []}
                                                                hint={resume ? resume?.name : ""}
                                                            />
                                                            {resume && (
                                                                <>
                                                                    <Button
                                                                        icon="close"
                                                                        id="Close"
                                                                        onClick={onClickClearResume}
                                                                        stylingMode="text"
                                                                        type="default"
                                                                        hint="Close"
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className='top-card-caption'>
                                                            Maximum file size 5MB, acceptable file type .pdf
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Personal Info */}
                                                <div className="row pt-md-0 pt-3">
                                                    <div className="col-md ">
                                                        <div className="editor-sub-header">Personal Information</div>
                                                        {showSkeleton ? (
                                                            <div className="">
                                                                <PopupSkeleton />
                                                                {/* <PopupSkeleton /> */}
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md">
                                                                        <TextBox
                                                                            label="First Name"
                                                                            maxLength={50}
                                                                            labelMode="floating"
                                                                            value={newRecord?.firstName}
                                                                            onValueChange={(e) =>
                                                                                setNewRecord({ ...newRecord, firstName: e })
                                                                            }
                                                                        >
                                                                            <Validator
                                                                                validationGroup={ValidationGroupName}
                                                                                elementAttr={{ class: "ForValidationAstrikMark" }}
                                                                            >
                                                                                <RequiredRule message="First Name is required" />
                                                                            </Validator>
                                                                        </TextBox>
                                                                    </div>
                                                                    <div className="col-md pt-md-0 pt-3">
                                                                        <TextBox
                                                                            label="Last Name"
                                                                            maxLength={50}
                                                                            labelMode="floating"
                                                                            value={newRecord?.lastName}
                                                                            onValueChange={(e) =>
                                                                                setNewRecord({ ...newRecord, lastName: e })
                                                                            }
                                                                        >
                                                                            <Validator
                                                                                validationGroup={ValidationGroupName}
                                                                                elementAttr={{ class: "ForValidationAstrikMark" }}
                                                                            >
                                                                                <RequiredRule message="Last Name is required" />
                                                                            </Validator>
                                                                        </TextBox>
                                                                    </div>
                                                                </div>

                                                                <div className="row pt-2">
                                                                    <div className="col-md pt-md-0 pt-1">
                                                                        <DateBox
                                                                            label="DOB"
                                                                            labelMode="floating"
                                                                            id="DOB_Candidate_Dropdown"
                                                                            value={newRecord.dob ? new Date(newRecord.dob) : null}
                                                                            dateOutOfRangeMessage="Invalid Date"
                                                                            onValueChange={(e) =>
                                                                                setNewRecord({ ...newRecord, dob: new Date(e) })
                                                                            }
                                                                            useMaskBehavior={true}
                                                                            min={minDate}
                                                                            dropDownOptions={
                                                                                {
                                                                                    hideOnParentScroll: true,
                                                                                    container: "#DOB_Candidate_Dropdown"
                                                                                }
                                                                            }
                                                                        >
                                                                            <Validator
                                                                                validationGroup={ValidationGroupName}
                                                                                elementAttr={{ class: "ForValidationAstrikMark" }}
                                                                            >
                                                                                <RequiredRule message="DOB is required" />
                                                                                <RangeRule
                                                                                    message="Candidate must be 18 years old"
                                                                                    max={DOBRangeRule}
                                                                                    ignoreEmptyValue={true}
                                                                                />
                                                                            </Validator>
                                                                        </DateBox>
                                                                    </div>
                                                                    <div className="col-md pt-md-0 pt-3">
                                                                        <SelectBox
                                                                            label="Gender"
                                                                            id="Gender_Dropdown"
                                                                            labelMode="floating"
                                                                            dataSource={dsGenderStatus}
                                                                            valueExpr={"genderID"}
                                                                            displayExpr={"genderStatus"}
                                                                            value={newRecord?.genderID}
                                                                            onValueChange={(e) =>
                                                                                setNewRecord({ ...newRecord, genderID: e })
                                                                            }
                                                                            dropDownOptions={
                                                                                {
                                                                                    hideOnOutsideClick: true,
                                                                                    container: "#Gender_Dropdown"
                                                                                }
                                                                            }
                                                                        >
                                                                            <Validator
                                                                                validationGroup={ValidationGroupName}
                                                                                elementAttr={{ class: "ForValidationAstrikMark" }}
                                                                            >
                                                                                <RequiredRule message="Gender is required" />
                                                                            </Validator>
                                                                        </SelectBox>
                                                                    </div>
                                                                    <div className="col-md pt-md-0 pt-3">
                                                                        <SelectBox
                                                                            label="Marital Status"
                                                                            id="MaritalStatus_Dropdown"
                                                                            labelMode="floating"
                                                                            dataSource={dsMaritalStatus}
                                                                            valueExpr={"maritalStatusID"}
                                                                            displayExpr={"maritalStatus"}
                                                                            value={newRecord?.maritalStatusID}
                                                                            onValueChange={(e) =>
                                                                                setNewRecord({ ...newRecord, maritalStatusID: e })
                                                                            }
                                                                            dropDownOptions={
                                                                                {
                                                                                    hideOnOutsideClick: true,
                                                                                    container: "#MaritalStatus_Dropdown"
                                                                                }
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>

                                                {/*-------- End Personal Info ---------*/}

                                                {/* Contact Info */}
                                                <div className="editor-sub-header pt-3">Contact Information</div>
                                                {showSkeleton ? (
                                                    <div className="">
                                                        <PopupSkeletonOneLine />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="row ">
                                                            <div className="col-xxl col-xl col-lg col-md">
                                                                <NumberBox
                                                                    label="Mobile No."
                                                                    labelMode="floating"
                                                                    value={
                                                                        newRecord?.mobileNo
                                                                            ? parseInt(newRecord?.mobileNo)
                                                                            : undefined
                                                                    }
                                                                    maxLength={10}
                                                                    format="#"
                                                                    inputAttr={{
                                                                        autocomplete: "new",
                                                                        maxLength: 10,
                                                                    }}
                                                                    onKeyDown={onKeyDown}
                                                                    step={0}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, mobileNo: e })
                                                                    }
                                                                >
                                                                    <Validator
                                                                        validationGroup={ValidationGroupName}
                                                                        elementAttr={{ class: "ForValidationAstrikMark" }}
                                                                    >
                                                                        <RequiredRule message="Mobile No. is required" />
                                                                        <StringLengthRule
                                                                            message="Mobile No. must have 10 digits"
                                                                            min={10}
                                                                            ignoreEmptyValue={true}
                                                                        />
                                                                        {/* <AsyncRule
                                                                        reevaluate={false}
                                                                        message="Can't accept duplicate Mobile No."
                                                                    validationCallback={asyncMobileNoValidation}
                                                                    /> */}
                                                                    </Validator>
                                                                </NumberBox>
                                                            </div>
                                                            <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0  pt-3">
                                                                <TextBox
                                                                    label="Email ID"
                                                                    maxLength={100}
                                                                    labelMode="floating"
                                                                    value={newRecord?.emailID}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, emailID: e })
                                                                    }
                                                                    inputAttr={{
                                                                        autocomplete: "new",
                                                                    }}
                                                                >
                                                                    <Validator
                                                                        validationGroup={ValidationGroupName}
                                                                        elementAttr={{ class: "ForValidationAstrikMark" }}
                                                                    >
                                                                        <RequiredRule message="Email ID is required" />
                                                                        <EmailRule message="Email ID is invalid" />
                                                                        {/* <AsyncRule
                                                                        reevaluate={false}
                                                                        message="Can't accept duplicate email ID"
                                                                    validationCallback={asyncEmailIDValidation}
                                                                    /> */}
                                                                    </Validator>
                                                                </TextBox>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {/*-------- End Contact Info ---------*/}

                                                {/* Address Info */}
                                                <div className="editor-sub-header pt-3">Address Information</div>
                                                {showSkeleton ? (
                                                    <div className="">
                                                        <PopupSkeletonOneLine />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="row ">
                                                            <div className="col-xxl col-xl col-lg col-md">
                                                                <TextBox
                                                                    label="Current City"
                                                                    labelMode="floating"
                                                                    maxLength={50}
                                                                    value={newRecord?.currentCity}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, currentCity: e })
                                                                    }
                                                                >
                                                                    <Validator
                                                                        validationGroup={ValidationGroupName}
                                                                        elementAttr={{ class: "ForValidationAstrikMark" }}
                                                                    >
                                                                        <RequiredRule message="Current City is required" />
                                                                    </Validator>
                                                                </TextBox>
                                                            </div>
                                                            <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0  pt-3">
                                                                <TextBox
                                                                    label="Home Town"
                                                                    labelMode="floating"
                                                                    maxLength={50}
                                                                    value={newRecord?.homeTown}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, homeTown: e })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {/*-------- End Address Info ---------*/}

                                                {/* Work Experience */}
                                                <div className="editor-sub-header pt-3">Work Experience</div>
                                                {showSkeleton ? (
                                                    <div className="">
                                                        <PopupSkeletonOneLine />
                                                    </div>
                                                ) : (
                                                    <div className="row">
                                                        <div className="col-xxl col-xl col-lg ">
                                                            <div className="row">
                                                                <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto  pt-2 mt-1 editor-label">
                                                                    Total Experience:
                                                                </div>
                                                                <div className="col">
                                                                    <NumberBox
                                                                        label="Years"
                                                                        labelMode="floating"
                                                                        maxLength={3}
                                                                        value={newRecord?.totalExperience}
                                                                        onValueChange={(e) =>
                                                                            setNewRecord({ ...newRecord, totalExperience: e })
                                                                        }
                                                                        onKeyDown={onKeyDown}
                                                                        format="#"
                                                                        inputAttr={{ maxLength: 3 }}
                                                                        step={0}
                                                                    />
                                                                </div>
                                                                <div className="col ">
                                                                    <NumberBox
                                                                        label="Months"
                                                                        labelMode="floating"
                                                                        maxLength={3}
                                                                        max={11}
                                                                        value={months.totalMonth}
                                                                        onValueChange={(e) => {
                                                                            setMonths({ ...months, totalMonth: e });
                                                                        }}
                                                                        onKeyDown={onKeyDown}
                                                                        format="#"
                                                                        inputAttr={{ maxLength: 5 }}
                                                                        step={0}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl col-xl col-lg pt-xxl-0 pt-xl-0 pt-lg-0 pt-3">
                                                            <div className="row">
                                                                <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto pt-2 mt-1 editor-label">
                                                                    Relevant Experience:
                                                                </div>
                                                                <div className="col">
                                                                    <NumberBox
                                                                        label="Years"
                                                                        labelMode="floating"
                                                                        maxLength={3}
                                                                        value={newRecord?.relevantExperience}
                                                                        onValueChange={(e) =>
                                                                            setNewRecord({ ...newRecord, relevantExperience: e })
                                                                        }
                                                                        onKeyDown={onKeyDown}
                                                                        inputAttr={{ maxLength: 3 }}
                                                                        step={0}
                                                                        format="#"
                                                                    />
                                                                </div>
                                                                <div className="col ">
                                                                    <NumberBox
                                                                        label="Months"
                                                                        labelMode="floating"
                                                                        maxLength={3}
                                                                        onKeyDown={onKeyDown}
                                                                        format="#"
                                                                        max={11}
                                                                        value={months.relevantMonth}
                                                                        onValueChange={(e) =>
                                                                            setMonths({ ...months, relevantMonth: e })
                                                                        }
                                                                        inputAttr={{ maxLength: 3 }}
                                                                        step={0}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {/* ------- End Work Experience -------------*/}

                                                {/* Other Information */}
                                                <div className="editor-sub-header pt-3">
                                                    Other Information
                                                </div>
                                                {showSkeleton ? (
                                                    <div className="">
                                                        <PopupSkeleton />
                                                        <PopupSkeletonOneLine />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-xxl col-xl col-lg ">
                                                                <div className="row">
                                                                    <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto  pt-2 mt-1 editor-label">
                                                                        Current CTC:
                                                                    </div>
                                                                    <div className="col">
                                                                        <NumberBox
                                                                            label="Yearly"
                                                                            labelMode="floating"
                                                                            maxLength={15}
                                                                            format="##.##"
                                                                            value={newRecord?.currentCTC && newRecord?.currentCTC.toFixed(2)}
                                                                            onValueChange={(e) => {
                                                                                setNewRecord({ ...newRecord, currentCTC: e })
                                                                                setCurrentCTCMonthlyValue((e * 100000) / 12)
                                                                            }}
                                                                            onKeyDown={onKeyDown_Withpoint}
                                                                            className="number-box-btn"
                                                                        >
                                                                            <NumberBoxButton
                                                                                location="after"
                                                                                name="current_ctc_yearly"
                                                                                options={{
                                                                                    text: 'LPA',
                                                                                    stylingMode: 'text',
                                                                                    hoverStateEnabled: false,
                                                                                    focusStateEnabled: false,
                                                                                    activeStateEnabled: false,
                                                                                }}
                                                                            />
                                                                        </NumberBox>
                                                                    </div>
                                                                    <div className="col-auto px-0 pt-3 text-gray font-regular-12">OR</div>
                                                                    <div className="col">
                                                                        <NumberBox
                                                                            label="Monthly"
                                                                            labelMode="floating"
                                                                            value={currentCTCMonthlyValue && currentCTCMonthlyValue.toFixed(2)}
                                                                            maxLength={15}
                                                                            format="##.##"
                                                                            onValueChange={(e) => {
                                                                                setCurrentCTCMonthlyValue(e);
                                                                                setNewRecord({ ...newRecord, currentCTC: ((e * 12) / 100000) })
                                                                            }}
                                                                            onKeyDown={onKeyDown_Withpoint}
                                                                            className="number-box-btn"
                                                                        >
                                                                            <NumberBoxButton
                                                                                location="after"
                                                                                name="current_ctc_monthly"
                                                                                options={{
                                                                                    text: 'per month',
                                                                                    stylingMode: 'text',
                                                                                    hoverStateEnabled: false,
                                                                                    focusStateEnabled: false,
                                                                                    activeStateEnabled: false,
                                                                                }}
                                                                            />
                                                                        </NumberBox>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row pt-2">
                                                            <div className="col-xxl col-xl col-lg pt-xxl-0 pt-xl-0 pt-lg-0">
                                                                <div className="row">
                                                                    <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto pt-2 mt-1 editor-label">
                                                                        Expected CTC:
                                                                    </div>
                                                                    <div className="col">
                                                                        <NumberBox
                                                                            label="Yearly"
                                                                            labelMode="floating"
                                                                            maxLength={15}
                                                                            format="##.##"
                                                                            value={newRecord?.expectedCTC && newRecord?.expectedCTC?.toFixed(2)}
                                                                            onValueChange={(e) => {
                                                                                setNewRecord({ ...newRecord, expectedCTC: e })
                                                                                setExpectedCTCMonthlyValue((e * 100000) / 12)
                                                                            }}
                                                                            onKeyDown={onKeyDown_Withpoint}
                                                                            className="number-box-btn"
                                                                        >
                                                                            <NumberBoxButton
                                                                                location="after"
                                                                                name="expected_ctc_yearly"
                                                                                options={{
                                                                                    text: 'LPA',
                                                                                    stylingMode: 'text',
                                                                                    hoverStateEnabled: false,
                                                                                    focusStateEnabled: false,
                                                                                    activeStateEnabled: false,
                                                                                }}
                                                                            />
                                                                        </NumberBox>
                                                                    </div>
                                                                    <div className="col-auto px-0 pt-3 text-gray font-regular-12">OR</div>
                                                                    <div className="col">
                                                                        <NumberBox
                                                                            label="Monthly"
                                                                            labelMode="floating"
                                                                            value={expectedCTCMonthlyValue && expectedCTCMonthlyValue.toFixed(2)}
                                                                            maxLength={15}
                                                                            format="##.##"
                                                                            onValueChange={(e) => {
                                                                                setExpectedCTCMonthlyValue(e);
                                                                                setNewRecord({ ...newRecord, expectedCTC: ((e * 12) / 100000) })
                                                                            }}
                                                                            onKeyDown={onKeyDown_Withpoint}
                                                                            className="number-box-btn"
                                                                        >
                                                                            <NumberBoxButton
                                                                                location="after"
                                                                                name="expected_ctc_monthly"
                                                                                options={{
                                                                                    text: 'per month',
                                                                                    stylingMode: 'text',
                                                                                    hoverStateEnabled: false,
                                                                                    focusStateEnabled: false,
                                                                                    activeStateEnabled: false,
                                                                                }}
                                                                            />
                                                                        </NumberBox>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row pt-2">
                                                            <div className="col">
                                                                <TextBox
                                                                    label="Current Company Name"
                                                                    labelMode="floating"
                                                                    value={newRecord?.currentCompanyName}
                                                                    maxLength={100}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, currentCompanyName: e })
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <NumberBox
                                                                    label="Notice Period (Days)"
                                                                    labelMode="floating"
                                                                    value={
                                                                        newRecord?.noticePeriod &&
                                                                        parseInt(newRecord?.noticePeriod)
                                                                    }
                                                                    maxLength={3}
                                                                    max={255}
                                                                    inputAttr={{ maxLength: 3 }}
                                                                    step={0}
                                                                    onKeyDown={onKeyDown}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, noticePeriod: e })
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col py-2">
                                                                <CheckBox
                                                                    text="Ready To Relocate"
                                                                    value={newRecord?.readyToRelocate || false}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, readyToRelocate: e })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row pt-2">
                                                            <div className="col-xxl col-xl col-lg col-md ">
                                                                <TextBox
                                                                    label="Reason For Leaving"
                                                                    labelMode="floating"
                                                                    value={newRecord?.reasonForLeaving}
                                                                    maxLength={100}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, reasonForLeaving: e })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {/* End Other Information */}

                                                <div className="pt-2">
                                                    <div className="registration_CheckBox">
                                                        <CheckBox
                                                            text={'I agree to the Terms and Conditions'}
                                                            iconSize={15}
                                                            value={newRecord?.acceptTermsAndConditions || false}
                                                            onValueChange={(e) =>
                                                                setNewRecord({ ...newRecord, acceptTermsAndConditions: e })
                                                            }
                                                        >
                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                                <RequiredRule message='You must agree with the terms and conditions' />
                                                            </Validator>
                                                        </CheckBox>
                                                        <span className='text-danger'> *</span>
                                                    </div>
                                                </div>

                                                <div className='d-flex justify-content-between align-items-center mt-3'>
                                                    <div className="">
                                                        <Button
                                                            text="Submit"
                                                            type="default"
                                                            width={100}
                                                            className='rounded-2'
                                                            useSubmitBehavior={true}
                                                            validationGroup={ValidationGroupName}
                                                            disabled={!newRecord.acceptTermsAndConditions}
                                                        />
                                                    </div>
                                                    <div className="text-decoration-underline pointer-link base-accent-text" >
                                                        <Button
                                                            text="Clear form"
                                                            type="normal"
                                                            className='rounded-2'
                                                            useSubmitBehavior={false}
                                                            validationGroup={ValidationGroupName}
                                                            onClick={clearForm}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                    </ScrollView>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
