import {
  DateBox,
  LoadPanel,
  NumberBox,
  Popup,
  ScrollView,
  SelectBox,
  TagBox,
  TextBox,
  Validator,
} from "devextreme-react";
import React, { useEffect, useState } from "react";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import { useScreenSize } from "../../utils/media-query";
import PopupFooter from "../../layouts/popup-header-footer/PopupFooter";
import { BlankProfile, completedIcon, pendingIcon, selectedIcon, shortlistedIcon } from "../../utils/base-64-Icons";
import StarRating, { ShowAlert, eCRUDStatus, handleNavigateToMap } from "../../utils/common-methods";
import { CustomRule, EmailRule, RequiredRule } from "devextreme-react/validator";
import { CandidateInteractionScheduleServices } from "../../api/services/CandidateInteractionScheduleServices";
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import { CandidateInteractionServices } from "../../api/services/CandidateInteractionServices";
import EmailStatusPopup from "./EmailStatusPopup";
import { JobOpeningServices } from "../../api/services/JobOpeningServices";
import { JobOpeningControlPanelServices } from "../../api/services/JobOpeningControlPanelServices";
import FilePreviewer from "../candidate/FilePreviewer";

const candidateInteractionScheduleServices = new CandidateInteractionScheduleServices();
const candidateControlServices = new CandidateControlServices();
const candidateInteractionServices = new CandidateInteractionServices();
const jobOpeningServices = new JobOpeningServices();
const jobOpeningControlPanelServices = new JobOpeningControlPanelServices();

const SchedulePopup = (props) => {
  const { isExSmall, isXXSmall, isXSmall, isSmall, isMedium } = useScreenSize();

  const minDate = new Date();
  const [candidateData, setCandidateData] = useState();
  const [candidateInteractionData, setCandidateInteractionData] = useState();
  const [dsInteractionRound, setDsInteractionRound] = useState();
  const [groupedInteractionRoundsResult, setGroupedInteractionRoundsResult] = useState({});
  const [candidateScheduleID, setCandidateScheduleID] = useState();
  const [showEmailStatusPopup, setShowEmailStatusPopup] = useState(false);
  const [profileImg, setProfileImg] = useState({
    uploadedFileUrl: null,
    attachedFile: null,
  });
  const [showFilePreview, setShowFilePreview] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [showFilePreviewLoad, setShowFilePreviewLoad] = React.useState(false);


  const ValidationGroupName = "ScheduleValidationGroupName";

  const [newRecord, setNewRecord] = useState({
    interactionNo: null,
    interactionRound: null,
    interviewers: "",
    coInterviewers: null,
    scheduleDate: null,
    scheduleTime: null,
    meetingLink: null,
  });

  const onClosePopup = () => {
    props.setOpenPopup(false);
  };

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={[
            <span key={"header-title"} className="base-accent-text">
              {" "}
              New
            </span>,
            " Schedule",
          ]}
          onSubmit={InsertRecord}
        />
      </>
    );
  };

  useEffect(() => {
    GetModelRecord(props?.candidateId);
    GetInteractionModel(props?.candidateInteractionID);
    GetInteractionRoundLookupList();
  }, []);

  useEffect(() => {
    if (props.dsInteractionRoundsResults.length > 0) {
      const result = Object.groupBy(props.dsInteractionRoundsResults, ({ interactionRoundName }) => interactionRoundName);
      setGroupedInteractionRoundsResult(result);
    }
  }, [props.dsInteractionRoundsResults]);

  const GetModelRecord = async (Id) => {
    const result = await candidateControlServices.GetModelData(Id);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
      // setShowSkeleton(false);
    } else {
      const response = result.data;
      setCandidateData(response);
      // setNewRecord(response)
    }
  };

  const GetInteractionModel = async (Id) => {
    const result = await candidateInteractionServices.GetModelData(Id);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
      // setShowSkeleton(false);
    } else {
      const response = result.data;
      setCandidateInteractionData(response);
      // setNewRecord(response)
      setNewRecord((prevState) => ({
        ...prevState,
        interactionRound: response.interactionRoundID,
      }));
      const interviewers = response?.interviewers
        .filter((person) => person.interviewerTypeID === 0)
        .map((person) => person.interviewerEmailID);
      const coInterviewer = response?.interviewers
        .filter((person) => person.interviewerTypeID === 1)
        .map((person) => person.interviewerEmailID);
      setNewRecord((prevState) => ({
        ...prevState,
        interviewers: interviewers[0],
        coInterviewers: coInterviewer,
      }));
    }
  };

  const GetInteractionRoundLookupList = async () => {
    const result = await jobOpeningServices.GetJobOpeningInteractionRoundLookupList(props?.jobOpeningID);
    if (result.isOk) {
      setDsInteractionRound(result.data);
    }
  };

  function mapCoInterviewEmails(emailArray) {
    if (emailArray) {
      return emailArray?.map((email) => {
        return {
          interviewerEmailID: email,
          interviewerTypeID: 1,
        };
      });
    }
  }

  function convertToISOString(dateTimestamp, timeTimestamp) {
    // Extract timezone offset from time timestamp
    let offset = timeTimestamp.getTimezoneOffset() * 60000; // Offset in milliseconds
    // Adjust timeDt by timezone offset
    let timeDtUTC = new Date(timeTimestamp.getTime() - offset);
    // Combine date and time
    let combinedDt = new Date(
      Date.UTC(
        dateTimestamp.getFullYear(),
        dateTimestamp.getMonth(),
        dateTimestamp.getDate(),
        timeDtUTC.getUTCHours(),
        timeDtUTC.getUTCMinutes(),
        timeDtUTC.getUTCSeconds()
      )
    );
    // Format to ISO 8601
    let isoFormat = combinedDt.toISOString();
    return isoFormat;
  }

  const HideEmailStatusPopup = () => {
    onClosePopup();
    setShowEmailStatusPopup(!showEmailStatusPopup)
  }

  const InsertRecord = async () => {
    // update candidate interaction
    let coInterviewers = []
    if (newRecord?.coInterviewers) {
      coInterviewers = mapCoInterviewEmails(newRecord?.coInterviewers);
    }
    let dataToUpdate = {
      updatedOn: candidateInteractionData?.updatedOn,
      candidateInteractionID: props?.candidateInteractionID,
      interactionRoundID: newRecord?.interactionRound,
      interviewers: [
        {
          interviewerEmailID: newRecord.interviewers,
          interviewerTypeID: 0,
        },
        ...coInterviewers,
      ],
    };

    const response = await candidateInteractionServices.UpdateData(
      dataToUpdate
    );
    if (!response.isOk) {
      if (response.statusCode === 400) {
        ShowAlert(response.data, "Vakency");
      }
    } else {
      props.setStatus({
        eStatus: eCRUDStatus.Updated,
        primaryKeyID: props.candidateInteractionID,
      });
    }
    // insert new schedule
    const dataForSchedule = {
      candidateInteractionID: props.candidateInteractionID,
      scheduleDateTime: `${convertToISOString(
        newRecord.scheduleDate,
        newRecord.scheduleTime
      )}`,
      meetingLink: newRecord.meetingLink,
      descrPlainText: null,
      descrFormattedText: null,
    };
    const result = await candidateInteractionScheduleServices.InsertData(
      dataForSchedule
    );
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      }
    } else {

      const ScheduleID = result?.data?.responseData?.data
      setCandidateScheduleID(ScheduleID)
      setShowEmailStatusPopup(true);
      props.setStatusSchedule({
        eStatus: eCRUDStatus.Inserted,
      });
    }
  };

  const ValidateInterviewer = () => {
    if (newRecord?.interviewers && newRecord?.coInterviewers) {
      for (let j = 0; j < newRecord?.coInterviewers.length; j++) {
        if (newRecord?.interviewers == newRecord?.coInterviewers[j]) {
          return false;
        }
      }
    }
    return true;
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    InsertRecord();
  };

  useEffect(() => {
    if (props?.candidateId) {
      GetProfileImage().then((res) => {
        setProfileImg({
          uploadedFile: {
            fileData: res?.size > 0 ? window.URL.createObjectURL(res) : null,
          },
          attachedFile: res?.size > 0 ? res : null,
        });
      });
    }
  }, []);

  const GetProfileImage = async () => {
    let params = `?CandidateID=${props?.candidateId}`;
    const result = await candidateControlServices.GetImage(params);
    let response = result.data;
    if (result.isOk) {
      return result.data;
    } else {
      return;
    }
  };
  const onPhoneButtonClick = () => {
    window.location.href = `tel:${candidateData?.mobileNo1}`;
  }

  const itemRender = (data) => {
    const currentRounds = groupedInteractionRoundsResult[data?.interactionRoundName];
    let existingRound;
    let roundResult;
    if (currentRounds && currentRounds?.length > 0) {
      existingRound = currentRounds[currentRounds?.length - 1];
      roundResult = existingRound.interactionResultName;
    } else {
      roundResult = null;
    }
    return (
      <div className="row">
        <div className={`text-break text-wrap col-10`}>{data.interactionRoundName}</div>
        <div className="col-2 d-flex justify-content-end">{roundResult === "Pending" ? <img height={20} width={20} src={pendingIcon} alt="pending" title={"Pending"} /> : roundResult === null ? "" : <img height={20} width={20} src={completedIcon} title={"Completed"} alt="completed" />}</div>
      </div>
    )
  }

  const onPictureClick =(fileSrc)=>{
    if(fileSrc){
        setShowFilePreview(true);
        setSelectedFile({
            fileSrc: fileSrc,
        }); 
    }
   }
   
   const onFilePreviewClose = () => {
    setShowFilePreview(false)
  }

  return (
    <>
     <LoadPanel
             visible={showFilePreviewLoad}
            message='File is loading. We’ll display the preview shortly.'
          />
      <Popup
        visible={props.openPopup}
        titleRender={PopupTitle}
        width={
          isExSmall || isXXSmall || isXSmall
            ? "90%"
            : isSmall || isMedium
              ? "60%"
              : "40%"
        }
        height={"auto"}
        maxHeight={"90vh"}
        wrapperAttr={{ class: "CustomPopup CommonPopupClass" }}
      >
        <form onSubmit={handleSubmit}>
          <ScrollView>
            <div className="row pt-2">
              <div className="col-auto" >
                <div onClick={()=>onPictureClick(profileImg.uploadedFile?.fileData)}>
                {profileImg.uploadedFile?.fileData ? (
                  <img
                    src={profileImg.uploadedFile?.fileData}
                    alt="candidate"
                    width={100}
                    height={80}
                    className="ms-3"
                  />
                ) :
                  <img
                    src={BlankProfile}
                    alt="candidate"
                    width={100}
                    height={80}
                    className="ms-3"
                  />}
                  </div>
              </div>
              <div className="col ">
                <div>
                  <div className="row pt-1">
                    <div className="col-auto font-semiBold-20">
                      {candidateData?.candidateName}
                    </div>
                    <div className="col font-medium-18">
                      <StarRating rating={3} />
                    </div>
                    <div className="col d-flex justify-content-end">
                      <div className="pe-3 ">
                        <img
                          src={shortlistedIcon}
                          alt="status"
                          width={22}
                          title="Shortlisted"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="pt-1 font-medium-14">
                    {candidateData?.currentCompanyName && (
                      <span title="Current Company Name">
                        {candidateData?.currentCompanyName}
                      </span>
                    )}
                    {candidateData?.currentCompanyName && (
                      <span className="px-2 verticle-line-small">|</span>
                    )}
                    {candidateData?.currentCompanyName && (
                      <span title="Working Period">2022 - Present</span>
                    )}
                    {candidateData?.academicQualificationYear && (
                      <span className="px-2 verticle-line-small">|</span>
                    )}
                    {candidateData?.academicQualificationYear && (
                      <span title="Last Education - year">
                        B.Tech - {candidateData?.academicQualificationYear}
                      </span>
                    )}
                  </div>
                  <div className="pt-1 font-medium-14">
                    {candidateData?.currentAddress && (
                      <i className="dx-icon-locationIcon dx-custom-icon cursor-pointer" onClick={() => handleNavigateToMap(candidateData?.currentAddress)}></i>
                    )}
                    {candidateData?.currentAddress}
                    {candidateData?.currentAddress && (
                      <span className="px-2 verticle-line-small">|</span>
                    )}
                    <span onClick={onPhoneButtonClick} className="cursor-pointer">
                      <i className="dx-icon-MobileIcon dx-custom-icon"></i>
                      {candidateData?.mobileNo1}
                    </span>{" "}
                    <span className="px-2 verticle-line-small">|</span>
                    {candidateData?.linkedIn ? <a
                      href={`${candidateData?.linkedIn}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="dx-icon-LinkedInIcon dx-custom-icon mb-1"></i>
                    </a> : <i className="dx-icon-LinkedInDisabledIcon dx-custom-icon mb-1"></i>
                    }
                    <span className="px-2 verticle-line-small">|</span>
                    {candidateData?.skype ? <a
                      href={`skype:${candidateData?.skype}?chat`}
                      rel="noreferrer"
                    >
                      <i className="dx-icon-skypeIcon dx-custom-icon mb-1"></i>
                    </a> : <i className="dx-icon-skypeDisabledIcon dx-custom-icon mb-1"></i>
                    }
                    <span className="px-2 verticle-line-small">|</span>
                    {candidateData?.mobileNo2 ? <a
                      href={`https://wa.me/91${candidateData?.mobileNo2}`}
                      rel="noreferrer"
                      target='_blank'
                    >
                      <i className="dx-icon-WhatsappIcon dx-custom-icon mb-1"></i>
                    </a> : <i className="dx-icon-WhatsappDisabledIcon dx-custom-icon mb-1"></i>
                    }
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row px-3">
              <div className="col-md">
                <NumberBox
                  label="Interaction No."
                  readOnly={true}
                  labelMode="floating"
                  // value={newRecord.interactionNo}
                  value={candidateInteractionData?.candidateInteractionNo}
                // onValueChange={(e) =>
                //   setNewRecord({ ...newRecord, interactionNo: e })
                // }
                />
              </div>
              <div className="col-md pt-3 pt-md-0">
                <SelectBox
                  id="Schedulepopup_InteractionRound_DropDown"
                  label="Interaction Round"
                  labelMode="floating"
                  valueExpr={"interactionRoundID"}
                  displayExpr={"interactionRoundName"}
                  dataSource={dsInteractionRound}
                  value={newRecord.interactionRound}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, interactionRound: e })
                  }
                  dropDownOptions={{
                    height: 160,
                    hideOnParentScroll: true,
                    container: "#Schedulepopup_InteractionRound_DropDown"
                  }}
                  itemRender={itemRender}
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Interaction Round is required" />
                  </Validator>
                </SelectBox>
              </div>
            </div>
            <div className="pt-3 px-3">
              <TextBox
                id="Schedulepopup_Interviwers_DropDown"
                label="Interviewers Email ID"
                labelMode="floating"
                value={newRecord.interviewers}
                onValueChange={(e) =>
                  setNewRecord({ ...newRecord, interviewers: e })
                }
                showDropDownButton={true}
                dropDownOptions={{
                  height: 160,
                  hideOnParentScroll: true,
                  container: "#Schedulepopup_Interviwers_DropDown"
                }}
              >
                <Validator
                  validationGroup={ValidationGroupName}
                  elementAttr={{ class: "ForValidationAstrikMark" }}
                >
                  <RequiredRule message="Interviewers are required" />
                  <EmailRule message="Enter a valid Email ID" />
                </Validator>
              </TextBox>
              </div>
              <div className="pt-3 px-3">
              <TagBox
                id="Schedulepopup_Interviwers_DropDown"
                label="Co-Interviewers Email ID"
                labelMode="floating"
                value={newRecord.coInterviewers}
                onValueChange={(e) =>
                  setNewRecord((prevState) => ({
                    ...prevState,
                    coInterviewers: e,
                  }))
                }
                showDropDownButton={false}
                openOnFieldClick={false}
                acceptCustomValue={true}
              >
                <Validator
                  validationGroup={ValidationGroupName}
                // elementAttr={{ class: "ForValidationAstrikMark" }}
                >
                  {/* <RequiredRule message="Interviewers are required" /> */}
                  <EmailRule message="Enter a valid Email ID" />
                  <CustomRule
                    message="Interviewer and Co-Interviewer should not be same."
                    validationCallback={ValidateInterviewer}
                  />
                </Validator>
              </TagBox>
            </div>
            <hr className="mx-3" />
            <div className="row px-3">
              <div className="col-auto pt-3 editor-label">Schedule:</div>
              <div className="col">
                <DateBox
                  id="Schedulepopup_Date_DropDown"
                  label="Date"
                  labelMode="floating"
                  value={newRecord.scheduleDate}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, scheduleDate: e })
                  }
                  dateOutOfRangeMessage="Invalid Date"
                  type="date"
                  useMaskBehavior={true}
                  min={minDate}
                  dropDownOptions={
                    {
                      // hideOnParentScroll: true,
                      // container: "#Schedulepopup_Date_DropDown"
                    }
                  }
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Date is required" />
                  </Validator>
                </DateBox>
              </div>
              <div className="col">
                <DateBox
                  id="Schedulepopup_time_DropDown"
                  label="Time"
                  labelMode="floating"
                  type="time"
                  value={newRecord.scheduleTime}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, scheduleTime: e })
                  }
                  dateOutOfRangeMessage="Invalid Date"
                  useMaskBehavior={true}
                  // min={minDate}
                  dropDownOptions={{
                    height: 300,
                    // hideOnParentScroll: true,
                    // container: "#Schedulepopup_time_DropDown"
                  }}
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Time is required" />
                  </Validator>
                </DateBox>
              </div>
            </div>
            <div className="px-3 pt-3">
              <TextBox
                label="Meeting Link"
                labelMode="floating"
                value={newRecord.meetingLink}
                onValueChange={(e) =>
                  setNewRecord({ ...newRecord, meetingLink: e })
                }
              >
                <Validator
                  validationGroup={ValidationGroupName}
                  elementAttr={{ class: "ForValidationAstrikMark" }}
                >
                  <RequiredRule message="Meeting Link is required" />
                </Validator>
              </TextBox>
            </div>
            <PopupFooter ValidationGroupName={ValidationGroupName} />
          </ScrollView>
        </form>
      </Popup>
      {showEmailStatusPopup && <EmailStatusPopup showEmailStatusPopup={showEmailStatusPopup} HideEmailStatusPopup={HideEmailStatusPopup} candidateData={candidateData} candidateScheduleID={candidateScheduleID} />}
      {
            showFilePreview && (
                <FilePreviewer
                    showFilePreview={showFilePreview}
                    selectedFile={selectedFile}
                    setShowFilePreviewLoad={setShowFilePreviewLoad}
                    onFilePreviewClose={onFilePreviewClose}
                    isImageOnly={true}
                />
            )
    }
    </>
  );
};

export default SchedulePopup;
