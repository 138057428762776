import React, { useRef, useState } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import UserPanel from "../user-panel/UserPanel";
import "./Header.scss";
import { Template } from "devextreme-react/core/template";
import { DateBox, DropDownButton, SelectBox } from "devextreme-react";
import { useScreenSize } from "../../utils/media-query";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { camelCase, minDate } from "../../utils/common-methods";
import "../../utils/base-64-icons.scss";
import UploadNewResume from "../../pages/upload-new-resume/UploadNewResume";
import { formatDate } from "devextreme/localization";

const dropDownOptions = {
  width: "85px",
};

export default function Header(props) {
  const { isSmall, isXSmall, isXXSmall, isExSmall } = useScreenSize();
  const [showAddManaullyResume, setShowAddManaullyResume] = useState(false);
  const [userImage, setUserImage] = useState();

  const DataGridRef = props.dataGridRef;
  const HeaderToolbarRef = useRef(null);

  const QuickUploadResumeClick = (e) => {
    setShowAddManaullyResume(true)
  }

  const dropdownItems = [
    { id: 1, name: "Excel", icon: "exportxlsx" },
    { id: 2, name: "CSV", icon: "xlsfile" },
  ];

  const onItemClick = (e) => {
    var fileName = camelCase(props.title);
    if (props.exportFileName !== undefined && props.exportFileName !== null) {
      fileName = camelCase(props.exportFileName);
    }

    if (e.itemData.id === 1) {
      var workbook = new Workbook();
      var worksheet = workbook.addWorksheet("SheetName");
      const gridInstance = DataGridRef.current?.instance;
      const columns = [
        'jobOpeningNo', 'jobProfileName', 'openings', 'requiredExperience',
        'jobOpeningDate','targetDate', 'maxSalary', 'hiringManagerName', 'public',
        'applications', 'shortlisted', 'underReview', 'hiredCount', 'onBoarding',
        'rejected', 'dropOut'
      ];
      if(props.isJobOpeningList){
        gridInstance.beginUpdate();
        gridInstance.option('showColumnHeaders', true); 
        columns.forEach(col => gridInstance.columnOption(col, 'visible', true))
      }
      exportDataGrid({
        component: DataGridRef.current?.instance,
        worksheet: worksheet,
        customizeCell: ({ gridCell, excelCell }) => {
          if (props.isJobOpeningList && gridCell.rowType === 'data') {
            if (gridCell.column.dataField  === "jobOpeningNo") {
              excelCell.value = `#${gridCell.data.jobOpeningNo}`;
            }
            if (gridCell.column.dataField  === "requiredExperience") {
              excelCell.value = gridCell.data.requiredExperience ? `${gridCell.data.requiredExperience} ${gridCell.data.requiredExperience <= 1 ? "yr" : "yrs"}` : null;
            }
            if (gridCell.column.dataField  === "maxSalary") {
              excelCell.value = gridCell.data.maxSalary ? `${gridCell.data.maxSalary?.toFixed(2)} CTC` : null;
            }
          }
          }
      }).then(function () {
        if(props.isJobOpeningList){
          
          gridInstance.option('showColumnHeaders', false); 
          columns.forEach(col => gridInstance.columnOption(col, 'visible', false));
          gridInstance.endUpdate();

          // start to Add Custom Header Row In Sheet //
          const sheet = workbook.getWorksheet('SheetName');
          sheet.insertRow(1, [`Total Applications : ${props.jobOpeningHeaderData.applications}`, `Total Profile : ${props.jobOpeningHeaderData.profile}`, `Total Openings : ${props.jobOpeningHeaderData.openings}` , `Total Shortlisted : ${props.jobOpeningHeaderData.shortlisted}` ,  `Total Hired : ${props.jobOpeningHeaderData.hired}` , `Total On Boarding : ${props.jobOpeningHeaderData.onBoarding}` , `Total Rejected : ${props.jobOpeningHeaderData.rejected}` , `Total DropOut : ${props.jobOpeningHeaderData.dropOut}` ]);
          sheet.insertRow(2, []);
          // End Add Custom Header Row In Sheet //

          worksheet.getRow(1).font = { bold: true };
        }

        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName + ".xlsx"
          );
        });
      });
    } else if (e.itemData.id === 2) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Report");
      const gridInstance = DataGridRef.current?.instance;
      const columns = [
        'jobOpeningNo', 'jobProfileName', 'openings', 'requiredExperience',
        'jobOpeningDate','targetDate', 'maxSalary', 'hiringManagerName', 'public',
        'applications', 'shortlisted', 'underReview', 'hiredCount', 'onBoarding',
        'rejected', 'dropOut'
      ];
      if(props.isJobOpeningList){
        gridInstance.beginUpdate();
        gridInstance.option('showColumnHeaders', true); 
        columns.forEach(col => gridInstance.columnOption(col, 'visible', true))
      }
      exportDataGrid({
        component: DataGridRef.current?.instance,
        worksheet: worksheet,
        customizeCell: ({ gridCell, excelCell }) => {
          if (props.isJobOpeningList && gridCell.rowType === 'data') {
            if (gridCell.column.dataField  === "jobOpeningNo") {
              excelCell.value = `#${gridCell.data.jobOpeningNo}`;
            }
            if (gridCell.column.dataField  === "requiredExperience") {
              excelCell.value = gridCell.data.requiredExperience ? `${gridCell.data.requiredExperience} ${gridCell.data.requiredExperience <= 1 ? "yr" : "yrs"}` : null;
            }
            if (gridCell.column.dataField  === "maxSalary") {
              excelCell.value = gridCell.data.maxSalary ? `${gridCell.data.maxSalary?.toFixed(2)} CTC` : null;
            }
            if (gridCell.column.dataField === "jobOpeningDate") {
              if (gridCell.data.jobOpeningDate) {
                const date = new Date(gridCell.data.jobOpeningDate);
                const formattedDate = formatDate(date, 'MM/dd/yyyy');
                excelCell.value = formattedDate;
              }
            }
            if (gridCell.column.dataField === "targetDate") {
              if (gridCell.data.targetDate) {
                const date = new Date(gridCell.data.targetDate);
                const formattedDate = formatDate(date, 'MM/dd/yyyy');
                excelCell.value = formattedDate;
              }
            }
        
          }
          }
      }).then(function () {
        if(props.isJobOpeningList){
          
          gridInstance.option('showColumnHeaders', false); 
          columns.forEach(col => gridInstance.columnOption(col, 'visible', false));
          gridInstance.endUpdate();

          // start to Add Custom Header Row In Sheet //
          const sheet = workbook.getWorksheet('Report');
          sheet.insertRow(1, [`Total Applications : ${props.jobOpeningHeaderData.applications}`, `Total Profile : ${props.jobOpeningHeaderData.profile}`, `Total Openings : ${props.jobOpeningHeaderData.openings}` , `Total Shortlisted : ${props.jobOpeningHeaderData.shortlisted}` ,  `Total Hired : ${props.jobOpeningHeaderData.hired}` , `Total On Boarding : ${props.jobOpeningHeaderData.onBoarding}` , `Total Rejected : ${props.jobOpeningHeaderData.rejected}` , `Total DropOut : ${props.jobOpeningHeaderData.dropOut}` ]);
          sheet.insertRow(2, []);
          // End Add Custom Header Row In Sheet //
        }

        workbook.csv.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName + ".csv"
          );
        });
      });
    }
  };

  const OnOpeningDateValueChange = (e) => {
    const selectedDate = new Date(e);
    if (selectedDate < minDate) {
      const minDate1901 = new Date('1901-01-01');
      props.setDate(minDate1901.toISOString().split('T')[0]);
    } else {
      props.setDate(e);
    }
  }

  const onAddButtonClick = () => {
    props.handleAdd();
  };

  const JobOpeningPublicRedirectClick = () => {
    props.handlePublicJobOpening();
  }

  const JobApplicationPublicRedirectClick = () => {
    props.handlePublicJobApplication();
  }

  const onRefreshButtonClick = async () => {
    props.GetRecord && await props.GetRecord();
    HeaderToolbarRef.current?.instance.repaint();
  };

  const onResetButtonClick = () => {
    DataGridRef.current?.instance.state(null);
    DataGridRef.current?.instance.clearFilter();
    HeaderToolbarRef.current?.instance.repaint();
    props.showDateFilter && props.setFilterOpen(true);
    props.showDateFilter && props.setFilterPublic("All");
    props.showDateFilter && props.setDate(null);
  }

  return (
    <header className={"header-component"} id="Comman-Header">
      <Toolbar className={"header-toolbar"} ref={HeaderToolbarRef}>
        <Item
          location={"before"}
          cssClass={"header-title ps-2"}
          visible={!!props.title}
        >
          <span className={(isExSmall || isXXSmall || isXSmall || isSmall) ? "ps-4 ms-3" : "ps-2"}>{props.title}</span>
        </Item>
        {!props.hideDefaultButtons && (
          <Item location={"before"}>
            <span className="vertical-line px-1">|</span>
          </Item>
        )}
        {!props.hideDefaultButtons && (
          <Item location="before" locateInMenu="auto">
            <Button
              icon="plus"
              className="rounded-5 default-button"
              id="Add"
              onClick={onAddButtonClick}
              stylingMode="outlined"
              type="default"
              hint="Add"
            ></Button>
          </Item>
        )}
        {props.showJobOpeningPublicRedirect && (
          <Item location={"before"}>
            <span className="vertical-line px-1">|</span>
          </Item>
        )}
        {props.showJobOpeningPublicRedirect && (
          <Item location="before" locateInMenu="auto">
            <Button
              icon="link"
              className="rounded-5 default-button"
              id="Redirect "
              onClick={JobOpeningPublicRedirectClick}
              stylingMode="outlined"
              type="default"
              hint="Click here to see your public job openings"
            ></Button>
          </Item>
        )}
        {props.showJobApplicationPublicRedirect && (
          <Item location={"before"}>
            <span className="vertical-line px-1">|</span>
          </Item>
        )}
        {props.showJobApplicationPublicRedirect && (
          <Item location="before" locateInMenu="auto">
            <Button
              icon="link"
              className="rounded-5 default-button"
              id="Redirect "
              onClick={JobApplicationPublicRedirectClick}
              stylingMode="outlined"
              type="default"
              hint="Click here to apply on job"
            ></Button>
          </Item>
        )}
        {props.submitEditor && (
          <Item location={"before"}>
            <span className="vertical-line px-1">|</span>
          </Item>
        )}
        {props.submitEditor && (
          <Item location="before" locateInMenu="auto">
            <Button
              icon="save"
              className="rounded-5 default-button"
              stylingMode="outlined"
              type="default"
              hint="Save"
              useSubmitBehavior={true}
              validationGroup={props.validationGroupName}
            ></Button>
          </Item>
        )}
        {props.StaticDropdownOne && props.StaticDropdownTwo &&
          <Item location='after' locateInMenu='auto'>
            <DateBox
              label='Opening Date'
              labelMode='floating'
              className='mt-0 me-1'
              width={175}
              type="date"
              dateOutOfRangeMessage="Invalid Date"
              showClearButton={true}
              useMaskBehavior={true}
              value={props.date}
              onValueChange={OnOpeningDateValueChange}
              min={minDate}
              dropDownOptions={{
                // hideOnParentScroll:false,
                // container:"#Comman-Header"
              }}
            />
          </Item>
        }
        {props.StaticDropdownOne && props.StaticDropdownTwo &&
          <Item location='after' locateInMenu='auto'>
            <SelectBox
              id="StaticDropDownOne_DropDownFilter"
              dataSource={props.StaticDropdownOne}
              className=' mx-1'
              width={120}
              displayExpr="name"
              valueExpr="value"
              value={props.filterOpen}
              onValueChange={(e) => props.setFilterOpen(e)}
              dropDownOptions={{
                hideOnParentScroll: false,
                container: `${isSmall|| isXSmall|| isXXSmall||isExSmall?"":"#StaticDropDownOne_DropDownFilter"}`
              }}
            />
          </Item>
        }

        {props.StaticDropdownOne && props.StaticDropdownTwo &&
          <Item location='after' locateInMenu='auto'>
            <SelectBox
              dataSource={props.StaticDropdownTwo}
              className=' ms-1 me-1'
              id="StaticDropDownTwo_DropDownFilter"
              width={120}
              displayExpr="name"
              valueExpr="value"
              defaultValue={null}
              value={props.filterPublic}
              onValueChange={(e) => props.setFilterPublic(e)}
              dropDownOptions={{
                hideOnParentScroll: false,
                container: `${isSmall|| isXSmall|| isXXSmall||isExSmall?"":"#StaticDropDownTwo_DropDownFilter"}`
              }}
            />
          </Item>
        }

        {props.StaticDropdownOne && props.StaticDropdownTwo &&
          <Item location={"after"}>
            <span className="vertical-line d-none d-md-block">|</span>
          </Item>
        }
        <Item location="after" locateInMenu="auto">
        {!props.hideDefaultButtons && (
            <Button
              icon="refresh"
              className="mx-1 rounded-5 default-button"
              id="refresh"
              onClick={onRefreshButtonClick}
              stylingMode="outlined"
              type="default"
              hint="Refresh"
            ></Button>
        )}
        {!props.hideDefaultButtons && (
            <DropDownButton
              displayExpr={"name"}
              items={dropdownItems}
              id={"export"}
              icon={"export"}
              hint="Export"
              dropDownOptions={dropDownOptions}
              stylingMode="outlined"
              type="default"
              className={"mx-1 rounded-5 export-dropdown-btn"}
              elementAttr={{ role: "button" }}
              showArrowIcon={false}
              onItemClick={onItemClick}
            ></DropDownButton>
        )}
        {!props.hideDefaultButtons && (
            <Button
              icon="pulldown"
              className="mx-1 rounded-5  ResetButton"
              id="reset"
              onClick={onResetButtonClick}
              stylingMode="outlined"
              type="default"
              hint="Reset"
            ></Button>
        )}
        </Item>
        {
          props.showUploadNewResume &&
          <Item location={"after"} locateInMenu="auto">
            <Button
              text="Upload New Resume"
              type="default"
              hint="Upload New Resume"
              icon="upload"
              onClick={QuickUploadResumeClick}
              className="rounded-2 mx-1"
            />
          </Item>
        }
        {
          props.onBackButtonClick &&
          <Item location={"after"} locateInMenu="auto">
            <Button
              text="Back"
              type="default"
              hint="Back to list page"
              icon="back"
              stylingMode="text"
              onClick={props.onBackButtonClick}
              hoverStateEnabled={false}
              activeStateEnabled={false}
              focusStateEnabled={false}
              className="editor-back-btn"
              width={65}
            />
          </Item>
        }
        {
          !isExSmall && !isXSmall && !isXXSmall &&
          <Item location={"after"}>
            <span className="vertical-line">|</span>
          </Item>
        }

        <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"userPanelTemplate"}
        >
          <Button
            className={"user-button authorization"}
            height={"100%"}
            stylingMode={"text"}
          >
            <UserPanel menuMode={"context"} userImage={userImage} setUserImage={setUserImage} setDropDownClick={props.setDropDownClick}/>
          </Button>
        </Item>
        <Template name={"userPanelTemplate"}>
          <UserPanel menuMode={"list"} userImage={userImage} setUserImage={setUserImage} setDropDownClick={props.setDropDownClick}/>
        </Template>
      </Toolbar>

      <UploadNewResume
        openPopup={showAddManaullyResume}
        setOpenPopup={setShowAddManaullyResume}
      />
    </header>
  );
}
