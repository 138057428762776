import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import './single-card.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, LoadIndicator } from 'devextreme-react';


export default function SingleCard({ title, subtitle, showLoader, children }) {

  const navigate = useNavigate();

  const location = useLocation();

  return (
    <ScrollView height={'100%'} width={'100%'} className={'with-footer single-card '}>
      <div className=' dx-card p-4 shadow bg-white rounded'>
        <div className='form-card'>
          {
            !showLoader &&
                <div className={'header px-md-1'}>
                  {location.pathname === "/sign-up" || location.pathname === "/login" ?
                    (<div className='mb-1'>
                      <Button
                        text='Sign In'
                        className={`${location.pathname === "/sign-up" ? "Signup_button_active" : "Signup_button"} rounded-5 position-absolute z-1 border-0`}
                        onClick={() => navigate("/login")}
                      />
                      <Button
                        text='Sign Up'
                        className={`${location.pathname === "/login" ? "Signin_button_active" : "Signin_button"} rounded-5 position-relative z-0  border-0`}
                        onClick={() => navigate("/sign-up")}
                      />
                    </div>) : (
                      <>
                        <div className={title && 'title text-center pb-2'}>{title}</div>
                        <div className={subtitle && 'subtitle pt-1 text-center w-70'}>{subtitle}</div>
                      </>
                    )
                  }
                </div>
          }
          {children}
        </div>
      </div>
    </ScrollView>
  )
}
