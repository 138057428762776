import Validator, { PatternRule, RequiredRule } from 'devextreme-react/validator';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import React, { useEffect, useState } from 'react';
import openeye from '../../images/openeye.png'
import closeeyeIcon from '../../images/closeeyeIcon .png'
import { Button, LoadIndicator } from 'devextreme-react';
import { CompareRule } from 'devextreme-react/data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword, validateResetPasswordCode } from '../../api/auth';
import './ResetPasswordForm.scss';
import { PasswordRegex, onDragOver } from '../../utils/common-methods';

export default function ResetPasswordForm(props) {

  let { ResetPasswordCode } = useParams();

  const [PasswordMode, setPasswordMode] = useState("password")
  const [showPasswordIcon, setShowpasswordIcon] = useState(openeye)
  const [confirmPasswordMode, setConfirmPasswordMode] = useState("password")
  const [showConfirmPasswordIcon, setShowConfirmpasswordIcon] = useState(openeye)
  const [formData, setFormData] = useState({
    resetPasswordCode: ResetPasswordCode,
    password: null,
    confirmPassword: null,
  });

  const ValidationGroupName = "resetPasswordValidation"

  const navigate = useNavigate();

  const validateCode = async () => {
    props.setShowLoader(true);
    const result = await validateResetPasswordCode(ResetPasswordCode);
    if (!result.isOk) {
      props.setShowLoader(false);
      navigate("/error-page")
    }
    else {
      props.setShowLoader(false);
    }
  }
  useEffect(() => {
    validateCode();
  }, [])

  const PasswordOptions = {
    icon: showPasswordIcon,
    stylingMode: "text",
    hoverStateEnabled: false,
    activeStateEnabled: false,
    focusStateEnabled: false,
    onClick: () => {
      setPasswordMode(PasswordMode === 'text' ? 'password' : 'text');
      setShowpasswordIcon(showPasswordIcon === openeye ? closeeyeIcon : openeye)
    }
  };

  const ConfirmPasswordOptions = {
    icon: showConfirmPasswordIcon,
    stylingMode: "text",
    hoverStateEnabled: false,
    activeStateEnabled: false,
    focusStateEnabled: false,
    onClick: () => {
      setConfirmPasswordMode(confirmPasswordMode === 'text' ? 'password' : 'text');
      setShowConfirmpasswordIcon(showConfirmPasswordIcon === openeye ? closeeyeIcon : openeye)
    }
  };

  const handleSubmit = async (e) => {
    props.setShowLoader(true);
    e.preventDefault();
    const result = await resetPassword(formData);
    if (result.isOk) {
      props.setShowLoader(false);
      navigate("/successful-set-password-page")
    }
    else{
      props.setShowLoader(false);
    }
  }

  const passwordComparison = () => {
    return formData.password
  }

  return (
    <>
      {props.showLoader ?
        <div className='m-auto text-center loaderCart-height'>
          <LoadIndicator className='indicator' id="small-indicator" height={50} width={50} visible={true} />
          <div className='pt-1'>Please Wait.....</div>
        </div>
        :
        <form onSubmit={handleSubmit}  onDragOver={onDragOver} >
          <div className='row px-3 py-2'>
            <TextBox
              label='New Password'
              mode={PasswordMode}
              labelMode='floating'
              stylingMode='underlined'
              className='py-1 px-0'
              maxLength={16}
              value={formData.password}
              onValueChange={(value) => setFormData({ ...formData, password: value })}
            >
              <TextBoxButton
                name="password"
                location="after"
                options={PasswordOptions}
              />
              <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                <RequiredRule message='New Password is required' />
                <PatternRule message="Password should contain minimum 8 & maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character" pattern={PasswordRegex} />
              </Validator>
            </TextBox>
          </div>
          <div className='row px-3 py-2 '>
            <TextBox
              label='Confirm Password'
              mode={confirmPasswordMode}
              labelMode='floating'
              stylingMode='underlined'
              className='py-1 px-0'
              maxLength={16}
              value={formData.confirmPassword}
              onValueChange={(value) => setFormData({ ...formData, confirmPassword: value })}
            >
              <TextBoxButton
                name="confirmPassword"
                location="after"
                options={ConfirmPasswordOptions}
              />
              <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                <RequiredRule message='Confirm Password is required' />
                <PatternRule message="Password should contain minimum 8 & maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character" pattern={PasswordRegex} />
                {formData.password && <CompareRule
                  message="Password and Confirm Password does not match"
                  comparisonTarget={passwordComparison}
                />
                }
              </Validator>
            </TextBox>
          </div>
          <div className='row pt-3 pb-2 mt-2 justify-content-center'>
            <Button
              width="auto"
              text='Reset Password'
              useSubmitBehavior={true}
              hoverStateEnabled={false}
              activeStateEnabled={false}
              focusStateEnabled={false}
              type='default'
              validationGroup={ValidationGroupName}
            >
            </Button>
          </div>
        </form>
      }
    </>
  )
}
