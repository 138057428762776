import React, { useEffect, useRef, useState } from 'react';
import {SelectBox ,  Button as TextBoxButton } from 'devextreme-react/select-box';
import { Validator } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/validator';
import UserPopup from './UserPopup';
import { ShowAlert, eCRUDStatus } from '../../../utils/common-methods';
import { UsersServices } from '../../../api/services/UsersServices';

const usersServices = new UsersServices();

export default function UserDropDown(props) {

  const [openPopup , setOpenPopup] = useState(false);
  const [userList, setUserList] = useState([])
  let [primaryKey, setPrimaryKey] = useState(0);
  const [userStatus, setUserStatus] = useState({
    popupStatus: eCRUDStatus.None,
    primaryKey: primaryKey
  })

  const SelectBoxRef = useRef(null)
  
  useEffect(()=>{
    openPopup === false && props.setDropDownClick && props.setDropDownClick(false);
  },[openPopup])

  useEffect(() => {
    userLookUpList();
  }, [])


  const userLookUpList = async () => {
    const result = await usersServices.GetLookupListData();

    const response = result.data;
    setUserList(response);
  }

  useEffect(() => {
    if (userStatus.popupStatus !== eCRUDStatus.None) {
      const PrimaryKeyID = userStatus.popupStatus === eCRUDStatus.Inserted ? userStatus.primaryKey : primaryKey;
      UpdateListByID(PrimaryKeyID);
    }
  }, [userStatus])

  const UpdateListByID = async (primaryKey) => {
    const result = await usersServices.GetListByIdData(primaryKey);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency")
    } else {
      if (userStatus.popupStatus === eCRUDStatus.Inserted) {
        setUserList([result.data[0], ...userList]);
      } else if (userStatus.popupStatus === eCRUDStatus.Updated) {
        setUserList(userList.map((i) => i.userID === primaryKey ? result.data[0] : i));
      }
    }
    props.setAddedUserID &&  props.setAddedUserID(primaryKey);
  };
 
  const AddButton = {
    icon:'plus',
    stylingMode: 'text',
    onClick: ()=> {
      props.setDropDownClick && props.setDropDownClick(true);
      setOpenPopup(!openPopup)
    }
  }

  const DownArrow = {
    icon:'spindown',
    stylingMode: 'text',
    onClick: (e) => {

      var selectBoxInstance = SelectBoxRef.current?.instance;
      var isOpened = selectBoxInstance.option("opened");
      if (isOpened) {
        selectBoxInstance.close();
      } else {
        selectBoxInstance.open();
        selectBoxInstance.focus();
      }
    }
  }

  return (
    <div>
    <SelectBox
      label={props.label ? props.label : 'User'}
      searchEnabled={true}
      id="User_dropDown"
      dataSource={userList}
      ref={SelectBoxRef}
      labelMode='floating'
      value={props.value}
      onValueChange={props.onValueChange}
      displayExpr="fullName"
      valueExpr="userID"
      dropDownOptions={{
        height: props.dropdownHeight ? props.dropdownHeight : 120,
        hideOnParentScroll:true,
        container:"#User_dropDown"
     }}
    >
       <TextBoxButton
              name="add"
              location="after"
              options={AddButton}
            />
        <TextBoxButton
              name="dropdown"
              location="after"
              options={DownArrow}
            />
              {props.validationRequired && (
          <Validator validationGroup={props.validationGroup} elementAttr={{ class: 'ForValidationAstrikMark' }}>
            <RequiredRule message={props.validationMessage ? props.validationMessage : "User is required"} />
          </Validator>
        )}
    </SelectBox>
    {openPopup === true && <UserPopup openPopup={openPopup} setOpenPopup={setOpenPopup} setUserStatus={setUserStatus} primaryKey={primaryKey}/>}
  </div>
  )
}
