const baseURL = process.env.REACT_APP_BASEURL;

const GetAuthToken = () => {
    return localStorage.getItem("authToken");
}

const authHeader = () => {
    // return authorization & content-type
    const getAuthToken = GetAuthToken();
    if (getAuthToken) {
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getAuthToken}`
        };
    } else {
        return {};
    }
};

const authHeaderWithoutAuthorization = () => {
    // return content-type
    return {
        'Content-Type': 'application/json',
    };
};

const authHeaderWithoutContentType = () => {
    // return authorization
    const getAuthToken = GetAuthToken();
    if (getAuthToken) {
        return {
            Authorization: `Bearer ${getAuthToken}`
        };
    } else {
        return {};
    }
}

const fetchFileAsBlob = async (fileUrl) => {
    try {
        const response = await fetch(fileUrl);
        if (response.ok) {
            const data = await response.blob();
            return {
                isOk: true,
                data,
                statusCode: response.status,
            };
        } else {
            return {
                isOk: false,
                message: `Error: ${response.statusText}`,
                statusCode: response.status
            };
        }
    } catch (error) {
        return {
            isOk: false,
            message: "Failed to fetch the file",
            error
        };
    }
};

export async function GetAPI(url) {

    let data = null;
    try {
        const getAPIOption = {
            method: 'GET',
            headers: authHeader()
        }

        data = await fetch(baseURL + url, getAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: await data.json(),
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

export async function GetCandidateDocAPI(url, params) {

    let data = null;

    try {
        const getAPIOption = {
            method: 'GET',
            headers: authHeader()
        }
        data = await fetch(baseURL + url + params, getAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: data.blob(),
                statusCode: data.status,
                fileExtension: data.headers.get('content-type')
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

export async function GetUserImageAPI(url) {
    let data = null;
    try {
        const getAPIOption = {
            method: 'GET',
            headers: authHeader()
        }

        data = await fetch(baseURL + url, getAPIOption)
        if (data.status && data.status === 200) {
            const fileUrl = await data.text();
            return fetchFileAsBlob(fileUrl);
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}




export async function Insert_Image(url, dataObj) {
    let data = null;
    try {
        const InsertAPIOption = {
            method: 'POST',
            headers: authHeaderWithoutContentType()
            ,
            body: dataObj
        }
        data = await fetch(baseURL + url, InsertAPIOption)
        if (data.status && data.status === 200) {
            return {
                isOk: data.ok,
                data: await data,
                statusCode: data.status
            };
        } else {
            return {
                isOk: data.ok,
                data: await data.text(),
                stautsCode: data.status && data.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            message: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: data.status
        };
    }
}

export async function InsertDocAPI(url, dataObj) {

    let data = null;
    try {

        const InsertAPIOption = {
            method: 'POST',
            headers: authHeaderWithoutContentType()
            ,
            body: dataObj
        }

        data = await fetch(baseURL + url, InsertAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: data.ok,
                data: await data,
                statusCode: data.status
            };
        } else {
            return {
                isOk: data.ok,
                data: await data.text(),
                stautsCode: data.status && data.status
            };
        }
    }

    catch (err) {
        return {
            isOk: false,
            message: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: data.status
        };
    }
}

export async function GetLookupListByIDAPI(url, id) {

    let data = null;
    try {
        const getAPIOption = {
            method: 'GET',
            headers: authHeader()
        }

        data = await fetch(baseURL + url + id, getAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: await data.json(),
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

export async function GetListByIdAPI(url, id) {

    let data = null;
    try {
        const getAPIOption = {
            method: 'GET',
            headers: authHeader()
        }

        data = await fetch(baseURL + url + id, getAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: await data.json(),
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

export async function GetListByIdAPI_Without_Id(url) {

    let data = null;
    try {
        const getAPIOption = {
            method: 'GET',
            headers: authHeader()
        }

        data = await fetch(baseURL + url, getAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: await data.json(),
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

export async function InsertAPI(url, dataObj) {

    let data = null;
    try {

        const InsertAPIOption = {
            method: 'POST',
            headers: authHeader()
            ,
            body: JSON.stringify(dataObj)
        }

        data = await fetch(baseURL + url, InsertAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: data.ok,
                data: await data.json(),
                statusCode: data.status
            };
        } else {
            return {
                isOk: data.ok,
                data: await data.text(),
                statusCode: data.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            message: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: data.status
        };
    }
}

export async function UpdateAPI(url, dataObj) {

    let data = null;
    try {
        const UpdateAPIOption = {
            method: 'PUT',
            headers: authHeader()
            ,
            body: JSON.stringify(dataObj)
        }

        data = await fetch(baseURL + url, UpdateAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: data.ok,
                data: await data.json(),
                statusCode: data.status
            };
        } else {
            return {
                isOk: data.ok,
                data: await data.text(),
                statusCode: data.status
            };
        }
    }

    catch (err) {
        return {
            isOk: false,
            message: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: data.status
        };
    }
}
export async function RejectApplication(url, dataObj) {

    let data = null;
    try {
        const UpdateAPIOption = {
            method: 'PUT',
            headers: authHeader()
            ,
            body: JSON.stringify(dataObj)
        }

        data = await fetch(baseURL + url, UpdateAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: data.ok,
                data: await data.json(),
                statusCode: data.status
            };
        } else {
            return {
                isOk: data.ok,
                data: await data.text(),
                statusCode: data.status
            };
        }
    }

    catch (err) {
        return {
            isOk: false,
            message: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: data.status
        };
    }
}

export async function MoveToShortlist(url, id) {

    let data = null;
    try {
        const MoveAPIOption = {
            method: 'PUT',
            headers: authHeader()
        }
        // data = await fetch(baseURL + url + id, MoveAPIOption)
        data = await fetch(baseURL + url, MoveAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: data,
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

export async function MoveToPending(url, id) {

    let data = null;
    try {
        const MoveAPIOption = {
            method: 'PUT',
            headers: authHeader()
        }
        data = await fetch(baseURL + url, MoveAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: data,
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}


export async function DeleteAPI(url, id) {

    let data = null;
    try {
        const DeleteAPIOption = {
            method: 'DELETE',
            headers: authHeader()
        }
        data = await fetch(baseURL + url + id, DeleteAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: data,
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}



export async function GetModelAPI(url, id) {

    let data = null;
    try {
        const getModalOption = {
            method: 'GET',
            headers: authHeader()
        }
        data = await fetch(baseURL + url + id, getModalOption)


        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: await data.json(),
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }

    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

export async function GetModelAPI_WithoutId(url) {

    let data = null;
    try {
        const getModalOption = {
            method: 'GET',
            headers: authHeader()
        }
        data = await fetch(baseURL + url, getModalOption)


        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: await data.json(),
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }

    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

export async function Insert_WithNo_Auth(url, dataObj) {

    let data = null;
    try {

        const InsertAPIOption = {
            method: 'POST',
            headers: authHeaderWithoutAuthorization()
            ,
            body: JSON.stringify(dataObj)
        }

        data = await fetch(baseURL + url, InsertAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: data.ok,
                data: await data.json(),
                statusCode: data.status
            };
        } else {
            return {
                isOk: data.ok,
                data: await data.text(),
                statusCode: data.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            message: "Failed To Load Data : " + JSON.stringify(err),
            statusCode: data.status
        };
    }
}

export async function Update_WithNo_Auth(url, dataObj) {

    let data = null;
    try {
        const UpdateAPIOption = {
            method: 'PUT',
            headers: authHeaderWithoutAuthorization()
            ,
            body: JSON.stringify(dataObj)
        }

        data = await fetch(baseURL + url, UpdateAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: data.ok,
                data: await data.json(),
                statusCode: data.status
            };
        } else {
            return {
                isOk: data.ok,
                data: await data.text(),
                statusCode: data.status
            };
        }
    }

    catch (err) {
        return {
            isOk: false,
            message: "Failed To Load Data : " + JSON.stringify(err),
            statusCode: data.status
        };
    }
}

export async function Get_WithNo_Auth(url) {

    let data = null;
    try {
        const getAPIOption = {
            method: 'GET',
            headers: authHeaderWithoutAuthorization()
        }

        data = await fetch(baseURL + url, getAPIOption)


        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: await data.json(),
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

export async function CheckDuplicate(url) {

    let data = null;
    try {
        const getAPIOption = {
            method: 'GET',
            headers: authHeader()
        }

        data = await fetch(baseURL + url, getAPIOption)
        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: data,
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}


export async function DeleteUserImageAPI(url) {

    let data = null;
    try {
        const DeleteAPIOption = {
            method: 'DELETE',
            headers: authHeader()
        }
        data = await fetch(baseURL + url, DeleteAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: data,
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

export async function InsertUserImage(url, dataObj) {
    let data = null;
    try {
        const InsertAPIOption = {
            method: 'POST',
            headers: authHeaderWithoutContentType()
            ,
            body: dataObj
        }
        data = await fetch(baseURL + url, InsertAPIOption)
        if (data.status && data.status === 200) {
            return {
                isOk: data.ok,
                data: await data,
                statusCode: data.status
            };
        } else {
            return {
                isOk: data.ok,
                data: await data.text(),
                stautsCode: data.status && data.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            message: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: data.status
        };
    }
}


export async function DeleteCandidateDocDataAPI(url) {

    let data = null;
    try {
        const DeleteAPIOption = {
            method: 'DELETE',
            headers: authHeader()
        }
        data = await fetch(baseURL + url, DeleteAPIOption)

        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: data,
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}