import React, { useEffect, useRef, useState } from 'react';
import { SelectBox, Button as TextBoxButton } from 'devextreme-react/select-box';
import CandidateDropDownPopup from './CandidateDropdownPopup';
import { Validator } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/validator';
import { ShowAlert, eCRUDStatus } from '../../utils/common-methods';
import { CandidateControlServices } from '../../api/services/CandidateControlServices';

const candidateControlServices = new CandidateControlServices();
export default function CandidateDropDown(props) {


  const [openPopup, setOpenPopup] = useState(false);
  const [CandidateList, setCandidateList] = useState([]);
  let [primaryKey, setPrimaryKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });

  const SelectBoxRef = useRef(null);

  useEffect(() => {
    openPopup === false && props.setDropDownClick && props.setDropDownClick(false);
  }, [openPopup])

  useEffect(() => {
    CandidateLookUpList();
  }, [])

  const CandidateLookUpList = async () => {
    const result = await candidateControlServices.GetLookupListData()
    const response = result.data;
    setCandidateList(response);
  }


  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID = status.eStatus === eCRUDStatus.Inserted ? status.primaryKeyID : primaryKey;
      UpdateListByID(PrimaryKeyID);
    }
  }, [status])

  const UpdateListByID = async (primaryKey) => {
    const result = await candidateControlServices.GetListByIdData(primaryKey);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency")
    } else {
      if (status.eStatus === eCRUDStatus.Inserted) {
        setCandidateList([result.data[0], ...CandidateList]);
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setCandidateList(CandidateList.map((i) => i.candidateID === primaryKey ? result.data[0] : i));
      }
    }
    props.setAddedCandidateID && props.setAddedCandidateID(primaryKey);
  };


  const AddButton = {
    icon: 'plus',
    hint: 'Add Candidate',
    stylingMode: 'text',
    onClick: () => {
      setOpenPopup(!openPopup)
    }
  }

  const DownArrow = {
    icon: 'spindown',
    stylingMode: 'text',
    onClick: (e) => {

      var selectBoxInstance = SelectBoxRef.current?.instance;
      var isOpened = selectBoxInstance.option("opened");
      if (isOpened) {
        selectBoxInstance.close();
      } else {
        selectBoxInstance.open();
        selectBoxInstance.focus();
      }
    }
  }

  return (
    <div>
      <SelectBox
        labelMode='floating'
        label="Candidate"
        id='Candidate_DropDown'
        searchEnabled={true}
        ref={SelectBoxRef}
        dataSource={CandidateList}
        displayExpr="candidateName"
        valueExpr="candidateID"
        value={props.value}
        readOnly={props.readOnly && props.readOnly}
        onValueChange={props.onValueChange}
        dropDownOptions={{
          height: props.dropdownHeight ? props.dropdownHeight : 120,
          hideOnParentScroll:true,
          container:"#Candidate_DropDown"
       }}
      >
        <TextBoxButton
          name="candidate"
          location="after"
          options={AddButton}
        />
        <TextBoxButton
          name="dropdown"
          location="after"
          options={DownArrow}
        />
        {props.validationRequired && (
          <Validator validationGroup={props.validationGroup} elementAttr={{ class: 'ForValidationAstrikMark' }}>
            <RequiredRule message={props.validationMessage ? props.validationMessage : "Candidate is required"} />
          </Validator>
        )}
      </SelectBox>
      {openPopup === true &&
        <CandidateDropDownPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          ValidationGroupName={props.validationGroup}
          setStatus={setStatus}
          primaryKey={primaryKey}
        />}
    </div>
  )
}
