import { VakencyApiServices } from "./VakencyApiServices";
import { CandidateInteractionSchedule } from "../../contexts/APIurl";
import { fetchWrapper } from "./authHeader";

const url = CandidateInteractionSchedule;
const baseURL = process.env.REACT_APP_BASEURL;

export class CandidateInteractionScheduleServices extends VakencyApiServices {
  constructor() {
    super(url);
  }

 SendEmailToInterviewer =  async(scheduleID)=>{
  const InsertUrl = url + `/SendToInterviewer?ScheduleID=${scheduleID}`
  let data = null;
  try {
      data = await fetchWrapper.postWithParams(baseURL + InsertUrl)
      if (data.status && data.status === 200) {
        return {
            isOk: true,
            data: await data?.text(),
            statusCode: data.status,
        };
    } else {
        return {
            isOk: false,
            data: await data?.text(),
            statusCode: data.status,
        };
    }
  }
  catch (err) {
      return {
          isOk: false,
          message: "Failed To Load  Data : " + JSON.stringify(err),
          statusCode: data.status
      };
  }
}
 SendEmailToCandidate=  async(scheduleID)=>{
  const InsertUrl = url + `/SendToCandidate?ScheduleID=${scheduleID}`
  let data = null;
  try {
      data = await fetchWrapper.postWithParams(baseURL + InsertUrl)
      if (data.status && data.status === 200) {
        return {
            isOk: true,
            data: await data?.text(),
            statusCode: data.status,
        };
    } else {
        return {
            isOk: false,
            data: await data?.text(),
            statusCode: data.status,
        };
    }
  }
  catch (err) {
      return {
          isOk: false,
          message: "Failed To Load  Data : " + JSON.stringify(err),
          statusCode: data.status
      };
  }
}

}
