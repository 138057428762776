import React from 'react'

const PopupSkeletonButton = () => {
  return (
    <>
    <div className="">
      <span className="custom-skeleton-button "></span>
    </div>
  </>
  )
}

export default PopupSkeletonButton
