import React from 'react';
import { Link } from 'react-router-dom';
import './ErrorPage.scss';

export default function ErrorPage() {
    return (
        <div className='row Error-page'>
            <div className='text-center mb-2'><i className='dx-icon-warning warning-icon'></i></div>
            <div className='text-center my-2 fw-semibold message'>Reset password link is expired or invalid.</div>
            <div className='text-center mb-2'><Link to="/forgot-password">Forgot password</Link></div>
        </div>
    )
}
