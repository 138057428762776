import React, { useEffect, useRef, useState } from "react";
import {
  SelectBox,
  Button as TextBoxButton,
} from "devextreme-react/select-box";
import { Validator } from "devextreme-react";
import { RequiredRule } from "devextreme-react/validator";
import CityPopup from "./CityPopup";
import { ShowAlert, eCRUDStatus } from "../../../utils/common-methods";
import { CityServices } from "../../../api/services/CityServices";

const cityServices = new CityServices();

export default function CityDropDown(props) {
  const [openPopup, setOpenPopup] = useState(false);
  const [cityList, setCityList] = useState([]);
  let [primaryKey, setPrimaryKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });

  const SelectBoxRef = useRef(null);

  useEffect(() => {
    openPopup === false &&
      props.setDropDownClick &&
      props.setDropDownClick(false);
  }, [openPopup]);

  useEffect(() => {
    if (props.cityStateID !== 0) {
      CityLookUpList(props.cityStateID);
    }
  }, [props.cityStateID]);

  const CityLookUpList = async (cityStateID) => {
    let result;
    if (cityStateID !== undefined && cityStateID !== null) {
      result = await cityServices.GetLookupListByIDData(props.cityStateID);
      const response = result.data;
      setCityList(response);
    }
  };

  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID =
        status.eStatus === eCRUDStatus.Inserted
          ? status.primaryKeyID
          : primaryKey;
      UpdateListByID(PrimaryKeyID);
    }
  }, [status]);

  const UpdateListByID = async (primaryKey) => {
    const result = await cityServices.GetListByIdData(primaryKey);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency");
    } else {
      if (status.eStatus === eCRUDStatus.Inserted) {
        setCityList([result.data[0], ...cityList]);
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setCityList(
          cityList.map((i) => (i.cityID === primaryKey ? result.data[0] : i))
        );
      }
    }
    props.setAddedCityID && props.setAddedCityID(primaryKey);
  };

  const AddButton = {
    icon: "plus",
    stylingMode: "text",
    onClick: () => {
      props.setDropDownClick && props.setDropDownClick(true);
      setOpenPopup(!openPopup);
    },
  };

  const DownArrow = {
    icon: "spindown",
    stylingMode: "text",
    onClick: (e) => {
      var selectBoxInstance = SelectBoxRef.current?.instance;
      var isOpened = selectBoxInstance.option("opened");
      if (isOpened) {
        selectBoxInstance.close();
      } else {
        selectBoxInstance.open();
        selectBoxInstance.focus();
      }
    },
  };

  return (
    <div>
      <SelectBox
        label="City"
        id="City_DropDown"
        searchEnabled={true}
        ref={SelectBoxRef}
        labelMode="floating"
        dataSource={cityList}
        displayExpr="cityName"
        valueExpr="cityID"
        value={props.value}
        onValueChange={props.onValueChange}
        dropDownOptions={{
          height: props.dropdownHeight ? props.dropdownHeight : 120,
          hideOnParentScroll: true,
          container: "#City_DropDown"
        }}
        inputAttr={{
          autocomplete: "new",
        }}
        mode="tel"
      >
        <TextBoxButton name="City" location="after" options={AddButton} />
        <TextBoxButton name="dropdown" location="after" options={DownArrow} />
        {props.validationRequired && (
          <Validator
            validationGroup={props.validationGroup}
            elementAttr={{ class: "ForValidationAstrikMark" }}
          >
            <RequiredRule
              message={
                props.validationMessage
                  ? props.validationMessage
                  : "City is required"
              }
            />
          </Validator>
        )}
      </SelectBox>
      {openPopup === true && (
        <CityPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          ValidationGroupName={props.validationGroup}
          setStatus={setStatus}
          primaryKey={primaryKey}
        />
      )}
    </div>
  );
}
