import React from 'react'
import "./Container.scss"
import { ScrollView } from 'devextreme-react'
import { useScreenSize } from '../../utils/media-query';
import VakencyTransparentLogo from '../../images/VakencyTransparentLogo.png';
import ContainerLogo from '../../images/container.png'

export default function Container({children}) {

    const { isXSmall, isXXSmall, isExSmall} = useScreenSize();

    return (
         <ScrollView height={'100%'} width={'100%'} className={'with-footer container'} >
            <div className='row main-container p-4  rounded m-auto d-flex justify-content-center shadow-sm'>
            { (isXSmall || isXXSmall || isExSmall) && 
                <div className='mb-3 text-center'>
                <img src={VakencyTransparentLogo} alt='Logo' width={150}/>
                </div>
             }
            { (!isXSmall && !isXXSmall && !isExSmall) && <div className='col-md me-3'>
                    <div className='row my-2  Logo-image2'><img src={VakencyTransparentLogo} className='px-0' alt='Logo'/></div>
                    <div className='row mt-5 fs-4 fw-semibold heading'>Simple Hiring For Smart Businesses</div>
                    <div className='row mt-2 mb-4 sub-heading'><p className='px-0'>Experience the power of Vakency with our unified cloud software, It's designed to help your hiring process with easy steps for increased organizational efficiency</p></div>
                    <div className='row mt-3 mb-0'><img className="design_image" src={ContainerLogo} alt='sign up' /></div>
                </div> }
                <div className='col-md-auto'>
                       {children}
                </div>
            </div>
     </ScrollView>
    )
}
