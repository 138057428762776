
export const navigation = [
  {
    text: 'Home',
    path: '/Home',
    icon: 'HomeLightIcon'
  },
  {
    text: 'Job Openings',
    path: '/JobOpening/List',
    icon: 'JobOpeningLightIcon'
  },
  {
    text: 'Candidates',
    path: '/Candidate/List',
    icon: 'CandidateLightIcon'
  },
  // {
  //   text: 'Job Applications',
  //   path: '/JobApplication/List',
  //   icon: 'JobApplicationLightIcon'
  // },
  // {
  //   text: 'Interactions',
  //   path: '/Interaction/List',
  //   icon: 'IntractionLightIcon'
  // },
  // {
  //   text: 'Schedules',
  //   path: '/Schedule/List',
  //   icon: 'ScheduleLightIcon'
  // },
  {
    text: 'Upload New Resume',
    path: '/UploadNewResume',
    icon: 'uploadResumeIcon'
  },
  {
    text: 'Application Settings',
    icon: 'AppSettingIcon',
    items:[
      {
        text: 'Cities',
        path: '/Cities/List',
        icon: 'BulletIcon'
      },
      {
        text: 'States',
        path: '/States/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Countries',
        path: '/Country/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Users',
        path: '/User/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Job Profiles',
        path: '/JobProfile/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Academic Qualifications',
        path: '/AcademicQualification/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Candidate Sources',
        path: '/CandidateSource/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Skills',
        path: '/Skill/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Interaction Rounds',
        path: '/InteractionRound/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Attitude Factors',
        path: '/AttitudeFactor/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Company Profile',
        path: '/CompanyProfile',
        icon: 'BulletIcon'
      },
    ]
  }
  
  ];
