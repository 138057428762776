import React, { useEffect, useState } from "react";
import StarRating, { GetFormattedDate, handleNavigateToMap , ShowAlert } from "../../utils/common-methods";
import { BlankProfile } from "../../utils/base-64-Icons";
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import { Button, LoadPanel, Popover } from "devextreme-react";
import { useScreenSize } from "../../utils/media-query";
import FilePreviewer from "../candidate/FilePreviewer";

const candidateControlServices = new CandidateControlServices();

const CandidateCard = ({
  candidateId,
  candidateRating,
  candidateApplicationStatus,
  jobProfileName,
  PopupType,
  lastRoundScheduleDate,
  dsInteractionRounds,
  lastInteractionRoundName,
}) => {
  const [profileImg, setProfileImg] = useState({
    uploadedFileUrl: null,
    attachedFile: null,
  });
  const [resume, setResume] = useState(null);
  const [candidateData, setCandidateData] = useState();
  const [showFilePreview, setShowFilePreview] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [showFilePreviewLoad, setShowFilePreviewLoad] = React.useState(false);

  const { isLarge, isExLarge } = useScreenSize();

  const GetProfileImage = async () => {
    let params = `?CandidateID=${candidateId}`;
    const result = await candidateControlServices.GetImage(params);
    if (result.isOk) {
      return result.data;
    } else {
      return;
    }
  };

  const GetResume = async () => {
    setResume();
    let params = `?CandidateID=${candidateId}`;
    const result = await candidateControlServices.GetCandidateDocData(params);
    if (result.isOk) {
      setResume(result.data);
    }
  };

  const GetCandidateModelData = async () => {
    if (candidateId) {
      const result = await candidateControlServices.GetModelData(candidateId);
      if (result.isOk) {
        const CandidateData = result.data;
        setCandidateData(CandidateData);
      } else {
        ShowAlert(result.data, "Vakency");
      }
    }
  };

  useEffect(() => {
    if (candidateId) {
      GetCandidateModelData();
      GetProfileImage().then((res) => {
        setProfileImg({
          uploadedFile: {
            fileData: res?.size > 0 ? window.URL.createObjectURL(res) : null,
          },
          attachedFile: res?.size > 0 ? res : null,
        });
      });
      GetResume();
    }
  }, [candidateId]);

  function openResume() {
    const file = new Blob([resume], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');
    // const link = document.createElement("a");
    // link.href = fileURL;
    // link.target = '_blank';
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    // window.URL.revokeObjectURL(fileURL);
  }

  const onEmailButtonClick = () => {
    window.location.href = `mailto:${candidateData?.emailID}`;
  }
  const onPhoneButtonClick = () => {
    window.location.href = `tel:${candidateData?.mobileNo1}`;
  }

  const onPictureClick =(fileSrc)=>{
    if(fileSrc){
        setShowFilePreview(true);
        setSelectedFile({
            fileSrc: fileSrc,
        }); 
    }
}
   
const onFilePreviewClose = () => {
    setShowFilePreview(false)
}


  return (
    <>
      <LoadPanel
             visible={showFilePreviewLoad}
            message='File is loading. We’ll display the preview shortly.'
          />
    <div className="col">
      <div className="col shadow-sm assessment-card">
        <div className="">
          <div className="row pt-3 px-2">
            <div className="col-auto">
              <div onClick={()=>onPictureClick(profileImg.uploadedFile?.fileData)}>
              {profileImg.uploadedFile?.fileData ? (
                <img
                  src={profileImg.uploadedFile?.fileData}
                  width={100}
                  height={80}
                  alt="candidate"
                  className=""
                />
              ) : (
                <img
                  src={BlankProfile}
                  width={100}
                  height={80}
                  alt="candidate"
                  className=""
                />
              )}
              </div>
              {resume ? (
                <div className='font-medium-14 text-decoration-underline text-center cursor-pointer' onClick={openResume}>
                  View Resume
                </div>
              ) : null}
            </div>

            <div className="col ps-4">
              <div className="font-medium-22 d-flex">
                <div className="">
                  {PopupType === "InitiateOffer" && (
                    "Initiate Offer To "
                  )}
                  {PopupType === "ReviseOffer" && (
                    "Revise Offer To"
                  )}
                  {PopupType === "RejectApplication" && (
                    "Reject Application of"
                  )}
                  {PopupType === "CandidateCancelled" && (
                    "Cancelled By"
                  )}
                  {PopupType === "CancelledByCandidate" && (
                    "Cancelled By"
                  )}
                  {PopupType === "CandidateNotResponding" && (
                    "Cancelled By"
                  )}
                  {PopupType === "CandidateDidNotAttend" && (
                    "Cancelled By"
                  )}
                  {PopupType === "RevokeOffer" && (
                    "Revoke Offer of"
                  )}
                  {PopupType === "OfferRejectedByCandidate" && (
                    "Offer Rejected by"
                  )}
                  {PopupType === "InteractionCancelledByUs" && (
                    `Cancelled ${lastInteractionRoundName} of`
                  )}
                  {PopupType === "Finalize Hiring" && (
                    "Hire"
                  )}
                  {PopupType === "onBoarding" && (
                    "Confirm on-boarding of"
                  )}{" "}
                  <span className="font-bold-20 text-decoration-underline">{candidateData?.candidateName}</span>{"  "}
                  for{"  "}
                  <span className="font-bold-20 text-decoration-underline">{jobProfileName}</span>
                </div>
                <div className="font-medium-20 ps-3">
                  <StarRating rating={candidateRating} />
                </div>
              </div>
              <div className="row py-1">
                {
                  dsInteractionRounds.length > 0 && (
                    <div
                      className={`col-sm-auto d-flex col-12 ${isLarge && isExLarge
                        ? "me-1"
                        : ""
                        }`}
                    >
                      <div className='d-flex flex-column gap-0'>
                        <div className='text-start font-semiBold-14'>Hiring Progress Tracker</div>
                        <div className='d-flex'>
                          {dsInteractionRounds.map((interaction, index) => (
                            <div className='d-flex align-items-center' key={interaction.interactionRoundID}>
                              <div
                                id={`link-card-${index}`}
                                className={`d-flex justify-content-center align-items-center ${interaction.interactionResultName === 'Pending' ? 'candidate_progress_circle_in_progress' : interaction.interactionResultName != null ? 'candidate_progress_circle_completed' : 'candidate_progress_circle_pending'
                                  }`}
                              />
                              <Popover
                                target={`#link-card-${index}`}
                                showEvent="mouseenter"
                                hideEvent="mouseleave"
                                position="bottom"
                                width={'auto'}
                              >
                                <div className='font-medium-14'>
                                  <span className='italic-text'>Round Name:</span>{" "}
                                  {interaction.interactionRoundName ? interaction.interactionRoundName : '-'}
                                </div>
                                <div className='font-medium-14'>
                                  <span className='italic-text'>Status:</span>{" "}
                                  {interaction.interactionResultName ? interaction.interactionResultName : '-'}
                                </div>
                                <div className='font-medium-14'>
                                  <span className='italic-text'>Date of Interaction:</span>{" "}
                                  {interaction.scheduleDateTime ? GetFormattedDate(interaction.scheduleDateTime) : '-'}
                                </div>
                              </Popover>
                              {index < dsInteractionRounds.length - 1 && (
                                <div
                                  className={interaction.interactionResultName === 'Pending' ? 'candidate_progress_line_pending' : interaction.interactionResultName != null ? 'candidate_progress_line_completed' : 'candidate_progress_line_pending'}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
              <div className="row font-regular-14">
                <div className="col-auto">
                  <div className="col-auto">
                    <Button
                      icon="email"
                      hint="Email"
                      stylingMode="text"
                      className="resume-edit-btn"
                      onClick={onEmailButtonClick}
                    />{" "}
                    {candidateData?.emailID ? candidateData?.emailID : ""}
                  </div>
                </div>
                {candidateData?.emailID && <div className="col-auto px-0 assessment-pipe">|</div>}
                <div className="col-auto">
                  <span onClick={onPhoneButtonClick} className="cursor-pointer">
                    <i className="dx-icon-MobileIcon dx-custom-icon"></i>{" "}
                    {candidateData?.mobileNo1}
                  </span>
                </div>
                {candidateData?.currentAddress && <div className="col-auto px-0 assessment-pipe">|</div>}
                {candidateData?.currentAddress &&
                  <>
                    <div className="col-auto ">
                      <i className="dx-icon-locationIcon dx-custom-icon mb-1 cursor-pointer" onClick={()=>handleNavigateToMap(candidateData?.currentAddress)}></i>{" "}
                      {candidateData?.currentAddress}
                    </div>
                  </>
                }
                {candidateData?.permanentAddress && <div className="col-auto px-0 assessment-pipe">|</div>}
                {candidateData?.permanentAddress &&
                  <>
                    <div className="col-auto ">
                      <i className="dx-icon-homeTownIcon dx-custom-icon mb-1 me-1 cursor-pointer"  onClick={()=>handleNavigateToMap(candidateData?.permanentAddress)}></i>{" "}
                      {candidateData?.permanentAddress}
                    </div>
                  </>
                }
                <div className="col-auto ">
                  {candidateData?.linkedIn ? <a
                    href={`${candidateData?.linkedIn}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="dx-icon-LinkedInIcon dx-custom-icon"></i>
                  </a> : <i className="dx-icon-LinkedInDisabledIcon dx-custom-icon"></i>
                  }
                </div>
                <div className="col-auto px-0 assessment-pipe">|</div>
                <div className="col-auto ">
                  {candidateData?.skype ? <a
                    href={`skype:${candidateData?.skype}?chat`}
                    rel="noreferrer"
                  >
                    <i className="dx-icon-skypeIcon dx-custom-icon"></i>
                  </a> : <i className="dx-icon-skypeDisabledIcon dx-custom-icon"></i>
                  }
                </div>
                <div className="col-auto px-0 assessment-pipe">|</div>
                <div className="col-auto">
                  {candidateData?.mobileNo2 ? <a
                    href={`https://wa.me/91${candidateData?.mobileNo2}`}
                    rel="noreferrer"
                    target='_blank'
                  >
                    <i className="dx-icon-WhatsappIcon dx-custom-icon"></i>
                  </a> : <i className="dx-icon-WhatsappDisabledIcon dx-custom-icon"></i>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="mx-1 mt-3 px-2">
            <div className="row">
              <span className="font-semiBold-14">Application Status :   <span className="font-bold-14">{candidateApplicationStatus}</span></span>
            </div>
            <div className="row">
              <span className="font-semiBold-14">{lastRoundScheduleDate ? "Last Interaction Date: " :  "" }
              <span className="font-bold-14">
                {lastRoundScheduleDate ? new Date(lastRoundScheduleDate).toLocaleDateString() : ""}{" "}
              </span>
              </span>
            </div>
          </div>
          <hr className="mb-1" />
        </div>
      </div>
    </div>
    {
            showFilePreview && (
                <FilePreviewer
                    showFilePreview={showFilePreview}
                    selectedFile={selectedFile}
                    setShowFilePreviewLoad={setShowFilePreviewLoad}
                    onFilePreviewClose={onFilePreviewClose}
                    isImageOnly={true}
                />
            )
    }
    </>

  );
};

export default CandidateCard;
