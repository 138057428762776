import {
  Button,
  CheckBox,
  NumberBox,
  Popup,
  ScrollView,
  SelectBox,
  TabPanel,
  TextArea,
  Validator
} from "devextreme-react";
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import React, { useEffect, useRef, useState } from "react";
import "./CompanyDetailWizard.scss";
import { Item } from "devextreme-react/tab-panel";
import { CompareRule, RequiredRule, StringLengthRule, PatternRule } from "devextreme-react/validator";
import { useScreenSize } from "../../utils/media-query";
import openeye from '../../images/openeye.png';
import closeeyeIcon from '../../images/closeeyeIcon .png'
import { ResWizard, userIcon, Vakency01Icon } from '../../utils/base-64-Icons';
import { PasswordRegex, ShowAlert, onKeyDown } from "../../utils/common-methods";
import { createAccount, validateOTP } from "../../api/auth";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import LoadingGIF from '../../images/loading.gif';


const CompanyDetailWizard = () => {

  let { email, otp } = useParams();
  const { signIn } = useAuth();

  const { isMedium, isSmall, isXSmall, isXXSmall, isLarge, isExSmall, isExLarge } = useScreenSize();
  const onShown = () => FocusedFeild.current?.instance.focus();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const FocusedFeild = useRef(null);
  const ValidationGroupName = "CompanyDetailValidationGroup";
  const ValidationGroupName2 = "CompanyDetailValidationGroup2";
  const ValidationGroupName3 = "CompanyDetailValidationGroup3";
  const navigate = useNavigate();

  const CompanySizeDropDown = [
    {
      Id: 0,
      Value: "Only me"
    },
    {
      Id: 1,
      Value: "2 - 9"
    },
    {
      Id: 2,
      Value: "10 - 19"
    },
    {
      Id: 3,
      Value: "20 - 49"
    },
    {
      Id: 4,
      Value: "50 - 99 "
    },
    {
      Id: 5,
      Value: "100 +"
    }
  ]

  const [companyRecord, setCompanyRecord] = useState({
    oTP: otp,
    firstName: undefined,
    lastName: undefined,
    emailID: email,
    password: undefined,
    confirmPassword: undefined,
    phoneNo: null,
    companyName: undefined,
    businessCategory: null,
    companySize: undefined,
    mobileNo: undefined,
    address: null,
    countryName: null,
    stateName: null,
    cityName: null,
  });

  const [PasswordMode, setPasswordMode] = useState("password")
  const [showPasswordIcon, setShowpasswordIcon] = useState(openeye)
  const [confirmPasswordMode, setConfirmPasswordMode] = useState("password")
  const [showConfirmPasswordIcon, setShowConfirmpasswordIcon] = useState(openeye)
  const [companyPopup, setCompanyPopup] = useState(false);
  const [loading, setLoading] = useState(false);


  const validateotp = async () => {
    setLoading(true);
    setTimeout(async () => {
      const result = await validateOTP(email, otp);
      if (!result.isOk) {
        setLoading(false)
        navigate("/otp-invalid-page",
          {
            state: { message: result.data }
          }
        );
      }
      else {
        setLoading(false)
        setCompanyPopup(true);
      }
    }, 3000);
  }


  useEffect(() => {
    setCompanyPopup(true);
    validateotp();
  }, []);


  const PasswordOptions = {
    icon: showPasswordIcon,
    stylingMode: "text",
    hoverStateEnabled: false,
    activeStateEnabled: false,
    focusStateEnabled: false,
    onClick: () => {
      setPasswordMode(PasswordMode === 'text' ? 'password' : 'text');
      setShowpasswordIcon(showPasswordIcon === openeye ? closeeyeIcon : openeye)
    }
  };

  const ConfirmPasswordOptions = {
    icon: showConfirmPasswordIcon,
    stylingMode: "text",
    hoverStateEnabled: false,
    activeStateEnabled: false,
    focusStateEnabled: false,
    onClick: () => {
      setConfirmPasswordMode(confirmPasswordMode === 'text' ? 'password' : 'text');
      setShowConfirmpasswordIcon(showConfirmPasswordIcon === openeye ? closeeyeIcon : openeye)
    }
  };

  const onSubmitCompanyDetail = async (e) => {
    setLoading(true)
    e.preventDefault();
    const result = await createAccount(companyRecord);
    if (result.statusCode === 200) {
      const result = await signIn({ emailid: companyRecord.emailID, password: companyRecord.password });
      if (!result.isOk) {
        if (result.statusCode === 400) {
          setLoading(false);
          ShowAlert(result.data, "Vakency");
          setTimeout(() => {
            navigate('/sign-up')
          }, 2000)
        }
        setCompanyPopup(false)
        setLoading(false)
      }
    }
    else if (result.statusCode === 400) {
      setLoading(false)
      ShowAlert(result.data, "Vakency");
    }
  };

  const passwordComparison = () => {
    return companyRecord.password
  }

  const CheckValidations = (e) => {
    const res = e.validationGroup.validate();
    if (res.isValid) {
      setSelectedTabIndex(1)
    }
  }

  const CheckValidationsTwo = (e) => {
    const res = e.validationGroup.validate();
    if (res.isValid) {
      setSelectedTabIndex(2)
    }
  }


  return (
    <>
      {loading ?
        <div className='validatingPage'>
          <div className='main-container rounded'>
            <div className='Loading-cart'>
              <LoadIndicator indicatorSrc={LoadingGIF} height={80} width={80} />
              <div className='text-center font-regular-18 validating-text py-3 '>Validating......</div>
            </div>
          </div>
        </div>
        :
        <Popup
          visible={companyPopup}
          showTitle={false}
          width={isMedium ? "90%" : isExLarge ? "45%" : isLarge ? "65%" : "94%"}
          height={"auto"}
          maxHeight={"99vh"}
          className="p-0"
          wrapperAttr={{ class: "wizardPopup" }}
          animation={{
            show: {
              type: "fadeIn",
            },
          }}
          shading={true}
          onShown={onShown}
        >
          <ScrollView height={"100%"} width={"100%"}>
            <div className="row px-0 mx-0">
              <div className={`col-md-auto align-items-center col-12 ${isSmall || isXSmall || isXXSmall || isExSmall ? "company-wizard-left-section-sm" : "company-wizard-left-section"} `} >
                <div className="px-2">
                  <div className="font-semiBold-24 text-center text-white py-4">
                    Registration in to Vakency
                    <div className="justify-content-center pt-4 d-flex">
                      <div className={selectedTabIndex === (1) || selectedTabIndex === (2) ? "progress_line_background2 progress_line position-absolute z-1 me-5" : "progress_line_background progress_line position-absolute z-1 me-5"} style={{ height: "3px", width: "88px" }}></div>
                      <div className={selectedTabIndex === (2) ? "progress_line_background2 progress_line position-absolute z-2 ms-5" : "progress_line_background progress_line position-absolute z-0 ms-5"} style={{ height: "3px", width: "90px" }}></div>
                      <div className="position-relative z-3 pe-4">
                        <div
                          className={selectedTabIndex === (0) ? "rounded-5 text-white border-2 circle_button2" : "rounded-5 text-white border-2 circle_button3"}
                        > <div className="number_button d-flex align-items-center justify-content-center">1</div>
                        </div>
                      </div>
                      <div className="position-relative z-3 px-4">
                        <div
                          className={selectedTabIndex === (1) ? "rounded-5 text-white border-2 circle_button2" : selectedTabIndex === (2) ? "rounded-5 text-white border-2 circle_button3" : "rounded-5 text-white border-2 circle_button"}
                        > <div className="number_button d-flex align-items-center justify-content-center">2</div>
                        </div>
                      </div>
                      <div className="position-relative z-3 ps-4">
                        <div
                          className={selectedTabIndex === (2) ? "rounded-5 text-white border-2 circle_button2" : "rounded-5  text-white border-2 circle_button"}
                        ><div className="number_button d-flex align-items-center justify-content-center">3</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img
                        src={ResWizard}
                        alt="welcome"
                        className="mt-0 pt-4"
                        draggable={false}
                      />
                    </div>
                    <div className="pt-4">
                      <img src={Vakency01Icon} alt="vakency" width={100} draggable={false} />
                    </div>
                  </div>

                </div>
              </div>
              <div className={`col-md col-12 p-4 ${isSmall || isXSmall || isXXSmall || isExSmall ? "company-wizard-right-section-sm" : "company-wizard-right-section"}`}>
                <div className="font-semiBold-23 ">Unlock your hiring potential with vakency</div>
                <p className="mb-0">
                  Make smarter hiring decisions and start building winning teams today!
                </p>
                <hr className="mb-2 mt-3" />
                <div style={{height:"42px"}}>
                  {
                    selectedTabIndex === 2 &&
                    <div className="row align-items-center py-1">
                      <div className="col">
                        <div className="line ">Almost Done! Add a few more details to Complete and you can start vakency.</div>
                      </div >
                      <div className="col-auto ">
                        <Button
                          text={"Back"}
                          hint="Back"
                          type="default"
                          stylingMode="text"
                          icon="spinprev"
                          width={100}
                          className="border-0 Back__button"
                          onClick={() => setSelectedTabIndex(1)}
                        />
                      </div>
                    </div>
                  }
                  {
                    selectedTabIndex === 1 &&
                    <div className="row align-items-center py-1">
                      <div className="col line">Enter your company details</div>
                      <div className="col-auto">
                        <Button
                          text={"Back"}
                          icon="spinprev"
                          type="default"
                          hint="Back"
                          width={100}
                          stylingMode="text"
                          className="border-0 Back__button"
                          onClick={() => setSelectedTabIndex(0)}
                        />
                      </div>
                    </div>

                  }
                  {
                    selectedTabIndex === 0 &&
                    <div className="py-1">
                      <img src={userIcon} alt="user" className="border rounded-5" />
                      <div className="d-inline">&nbsp;&nbsp;{companyRecord.emailID}</div>
                    </div>
                  }
                </div>
                <hr className="mt-2 mb-3" />
                <form onSubmit={onSubmitCompanyDetail} >
                  <TabPanel
                    className="wizard-tab-panel"
                    animationEnabled={true}
                    selectedIndex={selectedTabIndex}
                    // height={selectedTabIndex === 2 ? 310 : selectedTabIndex === 1?  325 : 322.5}
                    //  height={ (!isSmall || !isXSmall || !isXXSmall || !isExSmall) && selectedTabIndex === 2 ? 298 : selectedTabIndex === 1?  308.5 : 310}
                    //  height={ (!isSmall || !isXSmall || !isXXSmall || !isExSmall) && selectedTabIndex === 2 ? 298 : selectedTabIndex === 1?  308.5 : 310}
                    height={(isXSmall || isXXSmall || isExSmall) ? isSmall  ? 300 : 360 : 280}
                  >
                    <Item>
                      <div className="row">
                        <div className="col-md">
                          <TextBox
                            label="First Name"
                            maxLength={50}
                            labelMode="floating"
                            ref={FocusedFeild}
                            value={companyRecord.firstName}
                            onValueChange={(e) =>
                              setCompanyRecord({ ...companyRecord, firstName: e })
                            }
                            inputAttr={{
                              autocomplete: "new",
                            }}
                          >
                            <Validator validationGroup={ValidationGroupName2} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                              <RequiredRule message='First Name is required' />
                            </Validator>
                          </TextBox>
                        </div>
                        <div className="col-md mt-3 mt-md-0">
                          <TextBox
                            label="Last Name"
                            maxLength={50}
                            labelMode="floating"
                            value={companyRecord.lastName}
                            onValueChange={(e) =>
                              setCompanyRecord({ ...companyRecord, lastName: e })
                            }
                            inputAttr={{
                              autocomplete: "new",
                            }}
                            mode="text"
                          >
                            <Validator validationGroup={ValidationGroupName2} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                              <RequiredRule message='Last Name is required' />
                            </Validator>
                          </TextBox>
                        </div>
                      </div>
                      <div className="pt-3">
                      <TextBox
                        // className="mt-4"
                        label="Create Password"
                        labelMode="floating"
                        value={companyRecord.password}
                        mode={PasswordMode}
                        maxLength={16}
                        onValueChange={(e) =>
                          setCompanyRecord({ ...companyRecord, password: e })
                        }
                        inputAttr={{
                          autocomplete: "new",
                        }}

                      >  <TextBoxButton
                          name="password"
                          location="after"
                          options={PasswordOptions}
                        />
                        <Validator validationGroup={ValidationGroupName2} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                          <RequiredRule message='Password is required' />
                          <PatternRule message="Password should contain minimum 8 & maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character" pattern={PasswordRegex} />
                        </Validator>
                      </TextBox>
                      </div>
                      <div className="pt-3">
                      <TextBox
                        // className="mt-4"
                        label="Confirm Password"
                        labelMode="floating"
                        maxLength={16}
                        value={companyRecord.confirmPassword}
                        mode={confirmPasswordMode}
                        onValueChange={(e) =>
                          setCompanyRecord({ ...companyRecord, confirmPassword: e })
                        }
                        inputAttr={{
                          autocomplete: "new",
                        }}

                      >
                        <TextBoxButton
                          name="confirmPassword"
                          location="after"
                          options={ConfirmPasswordOptions}
                        />
                        <Validator validationGroup={ValidationGroupName2} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                          <RequiredRule message='Confirm Password is required' />
                          <PatternRule message="Password should contain minimum 8 & maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character" pattern={PasswordRegex} />
                          {companyRecord.password && <CompareRule
                            message="Password and Confirm Password does not match"
                            comparisonTarget={passwordComparison}
                          />}
                        </Validator>
                      </TextBox>
                      </div>
                      <div className="pt-3">
                      <NumberBox
                        label='Mobile Number'
                        labelMode='floating'
                        // className="mt-4"
                        format="#"
                        step={0}
                        onKeyDown={onKeyDown}
                        maxLength={10}
                        value={companyRecord.mobileNo}
                        onValueChange={(value) => setCompanyRecord({ ...companyRecord, mobileNo: value })}
                        inputAttr={{
                          autocomplete: "new",
                          maxLength: 10
                        }}

                      >
                        <Validator validationGroup={ValidationGroupName2} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                          <RequiredRule message='Mobile Number is required' />
                          <StringLengthRule message="Mobile Number must have 10 digits" min={10} />
                        </Validator>
                      </NumberBox>
                      </div>
                      <div className="pt-3">
                      <div className="registration_CheckBox">
                        <CheckBox
                          text={'I agree to the Terms and Conditions'}
                          iconSize={15}
                          // className="mt-3 mb-5"
                        >
                          <Validator validationGroup={ValidationGroupName2} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                            <RequiredRule message='You must agree with the terms and conditions' />
                          </Validator>
                        </CheckBox>
                        <span className='text-danger'> *</span>
                      </div>
                      </div>
                    </Item>
                    <Item>
                    <div className="">
                      <TextBox
                        className=""
                        label="Company Name"
                        labelMode="floating"
                        value={companyRecord.companyName}
                        onValueChange={(e) =>
                          setCompanyRecord({ ...companyRecord, companyName: e })
                        }
                      >
                        <Validator validationGroup={ValidationGroupName3} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                          <RequiredRule message='Company Name is required' />
                        </Validator>
                      </TextBox>
                      </div>
                      <div className="pt-3">
                      <TextBox
                        // className="mt-4"
                        label="Business Type"
                        labelMode="floating"
                        value={companyRecord.businessCategory}
                        onValueChange={(e) =>
                          setCompanyRecord({ ...companyRecord, businessCategory: e })
                        }
                      >
                      </TextBox>
                      </div>
                      <div className="pt-3">
                      <SelectBox
                        // className="mt-4"
                        label="Company Size"
                        id="Wizard_CompanySize_DropDown"
                        labelMode="floating"
                        displayExpr="Value"
                        valueExpr="Id"
                        dataSource={CompanySizeDropDown}
                        value={companyRecord.companySize}
                        onValueChange={(e) =>
                          setCompanyRecord({ ...companyRecord, companySize: e })
                        }
                        dropDownOptions={{
                          hideOnParentScroll:true,
                          container:"#Wizard_CompanySize_DropDown"
                        }}
                      >
                        <Validator validationGroup={ValidationGroupName3} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                          <RequiredRule message='Company Size is required' />
                        </Validator>
                      </SelectBox>
                      </div>
                      <div className="pt-3">
                      <NumberBox
                        label='Phone Number'
                        labelMode='floating'
                        // className="mt-4 mb-5"
                        format="#"
                        step={0}
                        onKeyDown={onKeyDown}
                        maxLength={12}
                        inputAttr={{ maxLength: 12 }}
                        value={companyRecord.phoneNo}
                        onValueChange={(value) => setCompanyRecord({ ...companyRecord, phoneNo: value })}
                      >
                        <Validator validationGroup={ValidationGroupName3} >
                          <StringLengthRule message="Phone Number must have 10 digits" min={10} ignoreEmptyValue={true} />
                        </Validator>
                      </NumberBox>
                      </div>
                    </Item>
                    <Item>
                    <div className="">
                      <TextArea
                        label="Address"
                        maxLength={255}
                        labelMode="floating"
                        value={companyRecord.address}
                        onValueChange={(e) =>
                          setCompanyRecord({ ...companyRecord, address: e })
                        }
                      />
                    </div>
                    <div className="pt-3">
                      <TextBox
                        // className="mt-4"
                        label="Country"
                        maxLength={50}
                        labelMode="floating"
                        value={companyRecord.countryName}
                        onValueChange={(e) =>
                          setCompanyRecord({ ...companyRecord, countryName: e })
                        }
                      >
                        <Validator
                          validationGroup={ValidationGroupName}
                          elementAttr={{ class: "ForValidationAstrikMark" }}
                        >
                          <RequiredRule message="Country Name is required" />
                        </Validator>
                      </TextBox>
                      </div>
                      <div className="pt-3">
                      <TextBox
                        // className="mt-4"
                        label="State"
                        labelMode="floating"
                        value={companyRecord.stateName}
                        onValueChange={(e) =>
                          setCompanyRecord({ ...companyRecord, stateName: e })
                        }
                      >
                        <Validator
                          validationGroup={ValidationGroupName}
                          elementAttr={{ class: "ForValidationAstrikMark" }}
                        >
                          <RequiredRule message="State Name is required" />
                        </Validator>
                      </TextBox>
                      </div>
                      <div className="pt-3">
                      <TextBox
                        // className="mt-4 mb-3"
                        label="City"
                        labelMode="floating"
                        value={companyRecord.cityName}
                        onValueChange={(e) =>
                          setCompanyRecord({ ...companyRecord, cityName: e })
                        }
                      >
                        <Validator
                          validationGroup={ValidationGroupName}
                          elementAttr={{ class: "ForValidationAstrikMark" }}
                        >
                          <RequiredRule message="City Name is required" />
                        </Validator>
                      </TextBox>
                      </div>
                    </Item>
                  </TabPanel>

                  <div className={isSmall || isXSmall || isXXSmall || isExSmall ? "row align-items-end text-end" : "row align-items-end text-end"}>
                    {
                      selectedTabIndex === 2 &&
                      // <div className="finish-button">
                      <div className="">
                        <Button
                          useSubmitBehavior={true}
                          text={"Finish"}
                          type="default"
                          width={100}
                          validationGroup={ValidationGroupName}
                        />
                      </div>

                    }
                    {
                      selectedTabIndex === 1 &&
                      // <div className="pt-4">
                      <div className="">
                        <Button
                          text={"Next"}
                          onClick={CheckValidationsTwo}
                          type="default"
                          width={100}
                          className=""
                          validationGroup={ValidationGroupName3}
                        />
                      </div>

                    }
                    {
                      selectedTabIndex === 0 &&
                      // <div className="Next-button">
                      <div className="">
                        <Button
                          text={"Next"}
                          type="default"
                          width={100}
                          onClick={CheckValidations}
                          validationGroup={ValidationGroupName2}
                        />
                      </div>
                    }
                  </div>
                </form>
              </div>
            </div>
          </ScrollView>
        </Popup>
      }
    </>
  );
};

export default CompanyDetailWizard;
