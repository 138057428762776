import { Popup, TextBox, Validator } from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import PopupHeader from "../../../layouts/popup-header-footer/PopupHeader";
import PopupFooter from "../../../layouts/popup-header-footer/PopupFooter";
import { CustomRule, RequiredRule } from "devextreme-react/data-grid";
import { useScreenSize } from "../../../utils/media-query";
import CountryDropDown from "../country/CountryDropDown";
import {
  ConflictPopup,
  ShowAlert,
  eCRUDStatus,
} from "../../../utils/common-methods";
import PopupSkeleton from "../../../layouts/templates/PopupSkeleton";
import { StateServices } from "../../../api/services/StateServices";
import { AsyncRule } from "devextreme-react/validator";

export default function StatePopup(props) {
  const ValidationGroupName = "StatePopupValidation";

  const { isExSmall, isXXSmall, isXSmall } = useScreenSize();
  const FocusedFeild = useRef(null);
  const onShown = () => FocusedFeild.current?.instance.focus();

  const [stateNewRecord, setStateNewRecord] = useState({});
  const [originalRecord, setOriginalRecord] = useState({});
  const [dropDownClick, setDropDownClick] = useState(false);
  const [addedCountryID, setAddedCountryID] = useState(0);
  let PrimaryKeyID = props.primaryKey;
  const [showSkeleton, setShowSkeleton] = useState(true);
  const stateServices = new StateServices();

  useEffect(() => {
    if (
      PrimaryKeyID !== null &&
      PrimaryKeyID !== 0 &&
      PrimaryKeyID !== undefined
    ) {
      GetModelRecord();
    } else {
      setStateNewRecord({});
      setShowSkeleton(false);
    }
  }, [PrimaryKeyID]);

  useEffect(() => {
    if (addedCountryID !== 0) {
      setStateNewRecord({ ...stateNewRecord, countryID: addedCountryID });
      setAddedCountryID(0);
    }
  }, [addedCountryID]);

  useEffect(() => {
    !showSkeleton && FocusedFeild.current?.instance.focus();
  }, [showSkeleton]);

  const GetModelRecord = async () => {
    setTimeout(async () => {
      const result = await stateServices.GetModelData(PrimaryKeyID);
      if (result?.statusCode === 200) {
        const response = result.data;
        setStateNewRecord(response);
        setOriginalRecord(response)
        setShowSkeleton(false);
      }
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (dropDownClick === false) {
      if (!PrimaryKeyID) {
        InsertRecord();
      } else {
        UpdateRecord();
      }
    }
  };

  const InsertRecord = async () => {
    
    setShowSkeleton(true);
    let dataToInsert = {
      stateName: stateNewRecord.stateName,
      shortName: stateNewRecord.shortName,
      countryID: stateNewRecord.countryID,
    };
    const result = await stateServices.InsertData(dataToInsert);

    if (!result?.isOk) {
      if (result?.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
        setShowSkeleton(false);
      }
    } else {
      props.setStatus({
        eStatus: eCRUDStatus.Inserted,
        primaryKeyID: result.data.responseData,
      });
      onClosePopup();
      setShowSkeleton(false);
    }
  };

  const UpdateRecord = async () => {
    if(originalRecord == stateNewRecord || (originalRecord !== stateNewRecord)){
    setShowSkeleton(true);
    var dataToUpdate = {
      updatedOn: stateNewRecord.updatedOn,
      cityStateID: stateNewRecord.cityStateID,
      stateName: stateNewRecord.stateName,
      shortName: stateNewRecord.shortName,
      countryID: stateNewRecord.countryID,
    };
    const result = await stateServices.UpdateData(dataToUpdate);
    if (!result?.isOk) {
      if (result?.statusCode === 409) {
        ConflictPopup(result.data).then((res) => {
          if (res) {
            GetModelRecord();
          } else {
            return;
          }
        });
        setShowSkeleton(false);
      } else if (result?.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
        setShowSkeleton(false);
      }
    } else {
      props.setStatus({
        eStatus: eCRUDStatus.Updated,
        primaryKeyID: result.data.responseData,
      });
      onClosePopup();
      setShowSkeleton(false);
    }
  }
  };

  const onClosePopup = () => {
    props.setOpenPopup(false);
    setStateNewRecord({});
  };

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={[
            <span key={"header_title"} className="base-accent-text">
              {" "}
              {PrimaryKeyID ? "Edit" : "New"}{" "}
            </span>,
            " State",
          ]}
          onSubmit={
            dropDownClick === false && PrimaryKeyID
              ? UpdateRecord
              : InsertRecord
          }
          hideSaveButton={showSkeleton ? true : false}
        />
      </>
    );
  };

const asyncStateNameValidation = async (e) => {
  const CountryID = stateNewRecord.countryID;
  if (CountryID) {
    const Name = e.value;
    // const Name = encodeURIComponent(e?.value);
    const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
    const CheckDuplicateParams = `Name=${Name}&CountryID=${CountryID}&ExcludeID=${ExcludeID}`;
    const result = await stateServices.CheckDuplicateStateName(
      CheckDuplicateParams
    );
    return new Promise((resolve) => {
      resolve(result.isOk !== false);
    });
  
  }
  else{
    return new Promise((resolve) => {
      resolve( true);
    });
  }
};


const asyncShortNameValidation = async (e) => {
  const CountryID = stateNewRecord.countryID;
  if (CountryID) {
    // const Name = e.value;
    const Name = encodeURIComponent(e?.value);
    const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
    const CheckDuplicateParams = `Name=${Name}&CountryID=${CountryID}&ExcludeID=${ExcludeID}`;
    const result = await stateServices.CheckDuplicateShortName(
      CheckDuplicateParams
    );
    return new Promise((resolve) => {
      resolve(result.isOk !== false);
    });
  
  }
  else{
    return new Promise((resolve) => {
      resolve( true);
    });
  }
};


  return (
    <div>
      {props.openPopup && (
        <Popup
          visible={props.openPopup}
          titleRender={PopupTitle}
          width={isExSmall || isXXSmall || isXSmall ? "90%" : "32%"}
          height={"auto"}
          wrapperAttr={{ class: "CustomPopup" }}
          onShown={onShown}
        >
          <form onSubmit={handleSubmit}>
            {showSkeleton ? (
              <div className="p-2 pb-0">
                <PopupSkeleton />
                <PopupSkeleton />
              </div>
            ) : (
              <div className="px-3">
                <div className=" py-2">
                  <TextBox
                    label="State"
                    maxLength={50}
                    value={stateNewRecord.stateName}
                    onValueChange={(e) =>
                      setStateNewRecord({ ...stateNewRecord, stateName: e })
                    }
                    labelMode="floating"
                    ref={FocusedFeild}
                  >
                    <Validator
                      validationGroup={ValidationGroupName}
                      elementAttr={{ class: "ForValidationAstrikMark" }}
                    >
                      <RequiredRule message="State Name is required" />
                      {/* <CustomRule
                        ignoreEmptyValue={true}
                        message="Can't accept duplicate State Name"
                        validationCallback={asyncStateNameValidation}
                      /> */}
                      <AsyncRule
                        reevaluate={false}
                        message="Can't accept duplicate Country Name"
                        validationCallback={asyncStateNameValidation}
                      />
                    </Validator>
                  </TextBox>
                </div>
                <div className=" py-2 ">
                  <TextBox
                    label="State Short Name"
                    maxLength={5}
                    value={stateNewRecord.shortName}
                    onValueChange={(e) =>
                      setStateNewRecord({ ...stateNewRecord, shortName: e })
                    }
                    labelMode="floating"
                  >
                    <Validator
                      validationGroup={ValidationGroupName}
                      elementAttr={{ class: "ForValidationAstrikMark" }}
                    >
                      <RequiredRule message="Short Name is required" />
                      {/* <CustomRule
                        ignoreEmptyValue={true}
                        message="Can't accept duplicate Short Name"
                        validationCallback={asyncShortNameValidation}
                      /> */}
                      <AsyncRule
                        reevaluate={false}
                        message="Can't accept duplicate Country Name"
                        validationCallback={asyncShortNameValidation}
                      />
                    </Validator>
                  </TextBox>
                </div>
                <div className=" py-2 ">
                  <CountryDropDown
                    value={stateNewRecord.countryID}
                    onValueChange={(e) =>
                      setStateNewRecord({ ...stateNewRecord, countryID: e })
                    }
                    setAddedCountryID={setAddedCountryID}
                    validationGroup={ValidationGroupName}
                    setDropDownClick={setDropDownClick}
                    validationRequired={true}
                    dropdownHeight={70}
                  />
                </div>
              </div>
            )}
            {showSkeleton ? (
              <div className="d-flex justify-content-end pb-3 pe-2">
                <div className="skeleton-View Skeleton-popup-footer"></div>
              </div>
            ) : (
              <PopupFooter
                ValidationGroupName={ValidationGroupName}
                openPopup={props.openPopup}
                setOpenPopup={props.setOpenPopup}
              />
            )}
          </form>
        </Popup>
      )}
    </div>
  );
}
