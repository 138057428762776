import { Routes, Route, Navigate } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ResetPasswordForm, SuccessFullSetPassword } from './components';
import RegistrationForm from './components/registration-form/RegistrationForm';
import Container from './layouts/container/Container';
import ForgotPassword from './components/forgot-password/ForgotPassword';
import RequestResetLink from './components/request-reset-link/RequestResetLink';
import ErrorPage from './components/error-page/ErrorPage';
import InvalidPage from './components/invalid-page/InvalidPage';
import CheckYourEmail from './components/registration-form/CheckYourEmail';
import CompanyDetailWizard from './pages/company/CompanyDetailWizard';
import OtpInvalidPage from './components/invalid-page/OtpInvalidPage';
import Assessment from './components/assessment/Assessment';
import { useState } from 'react';
import AcceptOffer from './components/accept-offer-page/AcceptOffer';
import AcceptOfferErrorPage from './components/accept-offer-page/AcceptOfferErrorPage';
import JobApplicationPublicView from './pages/job-application/JobApplicationPublicView';
import JobOpeningPublicList from './pages/job-opening/JobOpeningPublicList';
import PublicJobErrorPage from './components/error-page/PublicJobErrorPage';

export default function UnauthenticatedContent() {

  const [showLoader, setShowLoader] = useState(false);

  return (
    <Routes>
      <Route
        path='/login'
        element={
          <Container>
            <SingleCard showLoader={showLoader}>
              <LoginForm showLoader={showLoader} setShowLoader={setShowLoader} />
            </SingleCard>
          </Container>
        }
      />
      <Route
        path='/sign-up'
        element={
          <Container>
            <SingleCard showLoader={showLoader}>
              <RegistrationForm showLoader={showLoader} setShowLoader={setShowLoader} />
            </SingleCard>
          </Container>
        }
      />
      <Route
        path='/forgot-password'
        element={
          <Container>
            <SingleCard
              showLoader={showLoader}
              title="Forgot Password?"
              subtitle="Please enter the email address you'd like your password reset information sent to"
            >
              <ForgotPassword showLoader={showLoader} setShowLoader={setShowLoader} />
            </SingleCard>
          </Container>
        }
      />
      <Route
        path='/request-reset-link'
        element={
          <Container>
            <SingleCard>
              <RequestResetLink />
            </SingleCard>
          </Container>
        }
      />
      <Route
        path='/error-page'
        element={
          <Container>
            <SingleCard>
              <ErrorPage />
            </SingleCard>
          </Container>
        }
      />
      <Route
        path='/ResetPassword/:ResetPasswordCode'
        element={
          <Container>
            <SingleCard
              title="Reset Password"
              showLoader={showLoader}
            >
              <ResetPasswordForm showLoader={showLoader} setShowLoader={setShowLoader} />
            </SingleCard>
          </Container>
        }
      />
      <Route
        path='/invalid-page'
        element={
          <Container>
            <SingleCard>
              <InvalidPage />
            </SingleCard>
          </Container>
        }
      />
      <Route
        path='/otp-invalid-page'
        element={
          <Container>
            <SingleCard>
              <OtpInvalidPage />
            </SingleCard>
          </Container>
        }
      />

      <Route
        path='/successful-set-password-page'
        element={
          <Container>
            <SingleCard>
              <SuccessFullSetPassword />
            </SingleCard>
          </Container>
        }
      />

      <Route
        path='/check-your-email'
        element={
          <Container>
            <SingleCard
            >
              <CheckYourEmail />
            </SingleCard>
          </Container>
        }
      />

      <Route
        path='/registration/:email/:otp'
        element={
          <CompanyDetailWizard />
        }
      />

      <Route
        path='/Assessment/:openaccesscode'
        element={
          <Assessment />
        }
      />

      <Route
        path='/AcceptOffer/error-page'
        element={
          <AcceptOfferErrorPage />
        }
      />

      <Route
        path='/AcceptOffer/:openaccesscode'
        element={
          <AcceptOffer />
        }
      />


      <Route
        path='/Job/Public/error-page'
        element={
          <PublicJobErrorPage />
        }
      />

      <Route
        path='/JobOpenings/Public/:openaccesscode'
        element={
          <JobOpeningPublicList />
        }
      />

      <Route
        path='/JobApplication/Public/:openaccesscode'
        element={
          <JobApplicationPublicView />
        }
      />

      <Route path='*' element={<Navigate to={'/login'} />}></Route>
    </Routes>
  );
}
