import DataGrid, { Column, Button as DataGridButton, FilterPanel, Scrolling, ColumnChooser, HeaderFilter, SearchPanel, FilterBuilderPopup, Pager, StateStoring, Toolbar, Item, ColumnChooserSearch, Search } from 'devextreme-react/data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { Sorting } from 'devextreme-react/gantt';
import { useScreenSize } from '../../../utils/media-query';
import { Header } from '../../../components';
import ListSkeleton from '../../../layouts/templates/ListSkeleton';
import { GetFormattedDateTimeUTCString, HideDatagridLoader, ShowAlert, eCRUDStatus, onDragOver } from '../../../utils/common-methods';
import SkillPopup from './SkillPopup';
import { confirm } from 'devextreme/ui/dialog';
import { SkillServices } from '../../../api/services/SkillServices';

const skillServices = new SkillServices();
export default function SkillList() {



  const [filterVisible, setFilterVisible] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true)
  const [dsSkill, setDsSkill] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [primaryKey, setPrimaryKey] = useState(null)
  const [focusedRowKey, setfocusedRowKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });

  const DataGridRef = useRef(null);

  const GetList = async () => {
    // setShowSkeleton(true)
    const result = await skillServices.GetListData();
    if (result.statusCode === 400 || result.statusCode === 409) {
      ShowAlert(result.data, "Vakency")
      setShowSkeleton(false);
    }
    else {
      const SkillData = result.data?.map(item => {
        const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
        const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
        return {
          ...item,
          createdOn: createdOn ? createdOn: null,
          updatedOn: updatedOn ? updatedOn : null
        };
      });
      setDsSkill(SkillData);
      setShowSkeleton(false);
    }
  }

  useEffect(() => {
    GetList();
  }, [])

  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID =
        status.eStatus === eCRUDStatus.Inserted
          ? status.primaryKeyID
          : primaryKey;
      UpdateListByID(PrimaryKeyID);
    }
  }, [status])

  const UpdateListByID = async (primaryKey) => {
    const result = await skillServices.GetListByIdData(primaryKey);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency")
    } else {
      const SkillData = result.data?.map(item => {
        const createdOn = item.createdOn ? new Date(new Date(item.createdOn + "Z").toUTCString()).toLocaleString(): null;
        const updatedOn = item.updatedOn ?new Date(new Date(item.updatedOn + "Z").toUTCString()).toLocaleString() : null;
        return {
          ...item,
          createdOn: createdOn ? createdOn: null,
          updatedOn: updatedOn ? updatedOn : null
        };
      });
      if (status.eStatus === eCRUDStatus.Inserted) {
        setDsSkill([SkillData[0], ...dsSkill]);
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setDsSkill(dsSkill.map((i) => i.skillID === primaryKey ? SkillData[0] : i));
      }
    }

    setfocusedRowKey(primaryKey);
    setPrimaryKey(0);
  };

  const onOptionChange = (e) => {
    if (e.fullName === "filterValue") {
      setFilterVisible(!!e.value);
    }
  };

  const { isExSmall, isXXSmall } = useScreenSize();

  const handleAdd = () => {
    setPrimaryKey(null)
    setShowPopup(true)
  }

  const handleEdit = (e) => {
    setPrimaryKey(e.data.skillID)
    setShowPopup(true)
  }

  const onRowDbClick = (e) => {
    setPrimaryKey(e.data.skillID)
    setShowPopup(true)
  }

  const onDeleteButtonClick = (e) => {
    let res = confirm(
      `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
      "Vakency"
    );
    res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
  };

  const handleDelete = async (e) => {
    const Id = e.row.data.skillID;
    // const result = await DeleteData(SkillControlName, Id);
    const result = await skillServices.DeleteData(Id);
    if (result.statusCode === 400 || result.statusCode === 409) {
      ShowAlert(result.data, "Vakency")
    }
    else if (result.statusCode === 200) {
      setDsSkill(dsSkill.filter((x) => x.skillID !== e.row.data.skillID));
    }
  }

  const SkillName = (cellData) => {
    return <>
      <div><span className='text-decoration-underline pointer-link' title='Edit' onClick={(e) => handleEdit(cellData)}>{cellData.data.skillName}</span></div>
    </>
  }

  function onFocusedRowChanged(e) {
    setfocusedRowKey(e.component.option("focusedRowKey"));
  }

  return (
    <div>
      <div className='Skills' onDragOver={onDragOver}>
        <Header
          title={"Skills"}
          handleAdd={handleAdd}
          dataGridRef={DataGridRef}
          GetRecord={GetList}
        />
        <div className='list-section'>
          {
            showSkeleton ?
              <ListSkeleton />
              :
              <DataGrid
                ref={DataGridRef}
                dataSource={dsSkill}
                showBorders={true}
                showColumnLines={false}
                showRowLines={true}
                focusedRowEnabled={true}
                wordWrapEnabled={true}
                hoverStateEnabled={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                autoNavigateToFocusedRow={true}
                keyExpr="skillID"
                height={'100%'}
                width={"100%"}
                filterSyncEnabled={true}
                onOptionChanged={onOptionChange}
                className='List_DataGrid'
                loadPanel={HideDatagridLoader}
                onRowDblClick={onRowDbClick}
                focusedRowKey={focusedRowKey}
                noDataText='No Record Found'
                onFocusedRowChanged={onFocusedRowChanged}
              >
                <Sorting mode={"multiple"} />
                <FilterPanel visible={filterVisible} />
                <FilterBuilderPopup visible={false} />
                <Scrolling mode={"infinite"} preloadEnabled={true} useNative={true}/>
                <ColumnChooser enabled={true}>
                  <ColumnChooserSearch
                    enabled={true}
                  />
                </ColumnChooser>
                <HeaderFilter visible={true} >
                  <Search
                    enabled={true}
                  />
                </HeaderFilter>
                <SearchPanel visible={true} width={(isExSmall || isXXSmall) ? 200 : 400} />
                <StateStoring enabled={true} type="localStorage" storageKey="Skills_Layout" />
                <Pager
                  visible={true}
                  showInfo={true}
                  infoText={`{2} Rows`}
                ></Pager>
                <Toolbar>
                  <Item location={'after'} name="columnChooserButton" />
                  <Item location={'after'} name="searchPanel" />
                </Toolbar>
                <Column
                  caption='Skill'
                  cellRender={SkillName}
                  dataField='skillName'
                  minWidth={180}
                  allowHiding={false}
                  // defaultSortOrder="asc"
                  // sortOrder='asc'
                />
                <Column
                  dataField="createdByUserName"
                  caption="Created By"
                  visible={false}
                  width={125}
                  minWidth={80}
                  alignment="left"
                />

                <Column
                  dataField="updatedByUserName"
                  caption="Updated By"
                  visible={false}
                  width={125}
                  minWidth={80}
                  alignment="left"
                />
                <Column
                  dataField="createdOn"
                  caption="Created On"
                  visible={false}
                  width={135}
                  minWidth={80}
                  alignment="left"
                />
                <Column
                  dataField="updatedOn"
                  caption="Updated On"
                  visible={false}
                  width={137}
                  minWidth={80}
                  alignment="left"
                />
                <Column type='buttons' fixed={false} allowResizing={false} width={43} allowReordering={false}>
                  <DataGridButton
                    name='delete'
                    icon='trash'
                    hint='Delete'
                    onClick={onDeleteButtonClick}
                    visible={true}
                    cssClass='text-danger'
                  />
                </Column>
              </DataGrid>
          }
        </div>
        {showPopup === true &&
          <SkillPopup
            openPopup={showPopup}
            setOpenPopup={setShowPopup}
            primaryKey={primaryKey}
            setStatus={setStatus}
          />
        }
      </div>
    </div>
  )
}
