import { Button, Popup, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar';
import React from 'react';
import ImageViewer from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function FilePreviewer({
    showFilePreview,
    selectedFile,
    setShowFilePreviewLoad,
    onFilePreviewClose,
    isImageOnly
}) {

    const onShown = () => {
        setShowFilePreviewLoad(false);
    }

    const downloadFile = async () => {
        const elem = window.document.createElement("a");
        elem.href = selectedFile.fileSrc;
        elem.download = selectedFile.fileName;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }

    const titleRender = () => {
        return (
            <Toolbar>
                <Item location='before'>
                    <div className='font-medium-16'>{selectedFile.fileName}</div>
                </Item>
                <Item location='after'>
                    <Button
                        icon='download'
                        stylingMode='text'
                        onClick={downloadFile}
                    />
                </Item>
                <Item location='after'>
                    <Button
                        icon='close'
                        stylingMode='text'
                        onClick={onFilePreviewClose}
                    />
                </Item>
            </Toolbar>
        );
    }

    return (
        <>
            {
                selectedFile.type == 'photo' || isImageOnly ? (

                    <ImageViewer
                        mainSrc={selectedFile.fileSrc}
                        onCloseRequest={onFilePreviewClose}
                        imageTitle={selectedFile.fileName}
                        enableZoom={true}
                        onAfterOpen={onShown}
                        toolbarButtons={ isImageOnly ? [] : [
                            <button
                                className='dx-icon-download dx-custom-icon download-btn ril__toolbarItemChild ril__builtinButton'
                                onClick={downloadFile}
                            >

                            </button>
                        ]}
                        clickOutsideToClose={isImageOnly ? false : true}
                    />
                ) : (
                    <Popup
                        visible={showFilePreview}
                        hideOnOutsideClick={false}
                        showCloseButton={true}
                        width={"100%"}
                        height={"100%"}
                        wrapperAttr={{ class: "video-preview-popup" }}
                        onShown={onShown}
                        titleRender={titleRender}
                    >
                        <video width="98%" height="95%" controls >
                            <source src={selectedFile.fileSrc} />
                        </video>
                    </Popup>
                )
            }

        </>
    );

}