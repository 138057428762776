import React from 'react';
import './RequestResetLink.scss';
import { Link } from 'react-router-dom';

export default function RequestResetLink() {
  return (
    <div className='row request-reset-link'>
      <div className=' text-center my-3'><i className='dx-icon-check check-icon p-3 fs-4 rounded-5'></i></div>
      <div className='text-center my-3 message fw-bold '>Reset Link has been sent to your email address.</div>
      <div className=' text-center my-2 instruction'>Please follow given instructions on email to reset your password.</div>
      <div className='text-center my-2'>
        <Link to="/login"
          className='GoToLogin-button fw-semibold'
        >
          Go to Login
        </Link>
      </div>
    </div>
  )
}
