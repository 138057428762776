import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { CandidateAssessmentControlName } from "../../contexts/APIurl";

const url = CandidateAssessmentControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class CandidateAssessmentServices extends VakencyApiServices {
    constructor() {
        super(url);
    }

    GetCandidateAssesmentDetails = async (params) => {
        const GetUrl = url + `/Get/${params}`

        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GetCandidateProfileImage = async (params) => {
        const GetUrl = url + `/GetCandidateProfileImage/${params}`
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetUrl);
            if (data.status && data.status === 200) {
                const fileUrl = await data.text();
                return this.fetchFileAsBlob(fileUrl);
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GetCandidateResume = async (params) => {
        const GetUrl = url + `/GetResume/${params}`
        let data = null;

        try {
            data = await fetchWrapper.get(baseURL + GetUrl)
            if (data.status && data.status === 200) {
                const fileUrl = await data.text();
                return this.fetchFileAsBlob(fileUrl);
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GetAssessmentNotes = async (params) => {
        const GetUrl = url + `/GetAssessmentNotes/${params}`
        const result = await fetchWrapper.get(baseURL + GetUrl);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetInteractionResult = async (params) => {
        const GetUrl = url + `/GetInteractionResult/${params}`
        const result = await fetchWrapper.get(baseURL + GetUrl);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

}

