import React from 'react';
import { Header } from '../../components';
import { useScreenSize } from '../../utils/media-query';
import { Vakency130Icon, Vakency600Icon } from '../../utils/base-64-Icons';

export default function Home() {
  const {isMedium, isLarge, isExLarge } = useScreenSize(); 
  return (
    <React.Fragment>
      
      <Header
        title={""}
        hideDefaultButtons={true}
        showUploadNewResume={true}
      />
      
      <div className='list-section d-flex justify-content-center align-items-center'>
       {
        isExLarge || isLarge || isMedium ? <img src={Vakency600Icon} alt='vakency'  /> : <img src={Vakency130Icon} alt='vakency' />
       }
        
      </div>
   
      
    </React.Fragment>
)}
