import React, { useEffect, useRef, useState } from "react";
import {
  SelectBox,
  Button as TextBoxButton,
} from "devextreme-react/select-box";
import { Validator } from "devextreme-react";
import { RequiredRule } from "devextreme-react/validator";
import CountryPopup from "./CountryPopup";
import { ShowAlert, eCRUDStatus } from "../../../utils/common-methods";
import { CountryServices } from "../../../api/services/CountryServices";

const countryServices = new CountryServices();

export default function CountryDropDown(props) {
  const [openPopup, setOpenPopup] = useState(false);
  const [countryList, setCountryList] = useState([]);
  let [primaryKey, setPrimaryKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });

  const SelectBoxRef = useRef(null);

  useEffect(() => {
    openPopup === false &&
      props.setDropDownClick &&
      props.setDropDownClick(false);
  }, [openPopup]);

  useEffect(() => {
    countryLookUpList();
  }, []);

  const countryLookUpList = async () => {
    const result = await countryServices.GetLookupListData();
    const response = result.data;
    setCountryList(response);
  };

  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID =
        status.eStatus === eCRUDStatus.Inserted
          ? status.primaryKeyID
          : primaryKey;
      UpdateListByID(PrimaryKeyID);
    }
  }, [status]);

  const UpdateListByID = async (primaryKey) => {
    const result = await countryServices.GetListByIdData(primaryKey);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency");
    } else {
      if (status.eStatus === eCRUDStatus.Inserted) {
        setCountryList([result.data[0], ...countryList]);
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setCountryList(
          countryList.map((i) =>
            i.countryID === primaryKey ? result.data[0] : i
          )
        );
      }
    }
    props.setAddedCountryID && props.setAddedCountryID(primaryKey);
  };

  const AddButton = {
    icon: "plus",
    stylingMode: "text",
    onClick: () => {
      props.setDropDownClick && props.setDropDownClick(true);
      setOpenPopup(!openPopup);
    },
  };

  const DownArrow = {
    icon: "spindown",
    stylingMode: "text",
    onClick: (e) => {
      var selectBoxInstance = SelectBoxRef.current?.instance;
      var isOpened = selectBoxInstance.option("opened");
      if (isOpened) {
        selectBoxInstance.close();
      } else {
        selectBoxInstance.open();
        selectBoxInstance.focus();
      }
    },
  };

  return (
    <div>
      <SelectBox
        label="Country"
        id="Country_DropDown"
        searchEnabled={true}
        ref={SelectBoxRef}
        dataSource={countryList}
        labelMode="floating"
        displayExpr="countryName"
        valueExpr="countryID"
        value={props.value}
        onValueChange={props.onValueChange}
        dropDownOptions={{
          height: props.dropdownHeight ? props.dropdownHeight : 120,
          hideOnParentScroll: true,
          container: "#Country_DropDown"
        }}
      >
        <TextBoxButton name="country" location="after" options={AddButton} />
        <TextBoxButton name="dropdown" location="after" options={DownArrow} />
        {props.validationRequired && (
          <Validator
            validationGroup={props.validationGroup}
            elementAttr={{ class: "ForValidationAstrikMark" }}
          >
            <RequiredRule
              message={
                props.validationMessage
                  ? props.validationMessage
                  : "Country is required"
              }
            />
          </Validator>
        )}
      </SelectBox>
      {openPopup === true && (
        <CountryPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          ValidationGroupName={props.validationGroup}
          setStatus={setStatus}
          primaryKey={primaryKey}
        />
      )}
    </div>
  );
}
