import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { UserControlName } from "../../contexts/APIurl";

const url = UserControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class UsersServices extends VakencyApiServices {
  constructor() {
    super(url);
  }

DeleteUserProfilePicture=async(userID)=>{
     const DeleteUrl = url + `/DeleteUserProfileImage?UserID=${userID}`
    let data = null;
    try {
        data = await fetchWrapper.delete(baseURL + DeleteUrl)
        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: data,
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

GetUserProfilePicture=async(userID)=>{
    let data = null;
    const GetListUrl = url + `/GetUserProfileImage?userID=${userID}`
    try {
        data = await fetchWrapper.get(baseURL + GetListUrl)
        if (data.status && data.status === 200) {
            const fileUrl = await data.text();
            return this.fetchFileAsBlob(fileUrl);
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

CheckDuplicateData_fullPath=async(CheckDuplicateParams)=> {
    let data = null;
    try {
        data = await fetchWrapper.get(baseURL + url+CheckDuplicateParams)
        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: data,
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

InsertUserProfilePicture=async(params,id)=>{
    const formData = new FormData();
        formData.append("UserID", params.userID);
        formData.append("attachments", params.attachments);
        const UpdateUrl = url + `/UpdateUserProfileImage/${id}`
    let data = null;
    try {
        data = await fetchWrapper.postwithFile(baseURL + UpdateUrl,formData)
        if (data.status && data.status === 200) {
            return {
                isOk: data.ok,
                data: await data,
                statusCode: data.status
            };
        } else {
            return {
                isOk: data.ok,
                data: await data.text(),
                stautsCode: data.status && data.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            message: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: data.status
        };
    }
}

 GetZipCode=async(cityID)=>{
    const url = "CityManagement/City"
    const GetListUrl = url + `/GetZips?CityID=${cityID}`
    let data = null;
    try {
        data = await fetchWrapper.get(baseURL + GetListUrl)
        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: await data.json(),
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

}
