import React, { useRef, useState, useEffect } from "react";
import {
  SelectBox,
  Button as TextBoxButton,
} from "devextreme-react/select-box";
import JobProfilePopup from "./JobProfilePopup";
import { Validator } from "devextreme-react";
import { RequiredRule } from "devextreme-react/data-grid";
import { ShowAlert, eCRUDStatus } from "../../../utils/common-methods";
import { JobProfileServices } from "../../../api/services/JobProfileServices";

const jobProfileServices = new JobProfileServices()
export default function JobProfileDropDown(props) {

  const [openPopup, setOpenPopup] = useState(false);
  const [DsJobProfile, setDsJobProfile] = useState([]);
  let [primaryKey, setPrimaryKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });
  const [dropdownVisible,setDropdownVisible] = useState(false);

  useEffect(() => {
    GetJobProfileLookupList();
  }, []);

  useEffect(() => {
    openPopup === false &&
      props.setDropDownClick &&
      props.setDropDownClick(false);
  }, [openPopup]);

  const GetJobProfileLookupList = async () => {
    const result = await jobProfileServices.GetLookupListData();

    if (result.isOk) {
      setDsJobProfile(result.data);
    }
  };

  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID =
        status.eStatus === eCRUDStatus.Inserted
          ? status.primaryKeyID
          : primaryKey;
      UpdateListByID(PrimaryKeyID);
    } else {
      setPrimaryKey(0);
    }
  }, [status]);

  const UpdateListByID = async (primaryKey) => {
    const result = await jobProfileServices.GetListByIdData(primaryKey);

    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency");
    } else {
      if (status.eStatus === eCRUDStatus.Inserted) {
        setDsJobProfile([result.data[0], ...DsJobProfile]);
        setPrimaryKey(0);
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setDsJobProfile(
          DsJobProfile.map((i) =>
            i.jobProfileID === primaryKey ? result.data[0] : i
          )
        );
      }
    }
    props.setAddedJobProfileID && props.setAddedJobProfileID(primaryKey);
  };

  const SelectBoxRef = useRef(null);
  const AddButton = {
    icon: "plus",
    stylingMode: "text",
    onClick: () => {
      props.setDropDownClick && props.setDropDownClick(true);
      setOpenPopup(!openPopup);
    },
  };

  let isOpened = null;

  const DownArrow = {
    icon: "spindown",
    stylingMode: "text",
    onClick: (e) => {
      var selectBoxInstance;
      if (props.FocusedFeild) {
        selectBoxInstance = props.FocusedFeild?.current?.instance;
      } else {
        selectBoxInstance = SelectBoxRef?.current?.instance;
      }
      isOpened = selectBoxInstance?.option("opened");
      if (isOpened) {
        selectBoxInstance.close();
      } else {
        selectBoxInstance.open();
        selectBoxInstance.focus();
      }
    },
  };

  return (
    <div>
      <SelectBox
        label="Job Profile"
        id="JobProfile_DropDown"
        searchEnabled={true}
        ref={props.FocusedFeild ? props.FocusedFeild : SelectBoxRef}
        dataSource={DsJobProfile}
        displayExpr={"jobProfileName"}
        valueExpr="jobProfileID"
        value={props.value}
        onValueChange={props.onValueChange}
        labelMode="floating"
        readOnly={props.readOnly && props.readOnly}
        dropDownOptions={{
          height: props.dropdownHeight ? props.dropdownHeight : 120,
          onShowing:(()=>setDropdownVisible(true)),
          onHiding:(()=>setDropdownVisible(false)),
       }}
      
       validationMessagePosition={props.ValidationMessagePosition ? props.ValidationMessagePosition : dropdownVisible? "right" :'bottom'}
      >
        <TextBoxButton name="add" location="after" options={AddButton} />
        <TextBoxButton name="dropdown" location="after" options={DownArrow} />
        {props.validationRequired && (
         <Validator
            validationGroup={props.validationGroup}
            elementAttr={{ class: props.customValidate ? "" : "ForValidationAstrikMark" }}
        >
           {props.customValidate ? (
                   (!props.EditorData && !props.value) && (
                   <RequiredRule message={props.validationMessage} />
                   )
                   ) : 
                   (
                   <RequiredRule
                    message={
                      props.validationMessage
                      ? props.validationMessage
                      : "Job Profile is required"
                    }
                  />
            )}
         </Validator>
       )}
      </SelectBox>
      {openPopup === true && (
        <JobProfilePopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setStatus={setStatus}
          primaryKey={primaryKey}
        />
      )}
    </div>
  );
}
