import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { CandidateAssessmentOpenControlName } from "../../contexts/APIurl";

const url = CandidateAssessmentOpenControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class CandidateAssessmentOpenServices extends VakencyApiServices {
  constructor() {
    super(url);
  }



GetCandidateAssesmentDetails=async(params)=>{
    const GetListUrl = url + `/Get/${params}`

    let data = null;
    try {
        data = await fetchWrapper.getWithoutHeader(baseURL + GetListUrl)
      return this.handleResponse(data);
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

GetCandidateProfileImage=async(params)=>{
    const GetUrl = url + `/GetCandidateProfileImage/${params}`
    let data = null;
    try {
        data = await fetchWrapper.getWithoutHeader(baseURL + GetUrl);
        if (data.status && data.status === 200) {
            const fileUrl = await data.text();
            return this.fetchFileAsBlob(fileUrl);
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

GetCandidateResume=async(params)=>{
    const GetUrl = url + `/GetResume/${params}`
    let data = null;
  
    try {
        data = await fetchWrapper.getWithoutHeader(baseURL + GetUrl)          
        if (data.status && data.status === 200) {
            const fileUrl = await data.text();
            return this.fetchFileAsBlob(fileUrl);
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
  }



  GetAssessmentNotes = async (params) => {
    const GetUrl = url + `/GetAssessmentNotes/${params}`
    const result = await fetchWrapper.getWithoutHeader(baseURL+GetUrl);
    try {
        if (result.status && result.status === 200) {
            return {
                isOk: true,
                data: await result.json(),
                statusCode: result.status
            };

        } else {
            return {
                isOk: false,
                data: await result.text(),
                statusCode: result.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            data: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: result.status
        };
    }
}

GetAssessmentNoteTemplate = async (params) => {
    const GetUrl = url + `/GetAssessmentNoteTemplate/${params}`
    const result = await fetchWrapper.getWithoutHeader(baseURL+GetUrl);
    try {
        if (result.status && result.status === 200) {
            return {
                isOk: true,
                data: await result.json(),
                statusCode: result.status
            };

        } else {
            return {
                isOk: false,
                data: await result.text(),
                statusCode: result.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            data: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: result.status
        };
    }
}
GetOtherAssessmentNotes = async (params) => {
    const GetUrl = url + `/GetOtherAssessmentNotes/${params}`
    const result = await fetchWrapper.getWithoutHeader(baseURL+GetUrl);
    try {
        if (result.status && result.status === 200) {
            return {
                isOk: true,
                data: await result.json(),
                statusCode: result.status
            };

        } else {
            return {
                isOk: false,
                data: await result.text(),
                statusCode: result.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            data: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: result.status
        };
    }
}

UpdateAssessmentNotes = async (params,data) => {
    const result = await fetchWrapper.postWithoutHeader((baseURL+url +  '/UpdateAssessmentNotes/'+params),data);
    try {
        if (result.status && result.status === 200) {
            return {
                isOk: true,
                data: await result.json(),
                statusCode: result.status
            };

        } else {
            return {
                isOk: false,
                data: await result.text(),
                statusCode: result.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            data: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: result.status
        };
    }
}


RateAttitude = async (params,data) => {
    const result = await fetchWrapper.postWithoutHeader((baseURL+url +  '/RateAttitude/'+params),data);
    try {
        if (result.status && result.status === 200) {
            return {
                isOk: true,
                data: await result.json(),
                statusCode: result.status
            };

        } else {
            return {
                isOk: false,
                data: await result.text(),
                statusCode: result.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            data: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: result.status
        };
    }
}
RateSkill = async (params,data) => {
    const result = await fetchWrapper.postWithoutHeader((baseURL+url +  '/RateSkill/'+params),data);
    try {
        if (result.status && result.status === 200) {
            return {
                isOk: true,
                data: await result.json(),
                statusCode: result.status
            };

        } else {
            return {
                isOk: false,
                data: await result.text(),
                statusCode: result.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            data: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: result.status
        };
    }
}

PostAssessmentResult = async (params,data) => {
    const result = await fetchWrapper.postWithoutHeader((baseURL+url +  '/Post/'+params),data);
    try {
        if (result.status && result.status === 200) {
            return {
                isOk: true,
                // data: await result.json(),
                statusCode: result.status
            };

        } else {
            return {
                isOk: false,
                // data: await result.text(),
                statusCode: result.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            data: "Failed To Submit Data",
            statusCode: result.status
        };
    }
}

}

