const GetAuthToken = () => {
    return localStorage.getItem("authToken")
}

const authHeader = () => {
    // return authorization header with jwt token    
    const getAuthToken = GetAuthToken();
    if (getAuthToken) {
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getAuthToken}`
        };
    } else {
        return {};
    }
}

const authHeaderWithoutToken = () => {
    // return content type  
    return {
        'Content-Type': 'application/json'
    };
}

const authHeaderWithTokenOnly = () => {
    // return only authorization header with jwt token    
    const getAuthToken = GetAuthToken();
    if (getAuthToken) {
        return {
            Authorization: `Bearer ${getAuthToken}`,
        };
    } else {
        return {};
    }
}

const handleResponse = (response) => {
    if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
                localStorage.removeItem("userEmail");
                localStorage.removeItem("authToken");
                window.location.replace(window.location.origin);
        }
    }
    return response;
}

// Get Request With Header
const get = async (url) => {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    const response = await fetch(url, requestOptions).then(handleResponse);
    return response;
}

// Get Request Without Header
const getWithoutHeader = async (url) => {
    const requestOptions = {
        method: "GET",
        headers: authHeaderWithoutToken()
    };
    return await fetch(url, requestOptions).then(handleResponse);
}

// Post Request With Header
const post = async (url, body) => {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(body),
    };
    return await fetch(url, requestOptions).then(handleResponse);
}

// Post Request With Header and For File Attachment
const postwithFile = async (url, body) => {
    const requestOptions = {
        method: "POST",
        headers: authHeaderWithTokenOnly(),
        body: body,
    };
    return await fetch(url, requestOptions).then(handleResponse);
}

const putwithFile = async (url, body) => {
    const requestOptions = {
        method: "PUT",
        headers: authHeaderWithTokenOnly(),
        body: body,
    };
    return await fetch(url, requestOptions).then(handleResponse);
}

// Post Request With File Attachment and No Authentication
const postwithFileandNoAuth = async (url, body) => {
    const requestOptions = {
        method: "POST",
        body: body,
    };
    return await fetch(url, requestOptions).then(handleResponse);
}

// Post Request With Header and parameters
const postWithParams = async (url) => {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
    };
    return await fetch(url, requestOptions).then(handleResponse);
}

// Post Request Without Header
const postWithoutHeader = async (url, body) => {
    const requestOptions = {
        method: "POST",
        headers: authHeaderWithoutToken(),
        body: JSON.stringify(body),
    };
    return await fetch(url, requestOptions).then(handleResponse);
}

// Put Request With Header
const put = async (url, body) => {
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        body: JSON.stringify(body),
    };
    return await fetch(url, requestOptions).then(handleResponse);
}

// Put Request Without Header
const putWithoutHeader = async (url, body) => {
    const requestOptions = {
        method: "PUT",
        headers: authHeaderWithoutToken(),
        body: JSON.stringify(body),
    };
    return await fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
// Delete Request With Header
const _delete = async (url) => {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
    };
    return await fetch(url, requestOptions).then(handleResponse);
}

// Delete Request With Header and parameters
const _deleteWithBody = async (url, body) => {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
        body: JSON.stringify(body),
    };
    return await fetch(url, requestOptions).then(handleResponse);
}

export const fetchWrapper = {
    get,
    getWithoutHeader,
    post,
    postwithFile,
    postWithParams,
    postWithoutHeader,
    postwithFileandNoAuth,
    put,
    putwithFile,
    putWithoutHeader,
    delete: _delete,
    deleteWithBody: _deleteWithBody,
};
