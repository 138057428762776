import DataGrid, {
  Column,
  Button as DataGridButton,
  FilterPanel,
  Scrolling,
  ColumnChooser,
  HeaderFilter,
  SearchPanel,
  FilterBuilderPopup,
  Pager,
  StateStoring,
  Toolbar,
  Item,
  ColumnChooserSearch,
  Search,
} from "devextreme-react/data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Sorting } from "devextreme-react/gantt";
import { useScreenSize } from "../../../utils/media-query";
import { Header } from "../../../components";
import ListSkeleton from "../../../layouts/templates/ListSkeleton";
import {
  GetFormattedDateTimeUTCString,
  HideDatagridLoader,
  ShowAlert,
  eCRUDStatus,
  onDragOver,
} from "../../../utils/common-methods";
import CityPopup from "./CityPopup";
import { confirm } from "devextreme/ui/dialog";
import { CityServices } from "../../../api/services/CityServices";

const cityServices = new CityServices();

export default function CityList() {
  const { isExSmall, isXXSmall } = useScreenSize();

  const [filterVisible, setFilterVisible] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [dsCity, setDsCity] = useState([]);
  const [showCityPopup, setShowCityPopup] = useState(false);
  const [primaryKey, setPrimaryKey] = useState(null);
  const [focusedRowKey, setfocusedRowKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });

  const DataGridRef = useRef(null);

  const GetList = async () => {
    // setShowSkeleton(true)
    const result = await cityServices.GetListData();
    if (result?.statusCode === 400) {
      ShowAlert(result.data, "Vakency");
      setShowSkeleton(false);
    } else {
      const CityData = result?.data?.map((item) => {
        const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
        const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
        return {
          ...item,
          createdOn: createdOn ? createdOn : null,
          updatedOn: updatedOn ? updatedOn : null,
        };
      });
      setDsCity(CityData);
      setShowSkeleton(false);
    }
  };

  useEffect(() => {
    GetList();
  }, []);

  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID =
        status.eStatus === eCRUDStatus.Inserted
          ? status.primaryKeyID
          : primaryKey;
      UpdateListByID(PrimaryKeyID);
    }
  }, [status]);

  const UpdateListByID = async (primaryKey) => {
    setShowSkeleton(true);
    const result = await cityServices.GetListByIdData(primaryKey);
    if (result?.statusCode === 400) {
      ShowAlert(result.data, "Vakency");
    } else {
      const CityData = result?.data?.map((item) => {
        const createdOn = item.createdOn
          ? new Date(
              new Date(item.createdOn + "Z").toUTCString()
            ).toLocaleString()
          : null;
        const updatedOn = item.updatedOn
          ? new Date(
              new Date(item.updatedOn + "Z").toUTCString()
            ).toLocaleString()
          : null;
        return {
          ...item,
          createdOn: createdOn ? createdOn : null,
          updatedOn: updatedOn ? updatedOn : null,
        };
      });
      if (status.eStatus === eCRUDStatus.Inserted) {
        setShowSkeleton(false);
        setDsCity([CityData[0], ...dsCity]);
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setShowSkeleton(false);
        setDsCity(
          dsCity.map((i) => (i.cityID === primaryKey ? CityData[0] : i))
        );
      }
    }
    setfocusedRowKey(primaryKey);
    setPrimaryKey(0);
  };

  const handleAdd = () => {
    setPrimaryKey(null);
    setShowCityPopup(true);
  };

  const handleEdit = (e) => {
    setPrimaryKey(e.data.cityID);
    setShowCityPopup(true);
  };

  const onOptionChange = (e) => {
    if (e.fullName === "filterValue") {
      setFilterVisible(!!e.value);
    }
  };

  const onDeleteButtonClick = (e) => {
    let res = confirm(
      `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
      "Vakency"
    );
    res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
  };

  const handleDelete = async (e) => {
    const Id = e.row.data.cityID;
    const result = await cityServices.DeleteData(Id);
    if (result?.statusCode === 400) {
      ShowAlert(result.data, "Vakency");
    } else if (result?.statusCode === 200) {
      setDsCity(dsCity.filter((x) => x.cityID !== e.row.data.cityID));
    }
  };

  const onRowDbClick = (e) => {
    setPrimaryKey(e.data.cityID);
    setShowCityPopup(true);
  };

  const CityName = (cellData) => {
    return (
      <>
        <div>
          <span
            className="text-decoration-underline pointer-link"
            title="Edit"
            onClick={(e) => handleEdit(cellData)}
          >
            {cellData.data.cityName}
          </span>
        </div>
      </>
    );
  };

  function onFocusedRowChanged(e) {
    setfocusedRowKey(e.component.option("focusedRowKey"));
  }

  return (
    <div>
      <Header
        title={"Cities"}
        handleAdd={handleAdd}
        dataGridRef={DataGridRef}
        GetRecord={GetList}
      />
      <div className="list-section" onDragOver={onDragOver}>
        {showSkeleton ? (
          <ListSkeleton />
        ) : (
          <DataGrid
            ref={DataGridRef}
            dataSource={dsCity}
            showBorders={true}
            showColumnLines={false}
            showRowLines={true}
            focusedRowEnabled={true}
            wordWrapEnabled={true}
            hoverStateEnabled={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            autoNavigateToFocusedRow={true}
            focusedRowKey={focusedRowKey}
            keyExpr="cityID"
            height={"100%"}
            width={"100%"}
            filterSyncEnabled={true}
            onOptionChanged={onOptionChange}
            className="List_DataGrid"
            loadPanel={HideDatagridLoader}
            onRowDblClick={onRowDbClick}
            noDataText="No Record Found"
            onFocusedRowChanged={onFocusedRowChanged}
          >
            <Sorting mode={"multiple"} />
            <FilterPanel visible={filterVisible} />
            <FilterBuilderPopup visible={false} />
            <Scrolling  mode={"infinite"} preloadEnabled={true} useNative={true}/>
            <ColumnChooser enabled={true} width={!isExSmall ? 320 : "auto"}>
              <ColumnChooserSearch enabled={true} />
            </ColumnChooser>
            <HeaderFilter visible={true}>
              <Search enabled={true} />
            </HeaderFilter>
            <SearchPanel
              visible={true}
              width={isExSmall || isXXSmall ? 200 : 400}
            />
            <StateStoring
              enabled={true}
              type="localStorage"
              storageKey="City_Layout"
            />
            <Pager visible={true} showInfo={true} infoText={`{2} Rows`}></Pager>
            <Toolbar>
              <Item location={"after"} name="columnChooserButton" />
              <Item location={"after"} name="searchPanel" />
            </Toolbar>
            <Column
              caption="City"
              cellRender={CityName}
              dataField="cityName"
              minWidth={120}
              width={250}
              allowHiding={false}
            />
            <Column
              caption="District"
              dataField="districtName"
              minWidth={120}
              width={250}
            />
            <Column
              caption="Zip Code"
              dataField="zip"
              minWidth={120}
              width={100}
              alignment="left"
            />
            <Column caption="State" dataField="cityStateName" minWidth={120} />
            <Column caption="Country" dataField="countryName" minWidth={120} />
            <Column
              dataField="createdByUserName"
              caption="Created By"
              visible={false}
              width={125}
              minWidth={80}
              alignment="left"
            />
            <Column
              dataField="updatedByUserName"
              caption="Updated By"
              visible={false}
              width={125}
              minWidth={80}
              alignment="left"
            />
            <Column
              dataField="createdOn"
              caption="Created On"
              visible={false}
              width={135}
              minWidth={80}
              alignment="left"
              // dataType='datetime'
              // format="dd/MM/yyyy hh:mm a"
            />
            <Column
              dataField="updatedOn"
              caption="Updated On"
              visible={false}
              width={137}
              minWidth={80}
              alignment="left"
              // dataType='datetime'
              // format="dd/MM/yyyy hh:mm a"
            />
            <Column
              type="buttons"
              fixed={false}
              allowResizing={false}
              width={43}
              allowReordering={false}
            >
              <DataGridButton
                name="delete"
                icon="trash"
                hint="Delete"
                visible={true}
                cssClass="text-danger"
                onClick={onDeleteButtonClick}
              />
            </Column>
          </DataGrid>
        )}
      </div>

      {showCityPopup === true && (
        <CityPopup
          openPopup={showCityPopup}
          setOpenPopup={setShowCityPopup}
          primaryKey={primaryKey}
          setStatus={setStatus}
          status={status}
        />
      )}
    </div>
  );
}
