
import React, { useEffect, useState } from 'react';
import './JobOpening.scss';
import { CircularGaugeFunc, ShowAlert } from '../../utils/common-methods';
import gaugeColor from "../../utils/custom-additional-variables.scss";
import { JobOpeningServices } from '../../api/services/JobOpeningServices';

import { useScreenSize } from '../../utils/media-query';

const jobOpeningServices = new JobOpeningServices();

export default function JobOpeningHeader(props) {
  const[dsJobHeader,setDsJobHeader] = useState();
  const[dsJobOpenings,setDsJobOpenings] =useState();
  const [jobProfileCount,setJobProfileCount] = useState(0);

  const {
    isMedium,
    isLarge,
    isExLarge,
  } = useScreenSize();

  
  const GetListHeader = async () => {
    var params = new URLSearchParams();
    if (props?.filterDate) {
      const date = new Date(props?.filterDate);
      const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      params.append('JobOpeningDate', formattedDate);
    }
    if (props?.filterOpen === true || props?.filterOpen === false) {
      params.append('open', props?.filterOpen);
    }
    if (props?.filterPublic === true || props.filterPublic === false) {
      params.append('public', props?.filterPublic);
    }
    const result = await jobOpeningServices.GetListHeader(params.toString());
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency")
      }
      else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency")
      }
    }
    else {
      setDsJobHeader(result?.data)
      const uniqueJobProfileNames = props?.dsJobOpening && new Set(props?.dsJobOpening?.map(job => job.jobProfileName));
      const JobProfileCount = uniqueJobProfileNames.size;
      props.setJobOpeningHeaderData && props.setJobOpeningHeaderData({
        applications: result?.data?.applications || 0,
        shortlisted : result?.data?.shortlisted || 0,
        hired :  result?.data?.hired || 0,
        onBoarding :  result?.data?.onBoarding || 0,
        rejected :  result?.data?.rejected || 0,
        dropOut :  result?.data?.dropOut || 0,
        openings : props?.dsJobOpening ? props?.dsJobOpening?.length : 0,
        profile: JobProfileCount || 0,
        openAccessCode: result?.data?.openAccessCode || null,
      })
      setJobProfileCount(JobProfileCount)
    }
  }

  useEffect(() => {
    GetListHeader();
    setDsJobOpenings(props?.dsJobOpening)
  }, [props?.filterDate,props?.filterOpen,props?.filterPublic,props?.dsJobOpening])




  return (
    <>
      <div className="row px-3 justify-content-center">
      <div className="col px-0 mx-2 mt-3">
            <div className="custom-card p-3 row mx-0">
              <div className='col-auto px-0'>
              <CircularGaugeFunc
                endValue={(jobProfileCount && dsJobOpenings && dsJobOpenings.length > 0)
                  ?  Math.round((jobProfileCount / dsJobOpenings.length) * 100)
                  : 0}
                color={gaugeColor.underProcessGaugeColor}
                percentValue={(jobProfileCount && dsJobOpenings && dsJobOpenings.length > 0)
                  ? ((jobProfileCount / dsJobOpenings.length) * 100).toFixed(1)
                  : 0}
              />
              </div>
              <div className="ps-3 col px-0 text-center">
                
                  <span className="font-medium-16">{(dsJobHeader?.applications)?dsJobHeader?.applications:0}</span>{" "}
                  <span className="font-medium-14">Applications</span>
                  <div className='row mt-2'>
                    <div className='col-6 border-end'>
                    <span className="font-medium-16">{(jobProfileCount)?jobProfileCount:0}</span>
                    <span className="d-block top-card-caption">Profile</span>
                    </div>
                    <div className='col-6'>
                    <span className="font-medium-16">{dsJobOpenings?(dsJobOpenings?.length):0}</span>
                    <span className="d-block top-card-caption">Openings</span>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="col px-0 mx-2 mt-3">
            <div className="custom-card p-3 d-flex ">
              <CircularGaugeFunc
                endValue={dsJobHeader?.shortlistedRatio? Math.round((dsJobHeader?.shortlistedRatio) * 100) : 0}
                color={gaugeColor.shortListedGaugeColor}
                percentValue={dsJobHeader?.shortlistedRatio? Math.round((dsJobHeader?.shortlistedRatio ) * 100) :0}
              />
              <div className="ps-3 d-flex align-items-center">
                <div>
                  <span className="font-medium-18">{(dsJobHeader?.shortlisted)?dsJobHeader?.shortlisted:0}</span>
                  <br />
                  <span className="font-medium-16">Shortlisted</span>
                  <div className="top-card-caption">Out of {(dsJobHeader?.applications)?dsJobHeader?.applications:0} Applications</div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="col px-0 mx-2 mt-3">
            <div className="custom-card p-3 d-flex">
              <CircularGaugeFunc
                color={gaugeColor.hiredGaugeColor}
                endValue={dsJobHeader?.hiredRatio? Math.round((dsJobHeader?.hiredRatio) * 100) : 0}
                percentValue={dsJobHeader?.hiredRatio ? Math.round((dsJobHeader?.hiredRatio) * 100) : 0}
              />
              <div className="ps-3 d-flex align-items-center">
                <div>
                  <span className="font-medium-18">{(dsJobHeader?.hired)?dsJobHeader?.hired:0}</span>
                  <br />
                  <span className="font-medium-16">Hired</span>
                  <div className="top-card-caption">Out of {(dsJobHeader?.shortlisted)?dsJobHeader?.shortlisted:0} Shortlisted</div>
                </div>
              </div>
            </div>
          </div>
          {(isLarge || isMedium) && !isExLarge && <div className="w-100"></div>}
          <div className="col px-0 mx-2 mt-3">
            <div className="custom-card p-3 d-flex">
              <CircularGaugeFunc
                color={gaugeColor.onBoardingGaugeColor}
                endValue={dsJobHeader?.onBoardedRatio? Math.round((dsJobHeader?.onBoardedRatio ) * 100) : 0 }
                percentValue={dsJobHeader?.onBoardedRatio? Math.round((dsJobHeader?.onBoardedRatio) * 100) : 0}
              />
              <div className="ps-3 d-flex align-items-center">
                <div>
                  <span className="font-medium-18">{(dsJobHeader?.onBoarding)?dsJobHeader?.onBoarding:0}</span>
                  <br />
                  <span className="font-medium-16">On Boarding</span>
                  <div className="top-card-caption">Out of {(dsJobHeader?.hired)?dsJobHeader?.hired:0} Hired</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col px-0 mx-2 mt-3">
            <div className="custom-card p-3 d-flex ">
              <CircularGaugeFunc
                color={gaugeColor.rejectedGaugeColor}
                endValue={dsJobHeader?.rejectedRatio? Math.round((dsJobHeader?.rejectedRatio) * 100) : 0}
                percentValue={dsJobHeader?.rejectedRatio? Math.round((dsJobHeader?.rejectedRatio) * 100) : 0}
              />
              <div className="ps-3 d-flex align-items-center">
                <div>
                  <span className="font-medium-18">{(dsJobHeader?.rejected)?dsJobHeader?.rejected:0}</span>
                  <br />
                  <span className="font-medium-16">Rejected</span>
                  <div className="top-card-caption">
                    Out of {(dsJobHeader?.applications)?dsJobHeader?.applications:0} Applications
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col px-0 mx-2 mt-3">
            <div className="custom-card p-3 d-flex">
              <CircularGaugeFunc
                color={gaugeColor.dropoutGaugeColor}
                endValue={dsJobHeader?.dropOutRatio ? Math.round((dsJobHeader?.dropOutRatio) * 100) : 0}
                percentValue={dsJobHeader?.dropOutRatio ? Math.round((dsJobHeader?.dropOutRatio) * 100) : 0}
              />
              <div className="ps-3 d-flex align-items-center">
                <div>
                  <span className="font-medium-18">{(dsJobHeader?.dropOut)?dsJobHeader?.dropOut:0}</span>
                  <br />
                  <span className="font-medium-16">Dropout</span>
                  <div className="top-card-caption">
                    Out of {(dsJobHeader?.applications)?dsJobHeader?.applications:0} Applications
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
