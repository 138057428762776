import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { SkillControlName } from "../../contexts/APIurl";
const url = SkillControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class SkillServices extends VakencyApiServices {
  constructor() {
    super(url);
  }

  CheckDuplicateData= async(params)=> {
    let data = null;
    const noResponseData = true;
    try {
        const CheckUrl = `${url}/CheckDuplicateName?${params}`;
        data = await fetchWrapper.get(baseURL + CheckUrl)
      return this.handleResponse(data, noResponseData);
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}
}
