import React, { useMemo, useState } from "react";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import { useAuth } from "../../contexts/auth";
import "./UserPanel.scss";
import { userIcon } from "../../utils/base-64-Icons";
import UserProfile from "./UserProfile";

export default function UserPanel({ menuMode, setDropDownClick }) {
  const { signOut, profile } = useAuth();

  const [showView, setShowView] = useState(false);

  const menuItems = useMemo(
    () => [
      {
        text: "User Profile",
        icon: "user",
        onClick: () => setShowView(true),
      },
      {
        text: "Logout",
        icon: "runner",
        onClick: signOut,
      },
    ],
    [signOut]
  );

  return (
    <div className={"user-panel"}>
      <div className={"user-info"}>
        <div className={"image-container"}>
          {profile ? (
            <div className="userImageContainer">
              <img
                src={profile}
                alt="user"
                className="userImage_Profile"
                draggable={false}
              />
            </div>
          ) : (
            <div className="userImageContainer">
              <img src={userIcon} alt="user" draggable={false} />
            </div>
          )}
        </div>
      </div>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          width={180}
          cssClass={"user-menu"}
        >
          <Position
            my={{ x: "center", y: "top" }}
            at={{ x: "center", y: "bottom" }}
          />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
      {showView && (
        <UserProfile
          showView={showView}
          setShowView={setShowView}
          setDropDownClick={setDropDownClick}
        />
      )}
    </div>
  );
}
