import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { JobOpeningControlPanelName } from "../../contexts/APIurl";

const url = JobOpeningControlPanelName;
const baseURL = process.env.REACT_APP_BASEURL;

export class JobOpeningControlPanelServices extends VakencyApiServices {
    constructor() {
        super(url);
    }



    GetHeaderList = async (id) => {
        const GetListUrl = url + `/GetHeader/${id}`

        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }
    GetOfferDetails = async (id) => {
        const GetListUrl = url + `/GetOffer/${id}`

        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }
    GetOfferLetterFile = async (jobApplicationID) => {
        const GetUrl = url + `/GetOfferLetterFile/${jobApplicationID}`
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetUrl)
            if (data.status && data.status === 200) {
                const fileUrl = await data.text();
                return this.fetchFileAsBlob(fileUrl);
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GenerateOfferNo = async () => {
        const GetListUrl = url + `/GenerateOfferNo/`

        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }
    GetApplicatonList = async (id) => {
        const GetListUrl = url + `/GetApplications/${id}`


        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }
    GetInteractionList = async (id) => {
        const GetListUrl = url + `/GetInteractions/${id}`
        let data = null;
        try {

            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);

        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }
    GetSchedulesList = async (id) => {
        const GetListUrl = url + `/GetSchedules/${id}`
        let data = null;
        try {

            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);

        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    MoveToShortlistedApplication = async (JobApplicationID, UpdatedOn) => {
        const MoveURL = url + `/Shortlist?JobApplicationID=${JobApplicationID}` + `${UpdatedOn ? (`&UpdatedOn=` + UpdatedOn) : ""}`
        let data = null;
        try {
            const MoveAPIOption = {
                method: 'PUT',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
                }
            }
            data = await fetch(baseURL + MoveURL, MoveAPIOption)
            if (data.status && data.status === 200) {
                return {
                    isOk: true,
                    data: data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    RejectJobApplication = async (dataObj) => {
        const UpdateUrl = url + "/RejectApplication"
        let data = null;
        try {
            data = await fetchWrapper.put(baseURL + UpdateUrl, dataObj)
            return this.handleResponse(data);
        }

        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    CandidateNotResponding = async (dataObj) => {
        const UpdateUrl = url + "/CandidateNotResponding"
        let data = null;
        try {
            data = await fetchWrapper.put(baseURL + UpdateUrl, dataObj)
            return this.handleResponse(data);
        }

        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }
    CandidateCancelled = async (dataObj) => {
        const UpdateUrl = url + "/CandidateCancelled"
        let data = null;
        try {
            data = await fetchWrapper.put(baseURL + UpdateUrl, dataObj)
            return this.handleResponse(data);
        }

        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }
    FinalizeHiring = async (dataObj) => {
        const UpdateUrl = url + "/Hired"
        let data = null;
        try {
            data = await fetchWrapper.put(baseURL + UpdateUrl, dataObj)
            return this.handleResponse(data);
        }

        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }
    OfferRejectedByCandidate = async (dataObj) => {
        const UpdateUrl = url + "/OfferRejectedByCandidate"
        let data = null;
        try {
            data = await fetchWrapper.put(baseURL + UpdateUrl, dataObj)
            return this.handleResponse(data);
        }

        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }
    SendOfferRejectedByCandidateEmail = async (jobApplicationID) => {
        const InsertUrl = url + `/SendOfferRejectedByCandidateEmail?JobApplicationID=${jobApplicationID}`;
        let data = null;
        try {
            data = await fetchWrapper.postWithParams(baseURL + InsertUrl)
            if (data.status && data.status === 200) {
                return {
                    isOk: true,
                    data: await data?.text(),
                    statusCode: data.status,
                };
            } else {
                return {
                    isOk: false,
                    data: await data?.text(),
                    statusCode: data.status,
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }
    RevokeOffer = async (dataObj) => {
        const UpdateUrl = url + "/RevokeOffer"
        let data = null;
        try {
            data = await fetchWrapper.put(baseURL + UpdateUrl, dataObj)
            return this.handleResponse(data);
        }

        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }
    SendRevokeOfferEmail = async (jobApplicationID) => {
        const InsertUrl = url + `/SendRevokeOfferEmail?JobApplicationID=${jobApplicationID}`;
        let data = null;
        try {
            data = await fetchWrapper.postWithParams(baseURL + InsertUrl)
            if (data.status && data.status === 200) {
                return {
                    isOk: true,
                    data: await data?.text(),
                    statusCode: data.status,
                };
            } else {
                return {
                    isOk: false,
                    data: await data?.text(),
                    statusCode: data.status,
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    InitiateOffer = async (params) => {
        const formData = new FormData();

        if (params?.updatedOn !== null && params?.updatedOn !== undefined) {
            formData.append('updatedOn', params?.updatedOn);
        }

        formData.append('jobApplicationID', params?.jobApplicationID);
        formData.append('offerDate', params?.offerDate);
        formData.append('offervalidUpto', params?.offervalidUpto);
        formData.append('expectedJoiningDate', params?.expectedJoiningDate);
        formData.append('offeredCTCPA', params?.offeredCTCPA);
        formData.append('descrPlainText', params?.descrPlainText);
        formData.append('descrFormattedText', params?.descrFormattedText);
        formData.append('attachment', params?.attachment);
        const UpdateUrl = url + `/InitiateOffer`
        let data = null;
        try {
            data = await fetchWrapper.putwithFile(baseURL + UpdateUrl, formData)

            if (data.status && data.status === 200) {
                return {
                    isOk: data.ok,
                    data: await data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: data.ok,
                    data: await data.text(),
                    stautsCode: data.status && data.status
                };
            }
        }

        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    SendInitiateOfferEmail = async (jobApplicationID) => {
        const InsertUrl = url + `/SendInitiateOfferEmail?JobApplicationID=${jobApplicationID}`;
        let data = null;
        try {
            data = await fetchWrapper.postWithParams(baseURL + InsertUrl)
            if (data.status && data.status === 200) {
                return {
                    isOk: true,
                    data: await data?.text(),
                    statusCode: data.status,
                };
            } else {
                return {
                    isOk: false,
                    data: await data?.text(),
                    statusCode: data.status,
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    AcceptOffer = async (dataObj) => {
        const UpdateUrl = url + "/AcceptOffer"
        let data = null;
        try {
            data = await fetchWrapper.put(baseURL + UpdateUrl, dataObj)
            return this.handleResponse(data);
        }

        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    MoveToPendingApplication = async (JobApplicationID) => {
        const MoveURL = url + `/MoveToPending?JobApplicationID=${JobApplicationID}`
        let data = null;
        try {
            const MoveAPIOption = {
                method: 'PUT',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
                }
            }
            data = await fetch(baseURL + MoveURL, MoveAPIOption)

            if (data.status && data.status === 200) {
                return {
                    isOk: true,
                    data: data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }
    MoveToSelectApplication = async (JobApplicationID, UpdatedOn) => {
        const MoveURL = url + `/SelectApplication?JobApplicationID=${JobApplicationID}` + `${UpdatedOn ? (`&UpdatedOn=` + UpdatedOn) : ""}`
        let data = null;
        try {
            const MoveAPIOption = {
                method: 'PUT',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
                }
            }
            data = await fetch(baseURL + MoveURL, MoveAPIOption)

            if (data.status && data.status === 200) {
                return {
                    isOk: true,
                    data: data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }
    MoveToUnderReview = async (JobApplicationID) => {
        const MoveURL = url + `/MoveToUnderReview?JobApplicationID=${JobApplicationID}`
        let data = null;
        try {
            const MoveAPIOption = {
                method: 'PUT',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
                }
            }
            data = await fetch(baseURL + MoveURL, MoveAPIOption)

            if (data.status && data.status === 200) {
                return {
                    isOk: true,
                    data: data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }
    MoveToDeSelectApplication = async (JobApplicationID, UpdatedOn) => {
        const MoveURL = url + `/DeselectApplication?JobApplicationID=${JobApplicationID}` + `${UpdatedOn ? (`&UpdatedOn=` + UpdatedOn) : ""}`

        let data = null;
        try {
            const MoveAPIOption = {
                method: 'PUT',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
                }
            }
            data = await fetch(baseURL + MoveURL, MoveAPIOption)

            if (data.status && data.status === 200) {
                return {
                    isOk: true,
                    data: data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    OnBoardingApplication = async (dataObj) => {
        const UpdateUrl = url + "/OnBoarding"
        let data = null;
        try {
            data = await fetchWrapper.put(baseURL + UpdateUrl, dataObj)
            return this.handleResponse(data);
        }

        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    GetInteractionRounds = async (id) => {
        const GetListUrl = url + `/GetInteractionRounds/${id}`
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

}

