import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { CityControlName } from "../../contexts/APIurl";
const url = CityControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class CityServices extends VakencyApiServices {
  constructor() {
    super(url);
  }

// Function to check duplicate city name
  CheckDuplicateCityName = async (params) => {
    const noResponseData = true;
    let data = null;
    try {
      const CheckUrl = `${url}/CheckDuplicateCityName?${params}`;
      data = await fetchWrapper.get(baseURL + CheckUrl);
      return this.handleResponse(data, noResponseData);
    } catch {
      return {
        isOk: false,
        message: "Failed To Load Data",
        statusCode: data.status,
      };
    }
  };

  // Function to get lookup list by id for cityDropdown
  GetLookupListByIDData = async (id) => {
    let data = null;
    try {
      const GetListUrl = "/GetLookupList/";
      data = await fetchWrapper.get(baseURL + url + GetListUrl + id);
      return this.handleResponse(data);
    } catch {
      return {
        isOk: false,
        message: "Failed To Load Data",
        statusCode: data.status,
      };
    }
  };

  GetZipCode=async(cityID)=>{
    const GetListUrl = url + `/GetZips?CityID=${cityID}`
    let data = null;
    try {
        data = await fetchWrapper.get(baseURL + GetListUrl)
        return this.handleResponse(data);
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}
}
