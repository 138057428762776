import React, { useEffect, useRef, useState } from "react";
import {
  SelectBox,
  Button as TextBoxButton,
} from "devextreme-react/select-box";
import { Validator } from "devextreme-react";
import { RequiredRule } from "devextreme-react/validator";
import JobOpeningPopup from "./JobOpeningPopup";
import { ShowAlert, eCRUDStatus } from "../../utils/common-methods";
import { JobOpeningServices } from "../../api/services/JobOpeningServices";

const jobOpeningServices = new JobOpeningServices()
export default function JobOpeningDropDown(props) {

  const [openPopup, setOpenPopup] = useState(false);
  const [DsJobOpening, setDsJobOpening] = useState([]);
  let [primaryKey, setPrimaryKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });

  useEffect(() => {
    GetJobOpeningLookupList();
  }, []);

  const GetJobOpeningLookupList = async () => {
    const result = await jobOpeningServices.GetLookupListData();
    if (result.isOk) {
      setDsJobOpening(result.data);
    }
  };

  useEffect(() => {
    openPopup === false &&
      props.setDropDownClick &&
      props.setDropDownClick(false);
  }, [openPopup]);

  const SelectBoxRef = useRef(null);

  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID =
        status.eStatus === eCRUDStatus.Inserted
          ? status.primaryKeyID
          : primaryKey;
      UpdateListByID(PrimaryKeyID);
    } else {
      setPrimaryKey(0);
    }
  }, [status]);

  const UpdateListByID = async (primaryKey) => {
    const result = await jobOpeningServices.GetListByIdData(primaryKey);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency");
    } else {
      if (status.eStatus === eCRUDStatus.Inserted) {
        setDsJobOpening([result.data[0], ...DsJobOpening]);
        setPrimaryKey(0);
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setDsJobOpening(
          DsJobOpening.map((i) =>
            i.jobOpeningID === primaryKey ? result.data[0] : i
          )
        );
      }
    }
    props.setAddedJobOpeningID && props.setAddedJobOpeningID(primaryKey);
  };

  const AddButton = {
    icon: "plus",
    hint: "Add Job Opening",
    stylingMode: "text",
    onClick: () => {
      props.setDropDownClick && props.setDropDownClick(true);
      setOpenPopup(!openPopup);
    },
  };

  const DownArrow = {
    icon: "spindown",
    stylingMode: "text",
    onClick: (e) => {
      var selectBoxInstance = SelectBoxRef.current?.instance;
      var isOpened = selectBoxInstance.option("opened");
      if (isOpened) {
        selectBoxInstance.close();
      } else {
        selectBoxInstance.open();
        selectBoxInstance.focus();
      }
    },
  };

  return (
    <div>
      <SelectBox
        label={props.label ? props.label : "Job Opening"}
        maxLength={50}
        id="JobOpening_DropDown"
        dataSource={DsJobOpening}
        displayExpr={"jobProfileName"}
        valueExpr="jobOpeningID"
        searchEnabled={true}
        ref={SelectBoxRef}
        labelMode="floating"
        value={props.value}
        onValueChange={props.onValueChange}
        dropDownOptions={{
          height: props.dropdownHeight ? props.dropdownHeight : 120,
          hideOnParentScroll:true,
          container:"#JobOpening_DropDown"
        }}
        readOnly={props.readOnly && props.readOnly}
      >
        <TextBoxButton name="JobOpening" location="after" options={AddButton} />
        <TextBoxButton name="dropdown" location="after" options={DownArrow} />
        {props.validationRequired && (
          <Validator
            validationGroup={props.validationGroup}
            elementAttr={{ class: props.customValidate ? "" : "ForValidationAstrikMark" }}
          >
           {props.customValidate ? ( 
            (!props.EditorData && !props.value) && (
              <RequiredRule message={props.validationMessage} />
            ))
              : 
              (
               <RequiredRule
                   message={
                      props.validationMessage
                        ? props.validationMessage
                           : "Job Opening is required"
                  }            
          />
          )}
          </Validator>
        )}
      </SelectBox>
      {openPopup === true && (
        <JobOpeningPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setStatus={setStatus}
          primaryKey={primaryKey}
        />
      )}
    </div>
  );
}
