import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { CandidateControlName } from "../../contexts/APIurl";

const url = CandidateControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class CandidateControlServices extends VakencyApiServices {
    constructor() {
        super(url);
    }

    // Function to check duplicate data
    CheckDuplicateEmailData = async (params) => {
        const noResponseData = true;
        let data = null;
        try {
            const CheckUrl = `${url}/CheckDuplicateEmailID?${params}`

            data = await fetchWrapper.get(baseURL + CheckUrl);
            return this.handleResponse(data, noResponseData);
        } catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status,
            };
        }
    };
    CheckDuplicateMobileNoData = async (params) => {
        const noResponseData = true;
        let data = null;
        try {
            var CheckUrl = `${url}/CheckDuplicateMobileNo?${params}`
            data = await fetchWrapper.get(baseURL + CheckUrl);
            return this.handleResponse(data, noResponseData);
        } catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status,
            };
        }
    };

    GetCandidateDocData = async (params) => {
        const GetListUrl = url + "/GetCandidateResume"

        let data = null;

        try {
            data = await fetchWrapper.get(baseURL + GetListUrl + params)
            if (data.status && data.status === 200) {
                const fileUrl = await data.text();
                return this.fetchFileAsBlob(fileUrl);
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GetCandidateSearchData_withoutJobProfileID = async () => {
        const GetListUrl = url + `/GetSearchList`
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    DeleteCandidatePicture = async (candidateID) => {
        const DeleteUrl = url + `/DeleteCandidateProfileImage?CandidateID=${candidateID}`
        let data = null;
        try {
            data = await fetchWrapper.delete(baseURL + DeleteUrl)
            if (data.status && data.status === 200) {
                return {
                    isOk: true,
                    data: data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    DeleteCandidateDocData = async (candidateID) => {
        const DeleteUrl = url + `/DeleteCandidateResume?CandidateID=${candidateID}`
        let data = null;
        try {
            data = await fetchWrapper.delete(baseURL + DeleteUrl)

            if (data.status && data.status === 200) {
                return {
                    isOk: true,
                    data: data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GenerateCandidateNoData = async () => {
        const GetListUrl = url + "/GenerateCandidateNo/"
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data)
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    UpdateCandidateDocData = async (params, id) => {
        const formData = new FormData();
        formData.append('attachment', params.attachment);
        formData.append('candidateID', params.candidateID);
        const UpdateUrl = url + `/UpdateCandidateResume/${id}`
        let data = null;
        try {
            data = await fetchWrapper.postwithFile(baseURL + UpdateUrl, formData)

            if (data.status && data.status === 200) {
                return {
                    isOk: data.ok,
                    data: await data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: data.ok,
                    data: await data.text(),
                    stautsCode: data.status && data.status
                };
            }
        }

        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    InsertImage = async (params, id) => {
        const formData = new FormData();
        formData.append("candidateID", params.candidateID);
        formData.append("attachments", params.attachments);
        const UpdateUrl = url + `/UpdateCandidateProfileImage/${id}`
        let data = null;
        try {
            data = await fetchWrapper.postwithFile(baseURL + UpdateUrl, formData)
            if (data.status && data.status === 200) {
                return {
                    isOk: data.ok,
                    data: await data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: data.ok,
                    data: await data.text(),
                    stautsCode: data.status && data.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    GetImage = async (params) => {
        const GetListUrl = url + "/GetCandidateProfileImage" + params
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            if (data.status && data.status === 200) {
                const fileUrl = await data.text();
                return this.fetchFileAsBlob(fileUrl);
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    DeleteCandidatePicture = async (candidateID) => {
        const DeleteUrl = url + `/DeleteCandidateProfileImage?CandidateID=${candidateID}`
        let data = null;
        try {
            data = await fetchWrapper.delete(baseURL + DeleteUrl)
            if (data.status && data.status === 200) {
                return {
                    isOk: true,
                    data: data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    DeleteCandidateDocData = async (candidateID) => {
        const DeleteUrl = url + `/DeleteCandidateResume?CandidateID=${candidateID}`
        let data = null;
        try {
            data = await fetchWrapper.delete(baseURL + DeleteUrl)

            if (data.status && data.status === 200) {
                return {
                    isOk: true,
                    data: data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GenerateCandidateNoData = async () => {
        const GetListUrl = url + "/GenerateCandidateNo/"
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data)
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    UpdateCandidateDocData = async (params, id) => {
        const formData = new FormData();
        formData.append('attachment', params.attachment);
        formData.append('candidateID', params.candidateID);
        const UpdateUrl = url + `/UpdateCandidateResume/${id}`
        let data = null;
        try {
            data = await fetchWrapper.postwithFile(baseURL + UpdateUrl, formData)

            if (data.status && data.status === 200) {
                return {
                    isOk: data.ok,
                    data: await data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: data.ok,
                    data: await data.text(),
                    stautsCode: data.status && data.status
                };
            }
        }

        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    InsertImage = async (params, id) => {
        const formData = new FormData();
        formData.append("candidateID", params.candidateID);
        formData.append("attachments", params.attachments);
        const UpdateUrl = url + `/UpdateCandidateProfileImage/${id}`
        let data = null;
        try {
            data = await fetchWrapper.postwithFile(baseURL + UpdateUrl, formData)
            if (data.status && data.status === 200) {
                return {
                    isOk: data.ok,
                    data: await data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: data.ok,
                    data: await data.text(),
                    stautsCode: data.status && data.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    uploadFiles = async (formData) => {
        const UpdateUrl = url + `/UploadFiles`
        let data = null;
        try {
            data = await fetchWrapper.postwithFile(baseURL + UpdateUrl, formData)
            if (data.status && data.status === 200) {
                return {
                    isOk: data.ok,
                    data: await data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: data.ok,
                    data: await data.text(),
                    stautsCode: data.status && data.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    getFiles = async (candidateID) => {
        const GetListUrl = url + `/GetFileList?ID=${candidateID}`
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl);
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    getFileUrl = async (candidateID, fileName) => {
        const GetUrl = url + `/GetFileUrl?ID=${candidateID}&fileName=${fileName}`;
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetUrl)
            if (data.status && data.status === 200) {
                const fileUrl = await data.text();
                return this.fetchFileAsBlob(fileUrl);
                return {
                    isOk: true,
                    data: await data.text(),
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    deleteFile = async (candidateID, fileName) => {
        const DeleteUrl = url + `/DeleteFile?ID=${candidateID}&FileName=${fileName}`;
        const noResponseData = true;
        let data = null;
        try {
            data = await fetchWrapper.delete(baseURL + DeleteUrl)
            return this.handleResponse(data, noResponseData);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    renameFile = async (candidateID, oldFileName, newFileName) => {
        const RenameUrl = url + `/RenameFile?ID=${candidateID}&oldFileName=${oldFileName}&newFileName=${newFileName}`;
        let data = null;
        const noResponseData = true;
        try {
            data = await fetchWrapper.put(baseURL + RenameUrl, {});
            return this.handleResponse(data, noResponseData);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

}

