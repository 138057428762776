import { Popup } from "devextreme-react";
import React, { useEffect, useState } from "react";
import { eEmailStatus, ShowAlert } from "../../utils/common-methods";
import { useScreenSize } from "../../utils/media-query";
import { TickIcon, rejectedIcon } from "../../utils/base-64-Icons";
import CircleLoader from "../../images/CircleLoader.gif";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import { JobOpeningControlPanelServices } from "../../api/services/JobOpeningControlPanelServices";

const jobOpeningControlPanelServices = new JobOpeningControlPanelServices();

const JobApplicationEmailStatusPopup = ({
    showEmailPopup,
    onClosePopup,
    candidateData,
    jobApplicationID,
    actionName,
}) => {
    const { isExSmall, isXXSmall, isXSmall, isMedium, isSmall } = useScreenSize();
    const [dsGroupCandidate, setDsGroupCandidate] = useState({});
    const [candidateEmailSent, setCandidateEmailSent] = useState(false);

    const PopupTitle = (e) => {
        return (
            <>
                <PopupHeader
                    onClosePopup={onClosePopup}
                    showCloseButton={"true"}
                    title={"Email"}
                    hideSaveButton={true}
                />
            </>
        );
    };

    useEffect(() => {
        if (candidateData) {
            let data = { ...candidateData };
            data.candidateEmailStatus = eEmailStatus.InProgress;
            data.candidateEmailResult = "Loading...";
            setDsGroupCandidate(data);
        } else {
            setDsGroupCandidate();
        }
    }, [candidateData]);

    const SendToCandidateEmail = async (jobApplicationID) => {

        let actionToPerform;
        if (actionName === "InitiateOffer") {
            actionToPerform = jobOpeningControlPanelServices.SendInitiateOfferEmail;
        }
        else if (actionName === "ReviseOffer") {
            actionToPerform = jobOpeningControlPanelServices.SendInitiateOfferEmail;
        }
        else if (actionName === "RevokeOffer") {
            actionToPerform = jobOpeningControlPanelServices.SendRevokeOfferEmail;
        }
        else if (actionName === "OfferRejectedByCandidate") {
            actionToPerform = jobOpeningControlPanelServices.SendOfferRejectedByCandidateEmail;
        }

        try {
            setCandidateEmailSent(true);
            const result = await actionToPerform(jobApplicationID);
            if (!result.isOk) {
                setDsGroupCandidate(prevState => ({
                    ...prevState,
                    candidateEmailStatus: eEmailStatus.Error,
                    candidateEmailResult: result.data.includes("Message dropped because all recipients were suppressed")
                        ? "Message dropped because all recipients were suppressed"
                        : result.data.includes("Value cannot be empty or contain only white-space characters. (Parameter 'address')")
                            ? "Invalid Email"
                            : result.data
                }));
            } else if (result && result.isOk) {
                setDsGroupCandidate(prevState => ({
                    ...prevState,
                    candidateEmailStatus: eEmailStatus.Success,
                    candidateEmailResult: "Email sent successfully"
                }));
            }
        } catch (error) {
            ShowAlert(error, "Vakency");
        }

    };

    useEffect(() => {
        if (dsGroupCandidate) {
            if (dsGroupCandidate.candidateEmailStatus == eEmailStatus.InProgress && !candidateEmailSent) {
                SendToCandidateEmail(jobApplicationID)
            }
        }
    }, [dsGroupCandidate]);

    return (
        <>
            <Popup
                visible={showEmailPopup}
                onHiding={onClosePopup}
                width={
                    isXSmall || isXXSmall || isExSmall
                        ? "90%"
                        : isMedium || isSmall
                            ? "30%"
                            : "20%"
                }
                height={"auto"}
                maxHeight={"92vh"}
                titleRender={PopupTitle}
                wrapperAttr={{ class: "CustomPopup" }}
                deferRendering={false}
            >
                <div className="row m-0">
                    <div className="col text-center">
                        {
                            dsGroupCandidate?.candidateEmailStatus == eEmailStatus.InProgress && (
                                <img
                                    src={CircleLoader}
                                    alt=""
                                    title={"In progress"}
                                    className="rejected-icon-email-status"
                                />
                            )
                        }
                        {
                            dsGroupCandidate?.candidateEmailStatus == eEmailStatus.Success && (
                                <img
                                    src={TickIcon}
                                    alt=""
                                    title={"Email Sent"}
                                    className="rejected-icon-email-status"
                                />
                            )
                        }
                        {
                            dsGroupCandidate?.candidateEmailStatus == eEmailStatus.Error && (
                                <img
                                    src={rejectedIcon}
                                    alt=""
                                    title={"Email not sent"}
                                    className="rejected-icon-email-status"
                                />
                            )
                        }
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col py-2 text-center font-semiBold-18">
                        {
                            dsGroupCandidate?.candidateEmailStatus == eEmailStatus.InProgress && (
                                <>
                                    <span className="text-gray">Email sending on</span>{" "}{dsGroupCandidate?.emailID}
                                </>
                            )
                        }
                        {
                            dsGroupCandidate?.candidateEmailStatus == eEmailStatus.Success && (
                                <>
                                    {dsGroupCandidate?.candidateEmailResult}
                                </>
                            )
                        }
                        {
                            dsGroupCandidate?.candidateEmailStatus == eEmailStatus.Error && (
                                <>
                                    {dsGroupCandidate?.candidateEmailResult}
                                </>
                            )
                        }
                    </div>
                </div>
            </Popup >
        </>
    );
};

export default JobApplicationEmailStatusPopup;
