import React from "react";
import ViewMode from "../../layouts/templates/ViewMode";
import { useNavigate } from "react-router-dom";

const JobApplicationView = (props) => {
    const navigate = useNavigate();

    const viewData = props.viewData;

  const handleEdit = () => {
    navigate('/jobApplication/Edit', {
      state: {JobApplicationId : viewData?.jobApplicationID}
    })
  }
  return (
    <>
      <ViewMode
        showView={props.showView}
        setShowView={props.setShowView}
        title={"Job Application"}
        handleEdit={handleEdit}
      >
        <div className="row">
          <div className="col">
          <div className="view-text-value">
              {viewData?.jobApplicationNo}
            </div>
            <div className="view-label">Job Application No.</div>
          </div>
          <div className="col">
          <div className="view-text-value">
              {viewData?.jobApplicationDate?.toLocaleDateString()}
            </div>
            <div className="view-label">Job Application Date</div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
          <div className="view-text-value">{viewData?.jobProfile}</div>
            <div className="view-label">Job Profile</div>
          </div>
          <div className="col">
          <div className="view-text-value">{viewData?.jobOpening}</div>
            <div className="view-label">Job Opening</div>
          
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
          <div className="view-text-value">{viewData?.candidate}</div>
            <div className="view-label">Candidate</div>
         
          </div>
          <div className="col">
          <div className="view-text-value">
              {viewData?.hiringManager}
            </div>
            <div className="view-label">Hiring Manager</div>
            
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
          <div className="view-text-value">{viewData?.status}</div>
            <div className="view-label">Status</div>
         
          </div>
          <div className="col">
          <div className="view-text-value">
              {viewData?.lastInteraction}
            </div>
            <div className="view-label">Last Interaction</div>
           
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
          <div className="view-text-value">{viewData?.offerNo}</div>
            <div className="view-label">Offer No.</div>
          
          </div>
          <div className="col">
          <div className="view-text-value">
              {viewData?.offerDate?.toLocaleDateString()}
            </div>
            <div className="view-label">Offer Date</div>
           
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
          <div className="view-text-value">{viewData?.offeredCTC}</div>
            <div className="view-label">Offered CTC</div>
           
          </div>
          <div className="col">
          <div className="view-text-value">
             {viewData?.offerAcceptedDate?.toLocaleDateString()}
            </div>
            <div className="view-label">Offer Accepted Date</div>
           
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
          <div className="view-text-value">
              {viewData?.expectedJoiningDate?.toLocaleDateString()}
            </div>
            <div className="view-label">Expected Joining Date</div>
           
          </div>
          <div className="col">
          <div className="view-text-value">
              {viewData?.acceptedCTC}
            </div>
            <div className="view-label">Accepted CTC</div>
           
          </div>
        </div>
        <div className="mt-4">
        <div className="view-text-value">
              {viewData?.description}
            </div>
        <div className="view-label">Description</div>
           
        </div>
        <div className='row pt-4 px-2'><hr className='mb-1'/></div>
          <div className='row text-end font-regular-12'>
             <div>Created by <span className='Italic-text'><i>Admin</i> </span>at <span className='Italic-text'><i>01:41 PM</i> </span>on <span className='Italic-text'><i>27/12/2023</i> </span></div>
          </div>
      </ViewMode>
    </>
  );
};

export default JobApplicationView;
