import React from 'react';
import { Link } from 'react-router-dom';
import './InvalidPage.scss';

export default function InvalidPage() {
  return (
    <div className='row Invalid-page'>
      <div className='text-center mb-2'><i className='dx-icon-warning warning-icon'></i></div>
      <div className='text-center my-2 fw-semibold message'>Page url is not valid</div>
      <div className='text-center mb-2'><Link to="/login">Sign In</Link></div>
    </div>
  )
}
