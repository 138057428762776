import React, { useState, useEffect } from "react";
import ViewMode from "../../layouts/templates/ViewMode";
import { useNavigate } from "react-router-dom";
import { GetFormattedDate, GetFormattedDateTimeUTCString, GetFormattedTime } from '../../utils/common-methods';
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import { BlankProfile } from "../../utils/base-64-Icons";

const candidateControlServices = new CandidateControlServices();
const CandidateView = ({ showView, setShowView, candidateViewPrimaryKey }) => {

  const navigate = useNavigate();
  const [viewData, setViewData] = useState({})
  const [updatedDetails, setupdatedDetails] = useState({
    updatedText: "",
    updatedDate: null,
    updatedTime: null,
    updatedByUsername: ""
  })
  const [profileImg, setProfileImg] = useState({
    uploadedFileUrl: null,
    attachedFile: null,
  })
  const [resume, setResume] = useState(null);
  const [filename, setFilename] = useState(null)


  useEffect(() => {
    GetCandidateModelData();
  }, [])

  const GetCandidateModelData = async () => {
    const result = await candidateControlServices.GetListByIdData(candidateViewPrimaryKey);
    if(result.isOk){
      const CandidateData = result.data?.map(item => {
        const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
        const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
        return {
          ...item,
          createdOn: createdOn ? createdOn: null,
          updatedOn: updatedOn ? updatedOn : null
        };
      });
      setViewData(CandidateData[0])
      SetUpdatedCandidateDetailsValue(CandidateData[0])
      GetProfileImage(candidateViewPrimaryKey).then((res) => {
        setProfileImg({
          uploadedFile: { fileData: (res?.size > 0 ? window.URL.createObjectURL(res) : null) },
          attachedFile: (res?.size > 0 ? res : null)
        })
      });
      GetResume(candidateViewPrimaryKey);
    }
  }

  const GetProfileImage = async (CandidateID) => {
    let params = `?CandidateID=${CandidateID}`
    const result = await candidateControlServices.GetImage(params)
    if (result.isOk) {
      return result.data;
    } else {
      return;
    }
  }

  const GetResume = async (CandidateID) => {
    let params = `?CandidateID=${CandidateID}`
    const result = await candidateControlServices.GetCandidateDocData(params)
    if (result.isOk) {
      setFilename("Resume.pdf");
      setResume(result.data);
    }
  }



  const SetUpdatedCandidateDetailsValue = (data) => {
    if (data?.updatedOn === null) {
      setupdatedDetails({
        updatedText: "Created by ",
        updatedDate: GetFormattedDate(data?.createdOn),
        updatedTime: GetFormattedTime(data?.createdOn),
        updatedByUsername: data?.createdByUserName
      })
    }
    else {
      setupdatedDetails({
        updatedText: "Updated by ",
        updatedDate: GetFormattedDate(data?.updatedOn),
        updatedTime: GetFormattedTime(data?.updatedOn),
        updatedByUsername: data?.updatedByUserName
      })
    }
  }

  const handleEdit = () => {
    navigate('/Candidate/Edit', {
      state: { CandidateID: viewData?.candidateID }
    })
  }



  return (
    <>
      <ViewMode
        showView={showView}
        setShowView={setShowView}
        title={"Candidate"}
        hideSubTitle={false}
        handleEdit={handleEdit}
      >
        <div className='row'>
          <div className='view-panel-title'>Personal Information</div>
        </div>
        <div className="row mt-3">
          <div className="col">

            <div className="view-text-value">
              {viewData?.candidateName}
            </div>
            <div className="view-label ">Candidate Name</div>


            <div className="view-text-value mt-4">
              {viewData?.candidateNo}
            </div>
            <div className="view-label">Candidate No.</div>


            <div className="view-text-value mt-4">
              {viewData?.jobProfileName}
            </div>
            <div className="view-label">Job Profile</div>


          </div>
          <div className="col">
            {profileImg.uploadedFile?.fileData ? (
              <img
                src={profileImg.uploadedFile?.fileData}
                alt="candidate"
                width={165}
                height={165}
                className="rounded border"
              />
            ) : (
              <img
                src={BlankProfile}
                alt="candidate"
                width={165}
                height={165}
                className="rounded"
              />
            )}
          </div>
        </div>
        <hr className='my-4' />
        <div className='row '>
          <div className='view-panel-title'>Contact Information</div>
        </div>
        <div className="row mt-3">
          <div className="view-text-value">{viewData?.emailID}</div>
          <div className="view-label">Email ID</div>

        </div>
        <div className="row mt-4">
          <div className="col">
            <div className="view-text-value">
              {viewData?.mobileNo1}
            </div>
            <div className="view-label">Mobile No.1</div>

          </div>
          <div className="col">
            <div className="view-text-value">
              {viewData?.mobileNo2}
            </div>
            <div className="view-label">Mobile No.2</div>

          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <div className="view-text-value">
              {viewData?.linkedIn}
            </div>
            <div className="view-label">Linkedin</div>

          </div>
          <div className="col">
            <div className="view-text-value">{viewData?.skype}</div>
            <div className="view-label">Skype</div>

          </div>
        </div>
        <hr className='my-4' />
        <div className='row '>
          <div className='view-panel-title'>Current Address Information</div>
        </div>
        <div className="view-text-value mt-3">
          {viewData?.currentAddress}
        </div>
        <div className="view-label">
          Current Address
        </div>

        <div className="row mt-4">
          <div className="col">
            <div className="view-text-value">{viewData?.currentCityName}</div>
            <div className="view-label">City</div>

          </div>
          <div className="col">
            <div className="view-text-value">{viewData?.currentPIN}</div>
            <div className="view-label">Pin</div>

          </div>
        </div>
        <hr className='my-4' />
        <div className='row'>
          <div className='view-panel-title'>Permanent Address Information</div>
        </div>
        <div className="mt-3">
          <div className="view-text-value">
            {viewData?.permanentAddress}
          </div>
          <div className="view-label">Permanent Address</div>

        </div>
        <div className="row mt-4">
          <div className="col">
            <div className="view-text-value">
              {viewData?.permanentCityName}
            </div>
            <div className="view-label">City</div>

          </div>
          <div className="col">
            <div className="view-text-value">
              {viewData?.permanentPIN}
            </div>
            <div className="view-label">Pin</div>

          </div>
        </div>
        <hr className='my-4' />
        <div className='row'>
          <div className='view-panel-title '>Additional Personal Information</div>
        </div>
        <div className="row  mt-3">
          <div className="col ">
            <div className="view-text-value">
              {viewData?.dob && GetFormattedDate(viewData?.dob)}
            </div>
            <div className="view-label ">DOB</div>

          </div>
          <div className="col">
            <div className="view-text-value">
              {viewData?.maritalStatusName}
            </div>
            <div className="view-label">Matrital Status</div>

          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <div className="view-text-value">
              {viewData?.dependentFamilyMember}
            </div>
            <div className="view-label">Dependent Family Members</div>

          </div>
        </div>
        <hr className='my-4' />
        <div className='row'>
          <div className='view-panel-title'>Educational and Professional Information</div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="view-text-value">
              {viewData?.academicQualifications}
            </div>
            <div className="view-label">Academic Qualification</div>

          </div>
          <div className="col">
            <div className="view-text-value">
              {viewData?.primaryEducationLanguage}
            </div>
            <div className="view-label">Primary Education Language</div>

          </div>
        </div>

        <div className="row mt-4">
          <div className="col">

            <div className="view-text-value">
              {viewData?.majorTechnicalSkills}
            </div>
            <div className="view-label">Major Technical Skills</div>



          </div>
          <div className="col">

            <div className="view-text-value">
              {viewData?.minorTechnicalSkills}
            </div>
            <div className="view-label">Minor Technical Skills</div>


          </div>
        </div>

        <div className="mt-4">
          <div className="view-text-value">
            {viewData?.otherTechnicalSkills}
          </div>
          <div className="view-label">Other Skills</div>

        </div>
        <hr className='my-4' />
        <div className='row'>
          <div className='view-panel-title'>Personal Attributes and Prefrences</div>
        </div>

        <div className="row mt-3">
          <div className="col">
            <div className="view-text-value">
              {viewData?.attitude}
            </div>
            <div className="view-label">Attitude</div>

          </div>
          <div className="col">
            <div className="view-text-value">{viewData?.candidateSourceName}</div>
            <div className="view-label">Source</div>

          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <div className="view-text-value">
              {viewData?.currentCompanyName}
            </div>
            <div className="view-label">Current Company Name</div>

          </div>
          <div className="col">
            <div className="view-text-value">
              {viewData?.currentCTC}
            </div>
            <div className="view-label">Current CTC</div>

          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <div className="view-text-value">
              {viewData?.expectedCTC}
            </div>
            <div className="view-label">Expected CTC</div>

          </div>
          <div className="col">
            <div className="view-text-value">
              {viewData?.noticePeriod}
            </div>
            <div className="view-label">Notice Period</div>

          </div>
        </div>

        <div className="row mt-4">
          <div className="view-text-value">
            {viewData?.readyToRelocate ? "Yes" : "No"}
          </div>
          <div className="view-label">Ready To Relocate</div>

        </div>

        <hr className='my-4' />
        <div className='row'>
          <div className='view-panel-title'>Work Experience</div>
        </div>

        <div className="row mt-3">
          <div className="col">
            <div className="view-text-value">
              {viewData?.totalExperience}
            </div>
            <div className="view-label">Total Experience</div>

          </div>
          <div className="col">
            <div className="view-text-value">
              {viewData?.relevantExperience}
            </div>
            <div className="view-label">Relevant Experience</div>

          </div>
        </div>
        <hr className='my-4' />
        <div className='row'>
          <div className='view-panel-title'>Other Information</div>
        </div>

        <div className="row mt-3">
          <div className="col">
            <div className="view-text-value">{filename && filename}</div>
            <div className="view-label">Resume</div>
          </div>
        </div>
        <div className="mt-4">
          <div className="view-text-value">
            {viewData?.descrPlainText}
          </div>
          <div className="view-label">Description/Notes</div>

        </div>
        <div className='row px-2 mt-4'><hr className='mb-1' /></div>
        <div className='row text-end font-regular-12 pb-5'>
          <div>{updatedDetails?.updatedText}<span className='Italic-text'><i>{updatedDetails?.updatedByUsername}</i> </span>at <span className='Italic-text'><i>{updatedDetails?.updatedTime}</i> </span>on <span className='Italic-text'><i>{updatedDetails?.updatedDate}</i> </span></div>
        </div>
      </ViewMode>
    </>
  );
};

export default CandidateView;
