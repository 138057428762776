import React from 'react';
import { Link } from 'react-router-dom';
import './RegistrationForm.scss';
import { send_Email_Verification } from '../../api/auth';
import { LoadIndicator } from 'devextreme-react';
import { ShowAlert } from '../../utils/common-methods';

export default function CheckYourEmail() {

    const [isEmailSent, setIsEmailSent] = React.useState(false);

    const sendEmailVerification = async () => {
        setIsEmailSent(true);
        try {
            const result = await send_Email_Verification(localStorage.getItem("registration_Email"));
            if (!result.isOk) {
                if (result.statusCode === 400) {
                    ShowAlert(result.data, "Vakency");
                } else if (result.statusCode === 409) {
                    ShowAlert(result.data, "Vakency");
                }
            }
        } catch (error) {
            ShowAlert("An error occurred while processing the action.", "Vakency");
        } finally {
            setIsEmailSent(false);
        }
    }

    return (
        <div>
            {
                isEmailSent ? (
                    <div className='m-auto text-center loaderCart-height'>
                        <LoadIndicator className='indicator' id="small-indicator" height={50} width={50} visible={isEmailSent} />
                        <div className='pt-1'>Please Wait.....</div>
                    </div>
                ) : (
                    <>
                        <div className='row pb-3'>
                            <div className='font-semiBold-16 text-center registartion-title'>Please Check Your Email</div>
                        </div>
                        <div className='row'>
                            <div className='text-center font-regular-13'>An email has been sent to <span className='fw-semibold email_link' >{localStorage.getItem("registration_Email")}</span>.
                                Please follow the attached link to complete
                                the signup process.
                            </div>
                        </div>
                        <div className='row py-3'>
                            <div className='text-center font-regular-13'>
                                Can't find the email? <button className='text-decoration-underline border-0 bg-transparent email_link' onClick={sendEmailVerification}>click to resend the email</button>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='text-center font-regular-13'>
                                <Link to="/sign-up" state={{ registration_Email:  localStorage.getItem("registration_Email")}}>click to correct the email id</Link>
                            </div>
                        </div>
                        <hr />
                        <div className='row'>
                            <div className='text-center font-regular-13'>
                                Do you have trouble signing up?
                            </div>
                            <div className='text-center font-regular-13'>
                                <a href="mailto:">Send us an email</a>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}
