import React from 'react';
import './PopupSkeleton.scss';

export default function PopupSkeleton() {
  return (
    <>
      <div className="">
        <span className="custom-skeleton-two "></span>
        <span className="custom-skeleton-two "></span>
      </div>
    </>
  )
}
