import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function OtpInvalidPage() {

    const { state } = useLocation();

    const { message } = state || {};

    const [showMessage , setShowMessage] = useState();

    useEffect(()=>{
    if(message && message === "Invalid OTP entered."){
         setShowMessage("Invalid OTP")
    }
    else if (message && message === "Email ID or OTP didn't match."){
        setShowMessage("Email ID or OTP didn't match")
    }
    else if (message && message === "OTP has been expired."){
        setShowMessage("OTP expired")
    }
    else if (message && message === "Record not found."){
        setShowMessage("Record not found")
    }
    },[])

    return (
        <div className='row Invalid-page'>
            <div className='text-center mb-2'><i className='dx-icon-warning warning-icon'></i></div>
            <div className='text-center my-2 fw-semibold message'>{showMessage}</div>
        </div>
    )
}
