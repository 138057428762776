import React, { useEffect, useRef, useState } from 'react'
import { Header } from '../../components';
import { DataGrid } from 'devextreme-react';
import { Column, Button as DataGridButton, ColumnChooser, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, Pager, Scrolling, SearchPanel, Sorting, StateStoring, Toolbar, ColumnChooserSearch, Search, } from 'devextreme-react/data-grid';
import { useScreenSize } from '../../utils/media-query';
import { useNavigate } from 'react-router-dom';
import JobApplicationView from './JobApplicationView';
import ListSkeleton from '../../layouts/templates/ListSkeleton';
import { HideDatagridLoader, onDragOver } from '../../utils/common-methods';

const dsJobApplicationData = [{
  jobApplicationID: 1,
  jobApplicationNo: 5344,
  jobApplicationDate: new Date(),
  jobOpening: 'New',
  jobProfile: 'React Developer',
  candidate: 'Vishal Yadav',
  hiringManager: "Dhawal Sharma",
  status: "Pending",
  lastInteraction: "Good",
  offerNo: 4454,
  offerDate: new Date(),
  offeredCTC: 40000,
  offerAcceptedDate: new Date(),
  expectedJoiningDate: new Date(),
  acceptedCTC: 45000,
  description: "good behaviour, discussion remaining with team",
  createdByUserName: 'Admin',
  updatedByUserName: 'Admin',
  createdOn: '27/12/2023 01:41PM',
  updatedOn: '27/12/2023 03:41PM'

},
{
  jobApplicationID: 541,
  jobApplicationNo: 5344,
  jobApplicationDate: new Date(),
  jobOpening: 'New',
  jobProfile: 'React Developer',
  candidate: 'Vishal Yadav',
  hiringManager: "Dhawal Sharma",
  status: "Pending",
  lastInteraction: "Good",
  offerNo: 4454,
  offerDate: new Date(),
  offeredCTC: 40000,
  offerAcceptedDate: new Date(),
  expectedJoiningDate: new Date(),
  acceptedCTC: 45000,
  description: "good behaviour, discussion remaining with team",
  createdByUserName: 'Admin',
  updatedByUserName: 'Admin',
  createdOn: '27/12/2023 01:41PM',
  updatedOn: '27/12/2023 03:41PM'
},
{
  jobApplicationID: 143,
  jobApplicationNo: 5344,
  jobApplicationDate: new Date(),
  jobOpening: 'New',
  jobProfile: 'React Developer',
  candidate: 'Vishal Yadav',
  hiringManager: "Dhawal Sharma",
  status: "Pending",
  lastInteraction: "Good",
  offerNo: 4454,
  offerDate: new Date(),
  offeredCTC: 40000,
  offerAcceptedDate: new Date(),
  expectedJoiningDate: new Date(),
  acceptedCTC: 45000,
  description: "good behaviour, discussion remaining with team",
  createdByUserName: 'Admin',
  updatedByUserName: 'Admin',
  createdOn: '27/12/2023 01:41PM',
  updatedOn: '27/12/2023 03:41PM'

}]

const JobApplicationList = () => {

  const { isLarge, isExLarge } = useScreenSize();
  const NavigateTo = useNavigate();
  const [showView, setShowView] = useState(false);
  const [viewData, setViewData] = useState();
  const [filterVisible, setFilterVisible] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true)
  const [dsJobApplication, setDsJobApplication] = useState([])


  useEffect(() => {
    GetListData();
  }, [])

  const GetListData = () => {
    // setShowSkeleton(true)
    setTimeout(() => {
      setDsJobApplication(dsJobApplicationData)
      setShowSkeleton(false)
    }, 500)
  }

  const onOptionChange = (e) => {
    if (e.fullName === "filterValue") {
      setFilterVisible(!!e.value);
    }
  };
  const onEditClick = (e) => {
    NavigateTo("/JobApplication/Edit", {
      state: {
        JobApplicationId: e?.data.jobApplicationID
      },
    })
  }

  const onAddClick = (e) => {
    NavigateTo("/JobApplication/New")
  }

  const handleQuickView = (e) => {
    setViewData(e.row?.data)
    setShowView(!showView)
  }


  const JobApplicationNo = (cellData) => {
    return <>
      <div><span className='text-decoration-underline pointer-link' title='Edit' onClick={(e) => onEditClick(cellData)}>{cellData.data.jobApplicationNo}</span></div>
    </>
  }

  const JobApplicationDataGridRef = useRef(null);

  const RowDblClick = (e) => {
    NavigateTo("/JobApplication/Edit", {
      state: {
        JobApplicationId: e?.data.jobApplicationID
      },
    })
  }

  return (
    <>
      <Header
        title={"Job Applications"}
        handleAdd={onAddClick}
        dataGridRef={JobApplicationDataGridRef}
      />
      <div className='list-section'  onDragOver={onDragOver}>
        {
          showSkeleton ?
            <ListSkeleton />
            :
            <DataGrid
              ref={JobApplicationDataGridRef}
              dataSource={dsJobApplication}
              showBorders={true}
              showColumnLines={false}
              showRowLines={true}
              focusedRowEnabled={true}
              wordWrapEnabled={true}
              hoverStateEnabled={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              autoNavigateToFocusedRow={true}
              keyExpr="jobApplicationID"
              height={'100%'}
              width={"100%"}
              filterSyncEnabled={true}
              onOptionChanged={onOptionChange}
              className='List_DataGrid'
              loadPanel={HideDatagridLoader}
              onRowDblClick={RowDblClick}
              noDataText='No Record Found'
            >
              <Sorting mode={"multiple"} />
              <FilterPanel visible={filterVisible} />
              <FilterBuilderPopup visible={false} />
              <Scrolling mode={'virtual'} />
              <ColumnChooser enabled={true} >
                <ColumnChooserSearch
                  enabled={true}
                />
              </ColumnChooser>
              <HeaderFilter visible={true} >
                <Search
                  enabled={true}
                />
              </HeaderFilter>
              <SearchPanel visible={true} width={(isLarge || isExLarge) && 400} />
              <StateStoring enabled={true} type="localStorage" storageKey="JobApplication_Layout" />

              <Pager
                visible={true}
                showInfo={true}
                infoText={`{2} Rows`}
              ></Pager>
              <Toolbar>
                <Item location={'after'} name="columnChooserButton" />
                <Item location={'after'} name="searchPanel" />

              </Toolbar>
              <Column type='buttons' name="quickView_button_column" fixed={true} alignment='left' fixedPosition={'left'} width={30} minWidth={30} allowReordering={false} allowResizing={false} >
                <DataGridButton
                  name='quick view'
                  icon='QuickViewIcon'
                  visible={true}
                  onClick={handleQuickView}
                  hint='View'
                />
              </Column>
              <Column
                caption='#'
                dataField='jobApplicationNo'
                alignment='left'
                cellRender={JobApplicationNo}
                width={70}
                minWidth={60}
                allowHiding={false}
                allowHeaderFiltering={false}
              />
              <Column
                caption='Date'
                dataField='jobApplicationDate'
                alignment='left'
                width={100}
                minWidth={70}
              />
              <Column
                caption='Job Opening'
                dataField='jobOpening'
                alignment='left'
                width={180}
                minWidth={100}
              />
              <Column
                caption='Job Profile'
                dataField='jobProfile'
                alignment='left'
                width={180}
                minWidth={100}
              />
              <Column
                caption='Candidate'
                dataField='candidate'
                alignment='left'
                width={180}
                minWidth={100}
              />
              <Column
                caption='Hiring Manager'
                dataField='hiringManager'
                width={180}
                minWidth={100}
                alignment='left'
                dataType='date'
                format={'shortDate'}
              />
              <Column
                caption='Status'
                dataField='status'
                alignment='left'
                width={110}
                minWidth={80}
              />
              <Column
                caption='Last Interaction'
                dataField='lastInteraction'
                alignment='left'
                width={140}
                minWidth={80}
              />
              <Column
                caption='Offer No.'
                dataField='offerNo'
                alignment='left'
                width={100}
                minWidth={80}
              />
              <Column
                caption='Offer Date'
                dataField='offerDate'
                alignment='left'
                minWidth={80}
                width={120}
              />
              <Column
                caption='Offered CTC'
                dataField='offeredCTC'
                alignment='left'
                minWidth={80}
                width={120}
              />
              <Column
                caption='Offer Accepted Date'
                dataField='offerAcceptedDate'
                alignment='left'
                minWidth={80}
                width={170}
              />
              <Column
                caption='Expected Joining Date'
                dataField='expectedJoiningDate'
                alignment='left'
                minWidth={80}
                width={180}
              />
              <Column
                caption='Accepted CTC'
                dataField='acceptedCTC'
                alignment='left'
                minWidth={100}
                width={130}
              />
              <Column
                caption='Description'
                dataField='description'
                alignment='left'
                minWidth={300}
              />
              <Column
                dataField="createdByUserName"
                caption="Created By"
                visible={false}
                width={125}
                minWidth={80}
                alignment="left"
              />

              <Column
                dataField="updatedByUserName"
                caption="Updated By"
                visible={false}
                width={125}
                minWidth={80}
                alignment="left"
              />
              <Column
                dataField="createdOn"
                caption="Created On"
                visible={false}
                width={135}
                minWidth={80}
                alignment="left"
              //dataType="datetime"
              />
              <Column
                dataField="updatedOn"
                caption="Updated On"
                visible={false}
                width={137}
                minWidth={80}
                alignment="left"
              //dataType="datetime"
              />
              <Column type='buttons' name="delete_button_column" fixed={false} allowResizing={false} width={43} allowReordering={false}>
                <DataGridButton
                  name='delete'
                  icon='trash'
                  hint='Delete'
                  visible={true}
                  cssClass='text-danger'
                />
              </Column>
            </DataGrid>
        }
      </div>
      <JobApplicationView showView={showView} setShowView={setShowView} viewData={viewData} />

    </>
  )
}

export default JobApplicationList;