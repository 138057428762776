import {
  DateBox,
  NumberBox,
  Popup,
  SelectBox,
  TextArea,
  TextBox,
  Validator,
} from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import { RequiredRule } from "devextreme-react/data-grid";
import { useScreenSize } from "../../utils/media-query";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import PopupFooter from "../../layouts/popup-header-footer/PopupFooter";
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import UserDropDown from "../application-settings/user/UserDropDown";
import CandidateDropDown from "../candidate/CandidateDropdown";
import { ShowAlert, eCRUDStatus, minDate, onKeyDown, onKeyDown_Withpoint, onKeyDown_Withpoint_withoutPlus_withoutSpecialCharacters, truncateValue } from "../../utils/common-methods";
import JobOpeningDropDown from "./JobOpeningDropdown";
import PopupSkeleton from "../../layouts/templates/PopupSkeleton";
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import { CurrentUserControlServices } from "../../api/services/CurrentUserControlServices";
import { JobApplicationServices } from "../../api/services/JobApplicationServices";

const candidateControlServices = new CandidateControlServices()
const currentUserControlServices = new CurrentUserControlServices()
const jobApplicationServices =new JobApplicationServices()
export default function JobApplicationInsertPopup(props) {
  const ValidationGroupName = "CandidateSourcePopupValidation";

  const { isXSmall, isXXSmall, isExSmall } = useScreenSize();

  const [newRecord, setNewRecord] = useState([]);
  const [formattedText, setFormattedText] = useState();
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [dropDownClick, setDropDownClick] = useState(false);
  const [addedJobOpeningID, setAddedJobOpeningID] = useState(0);
  const [dropDownUserClick, setDropDownUserClick] = useState(false);
  const [addedUserID, setAddedUserID] = useState(0);

  const htmlEditorSecondAttributes = {
    class: "noteSecondHtml",
  };

  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
  const fontValues = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];

  const htmlEditorNoteRef = useRef(null);
  const FocusedFeild = useRef(null);
  const currentDate = new Date();

  const onClosePopup = () => {
    props.setOpenPopup(false)
    props.setOpenSearchPage(false)
    setNewRecord({})
  }

  useEffect(() => {
    GetCurrentUser();
  }, [])


  const GetCurrentUser = async () => {
    const result = await currentUserControlServices.GetModelAPI_WithoutId();
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency")
      setShowSkeleton(false);
    }
    else {
      let userId = result.data.userID
      GetModelRecord(userId);
    }
  }

  useEffect(() => {
    if (addedJobOpeningID !== 0) {
      setNewRecord({ ...newRecord, jobOpeningID: addedJobOpeningID });
      setAddedJobOpeningID(0);
    }
  }, [addedJobOpeningID]);

  useEffect(() => {
    if (addedUserID !== 0) {
      setNewRecord({ ...newRecord, hrManagerID: addedUserID });
      setAddedUserID(0);
    }
  }, [addedUserID]);


  useEffect(() => {
    if (!showSkeleton) {
      formattedText && htmlEditorNoteRef.current?.instance.option("value", formattedText);;
    }
  }, [showSkeleton])


  const GetModelRecord = async (userId) => {
    setTimeout(async () => {
      const result = await candidateControlServices.GetModelData(props.candidateID)

      const response = result.data;
      if (result.statusCode === 400 || result.statusCode === 409) {
        ShowAlert(result.data, "Vakency")
        setShowSkeleton(false);
      }
      else {
        setNewRecord({ ...response, jobApplicationDate: response.jobApplicationDate ? response.jobApplicationDate : currentDate && currentDate, jobOpeningID: props.JobOpeningID && props.JobOpeningID, hrManagerID: userId && userId, currentCTC: response?.currentCTC && response?.currentCTC?.toFixed(2), expectedCTC: response?.expectedCTC && response?.expectedCTC?.toFixed(2) });
        setFormattedText(response.descrFormattedText);
        setShowSkeleton(false);
      }
    }, 1000)
  }

  const OnSubmitJobApplication = async (e) => {
    e.preventDefault();
    if (dropDownClick === false && dropDownUserClick === false) {
        InsertRecord();
    }
  };

  const InsertRecord = async()=>{
    setShowSkeleton(true)
      let tempDivElement = htmlEditorNoteRef.current.instance.getQuillInstance();
      var FormattedText = htmlEditorNoteRef.current?.instance.option("value");
      const descrPlainText = tempDivElement.getText();
      const DatatoInsert = {
        jobApplicationDate: newRecord.jobApplicationDate,
        jobOpeningID: newRecord.jobOpeningID,
        candidateID: newRecord.candidateID,
        currentCTC: newRecord.currentCTC,
        expectedCTC: newRecord.expectedCTC,
        totalExperience: newRecord.totalExperience,
        relevantExperience: newRecord.relevantExperience,
        noticePeriod: newRecord.noticePeriod,
        leavingReason: newRecord.leavingReason,
        hrManagerID: newRecord.hrManagerID,
        descrFormattedText: FormattedText,
        descrPlainText: descrPlainText && descrPlainText?.trim() !== "" ? descrPlainText?.trim() : null
      }
      const result = await jobApplicationServices.InsertData(DatatoInsert);
      if (!result.isOk) {
        if (result.statusCode === 400) {
          ShowAlert(result.data, "Vakency")
          setShowSkeleton(false);
        }
      } else {
        onClosePopup();
        setShowSkeleton(false);
        props.setStatus({
          eStatus: eCRUDStatus.Updated,
          primaryKeyID:props.candidateID
        })
      }
  }

  const handleRelevantExperienceChange = (e) => {
    const integerPart = e?.toString().split('.')[0]
    const decimalpart = e?.toString().split('.')[1]
    if (decimalpart < 12) {
      setNewRecord({ ...newRecord, relevantExperience: e ? parseFloat(e) : null });
    }
    else {
      setNewRecord({ ...newRecord, relevantExperience: e ? parseInt(integerPart) + 1 : null })
    }
  }

  const handleTotalExperienceChange = (e) => {
    const integerPart = e?.toString().split('.')[0]
    const decimalpart = e?.toString().split('.')[1]
    if (decimalpart < 12) {
      setNewRecord({ ...newRecord, totalExperience: e ? parseFloat(e) : null })
    }
    else {
      setNewRecord({ ...newRecord, totalExperience: e ? parseInt(integerPart) + 1 : null })
    }
  }


  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={[<span key={"header-title"} className="base-accent-text">{"New"}</span>, " Job Application"]}
          onSubmit={InsertRecord}
          hideSaveButton={showSkeleton ? true : false}
        />
      </>
    )
  }

  return (
    <div className="">
      <Popup
        visible={props.openPopup}
        showTitle={true}
        titleRender={PopupTitle}
        width={(isXSmall || isXXSmall || isExSmall) ? "90%" : "750px"}
        height={"auto"}
        wrapperAttr={{ class: "CustomPopup" }}
        deferRendering={false}
        maxHeight={"92vh"}
        onShown={() => FocusedFeild.current?.instance.focus()}
      >
        <form onSubmit={OnSubmitJobApplication}>
          {showSkeleton ?
            <div className='p-2'>
              <PopupSkeleton />
              <PopupSkeleton />
              <PopupSkeleton />
              <PopupSkeleton />
              <PopupSkeleton />
              <PopupSkeleton />
            </div>
            :
            <>
              <div className="row mx-1 mt-3">
                <div className="col-xxl col-xl col-lg col-md">
                  <div className="row">
                    <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-3">
                      <DateBox
                        id="JobApplicationDate_Dropdown"
                        label="Job Application Date"
                        labelMode="floating"
                        ref={FocusedFeild}
                        value={newRecord.jobApplicationDate}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, jobApplicationDate: e })
                        }
                        type="date"
                        dateOutOfRangeMessage="Invalid Date"
                        useMaskBehavior={true}
                        min={minDate}
                        dropDownOptions={{
                          hideOnOutsideClick: true,
                          container: "#JobApplicationDate_Dropdown"
                        }}
                      >
                        <Validator
                          validationGroup={ValidationGroupName}
                          elementAttr={{ class: "ForValidationAstrikMark" }}
                        >
                          <RequiredRule message="Job Application Date is required" />
                        </Validator>
                      </DateBox>
                    </div>
                  </div>
                </div>
                <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-3">
                  <JobOpeningDropDown
                    validationRequired={true}
                    validationGroup={ValidationGroupName}
                    value={newRecord?.jobOpeningID}
                    onValueChange={(e) => { setNewRecord({ ...newRecord, jobOpeningID: e }) }}
                    setAddedJobOpeningID={setAddedJobOpeningID}
                    setDropDownClick={setDropDownClick}
                    dropdownHeight={180}
                  />
                </div>
              </div>
              <div className="row mx-1 mt-3">
                <CandidateDropDown
                  value={newRecord.candidateID}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, candidateID: e })
                  }
                  validationRequired={true}
                  validationGroup={ValidationGroupName}
                  readOnly={true}
                />
              </div>

              <div className="row mx-1 mt-3">
                <div className="col-xxl col-xl col-lg col-md">
                  <UserDropDown
                    label="Hiring Manager"
                    value={newRecord.hrManagerID}
                    onValueChange={(e) =>
                      setNewRecord({ ...newRecord, hrManagerID: e })
                    }
                    validationRequired={true}
                    validationGroup={ValidationGroupName}
                    setDropDownClick={setDropDownUserClick}
                    setAddedUserID={setAddedUserID}
                    dropdownHeight={180}
                  />
                </div>
                <div className="col-md">
                  <div className="row mt-3 mt-md-0">
                    <div className="col">
                      <NumberBox label="Current CTC" labelMode="floating"
                        value={newRecord?.currentCTC && parseFloat(newRecord?.currentCTC)}
                        maxLength={15}
                        format="##.##"
                        inputAttr={{ maxLength: 15 }}
                        step={0}
                        onKeyDown={onKeyDown_Withpoint}
                        onValueChange={(e) => setNewRecord({ ...newRecord, currentCTC: e?.toFixed(2) })}
                      />
                    </div>
                    <div className="col">
                      <NumberBox label="Expected CTC" labelMode="floating"
                        value={newRecord?.expectedCTC && parseFloat(newRecord?.expectedCTC)}
                        onValueChange={(e) => setNewRecord({ ...newRecord, expectedCTC: e?.toFixed(2) })}
                        maxLength={15}
                        format="##.##"
                        inputAttr={{
                          maxLength: 15,
                        }}
                        step={0}
                        onKeyDown={onKeyDown_Withpoint}
                      />
                    </div>
                  </div>
                </div>
                <div className="row pe-0 mt-0 mt-md-3">
                  <div className="col-md mt-3 mt-md-0 mt-3 pe-0 pe-md-2">
                    <NumberBox label="Total Experience" labelMode="floating"
                      maxLength={6}
                      value={newRecord?.totalExperience}
                      onValueChange={handleTotalExperienceChange}
                      onKeyDown={onKeyDown_Withpoint_withoutPlus_withoutSpecialCharacters}
                      format="##.##"
                      inputAttr={{ maxLength: 6 }}
                      step={0}
                      min={0}
                      max={998}
                    />
                  </div>
                  <div className="col-md mt-3 mt-md-0 mt-3 pe-0 pe-md-2">
                    <NumberBox label="Relevant Experience" labelMode="floating"
                      maxLength={6}
                      value={newRecord?.relevantExperience}
                      onValueChange={handleRelevantExperienceChange}
                      onKeyDown={onKeyDown_Withpoint}
                      inputAttr={{ maxLength: 6 }}
                      step={0}
                      min={0}
                      max={998}
                      format="##.##"
                    />
                  </div>
                  <div className="col-md mt-3 mt-md-0 mt-3 pe-0">
                    <NumberBox label="Notice Period (Days)" labelMode="floating"
                      value={newRecord?.noticePeriod && parseInt(newRecord?.noticePeriod)}
                      maxLength={3}
                      max={255}
                      inputAttr={{ maxLength: 3 }}
                      step={0}
                      onKeyDown={onKeyDown}
                      onValueChange={(e) => setNewRecord({ ...newRecord, noticePeriod: e })}
                    />
                  </div>
                </div>
                <div className="row ps-4 pe-0 mt-3">
                  <TextArea
                    label="Leaving Reason" labelMode="floating"
                    maxLength={100}
                    className="px-0"
                    value={newRecord?.leavingReason}
                    onValueChange={(e) => setNewRecord({ ...newRecord, leavingReason: e })}
                  />
                </div>
              </div>

              <div className="mx-3 pt-4">
                <HtmlEditor
                  ref={htmlEditorNoteRef}
                  id={"JobDescribtionHtmlEditor"}
                  elementAttr={htmlEditorSecondAttributes}
                  valueType="html"
                  height={200}
                  className="CustomizeHtmlEditor"
                >
                  <HtmlEditorToolbar multiline={false}>
                    <HtmlEditorItem name="link"></HtmlEditorItem>
                    <HtmlEditorItem name="font" acceptedValues={fontValues} />
                    <HtmlEditorItem name="size" acceptedValues={sizeValues} />
                    <HtmlEditorItem name="bold" />
                    <HtmlEditorItem name="italic" />
                    <HtmlEditorItem name="underline" />
                    <HtmlEditorItem name="background" />
                    <HtmlEditorItem name="color" />
                    <HtmlEditorItem name="orderedList" />
                    <HtmlEditorItem name="bulletList" />
                    <HtmlEditorItem name="clear" />
                  </HtmlEditorToolbar>
                </HtmlEditor>
              </div>
            </>
          }
          {showSkeleton ?
            <div className='d-flex justify-content-end pb-2 pe-2'>
              <div className='skeleton-View Skeleton-popup-footer'></div>
            </div>
            :
            <PopupFooter
              ValidationGroupName={ValidationGroupName}
              openPopup={props.openPopup}
              setOpenPopup={props.setOpenPopup}
              handleCancel={onClosePopup}
            />
          }
        </form>
      </Popup>
    </div>
  );
}
