import { fetchWrapper } from "./authHeader";

const baseURL = process.env.REACT_APP_BASEURL;
export class VakencyApiServices {
  constructor(url) {
    this.apiUrl = url;
  }

  // Function to handle unauthorized access
  // async handleUnauthorized() {
  //   localStorage.removeItem("userEmail");
  //   localStorage.removeItem("authToken");
  //   window.location.replace(window.location.origin);
  // }

  // Helper function to handle response
  handleResponse = async (data, noResponseData) => {
    if (data?.status && data?.status === 200) {
      return {
        isOk: true,
        data: noResponseData ? data : await data?.json(),
        statusCode: data?.status,
      };
    }
    // else if (data?.status == 401 || data?.status === 403) {
    //   await this.handleUnauthorized();
    // } 
    else {
      return {
        isOk: false,
        data: await data?.text(),
        statusCode: data?.status,
      };
    }
  };

  // Function to get list data
  GetListData = async () => {
    const result = await fetchWrapper.get(baseURL + this.apiUrl + '/GetList');
    try {
      return this.handleResponse(result);
    }
    catch (err) {
      return {
        isOk: false,
        data: "Failed To Load  Data : " + JSON.stringify(err),
        statusCode: result.status
      };
    }
  }

  // Function to get list data by ID
  GetListByIdData = async (ID) => {
    const result = await fetchWrapper.get(baseURL + this.apiUrl + '/GetList/' + ID);
    try {
      return this.handleResponse(result);
    }
    catch (err) {
      return {
        isOk: false,
        data: "Failed To Load  Data : " + JSON.stringify(err),
        statusCode: result.status
      };
    }
  }

  // Function to delete data
  DeleteData = async (ID) => {
    const result = await fetchWrapper.delete(baseURL+this.apiUrl + '/Delete/' + ID);
    const noResponseData = true;
    try {
        return this.handleResponse(result,noResponseData);
    }
    catch (err) {
      return {
        isOk: false,
        data: "Failed To Load  Data : " + JSON.stringify(err),
        statusCode: result.status
      };
    }
  }

  // Function to insert data
  InsertData = async (params) => {
    const result = await fetchWrapper.post(baseURL+this.apiUrl + '/Insert', params);
    try {
      return this.handleResponse(result);
    }
    catch (err) {
      return {
        isOk: false,
        data: "Failed To Load  Data : " + JSON.stringify(err),
        statusCode: result.status
      };
    }
  }

  // Function to get model data
  GetModelData = async (ID) => {
    const result = await fetchWrapper.get(baseURL+this.apiUrl + '/GetModel/' + ID);
    try {
      return this.handleResponse(result);
    }
    catch (err) {
      return {
        isOk: false,
        data: "Failed To Load  Data : " + JSON.stringify(err),
        statusCode: result.status
      };
    }

  }

  //Function to get profile detail
  GetProfileData = async (ID) => {
    const result = await fetchWrapper.get(baseURL+this.apiUrl + '/GetCandidateProfile/' + ID);
    try {
      return this.handleResponse(result);
    }
    catch (err) {
      return {
        isOk: false,
        data: "Failed To Load  Data : " + JSON.stringify(err),
        statusCode: result.status
      };
    }

  }

  // Function to update data
  UpdateData = async (params) => {
    const result = await fetchWrapper.put(baseURL+this.apiUrl + '/Update', params);
    try {
      return this.handleResponse(result);
    }
    catch (err) {
      return {
        isOk: false,
        data: "Failed To Load  Data : " + JSON.stringify(err),
        statusCode: result.status
      };
    }
  }

  // Function to get lookup list data
  GetLookupListData = async () => {
    const result = await fetchWrapper.get(baseURL+this.apiUrl + '/GetLookupList');
    try {
      return this.handleResponse(result);
    }
    catch (err) {
      return {
        isOk: false,
        data: "Failed To Load  Data : " + JSON.stringify(err),
        statusCode: result.status
      };
    }
  }

  fetchFileAsBlob = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      if (response.ok) {
        const data = await response.blob();
        return {
          isOk: true,
          data,
          statusCode: response.status,
        };
      } else {
        return {
          isOk: false,
          message: `Error: ${response.statusText}`,
          statusCode: response.status
        };
      }
    } catch (error) {
      return {
        isOk: false,
        message: "Failed to fetch the file",
        error
      };
    }
  };

}
