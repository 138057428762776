import React, { useEffect, useRef, useState } from "react";
import { Header } from "../../components";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Button as DataGridButton,
  ColumnChooser,
  FilterBuilderPopup,
  FilterPanel,
  HeaderFilter,
  Item,
  Pager,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
  Toolbar,
  LoadPanel,
  ColumnChooserSearch,
  Search,
} from "devextreme-react/data-grid";
import { useScreenSize } from "../../utils/media-query";
import { useNavigate } from "react-router-dom";
import ListSkeleton from "../../layouts/templates/ListSkeleton";
import InteractionView from "./InteractionView";
import { HideDatagridLoader, onDragOver } from "../../utils/common-methods";

const dsInteractionData = [
  {
    interactionID: 1,
    interactionNo: 5344,
    candidate: "Vishal ",
    jobAppliationNo: 33,
    jobProfile: "React Developer",
    interactionRound: 'Third',
    interviewers: "Aman",
    assessment: "lorem ipsum",
    attitude: "good",
    technicalSkillsAssessment: "Java",
    result: 'Pending'
  },
  {
    interactionID: 2,
    interactionNo: 54,
    candidate: "Shubham",
    jobAppliationNo: 633,
    jobProfile: "C# Developer",
    interactionRound: 'Third',
    interviewers: "Aman",
    assessment: "lorem ipsum",
    attitude: "good",
    technicalSkillsAssessment: "Java",
    result: 'Passed'
  },
  {
    interactionID: 3,
    interactionNo: 544,
    candidate: "Manish",
    jobAppliationNo: 633,
    jobProfile: ".Net Developer",
    interactionRound: 'Second',
    interviewers: "Aman",
    assessment: "lorem ipsum",
    attitude: "good",
    technicalSkillsAssessment: "Java",
    result: 'Passed'
  },
];

const InteractionList = () => {
  const { isExSmall, isXXSmall } = useScreenSize();
  const NavigateTo = useNavigate();
  const [showView, setShowView] = useState(false);
  const [viewData, setViewData] = useState();
  const [filterVisible, setFilterVisible] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [dsInteraction, setDsInteraction] = useState([])

  useEffect(() => {
    GetListData();
  }, [])

  const GetListData = () => {
    // setShowSkeleton(true)
    setTimeout(() => {
      setDsInteraction(dsInteractionData)
      setShowSkeleton(false)
    }, 500)
  }

  const onOptionChange = (e) => {
    if (e.fullName === "filterValue") {
      setFilterVisible(!!e.value);
    }
  };
  const onEditClick = (e) => {
    NavigateTo("/Interaction/Edit", {
      state: {
        InteractionID: e?.data.interactionID,
      },
    });
  };

  const onAddClick = (e) => {
    NavigateTo("/Interaction/Edit");
  };

  const handleQuickView = (e) => {
    setViewData(e.row.data);
    setShowView(!showView);
  };

  const InteractionNo =(cellData)=>{
    return <>
    <div><span className='text-decoration-underline pointer-link' title='Edit' onClick={(e)=>onEditClick(cellData)}>{cellData.data.interactionNo}</span></div>
    </>
  }

  const InteractionDataGridRef = useRef(null);

  const RowDblClick = (e) => {
    NavigateTo("/Interaction/Edit", {
      state: {
        InteractionID: e?.data.interactionID,
      },
    })
  }

  return (
    <>
      <Header
        title={"Interactions"}
        handleAdd={onAddClick}
        dataGridRef={InteractionDataGridRef}
      />
      <div className="list-section" onDragOver={onDragOver}>
        {showSkeleton ? (
          <ListSkeleton />
        ) : (
          <DataGrid
            ref={InteractionDataGridRef}
            dataSource={dsInteraction}
            showBorders={true}
            showColumnLines={false}
            showRowLines={true}
            focusedRowEnabled={true}
            wordWrapEnabled={true}
            hoverStateEnabled={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            autoNavigateToFocusedRow={true}
            keyExpr="interactionID"
            height={"100%"}
            filterSyncEnabled={true}
            onOptionChanged={onOptionChange}
            className="List_DataGrid"
            width={'100%'}
            loadPanel={HideDatagridLoader}
            onRowDblClick={RowDblClick}
            noDataText='No Record Found'
          >
            <LoadPanel enabled={false} />
            <Sorting mode={"multiple"} />
            <FilterPanel visible={filterVisible} />
            <FilterBuilderPopup visible={false} />
            <Scrolling mode={"virtual"} />
            <ColumnChooser enabled={true}>
                <ColumnChooserSearch
                  enabled={true}
                  />
              </ColumnChooser>
              <HeaderFilter visible={true} >
                <Search
                  enabled={true}
                />
              </HeaderFilter>
            <SearchPanel
              visible={true}
              width={isExSmall || isXXSmall ? 200 : 400}
            />
            <StateStoring
              enabled={true}
              type="localStorage"
              storageKey="Interaction_Layout"
            />
            <Pager visible={true} showInfo={true} infoText={`{2} Rows`}></Pager>
            <Toolbar>

              <Item location={"after"} name="columnChooserButton" />
              <Item location={"after"} name="searchPanel" />

            </Toolbar>
            <Column
              type="buttons"
              name="quickView_button_column"
              fixed={true}
              alignment="left"
              fixedPosition={"left"}
              width={30}
              minWidth={30}
              allowReordering={false}
              allowResizing={false}
            >
              <DataGridButton
                name="quick view"
                icon="QuickViewIcon"
                visible={true}
                onClick={handleQuickView}
                hint="View"
              />
            </Column>
            <Column
              caption="#"
              dataField="interactionNo"
              alignment="left"
              width={90}
              allowHiding={false}
              cellRender={InteractionNo}
              minWidth={70}
              allowHeaderFiltering={false}
            />
            <Column
              caption="Candidate"
              dataField="candidate"
              width={180}
              minWidth={100}
            />
            <Column
              caption="Job Appliation No."
              dataField="jobAppliationNo"
              width={160}
              minWidth={80}
              alignment="left"
            />
            <Column
              caption="Job Profile"
              dataField="jobProfile"
              minWidth={160}
            />
            <Column
              caption="Interaction Round"
              dataField="interactionRound"
              width={160}
              minWidth={80}
            />
            <Column
              caption="Interviewers"
              dataField="interviewers"
              width={160}
              minWidth={80}
            />
            <Column
              caption="Assessment"
              dataField="assessment"
              width={150}
              minWidth={80}
            />
            <Column
              caption="Attitude"
              dataField="attitude"
              minWidth={100}
              width={130}
            />
            <Column
              caption="result"
              dataField="result"
              minWidth={100}
              width={160}
            />
            <Column
              dataField="createdByUserName"
              caption="Created By"
              visible={false}
              width={125}
              minWidth={80}
              alignment="left"
            />

            <Column
              dataField="updatedByUserName"
              caption="Updated By"
              visible={false}
              width={125}
              minWidth={80}
              alignment="left"
            />
            <Column
              dataField="createdOn"
              caption="Created On"
              visible={false}
              width={135}
              minWidth={80}
              alignment="left"
            />
            <Column
              dataField="updatedOn"
              caption="Updated On"
              visible={false}
              width={137}
              minWidth={80}
              alignment="left"
            />
            <Column type='buttons' name="delete_button_column" fixed={false}  allowResizing={false} width={43} allowReordering={false}>
              <DataGridButton
                name='delete'
                icon='trash'
                hint='Delete'
                visible={true}
                cssClass='text-danger'
              />
            </Column>
          </DataGrid>
        )}
      </div>
      <InteractionView showView={showView} setShowView={setShowView} viewData={viewData} />
    </>
  );
};

export default InteractionList;
