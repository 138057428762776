import React from 'react';
import { Button, Toolbar, Popup, TextBox, Validator, LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar';
import { useScreenSize } from '../../utils/media-query';
import { CustomRule, PatternRule, RequiredRule } from 'devextreme-react/validator';
import { CandidateControlServices } from '../../api/services/CandidateControlServices';
import { ShowAlert } from '../../utils/common-methods';

const FileNameValidationGroup = "RenameFileValidationGroup";
const candidateControlServices = new CandidateControlServices();

export default function FileRenamePopup({
    openPopup,
    fileNameList,
    fileToRename,
    setFileToRename,
    closeRenameFilePopup,
    CandidateID,
}) {

    const { isXSmall, isXXSmall, isExSmall } = useScreenSize();

    const textBoxRef = React.useRef(null);
    const [newFileName, setNewFileName] = React.useState(null);
    const [showLoadPanel, setShowLoadPanel] = React.useState(false);

    const onClosePopup = () => {
        closeRenameFilePopup()
    }

    const PopupTitle = () => {

        return (

            <div className='rename_popup_header shadow-sm mb-2 '>
                <div className='row mb-2 mx-1'>
                    <Toolbar className='my-1'>
                        <Item
                            location="before"
                        >
                            <div className={`${(isXSmall || isXXSmall || isExSmall) ? 'font-medium-14' : 'font-medium-18'} fw-normal`}>Rename</div>
                        </Item>
                        <Item
                            location="after"
                        >
                            <Button
                                icon='close'
                                hint='Close'
                                stylingMode='text'
                                className='remove-icon'
                                hoverStateEnabled={false}
                                activeStateEnabled={false}
                                focusStateEnabled={false}
                                onClick={onClosePopup}
                            >
                            </Button>
                        </Item>
                    </Toolbar>
                </div>
            </div>
        )
    }

    const onShownSelectText = () => {
        let fileNameWIthExtension = fileToRename.oldFileName;
        let FileName = fileNameWIthExtension?.substring(
            0,
            fileNameWIthExtension?.lastIndexOf(".")
        );
        setNewFileName(FileName);
        const inputElement = textBoxRef.current.instance
            .element()
            .querySelector("input");
        if (inputElement) {
            inputElement.focus();
            inputElement.select();
        }
    };

    const updateFileList = () => {
        let extension = fileToRename.oldFileName.substring(
            fileToRename.oldFileName.lastIndexOf(".")
        );
        let NewFileName = newFileName + extension;

        setFileToRename((prevFileToRename) => ({
            ...prevFileToRename,
            newFileName: NewFileName,
        }));

    }

    const UpdateFileName = async () => {
        let OldFileName = fileToRename.oldFileName;
        let extension = fileToRename.oldFileName.substring(
            fileToRename.oldFileName.lastIndexOf(".")
        );
        let NewFileName = newFileName + extension;

        if (OldFileName === NewFileName) {
            onClosePopup();
            return;
        }

        setShowLoadPanel(true);
        if (fileToRename.isNew) {
            setShowLoadPanel(false)
            updateFileList()
            onClosePopup();
        } else {
            const result = await candidateControlServices.renameFile(CandidateID, OldFileName, NewFileName);
            if (!result.isOk) {
                setShowLoadPanel(false)
                if (result.statusCode === 400) {
                    ShowAlert(result.data, "Vakency");
                } else if (result.statusCode === 409) {
                    ShowAlert(result.data, "Vakency");
                }
            } else {
                setShowLoadPanel(false)
                updateFileList()
                onClosePopup();
            }
        }
    };

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        UpdateFileName();
    }

    const onLoadPanelHide = () => {
        setShowLoadPanel(false)
    }

    const CheckDuplicateFileName = (e) => {
        if (fileToRename.oldFileName && e.value && fileNameList) {
            let fileName = e?.value + '.' + fileToRename?.oldFileName?.split('.').pop()
            if (fileNameList.includes(fileName)) {
                e.rule.isValid = false;
                e.rule.message = "Can't accept duplicate file name";
                e.validator.validate();
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    return (
        <React.Fragment>
            <Popup
                visible={openPopup}
                showTitle
                width={'340px'}
                height={'auto'}
                titleRender={PopupTitle}
                wrapperAttr={{ class: "CustomPopup" }}
                deferRendering={false}
                onShown={onShownSelectText}
            >

                <LoadPanel
                    visible={showLoadPanel}
                    onHiding={onLoadPanelHide}
                />
                <form onSubmit={handleSubmit}>
                    <div className='py-2 px-3'>
                        <TextBox
                            ref={textBoxRef}
                            label='File Name'
                            maxLength={400}
                            labelMode='floating'
                            value={newFileName}
                            onValueChange={(e) => {
                                setNewFileName(e);
                            }}
                        >
                            <Validator validationGroup={FileNameValidationGroup} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                <RequiredRule message="Please enter file name" />
                                <CustomRule ignoreEmptyValue={true} validationCallback={CheckDuplicateFileName} message="Can't accept duplicate file name" />
                                <PatternRule pattern={/^[^\\/:*?"<>|]*$/} message={'A file name can\'t contain any of the following characters: \\ / : * ? \" < > |'} />
                            </Validator>
                        </TextBox>
                    </div>
                    <div>
                        <div className='row justify-content-end m-2 py-1'>
                            <div className='col-auto pe-1'>
                                <Button
                                    text='Save'
                                    className='px-1 rounded-3 '
                                    useSubmitBehavior={true}
                                    width={90}
                                    height={38}
                                    type='normal'
                                    validationGroup={FileNameValidationGroup}
                                >
                                </Button>
                            </div>
                            <div className='col-auto pe-1'>
                                <Button
                                    text='Cancel'
                                    className=' px-1'
                                    hint='Cancel'
                                    onClick={onClosePopup}
                                    width={90}
                                    height={38}
                                    type='normal'
                                >
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </Popup>
        </React.Fragment>
    )
}