import { Button } from 'devextreme-react'
import React from 'react'

export default function PopupFooter({ ValidationGroupName, handleCancel }) {


  return (
    <div>
      <hr className='mb-0' />
      <div className='row justify-content-end m-2 py-1'>
        <div className='col-auto pe-1'>
          <Button
            text='Save'
            className='px-1 rounded-3 '
            useSubmitBehavior={true}
            width={90}
            height={38}
            type='default'
            validationGroup={ValidationGroupName}
          >
          </Button>
        </div>
        {handleCancel &&
          <div className='col-auto pe-1'>
            <Button
              text='Cancel'
              className=' px-1 rounded-3'
              hint='Cancel'
              onClick={handleCancel}
              width={90}
              height={38}
              type='danger'
            >
            </Button>
          </div>
        }
      </div>
    </div>
  )
}
