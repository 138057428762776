import { Button, Popup, ScrollView } from 'devextreme-react';
import React from 'react';
import './ViewMode.scss';
import { useScreenSize } from '../../utils/media-query';

const ViewMode = (props) => {

  const { isSmall, isXSmall, isXXSmall, isExSmall } = useScreenSize();

  const HideView = () => {
    props.setShowView(false);
  }

  const ViewTitleRender = () => {
    return (
      <>

        <div className='view-title ps-1'>
         {props.title}
          {props.handleEdit && <span className='vertical-line px-2'>|</span>}
          {props.handleEdit && <Button
            icon='edit'
            type='default'
            stylingMode='outlined'
            hoverStateEnabled={false}
            activeStateEnabled={false}
            focusStateEnabled={false}
            className='Edit_Button rounded-5'
            hint='Edit'
            onClick={props.handleEdit}
          /> }
          <span className='view-close-btn'>
            <Button
              icon='close'
              hint='Close'
              className='float-end my-1'
              type='default'
              stylingMode='outlined'
              onClick={HideView}
            />
          </span>
        </div>
      </>
    )
  }

  return (
    <div>
      <Popup
        visible={props.showView}
        dragEnabled={false}
        titleRender={ViewTitleRender}
        onHiding={HideView}
        hideOnOutsideClick={true}
        width={props.width ? props.width : (isSmall || isXSmall || isXXSmall || isExSmall) ? 'auto' : 600}
        height={'100%'}
        position={"right"}
        wrapperAttr={{ class: "ViewPopup" }}
        animation={{
          show: {
            type: 'slideIn',
            direction: 'right'
          },
          hide: {
            type: 'slideOut',
            direction: 'right'
          }
        }}
      >
        <ScrollView height={"100%"} width={"100%"}>
          <div className='px-4 pt-4 pb-2'>
          {props.hideSubTitle !== false && <div className='row mb-1'>
            </div> 
            }
           {/* {props.hideSubTitle !== false && <hr className='mb-3 mt-2'/>} */}
            {props.children}
          </div>
        </ScrollView>
      </Popup>
    </div>
  )
}

export default ViewMode