import {
  Button,
  FileUploader,
  NumberBox,
  Popup,
  TextBox,
  Tooltip,
  Validator,
} from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import PopupFooter from "../../layouts/popup-header-footer/PopupFooter";
import "./UploadNewResume.scss";
import JobProfileDropDown from "../application-settings/job-profile/JobProfileDropDown";
import JobOpeningDropDown from "../job-opening/JobOpeningDropdown";
import { AsyncRule, EmailRule, RequiredRule } from "devextreme-react/validator";
import { useScreenSize } from "../../utils/media-query";
import { useLocation, useNavigate } from "react-router-dom";
import { ShowAlert, onKeyDown } from "../../utils/common-methods";
import { CustomRule, StringLengthRule } from "devextreme-react/data-grid";
import PopupSkeleton from "../../layouts/templates/PopupSkeleton";
import { CandidateControlServices } from "../../api/services/CandidateControlServices";

const candidateControlServices = new CandidateControlServices();
const UploadNewResume = (props) => {
  const ValidationGroupName = "UploadResumeValidationGroup";
  const UploadResumeRef = useRef();
  const { isExSmall, isXXSmall, isXSmall } = useScreenSize();
  const [newRecord, setNewRecord] = useState({});
  const [showUploadNewResume, setShowUploadNewResume] = useState(false);
  const [dropDownJobProfileClick, setDropDownJobProfileClick] = useState(false);
  const [addedJobProfileID, setAddedJobProfileID] = useState(0);
  const [dropDownJobOpeningClick, setDropDownJobOpeningClick] = useState(false);
  const [addedJobOpeningID, setAddedJobOpeningID] = useState(0);
  const [resume, setResume] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const currentLocation = useLocation();
  const FocusedFeild = useRef(null);

  const NavigateTo = useNavigate();

  const maxResumeSizeLength = 5 * 1024 * 1024;

  useEffect(() => {
    if (currentLocation.pathname === "/UploadNewResume") {
      setShowUploadNewResume(true);
    }
  }, []);

  useEffect(() => {
    if (addedJobProfileID !== 0) {
      setNewRecord({ ...newRecord, jobProfileID: addedJobProfileID });
      setAddedJobProfileID(0);
    }
  }, [addedJobProfileID]);

  useEffect(() => {
    if (addedJobOpeningID !== 0) {
      setNewRecord({ ...newRecord, jobOpeningID: addedJobOpeningID });
      setAddedJobOpeningID(0);
    }
  }, [addedJobOpeningID]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (dropDownJobProfileClick === false && dropDownJobOpeningClick === false) {
      InsertRecord();
    }
  };

  const InsertRecord = async () => {
    setShowSkeleton(true);
    let dataToInsert = {
      jobProfileID: newRecord?.jobProfileID,
      firstName: newRecord?.firstName,
      lastName: newRecord?.lastName,
      mobileNo1: newRecord?.mobileNo1 || null,
      mobileNo2: null,
      emailID: newRecord?.emailID || null,
      linkedIn: null,
      skype: null,
      currentAddress: null,
      currentStateID: null,
      currentCityID: null,
      currentPIN: null,
      permanentAddress: null,
      permanentCityID: null,
      permanentStateID: null,
      permanentPIN: null,
      dob: null,
      maritalStatusID: null,
      dependentFamilyMember: 0,
      academicQualificationID: null,
      academicQualificationGrade: null,
      academicQualificationYear: null,
      primaryEducationLanguage: null,
      majorTechnicalSkills: null,
      minorTechnicalSkills: null,
      otherTechnicalSkills: null,
      attitude: null,
      candidateSourceID: null,
      currentCompanyName: null,
      currentCTC: null,
      expectedCTC: null,
      noticePeriod: null,
      readyToRelocate: newRecord?.readyToRelocate || false,
      totalExperience: null,
      relevantExperience: null,
      descrPlainText: null,
      descrFormattedText: null,
    };
    const result = await candidateControlServices.InsertData(dataToInsert);

    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
        setShowSkeleton(false);
      }
    } else {
      UpdateResume(result.data.responseData);
      onClosePopup();
      setShowSkeleton(false);
      setNewRecord({});
    }
  };

  const UpdateResume = async (candidateID) => {
    var Datatoupload = { attachment: resume, candidateID: candidateID };
    await candidateControlServices.UpdateCandidateDocData(
      Datatoupload,
      candidateID
    );
  };

  const onClickClearResume = async (e) => {
    UploadResumeRef.current?.instance.reset();
    setResume(null);
  };

  const onClosePopup = () => {
    props.setOpenPopup
      ? props.setOpenPopup(false)
      : setShowUploadNewResume(false);
    setResume(null);
    setNewRecord({});
  };

  const onUploadResumeValueChanged = (e) => {
    const file = e[0];
    if (file && file?.size > maxResumeSizeLength) {
      ShowAlert(`Resume of more than 5 MB size cannot be uploaded`, "Vakency");
      UploadResumeRef?.current?.instance.reset();
      setResume(null);
    } else if (
      file &&
      // file["type"]?.split("/")[1] !== "doc" &&
      // file["type"]?.split("/")[1] !== "docx" &&
      // file["type"]?.split("/")[1] !== "jpg" &&
      // file["type"]?.split("/")[1] !== "png" &&
      file["type"]?.split("/")[1] !== "pdf"
      //  &&
      // file["type"]?.split("/")[1] !== "webp" &&
      // file["type"]?.split("/")[1] !== "jpeg" &&
      // file["type"]?.split("/")[1] !== "xlsx" &&
      // file["type"]?.split("/")[1] !== "xls" &&
      // file["type"]?.split("/")[1] !== "msword" &&
      // file["type"]?.split("/")[1] !== "vnd.ms-excel" &&
      // file["type"]?.split("/")[1] !==
      //   "vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      // file["type"]?.split("/")[1] !==
      //   "vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      ShowAlert(`This file is not accepted`, "Vakency");
      UploadResumeRef?.current?.instance.reset();
      setResume(null);
    } else {
      if(file){
        setResume(file);
      }
    }
  };

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={"Upload New Resume"}
          onSubmit={InsertRecord}
        />
      </>
    );
  };

  const onHiding = () => {
    if (currentLocation.pathname === "/UploadNewResume") {
      NavigateTo("/");
    }
  };

  const asyncEmailIDValidation = async (e) => {
    try {
      const EmailID = e.value;
      const ExcludeID = 0;
      const CheckDuplicateParams = `EmailID=${EmailID}&ExcludeID=${ExcludeID}`;
      const result = await candidateControlServices.CheckDuplicateEmailData(
        CheckDuplicateParams
      );
      return new Promise((resolve) => {
        resolve(result.isOk !== false);
      });
    } catch (error) {
      console.error("Error in asyncEmailIDValidation:", error);
      return new Promise((resolve) => {
        resolve(false);
      });
    }
  };

  const asyncMobileNoValidation = async (e) => {
    try {
      const mobileNo = e.value;
      const ExcludeID = 0;
      const CheckDuplicateParams = `mobileNo=${mobileNo}&ExcludeID=${ExcludeID}`;
      const result = await candidateControlServices.CheckDuplicateMobileNoData(
        CheckDuplicateParams
      );
      return new Promise((resolve) => {
        resolve(result.isOk !== false);
      });
    } catch (error) {
      console.error("Error in asyncMobileNoValidation:", error);
      return new Promise((resolve) => {
        resolve(false);
      });
    }
  };

  return (
    <>
      <Popup
        visible={props.openPopup || showUploadNewResume}
        onHiding={onHiding}
        height={"auto"}
        titleRender={PopupTitle}
        wrapperAttr={{ class: "CustomPopup" }}
        deferRendering={false}
        maxHeight={"90vh"}
        width={!isExSmall && !isXSmall && !isXXSmall ? 800 : "94%"}
        onShown={() => FocusedFeild.current?.instance.focus()}
        animation={{
          show: {
            type: "slideIn",
            direction: "top",
          },
          hide: {
            type: "slideOut",
            direction: "top",
            duration: 200,
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          {(props.openPopup || showUploadNewResume) && (
            <div className="px-3 py-1">
              {showSkeleton ? (
                <div>
                  <PopupSkeleton />
                  <PopupSkeleton />
                  <PopupSkeleton />
                  <PopupSkeleton />
                  <PopupSkeleton />
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md">
                      <TextBox
                        label="First Name"
                        labelMode={"floating"}
                        ref={FocusedFeild}
                        value={newRecord?.firstName}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, firstName: e })
                        }
                        maxLength={50}
                      >
                        <Validator
                          validationGroup={ValidationGroupName}
                          elementAttr={{ class: "ForValidationAstrikMark" }}
                        >
                          <RequiredRule message="First Name is Required" />
                        </Validator>
                      </TextBox>
                    </div>
                    <div className=" col-md  pt-md-0 pt-3">
                      <TextBox
                        label="Last Name"
                        labelMode={"floating"}
                        maxLength={50}
                        value={newRecord?.lastName}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, lastName: e })
                        }
                      >
                        <Validator
                          validationGroup={ValidationGroupName}
                          elementAttr={{ class: "ForValidationAstrikMark" }}
                        >
                          <RequiredRule message="Last Name is Required" />
                        </Validator>
                      </TextBox>
                    </div>
                  </div>
                  <div className="pt-3">
                    <TextBox
                      label="Email ID"
                      labelMode={"floating"}
                      maxLength={50}
                      inputAttr={{
                        autocomplete: "new",
                      }}
                      value={newRecord?.emailID}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, emailID: e })
                      }
                    >
                      <Validator validationGroup={ValidationGroupName}>
                        {/* <CustomRule
                          ignoreEmptyValue={true}
                          message="Can't accept duplicate email ID"
                          validationCallback={asyncEmailIDValidation}
                        /> */}
                        <AsyncRule
                          reevaluate={false}
                          message="Can't accept duplicate email ID"
                          validationCallback={asyncEmailIDValidation}
                        />
                        <EmailRule message="Email ID is not valid" />
                      </Validator>
                    </TextBox>
                  </div>
                  <div className="pt-3">
                    <NumberBox
                      label="Mobile No."
                      labelMode={"floating"}
                      value={newRecord?.mobileNo1 && newRecord?.mobileNo1}
                      maxLength={10}
                      format="#"
                      onKeyDown={onKeyDown}
                      inputAttr={{
                        maxLength: 10,
                        autocomplete: "new",
                      }}
                      step={0}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, mobileNo1: e })
                      }
                    >
                      <Validator
                        validationGroup={ValidationGroupName}
                        elementAttr={{ class: "ForValidationAstrikMark" }}
                      >
                        <RequiredRule message="Mobile No. is required" />
                        <StringLengthRule
                          message="Mobile No. must have 10 digits"
                          min={10}
                          ignoreEmptyValue={true}
                        />
                        {/* <CustomRule
                          ignoreEmptyValue={true}
                          message="Can't accept duplicate Mobile No."
                          validationCallback={asyncMobileNoValidation}
                        /> */}
                        <AsyncRule
                          reevaluate={false}
                          message="Can't accept duplicate Mobile No."
                          validationCallback={asyncMobileNoValidation}
                        />
                      </Validator>
                    </NumberBox>
                  </div>
                  <div className="row d-flex my-3">
                    <div className="col-md-auto editor-label pt-3 mt-2 pe-3">
                      Resume
                    </div>
                    <div className="col-md-9 col-lg-8 col resumeUpload mx-2  mx-md-0 d-flex border rounded align-items-center">
                      <FileUploader
                        ref={UploadResumeRef}
                        name="candidate resume"
                        multiple={false}
                        uploadMode={"useForm"}
                        selectButtonText="Browse"
                        allowCanceling={false}
                        labelText=""
                        hoverStateEnabled={false}
                        activeStateEnabled={false}
                        focusStateEnabled={false}
                        uploadedMessage="Resume Uploaded"
                        readyToUploadMessage=""
                        className=""
                        inputAttr={{ 'aria-label': 'Browse' }}
                        onValueChange={onUploadResumeValueChanged}
                        value={resume ? [resume] : []}
                        hint={resume ? resume?.name : ""}
                      />
                      {resume && (
                        <>
                          <Button
                            icon="close"
                            id="Close"
                            onClick={onClickClearResume}
                            stylingMode="text"
                            type="default"
                            hint="Close"
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="add-details-section">
                    <hr className="py-0 mt-2 mb-3 " />
                    <div className="font-semiBold-16 d-flex ">
                      {" "}
                      <span className="pe-3">Add Details</span>
                      {/* <Button
                    text="?"
                    className="text-white rounded-5 add-detail-question-mark"
                    type="default"
                    id="AddDetails"
                  />
                  <Tooltip
                    target="#AddDetails"
                    showEvent="click"
                    position={"top"}
                    width={220}
                    hideOnOutsideClick={true}
                  >
                    <div className="tooltip-content">
                      If you are adding multiple candidates, these details will
                      be applied to all of there profile
                    </div>
                  </Tooltip> */}
                    </div>

                    <div className="pt-2">
                      <JobProfileDropDown
                        validationRequired={true}
                        EditorData={newRecord?.jobOpeningID}
                        customValidate={true}
                        dropdownHeight={135}
                        setAddedJobProfileID={setAddedJobProfileID}
                        setDropDownClick={setDropDownJobProfileClick}
                        validationGroup={ValidationGroupName}
                        value={newRecord?.jobProfileID}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, jobProfileID: e })
                        }
                        ValidationMessagePosition={"top"}
                        validationMessage={"Job Profile or Job Opening is required"}
                      />
                    </div>
                    <div className="text-center py-1 mt-1 or-text">OR</div>
                    <div className="">
                      <JobOpeningDropDown
                        EditorData={newRecord?.jobProfileID}
                        customValidate={true}
                        validationRequired={true}
                        validationGroup={ValidationGroupName}
                        dropdownHeight={70}
                        value={newRecord?.jobOpeningID}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, jobOpeningID: e })
                        }
                        setAddedJobOpeningID={setAddedJobOpeningID}
                        setDropDownClick={setDropDownJobOpeningClick}
                        validationMessage={"Job Profile or Job Opening is required"}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {showSkeleton ? (
            <div className="d-flex justify-content-end pb-3 pe-3">
              <div className="skeleton-View Skeleton-popup-footer"></div>
            </div>
          ) : (
            <PopupFooter
              ValidationGroupName={ValidationGroupName}
              openPopup={props.openPopup || showUploadNewResume}
              setOpenPopup={props.setOpenPopup || setShowUploadNewResume}
            />
          )}
        </form>
      </Popup>
    </>
  );
};

export default UploadNewResume;
