import React from "react";
import ViewMode from "../../layouts/templates/ViewMode";
import { useNavigate } from "react-router-dom";
import { BlankProfile } from "../../utils/base-64-Icons";

const ScheduleView = (props) => {

  const navigate = useNavigate();

  const viewData = props.viewData;

  const handleEdit = () => {
    navigate('/Schedule/Edit', {
      state: { ScheduleId: viewData?.scheduleID }
    })
  }
  
  return (
    <>
      <ViewMode
        showView={props.showView}
        setShowView={props.setShowView}
        title={"Schedule"}
        handleEdit={handleEdit}
      >
        <div className="view-panel-title ">
          Schedule Information
          </div>
        <div className="row mt-4">
          <div className="col">
            <div className="view-text-value">
              {viewData?.scheduleNo}
            </div>
            <div className="view-label">Schedule No.</div>
          </div>
          <div className="col">
            <div className="view-text-value">
              {viewData?.candidateInteraction}
            </div>
            <div className="view-label">Candidate Interaction</div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <div className="view-text-value">
              {viewData?.scheduleDateAndTime.toLocaleString()}
            </div>
            <div className="view-label">Schedule Date & Time</div>
          </div>
        </div>
        <div className="mt-4">
          <div className="view-text-value text-break">
            <a href={viewData?.meetingLink} target="_blank" rel="noreferrer" >{viewData?.meetingLink}</a>
          </div>
          <div className="view-label">Meeting Link</div>
        </div>
        <div className="mt-4">
          <div className="view-text-value">
            {viewData?.description}
          </div>
          <div className="view-label">Description</div>
        </div>

        <div className="mt-4">
          <div className="view-text-value">
            {viewData?.meetingStatus}
          </div>
          <div className="view-label">Meeting Status</div>
        </div>
        <hr className="my-4" />
        <div >
          <div className="view-panel-title ">
            Candidate Interaction Information
          </div>

          <div className="row mt-3">
            <div className="col">
              <div className="view-text-value">
                Dhawal Sharma
              </div>
              <div className="view-label">Candidate Name</div>

              <div className="view-text-value mt-4">21</div>
              <div className="view-label">Candidate No.</div>

            </div>
            <div className="col">
              <img
                src={BlankProfile}
                alt="candidate_picture"
                width={120}
                className="rounded"
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <div className="view-text-value">
                React js Developer
              </div>
              <div className="view-label ">Job Profile</div>

            </div>
            <div className="col">
              <div className="view-text-value">9898222211</div>
              <div className="view-label ">Mobile No.</div>

            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="view-text-value mt-4">
                rohit@alittech.com
              </div>
              <div className="view-label ">Email ID</div>

            </div>
            <div className="col">
            <div className="view-text-value mt-4">
                2 years 4 months
              </div>
              <div className="view-label">Total Experience</div>
              
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
            <div className="view-text-value">
                <a href="">Download Now</a>
              </div>
              <div className="view-label ">Resume</div>
            
            </div>
            <div className="col">
            <div className="view-text-value">
                <a href="" className="text-break">
                  https://www.linkedin.com
                </a>
              </div>
              <div className="view-label ">Linkedin</div>
             
            </div>
          </div>
          <div className="row">
            <div className="col">
            <div className="view-text-value mt-4">544</div>
              <div className="view-label">Interaction No.</div>
              
            </div>
            <div className="col">
            <div className="view-text-value mt-4">Passed</div>
              <div className="view-label">Result</div>
             
            </div>
          </div>
        </div>
        <div className='row px-2 mt-4'><hr className='mb-1' /></div>
        <div className='row text-end font-regular-12 pb-5'>
          <div>Created by <span className='Italic-text'><i>Admin</i> </span>at <span className='Italic-text'><i>01:41 PM</i> </span>on <span className='Italic-text'><i>27/12/2023</i> </span></div>
        </div>
      </ViewMode>
    </>
  );
};

export default ScheduleView;
