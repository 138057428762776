import { Popup, TextBox, Validator } from 'devextreme-react';
import React, { useEffect, useRef, useState } from 'react';
import PopupHeader from '../../../layouts/popup-header-footer/PopupHeader';
import PopupFooter from '../../../layouts/popup-header-footer/PopupFooter';
import { RequiredRule } from 'devextreme-react/data-grid';
import { useScreenSize } from '../../../utils/media-query';
import { ConflictPopup, ShowAlert, eCRUDStatus } from '../../../utils/common-methods';
import { AsyncRule, CustomRule } from 'devextreme-react/validator';
import PopupSkeleton from '../../../layouts/templates/PopupSkeleton';
import { CandidateSourceServices } from '../../../api/services/CandidateSourceServices';

const candidateSourceServices = new CandidateSourceServices();
export default function CandidateSourcePopup(props) {

  const ValidationGroupName = "CandidateSourcePopupValidation"

  const { isExSmall, isXSmall, isXXSmall } = useScreenSize();

  const [newRecord, setNewRecord] = useState({});
  const [originalRecord, setOriginalRecord] = useState({});
  const [showSkeleton, setShowSkeleton] = useState(true);
  const onShown = () => { FocusedFeild.current?.instance.focus() }

  let PrimaryKeyID = props.primaryKey;

  useEffect(() => {
    if (PrimaryKeyID !== null && PrimaryKeyID !== 0 && PrimaryKeyID !== undefined) {
      GetModelRecord();
    } else {
      setNewRecord({});
      setShowSkeleton(false);

    }
  }, [PrimaryKeyID]);

  useEffect(() => {
    !showSkeleton && FocusedFeild.current?.instance.focus()
  }, [showSkeleton])

  const GetModelRecord = async () => {
    setTimeout(async () => {
    const result = await candidateSourceServices.GetModelData(PrimaryKeyID);
    if (result.statusCode === 200) {
      const response = result.data;
      setNewRecord(response);
      setOriginalRecord(response)
      setShowSkeleton(false);
    }
  }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!PrimaryKeyID) {
      InsertRecord();
    } else {
      UpdateRecord();
    }
  };

  const InsertRecord = async () => {

    setShowSkeleton(true);
    let dataToInsert = {
      candidateSourceName: newRecord.candidateSourceName,
    };
    const result = await candidateSourceServices.InsertData(dataToInsert);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
        setShowSkeleton(false);
      }
    } else {
      props.setStatus({
        eStatus: eCRUDStatus.Inserted,
        primaryKeyID: result.data.responseData,
      });
      onClosePopup();
      setShowSkeleton(false);
    }
  };

  const UpdateRecord = async () => {
    if(originalRecord == newRecord || (originalRecord !== newRecord)){
    setShowSkeleton(true);
    var dataToUpdate = {
      updatedOn: newRecord.updatedOn,
      candidateSourceID: newRecord.candidateSourceID,
      candidateSourceName: newRecord.candidateSourceName,
    };

    const result = await candidateSourceServices.UpdateData(dataToUpdate);
    if (!result.isOk) {
      if (result.statusCode === 409) {
        ConflictPopup(result.data).then((res) => {
          if (res) {
            GetModelRecord();
          } else {
            return;
          }
        });
        setShowSkeleton(false);
      } else if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
        setShowSkeleton(false);
      }
    } else {
      props.setStatus({
        eStatus: eCRUDStatus.Updated,
        primaryKeyID: result.data.responseData,
      });
      onClosePopup();
      setShowSkeleton(false);
    }
  }
  };


  const onClosePopup = () => {
    props.setOpenPopup(false)
    setNewRecord({});
  }

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={[<span key={"header_title"} className="base-accent-text">{props.primaryKey ? "Edit" : "New"}</span>, " Candidate Source"]}
          onSubmit={PrimaryKeyID ? UpdateRecord : InsertRecord}
          hideSaveButton={showSkeleton ? true : false}
        />
      </>
    )
  }

  const FocusedFeild = useRef(null);

  const asyncCandidateSourceNameValidation = async (e) => {
    try {
      const Name = e?.value;
      const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
      const CheckDuplicateParams = `Name=${Name}&ExcludeID=${ExcludeID}`;
      const result = await candidateSourceServices.CheckDuplicateData(CheckDuplicateParams);
      return new Promise((resolve) => {
        resolve(result.isOk !== false);
      });
    } catch (error) {
      console.error("Error in asyncCandidateSourceNameValidation:", error);
      return new Promise((resolve) => {
        resolve(false);
      });
    }
  };
  

  return (
    <div className='CandidateSource_popup'>
      <Popup
        visible={props.openPopup}
        titleRender={PopupTitle}
        width={(isExSmall || isXXSmall || isXSmall) ? "80%" : 500}
        height={'auto'}
        wrapperAttr={{ class: "CustomPopup" }}
        onShown={onShown}
      >
        <form onSubmit={handleSubmit}>
          {showSkeleton ?
            <div className='p-2 pb-0'>
              <PopupSkeleton />
            </div>
            :
            <div className='py-2 px-3'>
              <TextBox
                label='Candidate Source Name'
                maxLength={50}
                labelMode='floating'
                value={newRecord.candidateSourceName}
                ref={FocusedFeild}
                onValueChange={(e) => setNewRecord({ ...newRecord, candidateSourceName: e })}
              >
                <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                  <RequiredRule message='Candidate Source Name is required' />
                  {/* <CustomRule
                    ignoreEmptyValue={true}
                    message="Can't accept duplicate Candidate Source Name"
                    validationCallback={asyncCandidateSourceNameValidation}
                  /> */}
                   <AsyncRule
                        reevaluate={false}
                        message="Can't accept duplicate Candidate Source Name"
                        validationCallback={asyncCandidateSourceNameValidation}
                      />
                </Validator>
              </TextBox>
            </div>
          }
          {showSkeleton ?
            <div className='d-flex justify-content-end pb-3 pe-2'>
              <div className='skeleton-View Skeleton-popup-footer'></div>
            </div>
            :
            <PopupFooter
              ValidationGroupName={ValidationGroupName}
              openPopup={props.openPopup}
              setOpenPopup={props.setOpenPopup}
            />
          }
        </form>
      </Popup>
    </div>
  )
}
