import { Button, DataGrid } from 'devextreme-react'
import { Button as DataGridButton, Column, ColumnChooser, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, Pager, Scrolling, SearchPanel, Sorting, StateStoring, Toolbar, ColumnChooserSearch, Search, } from 'devextreme-react/data-grid'
import React, { useEffect, useRef, useState } from 'react'
import { useScreenSize } from '../../utils/media-query'
import { Header } from '../../components'
import { useNavigate, useLocation } from 'react-router'
import CandidateView from './CandidateView'
import ListSkeleton from '../../layouts/templates/ListSkeleton'
import StarRating, { HideDatagridLoader, ShowAlert, eCRUDStatus, GetFormattedDate, onDragOver, GetFormattedDateTimeUTCString } from '../../utils/common-methods'
import { confirm } from 'devextreme/ui/dialog';
import { CancelledByCandidateStatusIcon, CandidateNotRespondingStatusIcon, CandidateProfileOpeningIcon, HiredStatusIcon, JoinedStatusIcon, OfferAcceptedStatusIcon, OfferedStatusIcon, SelectJobOpening, ShortlisteddButNotSelectedStatusIcon, UnderReviewIcon, candidateRejectedByUsIcon, pendingIcon, rejectedIcon, selectedIcon, shortlistedIcon } from '../../utils/base-64-Icons'
import JobOpeningSearchPage from '../job-opening/JobOpeningSearchPage'
import { CandidateControlServices } from '../../api/services/CandidateControlServices'


const candidateControlServices = new CandidateControlServices();
const CandidateList = () => {


  const { state, pathname } = useLocation();

  let { popupStatus } = state || {};

  const { isExSmall, isXXSmall } = useScreenSize();
  const NavigateTo = useNavigate();
  const [filterVisible, setFilterVisible] = useState(false);
  const [showView, setShowView] = useState(false);
  const [CandidateViewPrimaryKey, setCandidateViewPrimaryKey] = useState(null)
  // const [showSkeleton, setShowSkeleton] = useState(false)
  const [showSkeleton, setShowSkeleton] = useState(true)
  const [dsCandidate, setDsCandidate] = useState([])
  const [focusedRowKey, setfocusedRowKey] = useState(0);
  const [primaryKey, setPrimaryKey] = useState(null);
  const [openSearchPage, setOpenSearchPage] = useState(false);
  const [candidateID, setCandidateID] = useState(null);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });



  const GetList = async () => {
    // setShowSkeleton(true)
    const result = await candidateControlServices.GetListData();
    const res = result.data
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency")
      }
      setShowSkeleton(false)
    } else {
      let CandidateData = res?.map(item => {
        const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
        const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
        const dob = item.dob ? new Date(new Date(item.dob + "Z").toUTCString()).toLocaleDateString() : null;
        var today = new Date();
        var birthDate = new Date(dob);
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age_now--;
        }
        return {
          ...item,
          dob: dob ? dob : null,
          createdOn: createdOn ? createdOn : null,
          updatedOn: updatedOn ? updatedOn : null,
          ageInYears: dob && age_now ? age_now + " yrs" : null,
          relevantExperience: item.relevantExperience ? item.relevantExperience <= 1 ? item.relevantExperience + " yr" : item.relevantExperience + " yrs" : null,
          totalExperience: item.totalExperience ? item.totalExperience <= 1 ? item.totalExperience : item.totalExperience : null,
          noticePeriod: item.noticePeriod ? item.noticePeriod + " days" : null
        };
      });
      if (popupStatus?.eStatus === eCRUDStatus.Inserted) {
        let updatedRow = CandidateData.filter(x => x.primaryKeyID !== popupStatus?.primaryKeyID)
        let insertedRecord = res.find(x => x.primaryKeyID === popupStatus?.primaryKeyID);
        updatedRow.unshift(insertedRecord);
        CandidateData = [...updatedRow];
      }
      setDsCandidate(CandidateData);

      if (popupStatus?.eStatus === eCRUDStatus.Inserted || popupStatus?.eStatus === eCRUDStatus.Updated || popupStatus?.eStatus === eCRUDStatus.None) {
        let updatedRow = res.filter(x => x.primaryKeyID === popupStatus?.primaryKeyID)
        setfocusedRowKey(updatedRow[0]?.primaryKeyID)
      }
      else {
        if (res.length > 0) {
          setfocusedRowKey(res[0].primaryKeyID)
        } else {
          setfocusedRowKey(null)
        }
      }
      popupStatus = {
        eStatus: eCRUDStatus.None,
        primaryKeyID: null
      }

      setShowSkeleton(false)
    }
  }

  useEffect(() => {
    GetList();
  }, [])

  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID = status.eStatus === eCRUDStatus.Updated ? status.primaryKeyID : primaryKey;
      UpdateListByID(PrimaryKeyID);
    }
  }, [status])

  const UpdateListByID = async (primaryKey) => {
    const result = await candidateControlServices.GetListByIdData(primaryKey);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency")
    } else {
      const CSData = result.data?.map(item => {
        const createdOn = item.createdOn ? new Date(new Date(item.createdOn + "Z").toUTCString()).toLocaleString() : null;
        const updatedOn = item.updatedOn ? new Date(new Date(item.updatedOn + "Z").toUTCString()).toLocaleString() : null;
        return {
          ...item,
          createdOn: createdOn ? createdOn : null,
          updatedOn: updatedOn ? updatedOn : null
        };
      });
      // if (status.eStatus === eCRUDStatus.Inserted) {
      //   setDsCandidateSource([CSData[0], ...dsCandidateSource]);
      // }
      if (status.eStatus === eCRUDStatus.Updated) {
        setDsCandidate(dsCandidate.map((i) => i.candidateID === primaryKey ? CSData[0] : i));
      }
    }
    setfocusedRowKey(primaryKey);
    setPrimaryKey(0);
  };


  const onOptionChange = (e) => {
    if (e.fullName === "filterValue") {
      setFilterVisible(!!e.value);
    }
  };

  const handleEdit = (e) => {
    setPrimaryKey(e?.data.candidateID)
    NavigateTo("/Candidate/Edit", {
      state: {
        ...state,
        CandidateID: e?.data.candidateID,
        editorOpenFrom: pathname,
      },
    })
  }

  const handleAdd = (e) => {
    setPrimaryKey(null)
    NavigateTo("/Candidate/New", {
      state: {
        CandidateID: null,
        editorOpenFrom: pathname,
      },
    })
  }

  const onDeleteButtonClick = (e) => {
    let res = confirm(
      `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
      "Vakency"
    );
    res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
  };


  const handleDelete = async (e) => {
    let Id = e.row?.data?.candidateID
    const result = await candidateControlServices.DeleteData(Id)
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency")
    }
    else if (result.statusCode === 200) {
      setDsCandidate(dsCandidate.filter((x) => x.candidateID !== Id));
      const deleteResult = await candidateControlServices.DeleteCandidatePicture(Id)
      if (deleteResult.statusCode === 400) {
        ShowAlert(deleteResult.data, "Vakency")
      }
      const deleteResume = await candidateControlServices.DeleteCandidateDocData(Id)
      if (deleteResume.statusCode === 400) {
        ShowAlert(deleteResume.data, "Vakency")
      }
    }

  }

  const VisitJobOpeningControlPanel = () => {
    NavigateTo("/JobOpeningControlPanel")
  }

  const handleQuickView = (e) => {
    setCandidateViewPrimaryKey(e.row?.data?.candidateID)
    setShowView(!showView)
  }

  const CandidateDataGridRef = useRef(null);

  const onRowDblClick = (e) => {
    setPrimaryKey(e?.data.candidateID)
    NavigateTo("/Candidate/Edit", {
      state: {
        ...state,
        CandidateID: e?.data.candidateID,
        editorOpenFrom: pathname,
      },
    })
  }

  function onFocusedRowChanged(e) {
    setfocusedRowKey(e.component.option("focusedRowKey"));
  }


  const VisitJobOpenigSearch = (e) => {
    setCandidateID(focusedRowKey)
    setOpenSearchPage(true);
  }

  const VisitCandidateProfile = (e) => {
    NavigateTo("/CandidateProfile", {
      state: {
        ...state,
        CandidateID: e?.data.candidateID,
        profileOpenFrom: pathname,
      },
    })
  }

  const CandidateNameCell = (cellData) => {
    return <>
      <div className='py-1'><span className='text-decoration-underline pointer-link font-semiBold-18 text-break' title='Edit' onClick={(e) => handleEdit(cellData)}>{cellData.data.candidateName}</span></div>
      <div className='py-1 font-medium-14 text-secondary text-break'>
        <i>{cellData.data.jobProfileName}</i>
      </div>
      <div className='py-1 '>
        <StarRating rating={cellData.data.rating} />
      </div>
      <div className='row'>
        <div className='col-auto pe-0 py-1 text-decoration-underline pointer-link' title="Visit Control Panel" onClick={cellData.data.JobOpeningNo && VisitJobOpeningControlPanel}>
          <i>{cellData.data.JobOpeningNo ? "#" + cellData.data.JobOpeningNo : ""}</i>
          {cellData.data.JobOpeningNo && <span className="pipe-view-resume-rating px-2">|</span>}
        </div>
        <div className='col ps-0'>
          {cellData?.data.jobApplicationStatusName ? (
            <img
              src={
                cellData?.data.jobApplicationStatusName === "Job application rejected by us"
                  ? rejectedIcon
                  : cellData?.data.jobApplicationStatusName === "Pending"
                    ? pendingIcon :
                    cellData?.data.jobApplicationStatusName === "Shortlisted"
                      ? shortlistedIcon :
                      cellData?.data.jobApplicationStatusName === "Under Review"
                        ? UnderReviewIcon :
                        cellData?.data.jobApplicationStatusName === "Selected"
                        ? selectedIcon :
                        cellData?.data.jobApplicationStatusName === "Offered"
                          ? OfferedStatusIcon :
                          cellData?.data.jobApplicationStatusName === "Offer accepted"
                            ? OfferAcceptedStatusIcon :
                            cellData?.data.jobApplicationStatusName === "Hired"
                              ? HiredStatusIcon :
                              cellData?.data.jobApplicationStatusName === "On-boarded/Joined"
                                ? JoinedStatusIcon :
                                cellData?.data.jobApplicationStatusName === "Offer rejected by candidate"
                                  ? candidateRejectedByUsIcon :
                                  cellData?.data.jobApplicationStatusName === "Shortlisted but not selected"
                                    ? ShortlisteddButNotSelectedStatusIcon :
                                    cellData?.data.jobApplicationStatusName === "Cancelled by Candidate"
                                      ? CancelledByCandidateStatusIcon :
                                      cellData?.data.jobApplicationStatusName === "Candidate is not responding"
                                      && CandidateNotRespondingStatusIcon
              }
              alt="status"
              className="pt-1"
              width={20}
              title={cellData?.data.jobApplicationStatusName}
            />
          )
            :
            (
              <img
                src={
                  SelectJobOpening
                }
                alt=""
                className="cursor-pointer"
                width={24}
                title={"Select Job Opening"}
                onClick={VisitJobOpenigSearch}
              />
            )
          }
          <span className="px-1"></span>
          <img
            src={
              CandidateProfileOpeningIcon
            }
            alt=""
            className="pt-1 cursor-pointer"
            width={24}
            title={"Candidate Profile"}
            onClick={(e) => VisitCandidateProfile(cellData)}
          />
        </div>
      </div>
    </>
  }

  const ExperienceCellRender = (e) => {
    return (
      <>
        {e.row?.data?.totalExperience !== null && e.row?.data?.totalExperience !== undefined
          ? (e.row?.data.totalExperience % 1 !== 0
            ? (e.row?.data.totalExperience.toString().split('.')[1]?.length === 1
              ? `${e.row?.data.totalExperience.toFixed(2)} ${e.row?.data.totalExperience == 1 ? 'yr' : 'yrs'}`
              : `${e.row?.data.totalExperience} ${e.row?.data.totalExperience == 1 ? 'yr' : 'yrs'}`)
            : `${e.row?.data.totalExperience} ${e.row?.data.totalExperience == 1 ? 'yr' : 'yrs'}`)
          : ""}
      </>
    );
  };

  return (
    <>
      <Header
        title={"Candidates"}
        handleAdd={handleAdd}
        dataGridRef={CandidateDataGridRef}
        GetRecord={GetList}
      />
      <div className='list-section' onDragOver={onDragOver}>
        {
          showSkeleton ?
            <ListSkeleton />
            :
            <DataGrid
              ref={CandidateDataGridRef}
              dataSource={dsCandidate}
              showBorders={true}
              showColumnLines={false}
              showRowLines={true}
              focusedRowEnabled={true}
              wordWrapEnabled={true}
              hoverStateEnabled={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              autoNavigateToFocusedRow={true}
              focusedRowKey={focusedRowKey}
              onFocusedRowChanged={onFocusedRowChanged}
              keyExpr="candidateID"
              height={'100%'}
              width={"100%"}
              className='List_DataGrid'
              filterSyncEnabled={true}
              onOptionChanged={onOptionChange}
              loadPanel={HideDatagridLoader}
              onRowDblClick={onRowDblClick}
              noDataText='No Record Found'
            >
              <Sorting mode={"multiple"} />
              <FilterPanel visible={filterVisible} />
              <Scrolling mode={"infinite"} rowRenderingMode='virtual' preloadEnabled={true} useNative={true} />
              <ColumnChooser enabled={true}>
                <ColumnChooserSearch
                  enabled={true}
                />
              </ColumnChooser>
              <HeaderFilter visible={true} >
                <Search
                  enabled={true}
                />
              </HeaderFilter>
              <SearchPanel visible={true} width={(isExSmall || isXXSmall) ? 200 : 400} />
              <FilterBuilderPopup />
              <StateStoring enabled={true} type="localStorage" storageKey="Candidate_Layout" />
              <Pager
                visible={true}
                showInfo={true}
                infoText={`{2} Rows`}
              ></Pager>
              <Toolbar>
                <Item location={'after'} name="columnChooserButton" />
                <Item location={'after'} name="searchPanel" />
              </Toolbar>
              {/* <Column type='buttons' name="quickView_button_column" fixed={true} fixedPosition='left' allowResizing={false} width={30} allowReordering={false}>
                <DataGridButton
                  name='quick view'
                  icon='QuickViewIcon'
                  visible={true}
                  onClick={handleQuickView}
                />
              </Column> */}
              {/* <Column caption='#'
                dataField='candidateNo'
                minWidth={40}
                cellRender={CandidateNo}
                width={70}
                alignment='left'
                allowHiding={false}
                allowHeaderFiltering={false}
              /> */}
              <Column
                dataField='jobProfileName'
                visible={false}
                showInColumnChooser={false}
              />
              <Column
                caption='Candidate'
                dataField='candidateName'
                minWidth={150}
                width={160}
                cellRender={CandidateNameCell}
                alignment='left'
                fixed={true}
                fixedPosition='left'
              />
              <Column
                caption='Experience'
                dataField='totalExperience'
                cellRender={ExperienceCellRender}
                minWidth={90}
                width={120}
                alignment='left'
              />
              <Column
                caption='CTC'
                dataField='currentCTC'
                minWidth={100}
                width={120}
                alignment='left'
              />
              <Column
                caption='Location'
                dataField='currentCityName'
                minWidth={90}
                width={110}
                alignment='left'
              />
              <Column
                caption='Qualification'
                dataField='academicQualificationName'
                minWidth={110}
                width={120}
              />
              <Column
                caption='Age'
                dataField='ageInYears'
                minWidth={80}
                width={90}
              />
              <Column
                caption='Mobile No.'
                dataField='mobileNo1'
                minWidth={80}
                width={110}
                alignment='left'
              />
              <Column
                caption='Email ID'
                dataField='emailID'
                minWidth={100}
                alignment='left'
              />
              <Column
                caption='DOB'
                dataField='dob'
                minWidth={80}
                width={85}
                alignment='left'
                visible={false}
              />
              <Column
                caption='Current Company'
                dataField='currentCompanyName'
                minWidth={80}
                width={100}
                visible={false}
              />
              <Column
                caption='Mobile No.2'
                dataField='mobileNo2'
                minWidth={80}
                width={110}
                alignment='left'
                visible={false}
              />
              <Column
                caption='Marital Status'
                dataField='maritalStatusName'
                minWidth={70}
                width={80}
                alignment='left'
                visible={false}
              />
              <Column
                caption='Dependent Family Members'
                dataField='dependentFamilyMember'
                minWidth={70}
                width={80}
                alignment='left'
                visible={false}
              />
              <Column
                caption='Linkedin'
                dataField='linkedIn'
                minWidth={70}
                width={90}
                alignment='left'
                visible={false}
              />
              <Column
                caption='Skype'
                dataField='skype'
                minWidth={70}
                width={90}
                alignment='left'
                visible={false}
              />
              <Column
                caption='Current Address'
                dataField='currentAddress'
                minWidth={90}
                width={110}
                alignment='left'
                visible={false}
              />
              <Column
                caption='Current Pin'
                dataField='currentPIN'
                minWidth={60}
                width={70}
                alignment='left'
                visible={false}
              />
              <Column
                caption='Permanent Address'
                dataField='permanentAddress'
                minWidth={90}
                alignment='left'
                visible={false}
              />
              <Column
                caption='Permanent City'
                dataField='permanentCityName'
                minWidth={90}
                width={110}
                alignment='left'
                visible={false}
              />
              <Column
                caption='Permanent Pin'
                dataField='permanentPIN'
                minWidth={60}
                width={70}
                alignment='left'
                visible={false}
              />
              <Column
                caption='Primary Education Language'
                dataField='primaryEducationLanguage'
                minWidth={70}
                width={90}
                visible={false}
              />
              <Column
                caption='Major Technical Skills'
                dataField='majorTechnicalSkills'
                minWidth={100}
                width={120}
                visible={false}
              />
              <Column
                caption='Minor Technical Skills'
                dataField='minorTechnicalSkills'
                minWidth={100}
                width={120}
                visible={false}
              />
              <Column
                caption='Other Skills'
                dataField='otherTechnicalSkills'
                minWidth={100}
                width={120}
                visible={false}
              />
              <Column
                caption='Attitude'
                dataField='attitude'
                minWidth={100}
                width={120}
                visible={false}
              />
              <Column
                caption='Source'
                dataField='candidateSourceName'
                minWidth={80}
                width={100}
                visible={false}
              />
              {/* <Column
                caption='Current CTC'
                dataField='currentCTC'
                minWidth={100}
                width={130}
                alignment='left'
                visible={false}
              /> */}
              <Column
                caption='Expected CTC'
                dataField='expectedCTC'
                minWidth={100}
                width={110}
                alignment='left'
                visible={false}
              />
              <Column
                caption='Notice Period'
                dataField='noticePeriod'
                minWidth={60}
                width={80}
                alignment='left'
                visible={false}
              />
              <Column
                caption='Ready To Relocate'
                dataField='readyToRelocate'
                minWidth={60}
                width={80}
                visible={false}
              />
              <Column
                caption='Relevant Experience'
                dataField='relevantExperience'
                minWidth={90}
                width={110}
                alignment='left'
                visible={false}
              />
              <Column
                caption='Description Notes'
                dataField='descrPlainText'
                minWidth={100}
                width={180}
                alignment='left'
                visible={false}
              />
              <Column
                dataField="createdByUserName"
                caption="Created By"
                visible={false}
                width={110}
                minWidth={80}
                alignment="left"
              />
              <Column
                dataField="updatedByUserName"
                caption="Updated By"
                visible={false}
                width={110}
                minWidth={80}
                alignment="left"
              />
              <Column
                dataField="createdOn"
                caption="Created On"
                visible={false}
                width={110}
                minWidth={80}
                alignment="left"

              />
              <Column
                dataField="updatedOn"
                caption="Updated On"
                visible={false}
                width={110}
                minWidth={80}
                alignment="left"

              />

              <Column type='buttons' name="Delete_button_column" fixed={false} allowResizing={false} width={43} allowReordering={false}>
                <DataGridButton
                  name='delete'
                  icon='trash'
                  hint='Delete'
                  visible={true}
                  onClick={onDeleteButtonClick}
                  cssClass='text-danger'
                />
              </Column>

            </DataGrid>
        }
      </div>
      {showView === true &&
        <CandidateView
          showView={showView}
          setShowView={setShowView}
          candidateViewPrimaryKey={CandidateViewPrimaryKey}
        />
      }
      {openSearchPage && (
        <JobOpeningSearchPage
          openSearchPage={openSearchPage}
          setOpenSearchPage={setOpenSearchPage}
          candidateID={candidateID}
          setStatus={setStatus}
        />
      )}
    </>
  )
}

export default CandidateList