import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { JobApplicationControlName } from "../../contexts/APIurl";

const url = JobApplicationControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class JobApplicationServices extends VakencyApiServices {
  constructor() {
    super(url);
  }


GetListByJobOpeningId=async(jobOpeningID)=>{
  const GetListUrl = url + "/GetList"+ `${jobOpeningID ? "?JobOpeningID=" + jobOpeningID : ""}`
  let data = null;
  try {
      data = await fetchWrapper.get(baseURL + GetListUrl)
      return this.handleResponse(data);
  }
  catch {
      return {
          isOk: false,
          message: "Failed To Load Data",
          statusCode: data.status
      };
  }
}

GetListByCandidateId=async(candidateID)=>{
  const GetListUrl = url + "/GetListByCandidateID"+ `${candidateID ? "?ID=" + candidateID : ""}`
  let data = null;
  try {
      data = await fetchWrapper.get(baseURL + GetListUrl)
      return this.handleResponse(data);
  }
  catch {
      return {
          isOk: false,
          message: "Failed To Load Data",
          statusCode: data.status
      };
  }
}

}

