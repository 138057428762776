import React, { useEffect, useState } from "react";
import { Header } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Popover, Popup } from "devextreme-react";
import {
  BlankProfile,
  closeIcon,
  openIcon,
  pendingIcon,
  rejectedIcon,
  shortlistedIcon,
} from "../../utils/base-64-Icons";
import "./ShortListed.scss";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import { useScreenSize } from "../../utils/media-query";
import Editor from "../../layouts/templates/Editor";
import JobOpeningPopup from "../job-opening/JobOpeningPopup";
import CandidateDropDownPopup from "../candidate/CandidateDropdownPopup";
import SearchCandidate from "../job-opening/SearchCandidate";
import StarRating, { GetFormattedDate, GetFormattedDateTimeUTCString, ShowAlert, eCRUDStatus , handleNavigateToMap } from "../../utils/common-methods";
import { LoadPanel } from "devextreme-react/load-panel";
import ListSkeleton from "../../layouts/templates/ListSkeleton";
import { confirm } from "devextreme/ui/dialog";
import { JobOpeningControlPanelServices } from "../../api/services/JobOpeningControlPanelServices";
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import { JobApplicationServices } from "../../api/services/JobApplicationServices";
import { JobOpeningServices } from "../../api/services/JobOpeningServices";
import FilePreviewer from "../candidate/FilePreviewer";

const jobOpeningControlPanelServices = new JobOpeningControlPanelServices();
const candidateControlServices = new CandidateControlServices();
const jobApplicationServices = new JobApplicationServices();
const jobOpeningServices = new JobOpeningServices();

const ShortListed = () => {
  const NavigateTo = useNavigate();
  const {
    isSmall,
    isXSmall,
    isExSmall,
    isXXSmall,
    isMedium,
    isLarge,
    isExLarge,
  } = useScreenSize();

  const { state, pathname } = useLocation();

  const {
    jobOpeningID,
    jobProfile,
    targetDate,
    open,
    requiredExperience,
    salary,
  } = state || {};
  const OnCancelClick = () => {
    NavigateTo("/JobOpening/List");
  };

  const JobOpeningState = {
    jobOpeningID,
    jobProfile,
    targetDate,
    open,
    requiredExperience,
    salary,
  }

  const [showRightSection, setshowRightSection] = useState(true);
  const [showLeftSection, setshowLeftSection] = useState(true);

  const [showLeftPopupSm, setShowLeftPopupSm] = useState(false);
  const [showRightPopupSm, setShowRightPopupSm] = useState(false);
  const [showjobOpeningEditor, setShowjobOpeningEditor] = useState(false);

  const [selectedRowKeyDetail, setSelectedRowKeyDetail] = useState();
  const [openPopup, setOpenPopup] = useState();
  const [showSearchCandidate, setShowSearchCandidate] = useState(false);
  const [dsJobShortlistHeader, setDsJobShortlistHeader] = useState();
  const [candidateData, setCandidateData] = useState();
  const [profileImg, setProfileImg] = useState({
    uploadedFileUrl: null,
    attachedFile: null,
  });
  const [resume, setResume] = useState(null);
  const [primaryKey, setPrimaryKey] = useState(null);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });
  const [JobOPeningStatus, SetJobOpeningStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });
  const [Open, setOpen] = useState(open)
  const [resumeStatus, setResumeStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
    uploading: false,
    uploaded: false,
  });
  const [imageStatus, setImageStatus] = useState(false);
  const [showCandidateEditor, setShowCandidateEditor] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [getApplicationStatus, setGetApplicationStatus] = useState(false);
  const [deletedApplicationId, setDeletedApplicationId] = useState();
  const [jobApplicationBySearch, setJobApplicationBySearch] = useState(false);
  const [dsInteractionRounds, setDsInteractionRounds] = useState([]);
  const [maxSalaryMonthlyValue, setMaxSalaryMonthlyValue] = useState(0);
  const [showFilePreview, setShowFilePreview] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [showFilePreviewLoad, setShowFilePreviewLoad] = React.useState(false);

  const EditJobOpening = () => {
    setShowjobOpeningEditor(true);
  };

  const onClickSearchCandidate = () => {
    setShowSearchCandidate(!showSearchCandidate);
  };

  const onClickAddNewCandidate = () => {
    setOpenPopup(!openPopup);
  };

  const GetHeader = async () => {
    const result = await jobOpeningControlPanelServices.GetHeaderList(
      jobOpeningID
    );
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
      // setShowSkeleton(false);
    } else {
      if (result.data?.maxSalary) {
        setMaxSalaryMonthlyValue((result.data.maxSalary * 100000) / 12)
      }
      setDsJobShortlistHeader(result.data);
    }
  };

  useEffect(() => {
    GetHeader();
  }, []);

  useEffect(() => {
    if (JobOPeningStatus.eStatus !== eCRUDStatus.None) {
      GetHeader();
    }
  }, [JobOPeningStatus])

  const GetCandidateModelData = async () => {
    if (selectedRowKeyDetail?.candidateID) {
      const result = await candidateControlServices.GetListByIdData(
        selectedRowKeyDetail?.candidateID
      );
      if (result.isOk) {
        // setGetApplicationStatus(false)
        const CandidateData = result.data?.map((item) => {
          const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
          const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
          let totalExp = null;
          if(item?.totalExperience > 0){
            let [year, month] = item?.totalExperience.toString()?.split('.');
            month = month ? Math.round(parseFloat(`.${month}`) * 100) : `0`;
            totalExp = `${year}.${month}`;
            totalExp = parseFloat(totalExp) <= 1 ? totalExp + ' yr' : totalExp + ' yrs'
          }
          return {
            ...item,
            createdOn: createdOn ? createdOn : null,
            updatedOn: updatedOn ? updatedOn : null,
            totalExperience: totalExp ? totalExp : null
          };
        });
        setCandidateData(CandidateData[0]);
        setTimeout(() => {
          setShowSkeleton(false);
        }, 500)
      } else {
        ShowAlert(result.data, "Vakency");
      }
    }
  };

  // useEffect(() => {
  //   setShowSkeleton(true)

  //   if (
  //     selectedRowKeyDetail?.candidateID &&
  //     (status.eCRUDStatus == null || status.eCRUDStatus == 2) && !imageStatus
  //   ) {
  //     GetCandidateModelData();
  //     GetProfileImage().then((res) => {
  //       setProfileImg({
  //         uploadedFile: {
  //           fileData: res?.size > 0 ? window.URL.createObjectURL(res) : null,
  //         },
  //         attachedFile: res?.size > 0 ? res : null,
  //       });
  //       setShowSkeleton(false)
  //     });
  //   }
  // }, [selectedRowKeyDetail, imageStatus,status]);

  useEffect(() => {
    // if (
    //   selectedRowKeyDetail?.candidateID &&
    //   (status.eCRUDStatus == null || status.eCRUDStatus == 2)
    // ) {
    // } else {
    // }
    setShowSkeleton(true);
    GetCandidateModelData();
  }, [selectedRowKeyDetail, status]);

  useEffect(() => {
    if (selectedRowKeyDetail?.candidateID) {
      GetProfileImage().then((res) => {
        setProfileImg({
          uploadedFile: {
            fileData: res?.size > 0 ? window.URL.createObjectURL(res) : null,
          },
          attachedFile: res?.size > 0 ? res : null,
        });
        setTimeout(() => {
          setShowSkeleton(false);
        }, 500)
      });
    }
  }, [selectedRowKeyDetail, imageStatus]);

  useEffect(() => {
    if (selectedRowKeyDetail?.candidateID) {
      GetResume().then((res) => {
        if (res?.statusCode == 204) {
          setResume({
            uploadedFile: {
              fileData: "NoData"
            },
            attachedFile: "NoData",
          })
        }
        else {
          setResume({
            uploadedFile: {
              fileData: res?.size > 0 ? window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' })) : null
            },
            attachedFile: res?.size > 0 ? res : null,
          });
        }
      });
    }
  }, [selectedRowKeyDetail]);

  useEffect(() => {
    if (resumeStatus.uploaded) {
      GetResume().then((res) => {
        setResume({
          uploadedFile: {
            fileData: res?.size > 0 ? window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' })) : null
          },
          attachedFile: res?.size > 0 ? res : null,
        });
      });
    }
  }, [resumeStatus]);

  useEffect(() => {
    if (selectedRowKeyDetail?.jobApplicationID) {
      GetInteractionRounds(selectedRowKeyDetail?.jobApplicationID);
    }
  }, [selectedRowKeyDetail]);

  const GetProfileImage = async () => {
    let params = `?CandidateID=${selectedRowKeyDetail?.candidateID}`;
    const result = await candidateControlServices.GetImage(params);
    let response = result.data;
    if (result.isOk) {
      return result.data;
    } else {
      return;
    }
  };

  const GetResume = async () => {
    setResume()
    let params = `?CandidateID=${selectedRowKeyDetail?.candidateID}`;
    const result = await candidateControlServices.GetCandidateDocData(params);
    if (result.isOk) {
      let response = result.data;
      return response;
    }
    else {
      if (result.statusCode == 204) {
        return result
      }
    }
  }

  function interactionRoundsSortFn(a, b) {
    // Define the priority for each interactionResultName status
    const getPriority = (resultName) => {
      if (resultName === null) return 2;       // Highest priority for sorting to last
      if (resultName === "Pending") return 1;   // Second priority for sorting to middle
      return 0;                                 // Lowest priority for sorting to first
    };

    // Compare by priority
    const priorityA = getPriority(a.interactionResultName);
    const priorityB = getPriority(b.interactionResultName);
    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    // If priorities are the same, sort by sortOrder
    return a.sortOrder - b.sortOrder;
  }

  const GetInteractionRounds = async (JobApplicationID) => {
    setDsInteractionRounds([]);
    const result = await jobOpeningControlPanelServices.GetInteractionRounds(JobApplicationID);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      if (result?.data.length > 0) {
        result.data.sort(interactionRoundsSortFn);
        setDsInteractionRounds(result.data);
      }
    }
  }

  const onDeleteButtonClick = (e) => {
    let res = confirm(
      `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this Job Application ?"}</span></div>`,
      "Vakency"
    );
    res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
  };

  const handleDelete = async (e) => {
    const Id = e?.jobApplicationID;
    const result = await jobApplicationServices.DeleteData(Id);
    if (result.statusCode === 400 || result.statusCode === 409) {
      ShowAlert(result.data, "Vakency");
    } else if (result.statusCode === 200) {
      setDeletedApplicationId(Id);
    }
  };

  const position = { of: "#middle-section" };

  const onPhoneButtonClick = () => {
    window.location.href = `tel:${candidateData?.mobileNo1}`;
  }

  const onPictureClick =(fileSrc)=>{
    if(fileSrc){
        setShowFilePreview(true);
        setSelectedFile({
            fileSrc: fileSrc,
        }); 
    }
}

const onFilePreviewClose = () => {
  setShowFilePreview(false)
}

  return (
    <>
      <Header
        title={[
          <span key={"header-title"} className="base-accent-text"></span>,
          "Shortlisted",
        ]}
        hideDefaultButtons={true}
        onBackButtonClick={OnCancelClick}
      />
        <LoadPanel
                visible={showFilePreviewLoad}
                message='File is loading. We’ll display the preview shortly.'
             />
      <Editor customWidth={true}>
        <div className="pt-2">
          {dsJobShortlistHeader && <div className="row align-items-center px-3">
            <div className={!isExLarge && !isLarge ? "col-12" : "col"}>
              <div
                className={`${!isExLarge ? "font-semiBold-20" : "font-semiBold-23"
                  } align-bottom  d-flex`}
              >
                <div className="text-decoration-underline cursor-pointer me-3  ">
                  <span onClick={EditJobOpening} title="Edit">
                    {dsJobShortlistHeader?.jobProfileName}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  {Open ? (
                    <img src={openIcon} alt="open" />
                  ) : (
                    <img src={closeIcon} alt="close" />
                  )}
                </div>
              </div>
            </div>
          </div>}
          {dsJobShortlistHeader && <div className="row justify-content-start align-items-center px-3 pt-2">
            <div className="col-md-7">
              <div className="row justify-content-start">
                <div className={"col-auto"}>
                  Opening Date:{" "}
                  {dsJobShortlistHeader?.jobOpeningDate && <b>
                    {new Date(
                      dsJobShortlistHeader?.jobOpeningDate
                    ).toLocaleDateString()}
                  </b>}
                </div>
                <div className="col-auto vertical-line d-none d-md-block">|</div>
                <div className={"col-auto"}>
                  <span>Target Date:</span>{" "}
                  {dsJobShortlistHeader?.targetDate && <b>
                    {new Date(
                      dsJobShortlistHeader?.targetDate
                    ).toLocaleDateString()}
                  </b>}
                </div>
                <div className="col-auto vertical-line d-none d-md-block">|</div>
                <div className={"col-auto"}>
                  <span>Required Experience:</span>{" "}
                  {dsJobShortlistHeader?.requiredExperience && <b>{dsJobShortlistHeader?.requiredExperience}</b>}<b>{dsJobShortlistHeader?.requiredExperience <= 1 ? " yr" : " yrs"}</b>
                </div>
                {
                  dsJobShortlistHeader?.maxSalary && (
                    <>
                      <div className="col-auto vertical-line d-none d-md-block">|</div>
                      <div className={"col-auto"}>
                        <span>Salary Budget:</span>{" "}
                        <b>{dsJobShortlistHeader?.maxSalary} LPA</b>
                        <span className="px-2 assessment-pipe">|</span>
                        <b>{maxSalaryMonthlyValue.toFixed(2)} Monthly</b>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
            <div className="col-md-5 py-2 py-md-0">
              <div className="row justify-content-md-end">
                <div className="col-auto  ps-2 pe-1">
                  <Button
                    text="Add New Candidate"
                    className="add-new-candidate-btn"
                    onClick={onClickAddNewCandidate}
                    disabled={!dsJobShortlistHeader}
                  />
                </div>
                <div className="col-auto pe-2">
                  <Button
                    text="Search Candidate"
                    className="search-candidate-btn"
                    onClick={onClickSearchCandidate}
                    disabled={!dsJobShortlistHeader}
                  />
                </div>
              </div>
            </div>
          </div>}
          <hr className="mt-3 mb-0" />

          <div className="row " id="middle-section">
            <div className="col-auto">
              {!isSmall &&
                !isXSmall &&
                !isXXSmall &&
                !isExSmall &&
                !isMedium ? (
                <>
                  <div className="expand-collapse-btn-section-left">
                    <Button
                      icon={
                        showLeftSection ? "ArrowLeftIcon" : "ArrowRightIcon"
                      }
                      onClick={() => setshowLeftSection(!showLeftSection)}
                      className={
                        showLeftSection
                          ? "collapse-btn-left"
                          : "expand-btn-left"
                      }
                      focusStateEnabled={false}
                      stylingMode="text"
                    />
                  </div>
                  <LeftPanel
                    showLeftSection={showLeftSection}
                    setshowLeftSection={setshowLeftSection}
                    setSelectedRowKeyDetail={setSelectedRowKeyDetail}
                    selectedRowKeyDetail={selectedRowKeyDetail}
                    jobOpeningID={jobOpeningID}
                    statusApplication={status}
                    setGetApplicationStatus={setGetApplicationStatus}
                    deletedApplicationId={deletedApplicationId}
                    imageStatus={imageStatus}
                    jobApplicationBySearch={jobApplicationBySearch}
                    setJobApplicationBySearch={setJobApplicationBySearch}
                    jobProfileName={dsJobShortlistHeader?.jobProfileName}
                    JobOpeningState={JobOpeningState}
                    currentPathname={pathname}
                  />
                </>
              ) : (
                <>
                  <div className="expand-collapse-btn-section-left">
                    <Button
                      icon={"ArrowRightIcon"}
                      onClick={() => setShowLeftPopupSm(!showLeftPopupSm)}
                      className={"expand-btn-left"}
                      focusStateEnabled={false}
                      stylingMode="text"
                    />
                  </div>
                  <Popup
                    visible={showLeftPopupSm}
                    dragEnabled={false}
                    showTitle={false}
                    onHiding={() => setShowLeftPopupSm(false)}
                    hideOnOutsideClick={true}
                    width={350}
                    height={"100%"}
                    position={"left"}
                    wrapperAttr={{ class: "ViewPopup" }}
                    animation={{
                      show: {
                        type: "slideIn",
                        direction: "left",
                      },
                      hide: {
                        type: "slideOut",
                        direction: "left",
                      },
                    }}
                  >
                    <div>
                      <div className="expand-collapse-btn-section-left-sm">
                        <Button
                          icon={"ArrowLeftIcon"}
                          onClick={() => setShowLeftPopupSm(!showLeftPopupSm)}
                          className={"collapse-btn-left"}
                          focusStateEnabled={false}
                          stylingMode="text"
                        />
                      </div>
                      <LeftPanel
                        showLeftSection={showLeftSection}
                        setshowLeftSection={setshowLeftSection}
                        setSelectedRowKeyDetail={setSelectedRowKeyDetail}
                        selectedRowKeyDetail={selectedRowKeyDetail}
                        jobOpeningID={jobOpeningID}
                        statusApplication={status}
                        setGetApplicationStatus={setGetApplicationStatus}
                        deletedApplicationId={deletedApplicationId}
                        imageStatus={imageStatus}
                        jobApplicationBySearch={jobApplicationBySearch}
                        setJobApplicationBySearch={setJobApplicationBySearch}
                        jobProfileName={dsJobShortlistHeader?.jobProfileName}
                        JobOpeningState={JobOpeningState}
                        currentPathname={pathname}
                      />
                    </div>
                  </Popup>
                </>
              )}
            </div>
            {
              <div className="col middle-section px-0">
                {getApplicationStatus || showSkeleton ? (
                  !selectedRowKeyDetail ? <p className="text-center mt-3">No data for preview.</p> : <ListSkeleton />
                ) : (
                  <>
                    <div className="pt-2 row">
                      <div
                        className={`${isLarge &&
                          !isExLarge &&
                          showLeftSection &&
                          showRightSection
                          ? ""
                          : "col-auto"
                          } d-flex align-items-center`}
                      >
                        <div className="ms-4 me-2">
                        {profileImg.uploadedFile?.fileData ? (
                          <img
                            src={profileImg.uploadedFile?.fileData}
                            alt="candidate"
                            width={110}
                            height={110}
                            className="candidate-image shadow-sm"
                            onClick={()=>onPictureClick(profileImg.uploadedFile?.fileData)}
                          />
                        ) : (
                          <img
                            src={BlankProfile}
                            alt="candidate"
                            width={110}
                            height={110}
                            className="candidate-image shadow-sm"
                          />
                        )}
                        </div>
                      </div>
                      <div className="col px-3">
                        <div className="row">
                          <div className="col">
                            <div className="font-semiBold-23 d-flex">
                              <div>{candidateData?.candidateName}</div>
                              <div className="filled-star ps-3">
                                <StarRating rating={candidateData?.rating} />
                              </div>
                            </div>
                          </div>
                          <div
                            className={`col d-flex justify-content-md-end ${showRightSection && isLarge && isExLarge
                              ? "me-1"
                              : "pe-4 me-2"
                              }`}
                          >
                            <div>
                              <img
                                src={
                                  candidateData?.jobApplicationStatusName ===
                                    "Job application rejected by us"
                                    ? rejectedIcon
                                    : candidateData?.jobApplicationStatusName ===
                                      "Pending"
                                      ? pendingIcon
                                      : shortlistedIcon
                                }
                                title={candidateData?.jobApplicationStatusName}
                                alt="status"
                                width={22}
                              />
                            </div>
                            <div className="px-2 verticle-line-small">|</div>
                            <div>
                              <Button
                                icon="edit"
                                stylingMode="text"
                                hint="Edit"
                                type="default"
                                className="resume-edit-btn"
                                onClick={() => setShowCandidateEditor(true)}
                              />
                            </div>
                            <div className="px-2 verticle-line-small">|</div>
                            <div>
                              <Button
                                icon="email"
                                hint="Email"
                                stylingMode="text"
                                className="resume-edit-btn"
                              />
                            </div>
                            <div className="px-2 verticle-line-small">|</div>
                            <div>
                              <Button
                                icon="print"
                                hint="Print"
                                stylingMode="text"
                                className="resume-edit-btn"
                                type="default"
                              />
                            </div>
                            <div className="px-2 verticle-line-small">|</div>
                            <div>
                              <Button
                                icon="trash"
                                hint="Delete"
                                stylingMode="text"
                                className="delete-edit-btn"
                                type="danger"
                                onClick={() =>
                                  onDeleteButtonClick(selectedRowKeyDetail)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col font-medium-16">
                            {candidateData?.currentCompanyName &&
                              candidateData?.currentCompanyName}
                            {" "}
                            {candidateData?.currentCompanyName ? (
                              <span className="me-1 px-1 verticle-line-small">
                                |
                              </span>
                            ) : ""}
                            {`${candidateData?.totalExperience
                              ? candidateData?.totalExperience == null 
                                ? ""
                                : candidateData?.totalExperience
                              : ""
                              } `}{" "}
                            {candidateData?.totalExperience ?
                              <span className="me-1 px-1 verticle-line-small">
                                |
                              </span> : ""
                            }
                            {candidateData?.academicQualificationName}
                          </div>
                        </div>

                        <div className="pt-1 font-medium-16">
                          {candidateData?.currentAddress && (
                            <i className="dx-icon-locationIcon dx-custom-icon cursor-pointer" title="Current Address" onClick={()=>handleNavigateToMap(candidateData?.currentAddress)}></i>
                          )}{" "}
                          {candidateData?.currentAddress}{" "}
                          {candidateData?.currentAddress ? (
                            <span className="px-2 verticle-line-small">|</span>
                          ) : ""}
                          {candidateData?.permanentAddress && (
                            <i className="dx-icon-homeTownIcon dx-custom-icon cursor-pointer" onClick={()=>handleNavigateToMap(candidateData?.permanentAddress)} title="Permanent Address"></i>
                          )}{" "}
                          {candidateData?.permanentAddress}{" "}
                          {candidateData?.permanentAddress ? (
                            <span className="px-2 verticle-line-small">|</span>
                          ) : ""}
                          <span onClick={onPhoneButtonClick} className="cursor-pointer">
                            <i className="dx-icon-MobileIcon dx-custom-icon"></i>
                            {candidateData?.mobileNo1}
                          </span>{" "}

                          <span className="px-2 verticle-line-small">|</span>
                          {candidateData?.linkedIn ? <a
                            href={`${candidateData?.linkedIn}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="dx-icon-LinkedInIcon dx-custom-icon"></i>
                          </a> : <i className="dx-icon-LinkedInDisabledIcon dx-custom-icon"></i>
                          }
                          <span className="px-2 verticle-line-small">|</span>
                          {candidateData?.skype ? <a
                            href={`skype:${candidateData?.skype}?chat`}
                            rel="noreferrer"
                          >
                            <i className="dx-icon-skypeIcon dx-custom-icon"></i>
                          </a> : <i className="dx-icon-skypeDisabledIcon dx-custom-icon"></i>
                          }
                          <span className="px-2 verticle-line-small">|</span>
                          {candidateData?.mobileNo2 ? <a
                            href={`https://wa.me/91${candidateData?.mobileNo2}`}
                            rel="noreferrer"
                            target='_blank'
                          >
                            <i className="dx-icon-WhatsappIcon dx-custom-icon"></i>
                          </a> : <i className="dx-icon-WhatsappDisabledIcon dx-custom-icon"></i>
                          }
                        </div>
                        <div className="row pt-1">
                          {
                            dsInteractionRounds.length > 0 && (
                              <div
                                className={`col-sm-auto d-flex justify-content-lg-end col-12 ${isLarge && isExLarge
                                  ? "me-1"
                                  : ""
                                  }`}
                              >
                                <div className='d-flex flex-column gap-0'>
                                  <div className='text-start font-semiBold-14'>Hiring Progress Tracker</div>
                                  <div className='d-flex justify-content-center'>
                                    {dsInteractionRounds.map((interaction, index) => (
                                      <div className='d-flex align-items-center' key={interaction.interactionRoundID}>
                                        <div
                                          id={`link-${index}`}
                                          className={`d-flex justify-content-center align-items-center ${interaction.interactionResultName === 'Pending' ? 'candidate_progress_circle_in_progress' : interaction.interactionResultName != null ? 'candidate_progress_circle_completed' : 'candidate_progress_circle_pending'
                                            }`}
                                        />
                                        <Popover
                                          target={`#link-${index}`}
                                          showEvent="mouseenter"
                                          hideEvent="mouseleave"
                                          position="bottom"
                                          width={'auto'}
                                        >
                                          <div className='font-medium-14'>
                                            <span className='italic-text'>Round Name:</span>{" "}
                                            {interaction.interactionRoundName ? interaction.interactionRoundName : '-'}
                                          </div>
                                          <div className='font-medium-14'>
                                            <span className='italic-text'>Status:</span>{" "}
                                            {interaction.interactionResultName ? interaction.interactionResultName : '-'}
                                          </div>
                                          <div className='font-medium-14'>
                                            <span className='italic-text'>Date of Interaction:</span>{" "}
                                            {interaction.scheduleDateTime ? GetFormattedDate(interaction.scheduleDateTime) : '-'}
                                          </div>
                                        </Popover>
                                        {index < dsInteractionRounds.length - 1 && (
                                          <div
                                            className={interaction.interactionResultName === 'Pending' ? 'candidate_progress_line_pending' : interaction.interactionResultName != null ? 'candidate_progress_line_completed' : 'candidate_progress_line_pending'}
                                          />
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                    <hr className="mt-2 verticle-line-small" />
                    <div>
                      <LoadPanel
                        visible={resumeStatus.uploading}
                        message="Please wait resume is uploading."
                        position={position}
                        container={"#middle-section"}
                        shading={true}
                        shadingColor="rgba(0,0,0,0.4)"
                      ></LoadPanel>

                      {resume?.uploadedFile?.fileData ? (
                        (resume?.uploadedFile?.fileData == "NoData") ?
                          <p className="d-flex justify-content-center">No data for preview.</p> :
                          <div id="iframe">
                            <iframe
                              src={`${resume?.uploadedFile?.fileData}#navpanes=0`}
                              width="100%"
                              className={
                                isExSmall || isXXSmall || isXSmall
                                  ? "resume-iframe-sm"
                                  : "resume-iframe"
                              }
                              title="Resume"
                            />
                          </div>
                      ) : <ListSkeleton />}
                    </div>
                  </>
                )}
              </div>
            }
            <div className="col-auto">
              {!isSmall &&
                !isXSmall &&
                !isXXSmall &&
                !isExSmall &&
                !isMedium ? (
                <>
                  <div className="expand-collapse-btn-reight-section">
                    <Button
                      icon={
                        showRightSection ? "ArrowRightIcon" : "ArrowLeftIcon"
                      }
                      onClick={() => setshowRightSection(!showRightSection)}
                      className={
                        showRightSection
                          ? "collapse-btn-right"
                          : "expand-btn-right"
                      }
                      focusStateEnabled={false}
                      stylingMode="text"
                    />
                  </div>
                  <RightPanel showRightSection={showRightSection} selectedRowKeyDetail={selectedRowKeyDetail} />
                </>
              ) : (
                <>
                  <div className="expand-collapse-btn-reight-section">
                    <Button
                      icon={"ArrowLeftIcon"}
                      onClick={() => setShowRightPopupSm(!showRightPopupSm)}
                      className={"expand-btn-right"}
                      focusStateEnabled={false}
                      stylingMode="text"
                    />
                  </div>
                  <Popup
                    visible={showRightPopupSm}
                    dragEnabled={false}
                    showTitle={false}
                    onHiding={() => setShowRightPopupSm(false)}
                    hideOnOutsideClick={true}
                    width={280}
                    height={"100%"}
                    position={"right"}
                    wrapperAttr={{ class: "ViewPopup" }}
                    animation={{
                      show: {
                        type: "slideIn",
                        direction: "right",
                      },
                      hide: {
                        type: "slideOut",
                        direction: "right",
                      },
                    }}
                  >
                    <div>
                      <div className="expand-collapse-btn-reight-section-sm">
                        <Button
                          icon={"ArrowRightIcon"}
                          onClick={() =>
                            setShowRightPopupSm(!showRightPopupSm)
                          }
                          className={"collapse-btn-right"}
                          focusStateEnabled={false}
                          stylingMode="text"
                        />
                      </div>
                      <RightPanel showRightSection={showRightSection} selectedRowKeyDetail={selectedRowKeyDetail} />
                    </div>
                  </Popup>
                </>
              )}
            </div>
          </div>
        </div>
      </Editor>
      {showjobOpeningEditor === true && (
        <JobOpeningPopup
          openPopup={showjobOpeningEditor}
          setOpenPopup={setShowjobOpeningEditor}
          primaryKey={jobOpeningID}
          setStatus={SetJobOpeningStatus}
          setOpen={setOpen}
        />
      )}
      {openPopup && (
        <CandidateDropDownPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          JobProfileDropDownReadOnly={true}
          JobOpeningDropDownReadOnly={true}
          jobOpeningID={jobOpeningID}
          jobProfileID={dsJobShortlistHeader?.jobProfileID}
          setStatus={setStatus}
          setResumeStatus={setResumeStatus}
        />
      )}
      {showSearchCandidate &&
        <SearchCandidate
          showSearchCandidate={showSearchCandidate}
          setShowSearchCandidate={setShowSearchCandidate}
          onHide={onClickSearchCandidate}
          jobOpeningID={jobOpeningID}
          setStatus={setStatus}
          setJobApplicationBySearch={setJobApplicationBySearch}
        />
      }
      {showCandidateEditor === true && (
        <CandidateDropDownPopup
          openPopup={showCandidateEditor}
          setOpenPopup={setShowCandidateEditor}
          candidateId={selectedRowKeyDetail?.candidateID}
          primaryKey={candidateData?.primaryKeyID}
          JobOpeningDropDownReadOnly={true}
          setStatus={setStatus}
          setResumeStatus={setResumeStatus}
          setImageStatus={setImageStatus}
          imageStatus={imageStatus}
          resumeStatus={resumeStatus}
          hideJobOpening={true}
        />
      )}
       {
                showFilePreview && (
                    <FilePreviewer
                        showFilePreview={showFilePreview}
                        selectedFile={selectedFile}
                        setShowFilePreviewLoad={setShowFilePreviewLoad}
                        onFilePreviewClose={onFilePreviewClose}
                        isImageOnly={true}
                    />
                )
            }
    </>
  );
};

export default ShortListed;
