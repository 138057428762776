import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { CandidateInteractionControlName } from "../../contexts/APIurl";

const url = CandidateInteractionControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class CandidateInteractionServices extends VakencyApiServices {
    constructor() {
        super(CandidateInteractionControlName);
    }


    GenerateCandidateInteractionNo = async () => {
        const GetListUrl = CandidateInteractionControlName + "/GenerateCandidateInteractionNo/"
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GetLookupListDataByControlName = async (url) => {
        const GetListUrl = url + "/GetLookupList/"
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GetModelDataByControlName = async (url, id) => {
        const GetModalUrl = url + "/GetModel/"
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetModalUrl + id)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    CandidateDidNotAttendInteraction = async (dataObj) => {
        const UpdateUrl = url + `/CandidateDidNotAttendInteraction?JobApplicationID=${dataObj.jobApplicationID}` + `${dataObj.updatedOn ? (`&UpdatedOn=` + dataObj.updatedOn) : ""}`
        let data = null;
        try {
            data = await fetchWrapper.put(baseURL + UpdateUrl, dataObj)
            return this.handleResponse(data);
        }
        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }
    
    InteractionCancelledByUs = async (dataObj) => {
        const UpdateUrl = url + `/InteractionCancelledByUs?JobApplicationID=${dataObj.jobApplicationID}` + `${dataObj.updatedOn ? (`&UpdatedOn=` + dataObj.updatedOn) : ""}`
        let data = null;
        try {
            data = await fetchWrapper.put(baseURL + UpdateUrl, dataObj)
            return this.handleResponse(data);
        }
        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }
}

