import React, { useState } from 'react';
import { Button, LoadIndicator } from 'devextreme-react';
import Validator, { EmailRule, RequiredRule } from 'devextreme-react/validator';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import openeye from '../../images/openeye.png';
import closeeyeIcon from '../../images/closeeyeIcon .png';
import { useNavigate } from 'react-router-dom';
import './LoginForm.scss';
import { useAuth } from '../../contexts/auth';
import { onDragOver } from '../../utils/common-methods';

export default function LoginForm(props) {

    const { signIn } = useAuth();
    const [PasswordMode, setPasswordMode] = useState("password");
    const [showPasswordIcon, setShowpasswordIcon] = useState(openeye)
    const [errorMessage, setErrorMessage] = useState(null)
    const [formData, setFormData] = useState({
        emailid: null,
        password: null,
    });

    const navigate = useNavigate();

    const ValidationGroupName = "loginValidation"

    const PasswordOptions = {
        icon: showPasswordIcon,
        stylingMode: "text",
        hoverStateEnabled: false,
        activeStateEnabled: false,
        focusStateEnabled: false,
        onClick: () => {
            setPasswordMode(PasswordMode === 'text' ? 'password' : 'text');
            setShowpasswordIcon(showPasswordIcon === openeye ? closeeyeIcon : openeye)
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        props.setShowLoader(true);
        e.preventDefault();
        const result = await signIn(formData);
        if (!result?.isOk) {
            setErrorMessage(result.message)
            props.setShowLoader(false);
        }
        else {
            setErrorMessage("")
            props.setShowLoader(false);
        }
    }

    return (
        <div>
            {props.showLoader ?
                <div className='m-auto text-center loaderCart-height'>
                    <LoadIndicator className='indicator' id="small-indicator" height={50} width={50} visible={true} />
                    <div className='pt-1'>Please Wait.....</div>
                </div>
                :
                <form onSubmit={handleSubmit} className='px-3 login-form' onDragOver={onDragOver}>

                <div className='row py-2 '>
                    <TextBox
                        label={'Email ID'}
                        mode='email'
                        labelMode='floating'
                        stylingMode='underlined'
                        className='py-1 px-0'
                        value={formData.emailid}
                        onValueChange={(value) => setFormData({ ...formData, emailid: value })}
                        on
                    >
                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                            <RequiredRule message='Email ID is required' />
                            <EmailRule message='Email ID is not valid' />
                        </Validator>
                    </TextBox>
                </div>
                <div className='row py-2 '>
                    <TextBox
                        label='Password'
                        mode={PasswordMode}
                        labelMode='floating'
                        stylingMode='underlined'
                        maxLength={16}
                        className='py-1 px-0'
                        value={formData.password}
                        onValueChange={(value) => setFormData({ ...formData, password: value })}
                    >
                        <TextBoxButton
                            name="password"
                            location="after"
                            options={PasswordOptions}
                        />
                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                            <RequiredRule message='Password is required' />
                        </Validator>
                    </TextBox>
                </div>
                <div className='text-danger text-center'>{errorMessage}</div>
                <div className='row py-2 mt-2 justify-content-center'>

                    <Button
                        width="auto"
                        text='Sign In'
                        useSubmitBehavior={true}
                        type='default'
                        validationGroup={ValidationGroupName}
                    >
                    </Button>
                </div>
                <div className='row py-2 justify-content-center'>

                    <Button
                        width="auto"
                        text='Forgot Password?'
                        focusStateEnabled={false}
                        hoverStateEnabled={false}
                        activeStateEnabled={false}
                        className='border-0 forgot-password-button'
                        onClick={() => navigate('/forgot-password')}
                    >
                    </Button>
                </div>
            </form>
            }
        </div>
    )
}
