import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { JobOpeningOpenApi } from "../../contexts/APIurl";

const url = JobOpeningOpenApi;
const baseURL = process.env.REACT_APP_BASEURL;

export class JobManagementOpenServices extends VakencyApiServices {
    constructor() {
        super(url);
    }



    GetOfferDetails = async (params) => {
        const GetListUrl = url + `/GetOffer/${params}`

        let data = null;
        try {
            data = await fetchWrapper.getWithoutHeader(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }
    GetOfferLetterFile = async (params) => {
        const GetUrl = url + `/GetOfferLetterFile/${params}`
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetUrl)
            if (data.status && data.status === 200) {
                const fileUrl = await data.text();
                return this.fetchFileAsBlob(fileUrl);
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }
    AcceptOffer = async (param, dataObj) => {
        const noResponseData = true;
        const UpdateUrl = url + `/AcceptOffer/${param}`;
        let data = null;
        try {
            data = await fetchWrapper.putWithoutHeader(baseURL + UpdateUrl, dataObj)
            return this.handleResponse(data, noResponseData);
        }
        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    OfferRejectedByCandidate = async (param, dataObj) => {
        const noResponseData = true;
        const UpdateUrl = url + `/OfferRejectedByCandidate/${param}`;
        let data = null;
        try {
            data = await fetchWrapper.putWithoutHeader(baseURL + UpdateUrl, dataObj)
            return this.handleResponse(data, noResponseData);
        }
        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    GetCandidateProfileImage = async (params) => {
        const GetUrl = url + `/GetCandidateProfileImage/${params}`
        let data = null;
        try {
            data = await fetchWrapper.getWithoutHeader(baseURL + GetUrl)
            if (data.status && data.status === 200) {
                return {
                    isOk: true,
                    data: await data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GetCandidateResume = async (params) => {
        const GetUrl = url + `/GetResume/${params}`
        let data = null;

        try {
            data = await fetchWrapper.getWithoutHeader(baseURL + GetUrl)
            if (data.status && data.status === 200) {
                return {
                    isOk: true,
                    data: data.blob(),
                    statusCode: data.status,
                    fileExtension: data.headers.get('content-type')
                };
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }



    GetAssessmentNotes = async (params) => {
        const GetUrl = url + `/GetAssessmentNotes/${params}`
        const result = await fetchWrapper.getWithoutHeader(baseURL + GetUrl);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetAssessmentNoteTemplate = async (params) => {
        const GetUrl = url + `/GetAssessmentNoteTemplate/${params}`
        const result = await fetchWrapper.getWithoutHeader(baseURL + GetUrl);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }
    GetOtherAssessmentNotes = async (params) => {
        const GetUrl = url + `/GetOtherAssessmentNotes/${params}`
        const result = await fetchWrapper.getWithoutHeader(baseURL + GetUrl);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    UpdateAssessmentNotes = async (params, data) => {
        const result = await fetchWrapper.postWithoutHeader((baseURL + url + '/UpdateAssessmentNotes/' + params), data);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }


    RateAttitude = async (params, data) => {
        const result = await fetchWrapper.postWithoutHeader((baseURL + url + '/RateAttitude/' + params), data);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }
    RateSkill = async (params, data) => {
        const result = await fetchWrapper.postWithoutHeader((baseURL + url + '/RateSkill/' + params), data);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    PostAssessmentResult = async (params, data) => {
        const result = await fetchWrapper.postWithoutHeader((baseURL + url + '/Post/' + params), data);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    // data: await result.json(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    // data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Submit Data",
                statusCode: result.status
            };
        }
    }


}

