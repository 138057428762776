import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices"
import { RegistrationControlName } from "../../contexts/APIurl";

const url = RegistrationControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class CompanyProfileServices extends VakencyApiServices {
    constructor() {
        super(url);
    }

    GetListByIdData_Two = async () => {
        const GetListUrl = url + "/Get/"
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    UpdateData_Two = async (dataObj) => {
        const UpdateUrl = url + "/Put"
        let data = null;
        try {
            data = await fetchWrapper.put(baseURL + UpdateUrl, dataObj)
            return this.handleResponse(data);
        }

        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    InsertCompanyProfilePicture_withoutParams = async (params) => {
        const formData = new FormData();
        formData.append("attachments", params.attachments);
        const UpdateUrl = url + "/UpdateCompanyLogo"
        let data = null;
        try {
            data = await fetchWrapper.postwithFile(baseURL + UpdateUrl, formData)
            if (data.status && data.status === 200) {
                return {
                    isOk: data.ok,
                    data: await data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: data.ok,
                    data: await data.text(),
                    stautsCode: data.status && data.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    GetCompanyLogo_withHeader = async () => {
        const GetListUrl = url + "/GetCompanyLogo"
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            if (data.status && data.status === 200) {
                const fileUrl = await data.text();
                return this.fetchFileAsBlob(fileUrl);
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GetCompanyLogo = async (key) => {
        const GetListUrl = url + `/GetCompanyLogo/${key}`;
        let data = null;
        try {
            data = await fetchWrapper.getWithoutHeader(baseURL + GetListUrl)
            if (data.status && data.status === 200) {
                const fileUrl = await data.text();
                return this.fetchFileAsBlob(fileUrl);
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GetByOpenAccessCode = async (key) => {
        const GetUrl = url + `/Get/${key}`
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }
}
