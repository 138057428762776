import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { CountryControlName } from "../../contexts/APIurl";

const url = CountryControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class CountryServices extends VakencyApiServices {
  constructor() {
    super(url);
  }

  // Function to check duplicate data
  CheckDuplicateData = async (params) => {
    const noResponseData = true;
    let data = null;
    try {
      const CheckUrl = `${url}/CheckDuplicateName?${params}`;
      data = await fetchWrapper.get(baseURL + CheckUrl);
      return this.handleResponse(data, noResponseData);
    } catch {
      return {
        isOk: false,
        message: "Failed To Load Data",
        statusCode: data.status,
      };
    }
  };
}

