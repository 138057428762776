import {
  DateBox,
  Popup,
  TextBox,
  Validator,
} from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import { useScreenSize } from "../../utils/media-query";
import PopupFooter from "../../layouts/popup-header-footer/PopupFooter";
import { ShowAlert, eCRUDStatus } from "../../utils/common-methods";
import { RequiredRule } from "devextreme-react/validator";
import { CandidateInteractionScheduleServices } from "../../api/services/CandidateInteractionScheduleServices";
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import EmailStatusPopup from "./EmailStatusPopup";

const candidateInteractionScheduleServices = new CandidateInteractionScheduleServices();
const candidateControlServices = new CandidateControlServices();

const UpdateSchedulePopup = (props) => {
  const { scheduleData } = props;
  const { isExSmall, isXXSmall, isXSmall, isSmall, isMedium } = useScreenSize();
  const htmlEditorSecondAttributes = {
    class: "noteSecondHtml",
  };
  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
  const fontValues = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];
  const minDate = new Date();
  const [candidateData, setCandidateData] = useState();
  const [showEmailStatusPopup, setShowEmailStatusPopup] = useState(false);

  const htmlEditorNoteRef = useRef(null);

  const ValidationGroupName = "ScheduleValidationGroupName";

  const [newRecord, setNewRecord] = useState({
    scheduleDate: null,
    scheduleTime: null,
    meetingLink: null,
  });

  const onClosePopup = () => {
    props.setOpenPopup(false);
  };

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={[
            <span key={"header-title"} className="base-accent-text">
              {" "}
              Update
            </span>,
            " Schedule",
          ]}
          onSubmit={UpdateRecord}
        />
      </>
    );
  };

  useEffect(() => {
    GetModelRecord(props?.candidateId);
  }, []);

  useEffect(() => {
    if (scheduleData) {
      const [meetingDate, meetingTime] = convertToLocalDateAndTime(scheduleData?.scheduleDateTime);
      setNewRecord({
        scheduleDate: meetingDate,
        scheduleTime: meetingTime,
        meetingLink: scheduleData?.meetingLink,
      })
    }
  }, [scheduleData]);

  const GetModelRecord = async (Id) => {
    const result = await candidateControlServices.GetModelData(Id);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      const response = result.data;
      setCandidateData(response);
    }
  };

  function convertToISOString(dateTimestamp, timeTimestamp) {
    // Extract timezone offset from time timestamp
    let offset = timeTimestamp.getTimezoneOffset() * 60000; // Offset in milliseconds
    // Adjust timeDt by timezone offset
    let timeDtUTC = new Date(timeTimestamp.getTime() - offset);
    // Combine date and time
    let combinedDt = new Date(
      Date.UTC(
        dateTimestamp.getFullYear(),
        dateTimestamp.getMonth(),
        dateTimestamp.getDate(),
        timeDtUTC.getUTCHours(),
        timeDtUTC.getUTCMinutes(),
        timeDtUTC.getUTCSeconds()
      )
    );
    // Format to ISO 8601
    let isoFormat = combinedDt.toISOString();
    return isoFormat;
  }

  function convertToLocalDateAndTime(datetime) {
    const dateObject = new Date(datetime);
    const dateTimestamp = new Date(
      Date.UTC(
        dateObject.getUTCFullYear(),
        dateObject.getUTCMonth(),
        dateObject.getUTCDate()
      )  
    );

    const timeTimestamp = new Date(
      dateObject.getTime() -
      Date.UTC(dateObject.getUTCFullYear(), dateObject.getUTCMonth(), dateObject.getUTCDate()));

    return [dateTimestamp, timeTimestamp]
  }

  const HideEmailStatusPopup = () => {
    onClosePopup();
    setShowEmailStatusPopup(!showEmailStatusPopup)
  }

  const UpdateRecord = async () => {
    // update scheduled meeting
    var descrText = htmlEditorNoteRef.current?.instance.getQuillInstance();
    var FormattedText = htmlEditorNoteRef.current?.instance.option("value");
    const descrPlainText = descrText.getText();
    const dataForSchedule = {
      candidateInteractionScheduleID: scheduleData?.candidateInteractionScheduleID,
      scheduleDateTime: `${convertToISOString(
        newRecord.scheduleDate,
        newRecord.scheduleTime
      )}`,
      meetingLink: newRecord.meetingLink,
      descrPlainText: descrPlainText && descrPlainText?.trim() !== "" ? descrPlainText?.trim() : null,
      descrFormattedText: FormattedText?.trim(),
      updatedOn: scheduleData?.updatedOn,
    };
    const result = await candidateInteractionScheduleServices.UpdateData(
      dataForSchedule
    );
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      }
    }
    else {
      setShowEmailStatusPopup(true);
      props.setStatusSchedule({
        eStatus: eCRUDStatus.Updated,
      });
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    UpdateRecord();
  };

  return (
    <>
      <Popup
        visible={props.openPopup}
        titleRender={PopupTitle}
        width={
          isExSmall || isXXSmall || isXSmall
            ? "90%"
            : isSmall || isMedium
              ? "60%"
              : "40%"
        }
        height={"auto"}
        maxHeight={"90vh"}
        wrapperAttr={{ class: "CustomPopup" }}
      >
        <form onSubmit={handleSubmit}>
          <div className="row  mx-2">
            <div className="col-auto pt-3 editor-label">Schedule:</div>
            <div className="col">
              <DateBox
                id="Schedulepopup_Date_DropDown"
                label="Date"
                labelMode="floating"
                value={newRecord.scheduleDate}
                onValueChange={(e) =>
                  setNewRecord({ ...newRecord, scheduleDate: e })
                }
                dateOutOfRangeMessage="Invalid Date"
                type="date"
                useMaskBehavior={true}
                min={minDate}
                dropDownOptions={
                  {
                    // hideOnParentScroll: true,
                    // container: "#Schedulepopup_Date_DropDown"
                  }
                }
              >
                <Validator
                  validationGroup={ValidationGroupName}
                  elementAttr={{ class: "ForValidationAstrikMark" }}
                >
                  <RequiredRule message="Date is required" />
                </Validator>
              </DateBox>
            </div>
            <div className="col">
              <DateBox
                id="Schedulepopup_time_DropDown"
                label="Time"
                labelMode="floating"
                type="time"
                value={newRecord.scheduleTime}
                onValueChange={(e) =>
                  setNewRecord({ ...newRecord, scheduleTime: e })
                }
                dateOutOfRangeMessage="Invalid Date"
                useMaskBehavior={true}
                // min={minDate}
                dropDownOptions={{
                  height: 300,
                  // hideOnParentScroll: true,
                  // container: "#Schedulepopup_time_DropDown"
                }}
              >
                <Validator
                  validationGroup={ValidationGroupName}
                  elementAttr={{ class: "ForValidationAstrikMark" }}
                >
                  <RequiredRule message="Time is required" />
                </Validator>
              </DateBox>
            </div>
          </div>
          <div className="px-3 pt-3">
            <TextBox
              label="Meeting Link"
              labelMode="floating"
              value={newRecord.meetingLink}
              onValueChange={(e) =>
                setNewRecord({ ...newRecord, meetingLink: e })
              }
            >
              <Validator
                validationGroup={ValidationGroupName}
                elementAttr={{ class: "ForValidationAstrikMark" }}
              >
                <RequiredRule message="Meeting Link is required" />
              </Validator>
            </TextBox>
            {/* <form onSubmit={handleSubmit}> */}

            <div className="row mt-3">
              <div className="assessment-html-editor-section">
                <HtmlEditor
                  ref={htmlEditorNoteRef}
                  id={"JobDescribtionHtmlEditor"}
                  elementAttr={htmlEditorSecondAttributes}
                  valueType="html"
                  // height={isExLarge ? "670px" :  "790px"
                  //     // : "1100px"
                  // }
                  width={"auto"}
                  height={"200px"}
                  className="CustomizeHtmlEditor"
                  placeholder="Overall Comments"
                >
                  <HtmlEditorToolbar multiline={false}>
                    <HtmlEditorItem name="link"></HtmlEditorItem>
                    <HtmlEditorItem
                      name="font"
                      acceptedValues={fontValues}
                    />
                    <HtmlEditorItem
                      name="size"
                      acceptedValues={sizeValues}
                    />
                    <HtmlEditorItem name="bold" />
                    <HtmlEditorItem name="italic" />
                    <HtmlEditorItem name="underline" />
                    <HtmlEditorItem name="background" />
                    <HtmlEditorItem name="color" />
                    <HtmlEditorItem name="orderedList" />
                    <HtmlEditorItem name="bulletList" />
                    <HtmlEditorItem name="clear" />
                  </HtmlEditorToolbar>
                </HtmlEditor>
              </div>
            </div>
            {/* <div className="d-flex justify-content-end py-2 pe-3">
                        <Button
                        validationGroup={ValidationGroupName}
                          text="Submit"
                          type="default"
                          height={33}
                          width={120}
                          className="rounded-3"
                          useSubmitBehavior={true}
                        />
                      </div>
          {/* </form> */}
          </div>
          <PopupFooter ValidationGroupName={ValidationGroupName} />
        </form>
      </Popup>
      {showEmailStatusPopup && <EmailStatusPopup showEmailStatusPopup={showEmailStatusPopup} HideEmailStatusPopup={HideEmailStatusPopup} candidateData={candidateData} candidateScheduleID={scheduleData?.candidateInteractionScheduleID} />}
    </>
  );
};

export default UpdateSchedulePopup;
