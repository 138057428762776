import React from 'react';
import { LoadPanel, Popup } from 'devextreme-react';
import FileManager, {
    Permissions,
    Toolbar,
    Item,
    ItemView,
    Details,
    Column,
    Upload,
    Notifications,
    FileSelectionItem,
} from "devextreme-react/file-manager";
import CustomFileSystemProvider from "devextreme/file_management/custom_provider";
import PopupHeader from '../../layouts/popup-header-footer/PopupHeader';
import PopupFooter from '../../layouts/popup-header-footer/PopupFooter';
import { useScreenSize } from '../../utils/media-query';
import FileRenamePopup from './FileRenamePopup';
import { addUniqueName, popupAnimation, ShowAlert } from '../../utils/common-methods';
import { CandidateControlServices } from '../../api/services/CandidateControlServices';
import FilePreviewer from './FilePreviewer';
import './Candidate.scss';

const ValidationGroupName = "CandidateFileManagerPopupValidation";
const candidateControlServices = new CandidateControlServices();

const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
const videoExtensions = ['.mp4', '.mov', '.avi', '.wmv', '.mkv'];

const customizeDetailColumns = (columns) => {
    columns[0].allowResizing = false
    columns[1].allowResizing = false
    columns[2].allowResizing = false
    return columns;
}

export default function CandidateFileManagerPopup({
    openPopup,
    setOpenPopup,
    CandidateID,
}) {

    const [uploadedFileNameList, setUploadedFileNameList] = React.useState([])
    const [showLoadPanel, setShowLoadPanel] = React.useState(false);
    let [filesList, setFilesList] = React.useState([]);
    const [showRenameFilePopup, setShowRenameFilePopup] = React.useState(false);
    const [fileToRename, setFileToRename] = React.useState({
        oldFileName: null,
        newFileName: null
    });
    let [isDeleted, setIsDeleted] = React.useState(false);
    const [deleteAttachedFile, setDeleteAttachedFile] = React.useState([]);
    const [deleteNewlyAttachedFile, setDeleteNewlyAttachedFile] = React.useState([]);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [showFilePreview, setShowFilePreview] = React.useState(false);
    const [showFilePreviewLoad, setShowFilePreviewLoad] = React.useState(false);

    const fileManagerRef = React.useRef(null);
    const { isXXSmall, isExSmall, isSmall, isXSmall, isMedium } = useScreenSize();

    React.useEffect(() => {
        if (fileToRename.newFileName && fileToRename.oldFileName) {
            let extension = fileToRename.oldFileName?.substring(fileToRename.oldFileName.lastIndexOf('.'));
            let RemoveRenameFileName = filesList.filter((data) => data.name !== fileToRename.oldFileName);
            var fileUniqueName = addUniqueName(RemoveRenameFileName, fileToRename.newFileName)

            let newFilesData = [...filesList];
            let OldDataIndex = newFilesData.findIndex((data) => data.name === fileToRename.oldFileName);

            let updatedFileName = fileUniqueName + extension;
            if (newFilesData[OldDataIndex].isNew) {
                newFilesData[OldDataIndex].file = new File([newFilesData[OldDataIndex].file], updatedFileName, { type: newFilesData[OldDataIndex].file.type })
            }
            newFilesData[OldDataIndex].name = updatedFileName;
            setFilesList(newFilesData)
            setFileToRename({
                oldFileName: null,
                newFileName: null,
                isNew: null
            })
            fileManagerRef.current?.instance._commandManager._actions['clearSelection']()
        }
    }, [fileToRename, setFileToRename, setFilesList, filesList]);

    const onFileUploaded = (e) => {
        if (e.fileData) {

            let FileObjectValue = e.fileData;
            var fileUniqueName = addUniqueName(filesList, e.fileData.name)
            const fileExt = e.fileData.name.substr(e.fileData.name.lastIndexOf('.') + 1);

            Object.defineProperty(FileObjectValue, 'name', {
                value: (fileUniqueName + "." + fileExt)
            });

            filesList.push({
                file: FileObjectValue,
                name: (fileUniqueName + "." + fileExt),
                size: e.fileData.size,
                modifiedOn: new Date(),
                isNew: true,
            });
            fileManagerRef.current?.instance.refresh();
        }
    }

    const getItems = React.useCallback(async (item) => {
        const result = await candidateControlServices.getFiles(CandidateID);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
            } else if (result.statusCode === 409) {
                ShowAlert(result.data, "Vakency");
            }
        } else {
            if (filesList.length > 0) {
                return filesList;
            }
            else {
                if (!isDeleted && filesList.length == 0) {
                        result.data?.forEach(f => {
                            if (!(f.name.startsWith('Resume/') || f.name.startsWith('ProfilePic/'))) {
                                filesList.push({
                                    name: f.name,
                                    size: f.size,
                                    modifiedOn: f.modifiedOn,
                                    isNew: false,
                                });
                            }
                        })
                }
                if (isDeleted == true) {
                    var newResult = [];
                    newResult = filesList.filter(item => result.data.find(ele => ele == item.name));
                    return newResult;
                }
                else {
                    return filesList;
                }
            }
        }
    }, [filesList]);

    const downloadItems = async (downloadFiles) => {
        for (let f = 0; f < downloadFiles.length; f++) {
            if (downloadFiles[f].dataItem.isNew === true) {
                const elem = window.document.createElement("a");
                const blob = new Blob([downloadFiles[f].dataItem.file]);
                elem.href = window.URL.createObjectURL(blob);
                elem.download = downloadFiles[f].dataItem.file.name;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            } else {
                const response = await candidateControlServices.getFileUrl(CandidateID, downloadFiles[f].name);
                const elem = window.document.createElement("a");
                elem.href = window.URL.createObjectURL(response.data);
                elem.download = downloadFiles[f].name;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            }
        }
    }

    const deleteItem = React.useCallback((item) => {
        var selectedItems = fileManagerRef.current?.instance.getSelectedItems();
        for (var k = 0; k < selectedItems.length; k++) {
            if (filesList.length > 0) {
                var deleteExistingFile = filesList.filter((x) => x.name === selectedItems[k]?.name && x.isNew === false);
                if (deleteExistingFile.length > 0) {
                    deleteAttachedFile.push(selectedItems[k]?.name);
                }
                isDeleted = true;

                var deleteNewlyFile = filesList.filter(x => x.name === selectedItems[k].name && x.isNew === true);
                if (deleteNewlyFile.length > 0) {
                    setDeleteNewlyAttachedFile([selectedItems[k]?.dataItem]);
                }
                filesList = filesList.filter(r => r.name !== selectedItems[k]?.name);
                setFilesList(filesList)
            }
        }
        fileManagerRef.current?.instance.refresh();
    }, [setIsDeleted, setFilesList, filesList]);

    const customFileProvider = React.useMemo(() => (
        new CustomFileSystemProvider({
            getItems,
            downloadItems,
            deleteItem
        })
    ), [deleteItem]);

    const PopupTitle = () => {
        return (
            <>
                <PopupHeader
                    ValidationGroupName={ValidationGroupName}
                    onClosePopup={onClosePopup}
                    title={"Candidate File Manager"}
                    hideSaveButton={true}
                />
            </>
        )
    }

    const onClosePopup = () => {
        setOpenPopup(false);
    }

    const CloseRenameFilePopup = () => {
        setShowRenameFilePopup(false);
    }

    const RenameButtonOption = {
        icon: 'rename',
        type: 'text',
        text: 'Rename',
        onClick: (e) => {
            const selectedItems = fileManagerRef.current?.instance.getSelectedItems();
            if (selectedItems) {
                setShowRenameFilePopup(true);
                let removeRenameFile = filesList.filter(file => file.name != selectedItems[0].name);
                setUploadedFileNameList(removeRenameFile);
                setFileToRename({
                    newFileName: null,
                    oldFileName: selectedItems[0].name,
                    isNew: selectedItems[0].dataItem.isNew
                });
            }
        }
    }

    const onHidingLoadPanel = () => {
        setShowLoadPanel(false);
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        try {
            // Show loader
            setShowLoadPanel(true);

            let uploadFileResult = { isOk: true };
            let deleteResults = [];

            // Handle file uploads
            if (filesList.length > 0) {
                const attachments = filesList.filter(item => item.isNew);
                if (attachments.length) {
                    const formData = new FormData();
                    formData.append("ID", CandidateID);
                    attachments.forEach(file => {
                        formData.append("attachments", file.file);
                    });

                    uploadFileResult = await candidateControlServices.uploadFiles(formData);

                    if (!uploadFileResult.isOk) {
                        if (uploadFileResult.statusCode === 400 || uploadFileResult.statusCode === 409) {
                            ShowAlert(uploadFileResult.data, "Vakency");
                        }
                    }
                }
            }

            // Handle file deletions
            if (deleteAttachedFile.length > 0) {
                const deletePromises = deleteAttachedFile.map(fileName =>
                    candidateControlServices.deleteFile(CandidateID, fileName)
                );
                deleteResults = await Promise.all(deletePromises);

                deleteResults.forEach(result => {
                    if (!result.isOk) {
                        if (result.statusCode === 400 || result.statusCode === 409) {
                            ShowAlert(result.data, "Vakency");
                        }
                    }
                });
            }

            if (uploadFileResult.isOk && deleteResults.every(result => result.isOk)) {
                onClosePopup();
            }
        } catch (error) {
            console.error("An error occurred:", error);
        } finally {
            setShowLoadPanel(false);
        }
    };

    const getPreviewFileType = (extension) => {
        if (imageExtensions.includes(extension.toLowerCase())) {
            return 'photo';
        } else if (videoExtensions.includes(extension.toLowerCase())) {
            return 'video';
        } else {
            return 'unknown';
        }
    }

    const onSelectedFileOpened = async (evt) => {
        const cellData = evt.file.dataItem;
        let extension = cellData.name?.substring(cellData.name.lastIndexOf('.'));
        let fileType = getPreviewFileType(extension);
        if (fileType == 'unknown') {
            if (cellData.isNew === true) {
                const elem = window.document.createElement("a");
                const blob = new Blob([cellData.file]);
                elem.href = window.URL.createObjectURL(blob);
                elem.download = cellData.file.name;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            } else {
                const response = await candidateControlServices.getFileUrl(CandidateID, cellData.name);
                const elem = window.document.createElement("a");
                elem.href = window.URL.createObjectURL(response.data);
                elem.download = cellData.name;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            }
        } else {
            setShowFilePreviewLoad(true);
            if (cellData.isNew) {
                const blob = new Blob([cellData.file]);
                const fileSrc = window.URL.createObjectURL(blob);
                setSelectedFile({
                    fileSrc: fileSrc,
                    fileName: cellData.file.name,
                    type: fileType,
                });
            } else {
                const response = await candidateControlServices.getFileUrl(CandidateID, cellData.name);
                const fileSrc = window.URL.createObjectURL(response.data);
                setSelectedFile({
                    fileSrc: fileSrc,
                    fileName: cellData.name,
                    type: fileType,
                });
            }
            setShowFilePreview(true);
        }
    }

    const onFilePreviewClose = () => {
        setShowFilePreview(false)
    }

    return (
        <React.Fragment>
            <Popup
                visible={openPopup}
                showTitle
                width={(isXSmall || isXXSmall || isExSmall || isSmall) ? "90%" : isMedium ? "82%" : " 40%"}
                height={"auto"}
                maxHeight={"95%"}
                titleRender={PopupTitle}
                wrapperAttr={{ class: "CustomPopup" }}
                deferRendering={false}
                animation={popupAnimation}
            >
                <form onSubmit={handleSubmit}>
                    <div className='row m-0'>
                        <div className='col py-2'>
                            <LoadPanel
                                onHiding={onHidingLoadPanel}
                                visible={showLoadPanel}
                            />
                            <LoadPanel
                                visible={showFilePreviewLoad}
                                message='File is loading. We’ll display the preview shortly.'
                            />
                            <FileManager
                                fileSystemProvider={customFileProvider}
                                ref={fileManagerRef}
                                onFileUploaded={onFileUploaded}
                                id="fileAttachmentManager"
                                disabled={false}
                                onSelectedFileOpened={onSelectedFileOpened}
                                customizeDetailColumns={customizeDetailColumns}
                            >
                                <Notifications showPopup={false} />
                                <Toolbar>
                                    <Item name="upload" />
                                    <Item name="separator" />
                                    <FileSelectionItem name="download" showText="always" />
                                    <FileSelectionItem name="delete" showText="always" />
                                    <FileSelectionItem icon="rename"
                                        options={RenameButtonOption}
                                        showText="always"
                                    />
                                    <FileSelectionItem name="switchView" showText="always" />
                                </Toolbar>
                                <ItemView>
                                    <Details>
                                        <Column dataField="thumbnail" />
                                        <Column dataField="name" />
                                        <Column dataField="size" caption={"Size"} alignment='left' />
                                    </Details>
                                </ItemView>
                                <Permissions
                                    delete={true}
                                    upload={true}
                                    download={true}
                                ></Permissions>
                                <Upload
                                    chunkSize={1024 * 1024}
                                    maxFileSize={300 * 1024 * 1024}
                                />
                            </FileManager>
                        </div>
                    </div>
                    <PopupFooter
                        ValidationGroupName={ValidationGroupName}
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    />
                </form>
            </Popup>
            {
                showRenameFilePopup && (
                    <FileRenamePopup
                        openPopup={showRenameFilePopup}
                        fileNameList={uploadedFileNameList}
                        fileToRename={fileToRename}
                        closeRenameFilePopup={CloseRenameFilePopup}
                        setFileToRename={setFileToRename}
                        CandidateID={CandidateID}
                    />
                )
            }
            {
                showFilePreview && (
                    <FilePreviewer
                        showFilePreview={showFilePreview}
                        selectedFile={selectedFile}
                        setShowFilePreviewLoad={setShowFilePreviewLoad}
                        onFilePreviewClose={onFilePreviewClose}
                    />
                )
            }
        </React.Fragment>
    );
}