import { DataGrid, LoadPanel, Popup, ScrollView } from 'devextreme-react'
import React, { useEffect, useRef, useState } from 'react'
import { useScreenSize } from '../../utils/media-query';
import PopupHeader from '../../layouts/popup-header-footer/PopupHeader';
import { Column, Scrolling, SearchPanel, StateStoring, Toolbar, Item } from 'devextreme-react/data-grid';
import { closeIcon, openIcon, privateIcon, publicIcon } from '../../utils/base-64-Icons';
import { HideDatagridLoader, ShowAlert } from '../../utils/common-methods';
import './JobOpening.scss';
import PopupSkeleton from '../../layouts/templates/PopupSkeleton';
import PopupSkeletonOneLine from '../../layouts/templates/PopupSkeletonOneLine'
import JobApplicationInsertPopup from './JobApplicationInsertPopup';
import { JobOpeningServices } from '../../api/services/JobOpeningServices';

const jobOpeningServices = new JobOpeningServices()
const JobOpeningSearchPage = (props) => {

  const { isExSmall, isXSmall, isXXSmall } = useScreenSize();

  const JobOpeningSearchDataGridRef = useRef(null);
  const [dsJobOpeningSearchList, setDsJobOpeningSearchList] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [currentJobOpeningID, setCurrentJobOpeningID] = useState(0);

  const onClosePopup = () => {
    props.setOpenSearchPage(false);
  }

  const GetList = async () => {
    const result = await jobOpeningServices.GetCandidateSearchData_withoutJobProfileID();
    setTimeout(() => {
      if (!result.isOk) {
        if (result.statusCode === 400) {
          ShowAlert(result.data, "Vakency")
          setShowSkeleton(false);
        }
        else if (result.statusCode === 409) {
          ShowAlert(result.data, "Vakency")
        }
        setShowSkeleton(false);
      }
      else {
        const listData = result.data?.map((item)=>{
          const RequiredExperience = item.requiredExperience
          ? item.requiredExperience <= 1
            ? item.requiredExperience + " yr "
            : item.requiredExperience + " yrs "
          : null
          const Openings = item.openings ? item.openings + " Openings" : null
          const JobOpeningNo = item.jobOpeningNo ? "#"+ item.jobOpeningNo +" " : null
          const PublicPrivateValue = item?.public === true ? "Public" : "Private"
          const  OpenCloseValue =  item?.open === true ? "open " : "close "
           return  {
            ...item,
            requiredExperience: RequiredExperience,
            openings: Openings,
            jobOpeningNo: JobOpeningNo,
            PublicPrivateValue : PublicPrivateValue,
            OpenCloseValue : OpenCloseValue
           }
        })
        setDsJobOpeningSearchList(listData);
        setShowSkeleton(false);
      }
    }, 1000)
  }

  useEffect(() => {
    GetList();
  }, [])

  const ShowJobAppilcationPopup = (e) => {
    setCurrentJobOpeningID(e?.data?.jobOpeningID)
    setShowPopup(true)
  }


  const ShowRowData = (e) => {
    const cellData = e?.data;
    return (
      <div className='JobOpeningSearchPageData'>
        <div className='row py-1 pb-0'>
          <div className='col'>
            <div className='font-semiBold-18 text-break'>
              {cellData?.jobProfileName} {" "}
            </div>
          </div>
          <div className='col-auto'>
            <div>
              {cellData?.open === true ? (
                <img src={openIcon} alt="open" width={34} />
              ) : (
                <img src={closeIcon} alt="close" width={34} />
              )}
            </div>
          </div>
        </div>
        <div className='row py-1'>
          <div className='d-flex justify-content-start'>
            {cellData?.public === true ? (
              <img src={publicIcon} alt="open" className='PublicPrivateIcon' />
            ) : (
              <img src={privateIcon} alt="close" className='PublicPrivateIcon' />
            )}
            <div className="ps-2 font-medium-14">
              {cellData?.public === true ? "Public" : "Private"}
            </div>
          </div>
        </div>
        <div className='row py-1'>
          <div className='col'>
            <i>
              {cellData?.jobOpeningNo}- {cellData?.openings}
            </i>
          </div>
          <div className='col'>
            {cellData?.requiredExperience &&
              <div className="col-auto text-end">
                <span className="font-medium-14">
                  {cellData?.requiredExperience}
                </span>
                <span className="of-experience ">of experience</span>
              </div>
            }
          </div>
        </div>
        <div className='row py-1'>
          <div className='col'>
            <span title="Hiring Manager" className="font-medium-14 text-secondary text-break">
              <i>{cellData?.hiringManagerName}</i>
            </span>
          </div>
          <div className='col'>
            {cellData?.maxSalary && <div className="col-auto font-medium-14  text-end">
              {cellData?.maxSalary?.toFixed(2)}{" "}
              <span className="of-experience ">CTC</span>
            </div>
            }
          </div>
        </div>
      </div>
    );
  }


  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          onClosePopup={onClosePopup}
          title={["Select Job Opening"]}
          hideSaveButton={true}
        />
      </>
    )
  }


  return (
    <div>
      <Popup
        visible={props.openSearchPage}
        showTitle={true}
        width={(isXSmall || isXXSmall || isExSmall) ? "90%" : "450px"}
        height={"auto"}
        titleRender={PopupTitle}
        wrapperAttr={{ class: "CustomPopup" }}
        deferRendering={false}
        maxHeight={"70vh"}
      >
        <ScrollView height={"100%"} width={"100%"}>
          <div className='p-2'>
            {
              showSkeleton ?
                <>
                  <PopupSkeleton />
                  <PopupSkeleton />
                  <PopupSkeleton />
                  <PopupSkeleton />
                  <PopupSkeletonOneLine/>
                </>
                :
                <DataGrid
                  ref={JobOpeningSearchDataGridRef}
                  dataSource={dsJobOpeningSearchList}
                  showBorders={true}
                  showColumnLines={false}
                  showRowLines={true}
                  focusedRowEnabled={true}
                  wordWrapEnabled={true}
                  hoverStateEnabled={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  autoNavigateToFocusedRow={true}
                  keyExpr="jobOpeningID"
                  className='JobOpeningSearchPage'
                  height={"100%"}
                  filterSyncEnabled={true}
                  showColumnHeaders={false}
                  noDataText='No Record Found'
                  loadPanel={HideDatagridLoader}
                  onRowDblClick={ShowJobAppilcationPopup}
                >
                  <SearchPanel
                    visible={true}
                    width={(isExSmall || isXSmall || isXXSmall) ? "250px " : "425px"}
                    placeholder="Search by Job Profile, Opening Status, Experience and more…"
                  />
                  <Scrolling mode="virtual" rowRenderingMode="virtual"></Scrolling>
                  <Toolbar>
                    <Item
                      name="searchPanel"
                      location="before"
                    />
                  </Toolbar>
                  <LoadPanel enabled={false} />
                  <Column dataField='jobProfileName' cellRender={ShowRowData} width={"100%"} />
                  <Column dataField="jobOpeningID" visible={false} />
                  <Column dataField="jobOpeningNo" visible={false} />
                  <Column dataField="openings" visible={false} />
                  <Column dataField="public" visible={false} />
                  <Column dataField="open" visible={false} />
                  <Column dataField="hiringManagerName" visible={false} />
                  <Column dataField="requiredExperience" visible={false} />
                  <Column dataField="maxSalary" visible={false} />
                  <Column dataField="PublicPrivateValue" visible={false} />
                  <Column dataField="OpenCloseValue" visible={false} />
                </DataGrid>
            }
        </div>
      </ScrollView>
    </Popup>
      {
    showPopup && (
      <JobApplicationInsertPopup
        openPopup={showPopup}
        setOpenPopup={setShowPopup}
        candidateID={props.candidateID}
        JobOpeningID={currentJobOpeningID}
        setOpenSearchPage={props.setOpenSearchPage}
        setStatus={props.setStatus}
      />
    )
  }
    </div >
  )
}

export default JobOpeningSearchPage
