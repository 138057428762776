import React from 'react';
import { Link } from 'react-router-dom';
import './SuccessFullSetPassword.scss';

export default function SuccessFullSetPassword() {
    return (
        <div className='row successful-set-password'>
            <div className=' text-center my-2'><i className='dx-icon-check check-icon fs-5 p-2 rounded-5'></i></div>
            <div className='text-center py-2 message fw-bold '>New password set successfully!</div>
            <div className='pb-2 text-center'>
                <Link to="/login"
                    className='sign-in-link w-auto'
                >
                    Sign in
                </Link>
            </div>
        </div>
    )
}
