import React from 'react';
import './Candidate.scss';
import '../../components/assessment/Assessment.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header } from '../../components';
import Editor from '../../layouts/templates/Editor';
import { Button, DataGrid, LoadPanel, Popover } from 'devextreme-react';
import {
    Column,
    SearchPanel,
    Toolbar as DataGridToolbar,
    Item as DataGridItem,
    HeaderFilter,
    Scrolling,
    Button as DataGridButton
} from "devextreme-react/data-grid";
import StarRating, {
    GetFormattedDate,
    GetFormattedDateTimeUTCString,
    ShowAlert,
    eCRUDStatus,
    handleNavigateToMap,
} from "../../utils/common-methods";
import InteractionPopup from '../job-opening/InteractionPopup';
import SchedulePopup from '../job-opening/SchedulePopup';
import { BlankProfile, CancelledByCandidateStatusIcon, CandidateNotRespondingStatusIcon, candidateRejectedByUsIcon, candidateUnavailableIcon, failedIcon, FileAttachmentIcon, googleMeetingIcon, HiredStatusIcon, interviewCancelledIcon, JoinedStatusIcon, notAttendIcon, OfferAcceptedStatusIcon, OfferedStatusIcon, pendingIcon, rejectedIcon, selectedIcon, ShortlisteddButNotSelectedStatusIcon, shortlistedIcon, skypeIcon, teamsIcon, UnderReviewIcon, zoomIcon } from '../../utils/base-64-Icons';
import { useScreenSize } from '../../utils/media-query';
import { confirm } from 'devextreme/ui/dialog';
import { CandidateControlServices } from '../../api/services/CandidateControlServices';
import { JobOpeningControlPanelServices } from '../../api/services/JobOpeningControlPanelServices';
import { CandidateInteractionServices } from '../../api/services/CandidateInteractionServices';
import { JobApplicationServices } from '../../api/services/JobApplicationServices';
import CandidateFileManagerPopup from './CandidateFileManagerPopup';
import { JobOpeningServices } from '../../api/services/JobOpeningServices';
import FilePreviewer from './FilePreviewer';

const candidateControlServices = new CandidateControlServices();
const jobOpeningControlPanelServices = new JobOpeningControlPanelServices();
const candidateInteractionServices = new CandidateInteractionServices();
const jobApplicationServices = new JobApplicationServices();
const jobOpeningServices = new JobOpeningServices();

const CandidateProfile = () => {

    const NavigateTo = useNavigate();
    const {
        isLarge,
        isExLarge,
    } = useScreenSize();
    const { state, pathname } = useLocation();
    const {
        CandidateID,
        profileOpenFrom,
    } = state;

    const [dsInteraction, setDsInteraction] = React.useState([]);
    const [intercationCandidateName, setIntercationCandidateName] = React.useState();
    const [dsSchedule, setDsSchedule] = React.useState([]);
    const [InteractionfocusedRowKey, setInteractionfocusedRowKey] = React.useState(0);
    const [SchedulefocusedRowKey, setSchedulefocusedRowKey] = React.useState(0);
    const [ScheduledName, setScheduledName] = React.useState();
    const [showInteractionPopup, setShowInteractionPopup] = React.useState(false);
    const [showSchedulePopup, setShowSchedulePopup] = React.useState(false);
    const [applicationfocusedRowKey, setApplicationfocusedRowKey] = React.useState(0);
    const [primaryKey, setPrimaryKey] = React.useState(null);
    const [dsApplications, setDsApplications] = React.useState([]);
    const [status, setStatus] = React.useState({
        eStatus: eCRUDStatus.None,
        primaryKeyID: primaryKey,
    });
    const [statusSchedule, setStatusSchedule] = React.useState({
        eStatus: eCRUDStatus.None,
    });
    const [candidateData, setCandidateData] = React.useState();
    const [profileImg, setProfileImg] = React.useState({
        uploadedFileUrl: null,
        attachedFile: null,
    });
    const [showSkeleton, setShowSkeleton] = React.useState(true);
    const [resume, setResume] = React.useState(null);
    const [totalInteractions, setTotalInteractions] = React.useState(0);
    const [totalSchedules, setTotalSchedules] = React.useState(0);
    const [totalCancelledSchedules, setTotalCancelledSchedules] = React.useState(0);
    const [jobApplicationDate, setJobApplicationDate] = React.useState(null);
    const [jobApplicationNo, setJobApplicationNo] = React.useState(null);
    const [showPopup, setShowPopup] = React.useState(false);
    const [jobOpeningId, setJobOpeningId] = React.useState(0);
    const [dsInteractionRound, setDsInteractionRound] = React.useState([]);
    const [lastInteractionRoundIndex, setLastInteractionRoundIndex] = React.useState();
    const [showFilePreview, setShowFilePreview] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [showFilePreviewLoad, setShowFilePreviewLoad] = React.useState(false);
    const [dsInteractionRounds, setDsInteractionRounds] = React.useState([]);
    const [currentCTCMonthlyValue, setCurrentCTCMonthlyValue] = React.useState();
    const [expectedCTCMonthlyValue, setExpectedCTCMonthlyValue] = React.useState();

    const interactionGridRef = React.useRef(null);
    const scheduleGridRef = React.useRef(null);
    const jobApplicationGridRef = React.useRef(null);

    React.useEffect(() => {
        if (
            CandidateID !== null &&
            CandidateID !== 0 &&
            CandidateID !== undefined
        ) {
            GetModelRecord();
        } else {
            setCandidateData({});
            setShowSkeleton(false);
        }
    }, [CandidateID]);

    React.useEffect(() => {
        GetApplication();
    }, [candidateData]);

    React.useEffect(() => {
        GetInteraction(applicationfocusedRowKey);
    }, [applicationfocusedRowKey]);

    React.useEffect(() => {
        if (dsInteraction.length > 0) {
            GetSchedules(InteractionfocusedRowKey);
        } else {
            setDsSchedule([]);
        }
    }, [InteractionfocusedRowKey, dsInteraction]);

    React.useEffect(() => {
        if (statusSchedule.eStatus === eCRUDStatus.Inserted) {
            GetSchedules(InteractionfocusedRowKey);
        }
    }, [statusSchedule]);

    React.useEffect(() => {
        if (status.eStatus !== eCRUDStatus.None) {
            const PrimaryKeyID =
                status.eStatus === eCRUDStatus.Inserted
                    ? status.primaryKeyID
                    : primaryKey;
            GetInteractionByID(PrimaryKeyID);
        }
    }, [status]);

    React.useEffect(() => {
        if (applicationfocusedRowKey) {
            GetInteractionRounds(applicationfocusedRowKey);
        }
    }, [applicationfocusedRowKey]);

    // API calls
    const GetModelRecord = async () => {
        const result = await candidateControlServices.GetProfileData(CandidateID);
        const response = result.data;
        if (result.statusCode === 400 || result.statusCode === 409) {
            ShowAlert(result.data, "Vakency");
            setShowSkeleton(false);
        } else {
            GetProfileImage().then((res) => {
                setProfileImg({
                    ...result.data,
                    uploadedFile: {
                        fileData: res?.size > 0 ? window.URL.createObjectURL(res) : null,
                    },
                    attachedFile: res?.size > 0 ? res : null,
                });
            });
            GetResume();
            // if (response.lastJobApplicationID) {
            //     GetInteractionRounds(response.lastJobApplicationID);
            // }
            let totalExp = null;
            let relevantExp = null;
            if (response?.totalExperience) {
                let [year, month] = response?.totalExperience.toString().split('.');
                month = month ? Math.round(parseFloat(`.${month}`) * 100) : `0`;
                totalExp = `${year}.${month}`;
                totalExp = parseFloat(totalExp) <= 1 ? totalExp + ' yr' : totalExp + ' yrs'
            }
            if (response?.relevantExperience) {
                let [year, month] = response?.relevantExperience.toString().split('.');
                month = month ? Math.round(parseFloat(`.${month}`) * 100) : `0`;
                relevantExp = `${year}.${month}`;
                relevantExp = parseFloat(relevantExp) <= 1 ? relevantExp + ' yr' : relevantExp + ' yrs'
            }
            if (response.currentCTC != null) {
                setCurrentCTCMonthlyValue((response.currentCTC * 100000) / 12);
            }
            if (response.expectedCTC != null) {
                setExpectedCTCMonthlyValue((response.expectedCTC * 100000) / 12);
            }
            setCandidateData({
                ...response,
                totalExperience: totalExp,
                relevantExperience: relevantExp,
            });
            setShowSkeleton(false);
        }
    };

    const GetResume = async () => {

        let params = `?CandidateID=${CandidateID}`;
        const result = await candidateControlServices.GetCandidateDocData(params);
        if (result.isOk) {
            setResume(result.data);
        }
    };

    const GetProfileImage = async () => {
        let params = `?CandidateID=${CandidateID}`;
        const result = await candidateControlServices.GetImage(params);
        if (result.isOk) {
            return result.data;
        } else {
            return;
        }
    };

    const GetApplication = async () => {
        if (!candidateData?.lastJobApplicationID) {
            setDsApplications([]);
            return;
        }
        const result = await jobApplicationServices.GetListByCandidateId(
            CandidateID
        );
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
            } else if (result.statusCode === 409) {
                ShowAlert(result.data, "Vakency");
            }
        } else {
            const ApplicationData = result.data?.map((item) => {

                const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
                const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
                const jobApplicationDate = GetFormattedDate(item.jobApplicationDate);

                return {
                    ...item,
                    createdOn: createdOn ? createdOn : null,
                    updatedOn: updatedOn ? updatedOn : null,
                    jobApplicationDate: jobApplicationDate ? jobApplicationDate : null
                };
            });
            setDsApplications(ApplicationData);
            // setShowSkeleton(false);
        }
    };

    function interactionRoundsSortFn(a, b) {
        // Define the priority for each interactionResultName status
        const getPriority = (resultName) => {
            if (resultName === null) return 2;       // Highest priority for sorting to last
            if (resultName === "Pending") return 1;   // Second priority for sorting to middle
            return 0;                                 // Lowest priority for sorting to first
        };

        // Compare by priority
        const priorityA = getPriority(a.interactionResultName);
        const priorityB = getPriority(b.interactionResultName);
        if (priorityA !== priorityB) {
            return priorityA - priorityB;
        }

        // If priorities are the same, sort by sortOrder
        return a.sortOrder - b.sortOrder;
    }

    const GetInteractionRounds = async (JobApplicationID) => {
        setDsInteractionRounds([]);
        const result = await jobOpeningControlPanelServices.GetInteractionRounds(JobApplicationID);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
            } else if (result.statusCode === 409) {
                ShowAlert(result.data, "Vakency");
            }
        } else {
            if (result?.data.length > 0) {
                result.data.sort(interactionRoundsSortFn);
                setDsInteractionRounds(result.data);
            }
        }
    }

    const GetInteractionByID = async (id) => {
        const result = await candidateInteractionServices.GetListByIdData(id);
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Vakency");
        }
        if (status.eStatus === eCRUDStatus.Inserted) {
            setDsInteraction([result.data[0], ...dsInteraction]);
            setTotalInteractions((total) => total + 1);
        } else if (status.eStatus === eCRUDStatus.Updated) {
            setDsInteraction(
                dsInteraction.map((i) =>
                    i.candidateInteractionID === primaryKey ? result.data[0] : i
                )
            );
        }
    };

    const GetInteraction = async (JobApplicationID) => {
        if (JobApplicationID) {
            const result = await jobOpeningControlPanelServices.GetInteractionList(
                JobApplicationID
            );

            if (!result.isOk) {
                if (result.statusCode === 400) {
                    ShowAlert(result.data, "Vakency");
                } else if (result.statusCode === 409) {
                    ShowAlert(result.data, "Vakency");
                }
                setTotalInteractions(0);
                // setShowSkeleton(false);
            } else {
                const InteractionData = result.data?.map((item) => {
                    const interactionCreatedOn = GetFormattedDate(item.interactionCreatedOn);
                    return {
                        ...item,
                        interactionCreatedOn: interactionCreatedOn ? interactionCreatedOn : null,
                    };
                });
                setDsInteraction(InteractionData);
                setTotalInteractions(InteractionData.length || 0);
            }
        }
        else {
            setDsInteraction([]);
            setIntercationCandidateName("");
            setTotalInteractions(0);
        }
    };

    const GetSchedules = async (InteractionId) => {
        const result = await jobOpeningControlPanelServices.GetSchedulesList(
            InteractionId
        );
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
            } else if (result.statusCode === 409) {
                ShowAlert(result.data, "Vakency");
            }
            setTotalSchedules(0);
            setTotalCancelledSchedules(0);
            // setShowSkeleton(false);
        } else {
            const ScheduledData = result.data?.map((item) => {
                const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
                const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
                const scheduleDateTime = GetFormattedDate(item.scheduleDateTime);
                return {
                    ...item,
                    createdOn: createdOn ? createdOn : null,
                    updatedOn: updatedOn ? updatedOn : null,
                    scheduleDateTime: scheduleDateTime ? scheduleDateTime : null,
                };
            });
            setDsSchedule(ScheduledData);
            setTotalSchedules(ScheduledData.length || 0);
            setTotalCancelledSchedules(ScheduledData.filter(s => s.meetingStatusName == "Cancelled ").length || 0);
        }
    };

    //handlers
    const onIntercationFocusedRowChanged = (e) => {
        setScheduledName(e?.row?.data?.interactionRoundName);
        setInteractionfocusedRowKey(e.component.option("focusedRowKey"));
    };

    const onScheduleFocusedRowChanged = (e) => {
        setSchedulefocusedRowKey(e.component.option("focusedRowKey"));
    };

    const OnCancelClick = () => {
        NavigateTo(profileOpenFrom, {
            state: {
                ...state
            }
        });
    };

    const onClickAddInteraction = () => {
        setShowInteractionPopup(!showInteractionPopup);
    };

    const onClickAddSchedule = () => {
        // setPrimaryKey(candidateInteractionID)
        setPrimaryKey(InteractionfocusedRowKey);
        setShowSchedulePopup(!showSchedulePopup);
    };

    function onApplicationFocusedRowChanged(e) {
        setIntercationCandidateName(e?.row?.data?.candidateName);
        setApplicationfocusedRowKey(e.component.option("focusedRowKey"));
        setDsSchedule([]);
        setJobApplicationNo(e?.row?.data?.jobApplicationNo);
        setJobApplicationDate(e?.row?.data?.jobApplicationDate);
        setJobOpeningId(e?.row?.data?.jobOpeningID);
    }

    function openResume() {
        const file = new Blob([resume], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
        // const link = document.createElement("a");
        // link.href = fileURL;
        // link.target = '_blank';
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(fileURL);
    }

    const handleEdit = (e) => {
        NavigateTo("/Candidate/Edit", {
            state: {
                ...state,
                CandidateID: CandidateID,
                editorOpenFrom: pathname,
            },
        })
    }

    const onDeleteButtonClick = (e) => {
        let res = confirm(
            `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
            "Vakency"
        );
        res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
    };

    const handleDelete = async (e) => {
        const result = await candidateControlServices.DeleteData(CandidateID)
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Vakency")
        }
        else if (result.statusCode === 200) {
            const deleteResult = await candidateControlServices.DeleteCandidatePicture(CandidateID)
            if (deleteResult.statusCode === 400) {
                ShowAlert(deleteResult.data, "Vakency")
            }
            const deleteResume = await candidateControlServices.DeleteCandidateDocData(CandidateID)
            if (deleteResume.statusCode === 400) {
                ShowAlert(deleteResume.data, "Vakency")
            }
            NavigateTo("/Candidate/List");
        }

    }
    
    const onFilePreviewClose = () => {
        setShowFilePreview(false)
    }

    const onEmailButtonClick = () => {
        window.location.href = `mailto:${candidateData?.emailID}`;
    }
    const onPhoneButtonClick = () => {
        window.location.href = `tel:${candidateData?.mobileNo1}`;
    }

    const handleAttachments = () => {
        setShowPopup(true);
    }

    // cell render methods
    const InteractionNoHeaderCellRender = (e) => {
        return (
            <>
                Interaction No.
                <br />
                Date
            </>
        );
    };
    const InteractionCellRender = (e) => {
        return (
            <>
                #{e.row?.data.candidateInteractionNo}
                <br />
                {e.row?.data.interactionCreatedOn}
            </>
        );
    };
    const ResultCellRender = (e) => {
        return (
            <>
                {e.row?.data.interactionResultName}
                <br />{" "}
                <span className="font-medium-18">
                    <StarRating rating={e.row.data.interactionRating} />
                </span>
            </>
        );
    };
    const inteviewerCellRender = (e) => {
        const focusedInteraction = dsInteraction?.filter((interaction) => interaction?.candidateInteractionID == InteractionfocusedRowKey)
        return (
            <p>
                {focusedInteraction[0]?.interviewerEmailID}
            </p>
        );
    };
    const ScheduleNoCellRender = (e) => {
        return (
            <>
                #{e.row?.data.scheduleNo}
                <br />
                {e.row.data.scheduleDateTime}
            </>
        );
    };
    const ScheduleNoHeaderCellRender = (e) => {
        return (
            <>
                Schedule No.
                <br />
                Date
            </>
        );
    };
    const meetingStatusCellRender = (e) => {
        var meetingLink = e.row?.data?.meetingLink;
        var meetingStatus = e.row?.data.meetingStatus;

        var meetingDate = new Date(e.row?.data.scheduleDateTime);
        var todaysDate = new Date();

        var tomorrowDate = new Date(todaysDate);
        tomorrowDate.setDate(todaysDate.getDate() + 1);

        var sixthDayFromNow = new Date(todaysDate);
        sixthDayFromNow.setDate(todaysDate.getDate() + 6);

        var fourteenthFromNow = new Date(todaysDate);
        fourteenthFromNow.setDate(todaysDate.getDate() + 14);

        return (
            <>
                {meetingStatus === "Completed" ? (
                    <>
                        <img
                            src={shortlistedIcon}
                            alt="completed"
                            width={20}
                            className="me-1"
                        />{" "}
                        Completed
                    </>
                ) : meetingDate.getDate() === todaysDate.getDate() ? (
                    "Scheduled Today"
                ) : tomorrowDate.getDate() === meetingDate.getDate() ? (
                    "Scheduled Tomorrow"
                ) : meetingDate.getDate() > tomorrowDate.getDate() &&
                    meetingDate.getDate() <= sixthDayFromNow.getDate() ? (
                    `Scheduled on ${meetingDate.toLocaleDateString("en-IN", {
                        weekday: "long",
                    })}`
                ) : meetingDate > sixthDayFromNow &&
                    meetingDate <= fourteenthFromNow ? (
                    `Scheduled In Next Week`
                ) : (
                    "Scheduled"
                )}

                <br />
                {meetingStatus !== "Completed" && (
                    <a
                        href={meetingLink}
                        target="_blank"
                        rel="noreferrer"
                        className="join-meeting-section"
                    >
                        {meetingLink?.includes("meet") ? (
                            <img src={googleMeetingIcon} alt="google meet" width={20} />
                        ) : meetingLink?.includes("teams") ? (
                            <img src={teamsIcon} alt="teams" width={20} />
                        ) : meetingLink?.includes("skype") ? (
                            <img src={skypeIcon} alt="skype" width={20} />
                        ) : meetingLink?.includes("zoom") ? (
                            <img src={zoomIcon} alt="zoom" width={24} />
                        ) : (
                            ""
                        )}

                        <span className="join-meeting">join meeting</span>
                    </a>
                )}
            </>
        );
    };
    const JobAppheaderCellRender = (e) => {
        return (
            <>
                Job App. No.
                <br />
                Date
            </>
        );
    };
    const JobAppCellRender = (e) => {
        return (
            <>
                #{e.row?.data.jobApplicationNo}
                <br />
                {e.row?.data.jobApplicationDate}
            </>
        );
    };

    const JobProfileHeaderCellRender = (e) => {
        return (
            <>
                Job Profile
            </>
        );
    };

    const HiringManagerHeaderCellRender = (e) => {
        return (
            <>
                Hiring Manager
            </>
        );
    };

    const JobApplicationStatusHeaderCellRender = (e) => {
        return (
            <>
                Application Status
            </>
        );
    };

    const JobDescriptionHeaderCellRender = (e) => {
        return (
            <>
                Note
            </>
        );
    };
    const JobStatusCellRender = (e) => {
        return (
            <>
                <img
                    src={
                        e.row?.data.jobApplicationStatus === "Job application rejected by us"
                            ? rejectedIcon
                            : e.row?.data.jobApplicationStatus === "Pending"
                                ? pendingIcon :
                                e.row?.data.jobApplicationStatus === "Shortlisted"
                                    ? shortlistedIcon :
                                    e.row?.data.jobApplicationStatus === "Under Review"
                                        ? UnderReviewIcon :
                                        e.row?.data.jobApplicationStatus === "Selected"
                                            ? selectedIcon :
                                            e.row?.data.jobApplicationStatus === "Offered"
                                                ? OfferedStatusIcon :
                                                e.row?.data.jobApplicationStatus === "Offer accepted"
                                                    ? OfferAcceptedStatusIcon :
                                                    e.row?.data.jobApplicationStatus === "Hired"
                                                        ? HiredStatusIcon :
                                                        e.row?.data.jobApplicationStatus === "On-boarded/Joined"
                                                            ? JoinedStatusIcon :
                                                            e.row?.data.jobApplicationStatus === "Offer rejected by candidate"
                                                                ? candidateRejectedByUsIcon :
                                                                e.row?.data.jobApplicationStatus === "Shortlisted but not selected"
                                                                    ? ShortlisteddButNotSelectedStatusIcon :
                                                                    e.row?.data.jobApplicationStatus === "Cancelled by Candidate"
                                                                        ? CancelledByCandidateStatusIcon :
                                                                        e.row?.data.jobApplicationStatus === "Candidate is not responding"
                                                                        && CandidateNotRespondingStatusIcon
                    }
                    title={e.row?.data.jobApplicationStatus}
                    alt="status"
                    width={22}
                    className='me-1'
                />
                {e.row?.data.jobApplicationStatus}
            </>
        );
    };

    const onAssessmentViewerButtonClick = (e) => {
        NavigateTo('/AssessmentViewer', {
            state: {
                InteractionID: e.row.data.candidateInteractionID
            },
        });
    }

    const onPictureClick = (fileSrc) => {
        if (fileSrc) {
            setShowFilePreview(true);
            setSelectedFile({
                fileSrc: fileSrc,
            });
        }
    }

    const onPrintClick = () => {
        const file = new Blob([resume], { type: 'application/pdf' });
        const fileURL = URL?.createObjectURL(file);

        const iframe = document?.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.width = '0px';
        iframe.style.height = '0px';
        iframe.style.border = 'none';

        document?.body?.appendChild(iframe);

        iframe.src = fileURL;

        iframe.onload = () => {
            iframe?.contentWindow.print();
        };
    }

    return (
        <>
            <Header
                title="Candidate Profile"
                hideDefaultButtons={true}
                onBackButtonClick={OnCancelClick}
            />
            <LoadPanel
                visible={showFilePreviewLoad}
                message='File is loading. We’ll display the preview shortly.'
            />
            <Editor customWidth={true}>
                <div className="row pt-1 px-3">
                    <div
                        className={`col-auto d-flex px-0 mx-2`}
                    >
                        <div>
                            <div>
                                {(profileImg.uploadedFile?.fileData) ? <img
                                    src={profileImg.uploadedFile?.fileData}
                                    width={100}
                                    height={100}
                                    alt="candidate"
                                    className="candidate-image shadow-sm"
                                    onClick={() => onPictureClick(profileImg.uploadedFile?.fileData)}
                                /> :
                                    <img
                                        src={BlankProfile}
                                        width={100}
                                        height={100}
                                        alt="candidate"
                                        className="candidate-image shadow-sm"
                                    />}
                            </div>

                            {
                                resume && (
                                    <div className='font-medium-14 text-decoration-underline text-center cursor-pointer' onClick={openResume}>
                                        View Resume
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="col px-0 mx-2">
                        <div className="row">
                            <div className="col order-1">
                                <div className="font-semiBold-23 ">
                                    {candidateData?.candidateName}
                                </div>
                            </div>
                            {
                                dsInteractionRounds.length > 0 && (
                                    <div
                                        className={`col-lg-auto d-flex justify-content-lg-end order-3 order-lg-2 col-12 ${isLarge && isExLarge
                                            ? "me-1"
                                            : ""
                                            }`}
                                    >
                                        <div className='d-flex flex-column gap-0'>
                                            <div className='text-start text-md-center font-semiBold-14'>Hiring Progress Tracker</div>
                                            <div className='d-flex flex-wrap justify-content-lg-center'>
                                                {dsInteractionRounds.map((interaction, index) => (
                                                    <div className='d-flex align-items-center mb-1' key={interaction.interactionRoundID}>
                                                        <div
                                                            id={`link-${index}`}
                                                            className={`d-flex justify-content-center align-items-center ${interaction.interactionResultName === 'Pending' ? 'candidate_progress_circle_in_progress' : interaction.interactionResultName != null ? 'candidate_progress_circle_completed' : 'candidate_progress_circle_pending'
                                                                }`}
                                                        />
                                                        <Popover
                                                            target={`#link-${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            position="bottom"
                                                            width={'auto'}
                                                        >
                                                            <div className='font-medium-14'>
                                                                <span className='italic-text'>Round Name:</span>{" "}
                                                                {interaction.interactionRoundName ? interaction.interactionRoundName : '-'}
                                                            </div>
                                                            <div className='font-medium-14'>
                                                                <span className='italic-text'>Status:</span>{" "}
                                                                {interaction.interactionResultName ? interaction.interactionResultName : '-'}
                                                            </div>
                                                            <div className='font-medium-14'>
                                                                <span className='italic-text'>Date of Interaction:</span>{" "}
                                                                {interaction.scheduleDateTime ? GetFormattedDate(interaction.scheduleDateTime) : '-'}
                                                            </div>
                                                        </Popover>
                                                        {index < dsInteractionRounds.length - 1 && (
                                                            <div
                                                                className={interaction.interactionResultName === 'Pending' ? 'candidate_progress_line_pending' : interaction.interactionResultName != null ? 'candidate_progress_line_completed' : 'candidate_progress_line_pending'}
                                                            />
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <div
                                className={`col-auto d-flex align-items-center justify-content-md-end order-2 order-lg-3 ${isLarge && isExLarge
                                    ? "me-1"
                                    : ""
                                    }`}
                            >
                                <div>
                                    {candidateData?.lastJobApplicationStatus && <img
                                        src={
                                            candidateData?.lastJobApplicationStatus === "Job application rejected by us"
                                                ? rejectedIcon
                                                : candidateData?.lastJobApplicationStatus === "Pending"
                                                    ? pendingIcon :
                                                    candidateData?.lastJobApplicationStatus === "Shortlisted"
                                                        ? shortlistedIcon :
                                                        candidateData?.lastJobApplicationStatus === "Under Review"
                                                            ? UnderReviewIcon :
                                                            candidateData?.lastJobApplicationStatus === "Selected"
                                                                ? selectedIcon :
                                                                candidateData?.lastJobApplicationStatus === "Offered"
                                                                    ? OfferedStatusIcon :
                                                                    candidateData?.lastJobApplicationStatus === "Offer accepted"
                                                                        ? OfferAcceptedStatusIcon :
                                                                        candidateData?.lastJobApplicationStatus === "Hired"
                                                                            ? HiredStatusIcon :
                                                                            candidateData?.lastJobApplicationStatus === "On-boarded/Joined"
                                                                                ? JoinedStatusIcon :
                                                                                candidateData?.lastJobApplicationStatus === "Offer rejected by candidate"
                                                                                    ? candidateRejectedByUsIcon :
                                                                                    candidateData?.lastJobApplicationStatus === "Shortlisted but not selected"
                                                                                        ? ShortlisteddButNotSelectedStatusIcon :
                                                                                        candidateData?.lastJobApplicationStatus === "Cancelled by Candidate"
                                                                                            ? CancelledByCandidateStatusIcon :
                                                                                            candidateData?.lastJobApplicationStatus === "Candidate is not responding"
                                                                                            && CandidateNotRespondingStatusIcon
                                        }
                                        title={candidateData?.lastJobApplicationStatus}
                                        alt="status"
                                        width={22}
                                    />}
                                </div>
                                {candidateData?.lastJobApplicationStatus && <div className="px-2 verticle-line-small">|</div>}
                                <div>
                                    <Button
                                        icon="attach"
                                        hint="Attachment"
                                        stylingMode="text"
                                        className="resume-edit-btn"
                                        type="default"
                                        onClick={handleAttachments}
                                    />
                                </div>
                                <div className="px-2 verticle-line-small">|</div>
                                <div>
                                    <Button
                                        icon="edit"
                                        stylingMode="text"
                                        hint="Edit"
                                        type="default"
                                        className="resume-edit-btn"
                                        onClick={handleEdit}
                                    />
                                </div>
                                {
                                    candidateData?.emailID && (
                                        <>
                                            <div className="px-2 verticle-line-small">|</div>
                                            <div>
                                                <Button
                                                    icon="email"
                                                    hint="Email"
                                                    stylingMode="text"
                                                    className="resume-edit-btn"
                                                    onClick={onEmailButtonClick}
                                                />
                                            </div>
                                        </>
                                    )
                                }
                                <div className="px-2 verticle-line-small">|</div>
                                {resume &&
                                    <>
                                        <div>
                                            <Button
                                                icon="print"
                                                hint="Print Resume"
                                                stylingMode="text"
                                                className="resume-edit-btn"
                                                type="default"
                                                onClick={onPrintClick}
                                            />
                                        </div>
                                        <div className="px-2 verticle-line-small">|</div>
                                    </>
                                }
                                <div>
                                    <Button
                                        icon="trash"
                                        hint="Delete"
                                        stylingMode="text"
                                        className="delete-edit-btn"
                                        type="danger"
                                        onClick={onDeleteButtonClick}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-auto'>
                                <div className="font-semiBold-16 d-flex align-items-center">
                                    <div className="">{candidateData?.jobProfileName ? candidateData.jobProfileName : '-'}</div>
                                    <div className="font-medium-20 ps-3">
                                        <StarRating rating={candidateData?.rating || 0} />
                                    </div>
                                </div>
                                <div className="pt-1">
                                    {candidateData?.lastJobApplicationStatus &&
                                        <span title=''>
                                            Last Application Status:{" "}
                                            <span className='font-semiBold-14'>
                                                <img
                                                    src={
                                                        candidateData?.lastJobApplicationStatus === "Job application rejected by us"
                                                            ? rejectedIcon
                                                            : candidateData?.lastJobApplicationStatus === "Pending"
                                                                ? pendingIcon :
                                                                candidateData?.lastJobApplicationStatus === "Shortlisted"
                                                                    ? shortlistedIcon :
                                                                    candidateData?.lastJobApplicationStatus === "Under Review"
                                                                        ? UnderReviewIcon :
                                                                        candidateData?.lastJobApplicationStatus === "Selected"
                                                                            ? selectedIcon :
                                                                            candidateData?.lastJobApplicationStatus === "Offered"
                                                                                ? OfferedStatusIcon :
                                                                                candidateData?.lastJobApplicationStatus === "Offer accepted"
                                                                                    ? OfferAcceptedStatusIcon :
                                                                                    candidateData?.lastJobApplicationStatus === "Hired"
                                                                                        ? HiredStatusIcon :
                                                                                        candidateData?.lastJobApplicationStatus === "On-boarded/Joined"
                                                                                            ? JoinedStatusIcon :
                                                                                            candidateData?.lastJobApplicationStatus === "Offer rejected by candidate"
                                                                                                ? candidateRejectedByUsIcon :
                                                                                                candidateData?.lastJobApplicationStatus === "Shortlisted but not selected"
                                                                                                    ? ShortlisteddButNotSelectedStatusIcon :
                                                                                                    candidateData?.lastJobApplicationStatus === "Cancelled by Candidate"
                                                                                                        ? CancelledByCandidateStatusIcon :
                                                                                                        candidateData?.lastJobApplicationStatus === "Candidate is not responding"
                                                                                                        && CandidateNotRespondingStatusIcon
                                                    }
                                                    title={candidateData?.lastJobApplicationStatus}
                                                    alt="status"
                                                    width={22}
                                                />
                                            </span>
                                            <span className='ms-1'>{candidateData?.lastJobApplicationStatus}</span>
                                        </span>
                                    }
                                </div>
                                {candidateData?.lastRoundScheduleDate &&
                                    <div className="pt-1">
                                        <span title="">
                                            Last Interaction Date:{" "}
                                            <span className="font-semiBold-14">
                                                {candidateData?.lastRoundScheduleDate ? new Date(candidateData.lastRoundScheduleDate).toLocaleDateString() : ""}{" "}
                                            </span>
                                        </span>
                                    </div>
                                }
                            </div>
                            <div
                                className={`col-xl-12 justify-content-xl-start justify-content-xxl-end col-xxl d-flex ${isLarge && isExLarge
                                    ? "me-1"
                                    : ""
                                    }`}
                            >
                                <div className='row font-medium-14 text-center'>
                                    <div className="col mt-3">
                                        <div className="candidate-custom-card p-1 d-flex justify-content-center">
                                            <div className="d-flex align-items-center">
                                                <div className='row text-truncate'>
                                                    <div className='col-auto border-end'>
                                                        <div className='row'>
                                                            <div className='col border-end'>
                                                                <span className="" title={candidateData?.currentCTC?.toFixed(2)}>{(candidateData?.currentCTC) ? candidateData?.currentCTC?.toFixed(2) : "-"}</span>
                                                                <span className="d-block top-card-caption">LPA</span>
                                                            </div>
                                                            <div className='col '>
                                                                <span className="" title={currentCTCMonthlyValue?.toFixed(2)}>{(currentCTCMonthlyValue) ? currentCTCMonthlyValue?.toFixed(2) : "-"}</span>
                                                                <span className="d-block top-card-caption">Monthly</span>
                                                            </div>
                                                        </div>
                                                        <span className="d-block top-card-caption">Current CTC</span>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <div className='row'>
                                                            <div className='col border-end'>
                                                                <span className="" title={candidateData?.expectedCTC?.toFixed(2)}>{(candidateData?.expectedCTC) ? candidateData?.expectedCTC?.toFixed(2) : "-"}</span>
                                                                <span className="d-block top-card-caption">LPA</span>
                                                            </div>
                                                            <div className='col'>
                                                                <span className="" title={expectedCTCMonthlyValue?.toFixed(2)}>{(expectedCTCMonthlyValue) ? expectedCTCMonthlyValue?.toFixed(2) : "-"}</span>
                                                                <span className="d-block top-card-caption">Monthly</span>
                                                            </div>
                                                        </div>
                                                        <span className="d-block top-card-caption">Expected CTC</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col mt-3">
                                        <div className="candidate-custom-card p-1 d-flex justify-content-center">
                                            <div className="d-flex align-items-center">
                                                <div className='row'>
                                                    <div className='col-4 border-end'>
                                                        <span className="">
                                                            {(candidateData?.totalExperience) ?
                                                                // (candidateData?.totalExperience <= 1 ? candidateData?.totalExperience + " yr" : candidateData?.totalExperience + " yrs")
                                                                (candidateData?.totalExperience)
                                                                : "-"}
                                                        </span>
                                                        <span className="d-block top-card-caption">Total Experience</span>
                                                    </div>
                                                    <div className='col-4 border-end'>
                                                        <span className="">
                                                            {(candidateData?.relevantExperience) ?
                                                                // (candidateData?.relevantExperience <= 1 ? candidateData?.relevantExperience + " yr" : candidateData?.relevantExperience + " yrs")
                                                                (candidateData?.relevantExperience)
                                                                : "-"}
                                                        </span>
                                                        <span className="d-block top-card-caption">Relevant Experience</span>
                                                    </div>
                                                    <div className='col-4'>
                                                        <span className="">{(candidateData?.noticePeriod) ? (candidateData?.noticePeriod + " days") : "-"}</span>
                                                        <span className="d-block top-card-caption">Notice Period</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col mt-3">
                                        <div className="candidate-custom-card p-1 d-flex justify-content-center">
                                            <div className="d-flex align-items-center">
                                                <div className='row'>
                                                    <div className='col-4 border-end'>
                                                        <span className="">{totalInteractions}</span>
                                                        <span className="d-block top-card-caption">Total Interactions</span>
                                                    </div>
                                                    <div className='col-4 border-end'>
                                                        <span className="">{totalSchedules}</span>
                                                        <span className="d-block top-card-caption">Total Schedules</span>
                                                    </div>
                                                    <div className='col-4'>
                                                        <span className="">{totalCancelledSchedules}</span>
                                                        <span className="d-block top-card-caption">Total Cancelled Schedules</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <hr className="mb-0 mt-3" />
                <div className="row px-3 font-medium-14 text-center">
                    <div className="col px-0 mx-2 mt-3">
                        <div className="candidate-secondary-custom-card p-1 d-flex justify-content-center">
                            <div className="d-flex align-items-center">
                                <div className='row'>
                                    <div className='col-auto border-end'>
                                        <span className="">#{(jobApplicationNo) ? (jobApplicationNo) : "-"}</span>
                                        <span className="d-block top-card-caption">Application No.</span>
                                    </div>
                                    <div className='col-auto'>
                                        <span className="">{jobApplicationDate
                                            ? GetFormattedDate(jobApplicationDate)
                                            : "-"}</span>
                                        <span className="d-block top-card-caption">Applicatio Date</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        candidateData?.currentAddress && (
                            <div className="col px-0 mx-2 mt-3">
                                <div className="candidate-secondary-custom-card p-1 d-flex justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className='d-flex'>
                                            <div className='d-flex align-items-center px-1'>
                                                <i className="dx-icon-locationIcon dx-custom-icon cursor-pointer" onClick={() => handleNavigateToMap(candidateData?.currentAddress)}></i>{" "}
                                            </div>
                                            <div>
                                                <b>{candidateData?.currentAddress}</b>
                                                <br />
                                                <b>{candidateData?.currentCityName ? candidateData?.currentCityName : ""}
                                                    {candidateData?.currentStateName ? ', ' + candidateData?.currentStateName : ""}
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="col px-0 mx-2 mt-3">
                        <div className="candidate-secondary-custom-card p-1 d-flex justify-content-center">
                            <div className="d-flex align-items-center">
                                <div>
                                    <div>
                                        <Button
                                            icon="email"
                                            hint="Email"
                                            stylingMode="text"
                                            className="resume-edit-btn"
                                            onClick={onEmailButtonClick}
                                        />{" "}
                                        <b title={candidateData?.emailID}>{candidateData?.emailID ? candidateData?.emailID : '-'}</b>
                                    </div>
                                    <div className="d-flex mt-1">
                                        <div onClick={onPhoneButtonClick} className="cursor-pointer">
                                            <i className="dx-icon-MobileIcon dx-custom-icon"></i>{" "}
                                            <b>{candidateData?.mobileNo1 ? candidateData?.mobileNo1 : '-'}</b>
                                        </div>
                                        <div className='border-end mx-1'></div>
                                        <div className=''>
                                            {candidateData?.linkedIn ? <a
                                                href={`${candidateData?.linkedIn}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="dx-icon-LinkedInIcon dx-custom-icon mx-1"></i>
                                            </a> : <i className="dx-icon-LinkedInDisabledIcon dx-custom-icon mx-1"></i>
                                            }
                                            {candidateData?.skype ? <a
                                                href={`skype:${candidateData?.skype}?chat`}
                                                rel="noreferrer"
                                            >
                                                <i className="dx-icon-skypeIcon dx-custom-icon mx-1"></i>
                                            </a> : <i className="dx-icon-skypeDisabledIcon dx-custom-icon mx-1"></i>
                                            }
                                            {candidateData?.mobileNo2 ? <a
                                                href={`https://wa.me/91${candidateData?.mobileNo2}`}
                                                rel="noreferrer"
                                                target='_blank'
                                            >
                                                <i className="dx-icon-WhatsappIcon dx-custom-icon mx-1"></i>
                                            </a> : <i className="dx-icon-WhatsappDisabledIcon dx-custom-icon mx-1"></i>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col px-0 mx-2 mt-3">
                        <div className="candidate-secondary-custom-card p-1 d-flex justify-content-center">
                            <div className="d-flex align-items-center">
                                <div className='row'>
                                    <div className='col-auto border-end'>
                                        <span className="">{(candidateData?.academicQualificationName) ? (candidateData?.academicQualificationName) : "-"}</span>
                                        <span className="d-block top-card-caption">Qualification</span>
                                    </div>
                                    <div className='col-auto'>
                                        <span className="">{(candidateData?.academicQualificationYear) ? (candidateData?.academicQualificationYear) : "-"}</span>
                                        <span className="d-block top-card-caption">Year</span>
                                    </div>
                                </div>
                                {/* <div>
                                    <div className="">
                                        <span>Qualification:</span>{" "}
                                        <b>{(candidateData?.academicQualificationName) ? (candidateData?.academicQualificationName) : "-"}</b>
                                    </div>
                                    <div className="mt-1">
                                        <span>Year:</span>{" "}
                                        <b>{(candidateData?.academicQualificationYear) ? (candidateData?.academicQualificationYear) : "-"}</b>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col px-0 mx-2 mt-3">
                        <div className="candidate-secondary-custom-card p-1 d-flex justify-content-center">
                            <div className="d-flex align-items-center">
                                <div className='row'>
                                    <div className='col-auto'>
                                        <span className="">{candidateData?.maritalStatusName ? candidateData?.maritalStatusName : '-'}</span>
                                        <span className="d-block top-card-caption">Marital Status</span>
                                    </div>
                                </div>
                                {/* <div>
                                    <div className="">
                                        <span>Marital Status:</span>{" "}
                                        <b>{candidateData?.maritalStatusName ? candidateData?.maritalStatusName : '-'}</b>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col px-0 mx-2 mt-3">
                        <div className="candidate-secondary-custom-card p-1 d-flex justify-content-center">
                            <div className="d-flex align-items-center">
                                <div className='row'>
                                    <div className='col-auto'>
                                        <span className="">{candidateData?.currentCompanyName ? candidateData?.currentCompanyName : '-'}</span>
                                        <span className="d-block top-card-caption">Current Company Name</span>
                                    </div>
                                </div>
                                {/* <div>
                                    <div className="">
                                        <span>Last Company:</span>{" "}
                                        <b>{candidateData?.currentCompanyName ? candidateData?.currentCompanyName : '-'}</b>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row px-3">
                    <div className="col px-0 mx-2">
                        <div className="custom-card-grid mt-3 pt-2">
                            <DataGrid
                                ref={jobApplicationGridRef}
                                keyExpr={"jobApplicationID"}
                                className="Application_DataGrid mx-2 mb-2"
                                dataSource={dsApplications}
                                showColumnLines={false}
                                showRowLines={true}
                                height={270}
                                wordWrapEnabled={true}
                                allowResizing={true}
                                allowReordering={true}
                                hoverStateEnabled={true}
                                focusedRowEnabled={true}
                                noDataText="No Record Found"
                                defaultFocusedRowIndex={[0]}
                                focusedRowKey={applicationfocusedRowKey}
                                onFocusedRowChanged={onApplicationFocusedRowChanged}
                                autoNavigateToFocusedRow={true}
                            >
                                <DataGridToolbar>
                                    <DataGridItem location="before">
                                        <span className="font-semiBold-20">Job Applications</span>
                                    </DataGridItem>
                                    <DataGridItem name="searchPanel" locateInMenu="auto" />
                                </DataGridToolbar>
                                <Scrolling mode="virtual" />
                                <SearchPanel visible={true} width={260} />
                                <HeaderFilter visible={true} />
                                {/* <FilterPanel visible={true}/> */}
                                <Scrolling mode="virtual" />
                                <Column
                                    headerCellRender={JobAppheaderCellRender}
                                    dataField="jobApplicationNo"
                                    alignment="left"
                                    cellRender={JobAppCellRender}
                                    width={135}
                                    minWidth={80}
                                />
                                <Column
                                    dataField="jobProfileName"
                                    minWidth={160}
                                    width={'240'}
                                    headerCellRender={JobProfileHeaderCellRender}
                                />
                                <Column
                                    dataField="hiringManagerName"
                                    headerCellRender={HiringManagerHeaderCellRender}
                                    width={220}
                                    minWidth={80}
                                />
                                <Column
                                    dataField="jobApplicationStatus"
                                    headerCellRender={JobApplicationStatusHeaderCellRender}
                                    cellRender={JobStatusCellRender}
                                    width={220}
                                    minWidth={100}
                                />
                                <Column
                                    dataField="descrPlainText"
                                    headerCellRender={JobDescriptionHeaderCellRender}
                                    width={240}
                                    minWidth={80}
                                />
                                <Column dataField="jobApplicationDate" visible={false} />
                                <Column dataField="jobProfileName" visible={false} />
                                <Column dataField="hiringManagerName" visible={false} />
                                <Column dataField="jobApplicationStatus" visible={false} />
                                <Column dataField="descrPlainText" visible={false} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <div className="row px-3">
                    <div className="col px-0 mx-2">
                        <div className="custom-card-grid mt-3 pt-2">
                            <DataGrid
                                ref={interactionGridRef}
                                keyExpr={"candidateInteractionID"}
                                className="Application_DataGrid mx-2 mb-2"
                                dataSource={dsInteraction}
                                showColumnLines={false}
                                showRowLines={true}
                                height={300}
                                // width={'100%'}
                                wordWrapEnabled={true}
                                allowResizing={true}
                                allowReordering={true}
                                hoverStateEnabled={true}
                                focusedRowEnabled={true}
                                noDataText="No Record Found"
                                defaultFocusedRowIndex={[0]}
                                focusedRowKey={InteractionfocusedRowKey}
                                onFocusedRowChanged={onIntercationFocusedRowChanged}
                                autoNavigateToFocusedRow={true}
                                columnAutoWidth
                            >
                                <DataGridToolbar>
                                    <DataGridItem location="before" locateInMenu="auto">
                                        <span className="font-semiBold-20">
                                            Interactions{" "}
                                            <span className="font-semiBold-14 blue-text">
                                                {intercationCandidateName &&
                                                    "for " + intercationCandidateName}
                                            </span>
                                            <span className="vertical-line px-2">|</span>
                                            <Button
                                                icon="add"
                                                className="add-interaction-btn me-2"
                                                stylingMode="outlined"
                                                hint="Add Interaction"
                                                onClick={onClickAddInteraction}
                                                disabled={!applicationfocusedRowKey}
                                            />
                                        </span>
                                    </DataGridItem>
                                    <DataGridItem name="searchPanel" locateInMenu="auto" />
                                </DataGridToolbar>
                                <SearchPanel visible={true} width={260} />
                                <HeaderFilter visible={true} />
                                <Column
                                    dataField="candidateInteractionNo"
                                    headerCellRender={InteractionNoHeaderCellRender}
                                    cellRender={InteractionCellRender}
                                    alignment="left"
                                    width={140}
                                    minWidth={100}
                                />
                                <Column
                                    dataField="interactionRoundName"
                                    width={150}
                                    minWidth={100}
                                />
                                <Column
                                    dataField="interactionResultName"
                                    cellRender={ResultCellRender}
                                    width={110}
                                    minWidth={80}
                                />
                                <Column
                                    dataField="interviewerEmailID"
                                    caption="Interviewers"
                                    width={240}
                                    minWidth={80}
                                />
                                <Column
                                    dataField="observation"
                                    hidingPriority={1}
                                />
                                <Column
                                    type='buttons'
                                    fixed={false}
                                    allowResizing={false}
                                    width={43}
                                    allowReordering={false}
                                >
                                    <DataGridButton
                                        name='assessment'
                                        icon='assessmentIcon'
                                        hint='View Assessment'
                                        visible={(e) => e.row.data.interactionResultName == "Passed"}
                                        onClick={onAssessmentViewerButtonClick}
                                    />
                                </Column>
                            </DataGrid>
                        </div>
                    </div>
                    <div className="col px-0 mx-2">
                        <div className="custom-card-grid mt-3 pt-2">
                            <DataGrid
                                ref={scheduleGridRef}
                                keyExpr={"scheduleNo"}
                                className="Application_DataGrid mx-2 mb-2"
                                dataSource={dsSchedule}
                                showColumnLines={false}
                                showRowLines={true}
                                height={300}
                                wordWrapEnabled={true}
                                allowResizing={true}
                                allowReordering={true}
                                hoverStateEnabled={true}
                                focusedRowEnabled={true}
                                defaultFocusedRowIndex={[0]}
                                focusedRowKey={SchedulefocusedRowKey}
                                onFocusedRowChanged={onScheduleFocusedRowChanged}
                                noDataText="No Record Found"
                                autoNavigateToFocusedRow={true}
                            >
                                <DataGridToolbar>
                                    <DataGridItem location="before" locateInMenu="auto">
                                        <span className="font-semiBold-20">
                                            Schedules{" "}
                                            {dsInteraction?.length ? <span className="font-semiBold-14 blue-text">
                                                {ScheduledName && "for " + ScheduledName}
                                            </span> : ""}
                                            <span className="vertical-line px-2">|</span>
                                            <Button
                                                icon="add"
                                                className="add-interaction-btn me-2"
                                                stylingMode="outlined"
                                                hint="Add Schedule"
                                                onClick={onClickAddSchedule}
                                                disabled={!InteractionfocusedRowKey || !dsInteraction.length}
                                            />
                                        </span>
                                    </DataGridItem>
                                    <DataGridItem name="searchPanel" locateInMenu="auto" />
                                </DataGridToolbar>
                                <SearchPanel visible={true} width={260} />
                                <Column
                                    dataField="scheduleNo"
                                    headerCellRender={ScheduleNoHeaderCellRender}
                                    cellRender={ScheduleNoCellRender}
                                    alignment="left"
                                    width={170}
                                    minWidth={150}
                                />
                                <Column
                                    caption="Interviewers"
                                    cellRender={inteviewerCellRender}
                                    minWidth={80}
                                    width={350}
                                />
                                <Column
                                    dataField="meetingStatus"
                                    cellRender={meetingStatusCellRender}
                                    hidingPriority={1}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </Editor>
            {
                showPopup && (
                    <CandidateFileManagerPopup
                        openPopup={showPopup}
                        setOpenPopup={setShowPopup}
                        CandidateID={CandidateID}
                    />
                )
            }
            {showInteractionPopup && (
                <InteractionPopup
                    openPopup={showInteractionPopup}
                    setOpenPopup={setShowInteractionPopup}
                    jobApplicationID={applicationfocusedRowKey}
                    setStatus={setStatus}
                    candidateId={CandidateID}
                    jobOpeningID={jobOpeningId}
                    dsInteractionRoundsResults={dsInteractionRounds}
                />
            )}
            {showSchedulePopup && (
                <SchedulePopup
                    openPopup={showSchedulePopup}
                    setOpenPopup={setShowSchedulePopup}
                    candidateId={dsInteraction.length && CandidateID}
                    candidateInteractionID={dsInteraction.length && InteractionfocusedRowKey}
                    setStatus={setStatus}
                    setStatusSchedule={setStatusSchedule}
                    jobOpeningID={jobOpeningId}
                    jobApplicationID={applicationfocusedRowKey}
                    dsInteractionRoundsResults={dsInteractionRounds}
                />
            )}
            {
                showFilePreview && (
                    <FilePreviewer
                        showFilePreview={showFilePreview}
                        selectedFile={selectedFile}
                        setShowFilePreviewLoad={setShowFilePreviewLoad}
                        onFilePreviewClose={onFilePreviewClose}
                        isImageOnly={true}
                    />
                )
            }
        </>
    );

}

export default CandidateProfile;