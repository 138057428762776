import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { JobOpeningControlName } from "../../contexts/APIurl";

const url = JobOpeningControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class JobOpeningServices extends VakencyApiServices {
  constructor() {
    super(url);
  }

  GetList_with_Filters=async(params)=> {
    const GetListUrl = url + "/GetList?" + params
    let data = null;
    try {
        data = await fetchWrapper.get(baseURL + GetListUrl)
        return this.handleResponse(data);
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

GetLookupListDataByControlName=async(url)=> {
const GetListUrl = url + "/GetLookupList/"
    let data = null;
    try {
        data = await fetchWrapper.get(baseURL + GetListUrl)
        return this.handleResponse(data);
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

GenerateJobOpeningNoData=async()=>{
    const GetListUrl = url + "/GenerateJobOpeningNo/"
    let data = null;
    try {
        data = await fetchWrapper.get(baseURL + GetListUrl)
        return this.handleResponse(data);
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

GetCandidateSearchData_withoutJobProfileID=async()=>{
    const GetListUrl = url + `/GetSearchList`
    let data = null;
    try {
        data = await fetchWrapper.get(baseURL + GetListUrl)
        return this.handleResponse(data);
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

GetListHeader=async(params)=> {
    const GetListUrl = url + "/GetListHeader?" + params
    let data = null;
    try {
        data = await fetchWrapper.get(baseURL + GetListUrl)
        return this.handleResponse(data);
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}


GetJobOpeningInteractionRound = async (jobProfileID) => {
    const GetListUrl = `${url}/GetJobOpeningInteractionRound/${jobProfileID}`;
    let data = null;
    try {
        data = await fetchWrapper.get(baseURL + GetListUrl)
        return this.handleResponse(data);
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

UpdateJobOpeningInteractionRound = async (jobProfileID, dataObj) => {
    const UpdateUrl = `${url}/UpdateJobOpeningInteractionRound/${jobProfileID}`
    let data = null;
    try {
        data = await fetchWrapper.put(baseURL + UpdateUrl, dataObj)
        return this.handleResponse(data);
    }

    catch (err) {
        return {
            isOk: false,
            message: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: data.status
        };
    }
}

    GetJobOpeningInteractionRoundLookupList = async (jobApplicationID) => {
        const GetListUrl = url + `/GetJobOpeningInteractionRoundLookupList/${jobApplicationID}`;
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }


}

