import {
    NumberBox,
    Popup,
    ScrollView,
    SelectBox,
    TagBox,
    TextBox,
    Validator,
} from "devextreme-react";
import React, { useEffect, useState } from "react";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import { useScreenSize } from "../../utils/media-query";
import PopupFooter from "../../layouts/popup-header-footer/PopupFooter";
import { ShowAlert, eCRUDStatus, eEmailStatus } from "../../utils/common-methods";
import {
    CustomRule,
    EmailRule,
    RequiredRule,
} from "devextreme-react/validator";
import { CandidateInteractionServices } from "../../api/services/CandidateInteractionServices";
import { TickIcon, rejectedIcon } from "../../utils/base-64-Icons";
import CircleLoader from "../../images/CircleLoader.gif";
import { JobOpeningServices } from "../../api/services/JobOpeningServices";
import { CandidateInteractionScheduleServices } from "../../api/services/CandidateInteractionScheduleServices";

const candidateInteractionServices = new CandidateInteractionServices();
const jobOpeningServices = new JobOpeningServices();
const candidateInteractionScheduleServices = new CandidateInteractionScheduleServices();

const UpdateInteractionPopup = (props) => {
    const { isExSmall, isXXSmall, isXSmall, isSmall, isMedium } = useScreenSize();

    const [dsInteractionRound, setDsInteractionRound] = useState();
    const [newRecord, setNewRecord] = useState({
        interactionNo: null,
        interactionRound: null,
        interviewers: "",
        coInterviewers: [],
    });
    const [showEmailStatusPopup, setShowEmailStatusPopup] = useState(false);
    const [candidateInteractionData, setCandidateInteractionData] = useState();

    const ValidationGroupName = "ScheduleValidationGroupName";

    useEffect(() => {
        GetInteractionRoundLookupList();
        GetInteractionModel(props?.candidateInteractionID);
    }, []);

    const GetInteractionModel = async (Id) => {
        const result = await candidateInteractionServices.GetModelData(Id);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
            } else if (result.statusCode === 409) {
                ShowAlert(result.data, "Vakency");
            }
        } else {
            const response = result.data;
            setCandidateInteractionData(response);
            setNewRecord((prevState) => ({
                ...prevState,
                interactionRound: response.interactionRoundID,
            }));
            const interviewers = response?.interviewers
                .filter((person) => person.interviewerTypeID === 0)
                .map((person) => person.interviewerEmailID);
            const coInterviewer = response?.interviewers
                .filter((person) => person.interviewerTypeID === 1)
                .map((person) => person.interviewerEmailID);
            setNewRecord((prevState) => ({
                ...prevState,
                interviewers: interviewers[0],
                coInterviewers: coInterviewer,
            }));
        }
    };

    const GetInteractionRoundLookupList = async () => {
        const result = await jobOpeningServices.GetJobOpeningInteractionRoundLookupList(props?.jobOpeningID);
        if (result.isOk) {
            setDsInteractionRound(result.data);
        }
    };

    const onClosePopup = () => {
        props.setOpenPopup(false);
    };

    function mapCoInterviewEmails(emailArray) {
        return emailArray.map((email) => {
            return {
                interviewerEmailID: email,
                interviewerTypeID: 1,
            };
        });
    }

    const HideEmailStatusPopup = () => {
        onClosePopup();
        setShowEmailStatusPopup(!showEmailStatusPopup);
    }

    const UpdateRecord = async () => {
        let coInterviewers = [];
        if (newRecord?.coInterviewers) {
            coInterviewers = mapCoInterviewEmails(newRecord?.coInterviewers);
        }
        let interactionUpdated = false;
        const existingCoInterviewers = candidateInteractionData?.interviewers.filter(interviewer => interviewer.interviewerTypeID === 1);
        const existingInterviewers = candidateInteractionData?.interviewers.filter(interviewer => interviewer.interviewerTypeID === 0);
        if (newRecord.interviewers != existingInterviewers[0].interviewerEmailID) {
            interactionUpdated = true;
        } else {
            if (existingCoInterviewers.length != coInterviewers.length) {
                interactionUpdated = true;
            } else {
                const sortedArr1 = existingCoInterviewers.slice().sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
                const sortedArr2 = coInterviewers.slice().sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
                interactionUpdated = !sortedArr1.every((obj, index) => obj.interviewerEmailID === sortedArr2[index].interviewerEmailID);
            }
        }
        if(!interactionUpdated) {
            onClosePopup();
            return;
        }
        let dataToUpdate = {
            updatedOn: candidateInteractionData?.updatedOn,
            candidateInteractionID: props?.candidateInteractionID,
            interactionRoundID: newRecord?.interactionRound,
            interviewers: [
                {
                    interviewerEmailID: newRecord.interviewers,
                    interviewerTypeID: 0,
                },
                ...coInterviewers,
            ],
        };
        const response = await candidateInteractionServices.UpdateData(
            dataToUpdate
        );
        if (!response.isOk) {
            if (response.statusCode === 400) {
                ShowAlert(response.data, "Vakency");
            }
        } else {
            setShowEmailStatusPopup(true);
            props.setStatus({
                eStatus: eCRUDStatus.Updated,
                primaryKeyID: props.candidateInteractionID,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        UpdateRecord();
    };

    const ValidateInterviewer = () => {
        if (newRecord.interviewers) {
            for (let j = 0; j < newRecord.coInterviewers.length; j++) {
                if (newRecord.interviewers == newRecord.coInterviewers[j]) {
                    return false;
                }
            }
        }
        return true;
    };

    const PopupTitle = () => {
        return (
            <>
                <PopupHeader
                    ValidationGroupName={ValidationGroupName}
                    onClosePopup={onClosePopup}
                    title={[
                        <span key={"header-title"} className="base-accent-text">
                            {" "}
                            Update
                        </span>,
                        " Interaction",
                    ]}
                    onSubmit={UpdateRecord}
                />
            </>
        );
    };

    return (
        <>
            <Popup
                visible={props.openPopup}
                titleRender={PopupTitle}
                width={
                    isExSmall || isXXSmall || isXSmall
                        ? "90%"
                        : isSmall || isMedium
                            ? "60%"
                            : "40%"
                }
                height={"auto"}
                maxHeight={"90vh"}
                wrapperAttr={{ class: "CustomPopup" }}
            >
                <form onSubmit={handleSubmit}>
                    <ScrollView>
                        <div className="row px-3">
                            <div className="col">
                                <NumberBox
                                    label="Interaction No."
                                    readOnly={true}
                                    labelMode="floating"
                                    value={candidateInteractionData?.candidateInteractionNo}
                                />
                            </div>
                            <div className="col">
                                <SelectBox
                                    id="InteractionRoundPopup_DropDown"
                                    label="Interaction Round"
                                    labelMode="floating"
                                    valueExpr={"interactionRoundID"}
                                    displayExpr={"interactionRoundName"}
                                    dataSource={dsInteractionRound}
                                    value={newRecord.interactionRound}
                                    onValueChange={(e) =>
                                        setNewRecord({ ...newRecord, interactionRound: e })
                                    }
                                    readOnly={true}
                                    dropDownOptions={{
                                        height: 160,
                                        hideOnParentScroll: true,
                                        container: "#InteractionRoundPopup_DropDown",
                                    }}
                                >
                                    <Validator
                                        validationGroup={ValidationGroupName}
                                        elementAttr={{ class: "ForValidationAstrikMark" }}
                                    >
                                        <RequiredRule message="Interaction Round is required" />
                                    </Validator>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="pt-3 px-3">
                            <TextBox
                                id="Interviewers"
                                name="Interviewers"
                                label="Interviewers Email ID"
                                labelMode="floating"
                                value={newRecord?.interviewers}
                                onValueChange={(e) =>
                                    setNewRecord({ ...newRecord, interviewers: e })
                                }
                                showDropDownButton={false}
                                openOnFieldClick={false}
                                acceptCustomValue={true}
                            >
                                <Validator
                                    validationGroup={ValidationGroupName}
                                    elementAttr={{ class: "ForValidationAstrikMark" }}
                                >
                                    <RequiredRule message="Interviewers are required" />
                                    <EmailRule message="Enter a valid Email ID" />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className="pt-3 px-3">
                            <TagBox
                                id="Co-Interviewers"
                                name="Co-Interviewers"
                                label="Co-Interviewers Email ID"
                                labelMode="floating"
                                value={newRecord.coInterviewers}
                                onValueChange={(e) =>
                                    setNewRecord((prevState) => ({
                                        ...prevState,
                                        coInterviewers: e,
                                    }))
                                }
                                showDropDownButton={false}
                                openOnFieldClick={false}
                                acceptCustomValue={true}
                                placeholder=""
                            >
                                <Validator
                                    validationGroup={ValidationGroupName}
                                >
                                    <CustomRule
                                        message="Interviewer and Co-Interviewer should not be same."
                                        validationCallback={ValidateInterviewer}
                                    />
                                    <EmailRule message="Enter a valid Email ID" />
                                </Validator>
                            </TagBox>
                        </div>
                        <PopupFooter ValidationGroupName={ValidationGroupName} />
                    </ScrollView>
                </form>
            </Popup>
            {showEmailStatusPopup && <EmailStatusPopup showEmailStatusPopup={showEmailStatusPopup} HideEmailStatusPopup={HideEmailStatusPopup} interactionScheduleID={props.scheduleData.candidateInteractionScheduleID} />}
        </>
    );
};

const EmailStatusPopup = ({
    showEmailStatusPopup,
    HideEmailStatusPopup,
    interactionScheduleID,
}) => {
    const { isExSmall, isXXSmall, isXSmall, isMedium, isSmall } = useScreenSize();
    const [dsEmail, setDsEmail] = useState({});
    const [emailSent, setEmailSent] = useState(false);

    const PopupTitle = (e) => {
        return (
            <>
                <PopupHeader
                    onClosePopup={HideEmailStatusPopup}
                    showCloseButton={"true"}
                    title={"Email"}
                    hideSaveButton={true}
                />
            </>
        );
    };

    useEffect(() => {
        let data = {};
        data.emailStatus = eEmailStatus.InProgress;
        data.emailResult = "Loading...";
        setDsEmail(data);
    }, []);

    const SendToInterviewerEmail = async () => {
        try {
            setEmailSent(true);
            const result = await candidateInteractionScheduleServices.SendEmailToInterviewer(interactionScheduleID);
            if (!result.isOk) {
                setDsEmail(prevState => ({
                    ...prevState,
                    emailStatus: eEmailStatus.Error,
                    emailResult: result.data.includes("Message dropped because all recipients were suppressed")
                        ? "Message dropped because all recipients were suppressed"
                        : result.data.includes("Value cannot be empty or contain only white-space characters. (Parameter 'address')")
                            ? "Invalid Email"
                            : result.data
                }));
            } else if (result && result.isOk) {
                setDsEmail(prevState => ({
                    ...prevState,
                    emailStatus: eEmailStatus.Success,
                    emailResult: "Email sent successfully"
                }));
            }
        } catch (error) {
            ShowAlert(error, "Vakency");
        }
    };

    useEffect(() => {
        if (dsEmail) {
            if (dsEmail.emailStatus == eEmailStatus.InProgress && !emailSent) {
                SendToInterviewerEmail();
            }
        }
    }, [dsEmail]);

    return (
        <>
            <Popup
                visible={showEmailStatusPopup}
                onHiding={HideEmailStatusPopup}
                width={
                    isXSmall || isXXSmall || isExSmall
                        ? "90%"
                        : isMedium || isSmall
                            ? "30%"
                            : "20%"
                }
                height={"auto"}
                maxHeight={"92vh"}
                titleRender={PopupTitle}
                wrapperAttr={{ class: "CustomPopup" }}
                deferRendering={false}
            >
                <div className="row m-0">
                    <div className="col text-center">
                        {
                            dsEmail?.emailStatus == eEmailStatus.InProgress && (
                                <img
                                    src={CircleLoader}
                                    alt=""
                                    title={"In progress"}
                                    className="rejected-icon-email-status"
                                />
                            )
                        }
                        {
                            dsEmail?.emailStatus == eEmailStatus.Success && (
                                <img
                                    src={TickIcon}
                                    alt=""
                                    title={"Email Sent"}
                                    className="rejected-icon-email-status"
                                />
                            )
                        }
                        {
                            dsEmail?.emailStatus == eEmailStatus.Error && (
                                <img
                                    src={rejectedIcon}
                                    alt=""
                                    title={"Email not sent"}
                                    className="rejected-icon-email-status"
                                />
                            )
                        }
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col py-2 text-center font-semiBold-18">
                        {
                            dsEmail?.emailStatus == eEmailStatus.InProgress && (
                                <>
                                    <span className="text-gray">Sending Email</span>
                                </>
                            )
                        }
                        {
                            dsEmail?.emailStatus == eEmailStatus.Success && (
                                <>
                                    {dsEmail?.emailResult}
                                </>
                            )
                        }
                        {
                            dsEmail?.emailStatus == eEmailStatus.Error && (
                                <>
                                    {dsEmail?.emailResult}
                                </>
                            )
                        }
                    </div>
                </div>
            </Popup >
        </>
    );
};

export default UpdateInteractionPopup;
