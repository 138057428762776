import React, { useEffect, useRef, useState } from 'react';
import { Button, NumberBox, Popup, ScrollView, SelectBox, TextArea, TextBox, Validator } from 'devextreme-react';
import { useScreenSize } from '../../../utils/media-query';
import { ShowAlert, onDragOver, onKeyDown } from '../../../utils/common-methods';
import './CompanyProfile.scss';
import { RequiredRule, StringLengthRule } from 'devextreme-react/data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import PopupSkeleton from '../../../layouts/templates/PopupSkeleton';
import { CompanyProfileServices } from '../../../api/services/CompanyProfileServices';
import CompanyProfile1Logo from '../../../images/companyProfile1.png';
import UserUploader from '../../../components/user-panel/UserUploader';


const companyProfileServices = new CompanyProfileServices();
export default function CompanyProfile() {

    const CompanySizeDropDown = [
        {
            Id: 0,
            Value: "Only me"
        },
        {
            Id: 1,
            Value: "2 - 9"
        },
        {
            Id: 2,
            Value: "10 - 19"
        },
        {
            Id: 3,
            Value: "20 - 49"
        },
        {
            Id: 4,
            Value: "50 - 99 "
        },
        {
            Id: 5,
            Value: "100 +"
        }
    ]

    const FocusedFeild = useRef(null);
    const { isSmall, isXSmall, isXXSmall, isExSmall } = useScreenSize();
    const currentLocation = useLocation();
    const onShown = () => FocusedFeild.current?.instance.focus();
    const ValidationGroupName = "CompanyProfileValidation";
    const [companyData, setCompanyData] = useState({});
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true)
    const [companyImage, setCompanyImage] = useState({});

    const GetImage = async () => {
        const result = await companyProfileServices.GetCompanyLogo_withHeader();
        if (result.isOk) {
            return result.data;
        } else {
            return;
        }
    }

    const GetList = async () => {
        setTimeout(async () => {
            const result = await companyProfileServices.GetListByIdData_Two()
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency")
            }
            else {
                GetImage().then((res) => {
                    setCompanyImage({
                        ...result.data,
                        uploadedFile: { fileData: (res?.size > 0 ? window.URL.createObjectURL(res) : null) },
                        attachedFile: (res?.size > 0 ? res : null)
                    })
                    setShowSkeleton(false)
                })
                setCompanyData(result.data);
            }
        }, 1000);
    }

    useEffect(() => {
        if (currentLocation.pathname === '/CompanyProfile') {
            setShowPopup(true)
        }
        GetList();
    }, [])


    useEffect(() => {
        !showSkeleton && FocusedFeild.current?.instance.focus()
    }, [showSkeleton])

    const onHiding = () => {
        if (currentLocation.pathname === '/CompanyProfile') {
            navigate('/Home')
        }
    }


    const CancelButton = () => {
        navigate('/Home')
    }

    const UploadCompanyProfile = async () => {
        var Datatoupload = { attachments: companyImage.attachedFile }
        const result = await companyProfileServices.InsertCompanyProfilePicture_withoutParams(Datatoupload);
        if (result.isOk) {

            return;
        } else {
            ShowAlert(result.data)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowSkeleton(true);
        UploadCompanyProfile();
        const result = await companyProfileServices.UpdateData_Two(companyData);
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Vakency")
            setShowSkeleton(false);
        }
        else {
            setCompanyData(result.data);
            setShowSkeleton(false)
            navigate('/Home');
        }
    }

    return (
        <>
            {showPopup &&
                <Popup
                    visible={showPopup}
                    onHiding={onHiding}
                    height={"auto"}
                    showTitle={false}
                    wrapperAttr={{ class: "CustomPopup" }}
                    deferRendering={false}
                    maxHeight={"90vh"}
                    width={(!isExSmall && !isXSmall && !isXXSmall && !isSmall) ? 850 : '90%'}
                    onShown={onShown}
                >
                    <div className='shadow rounded Company_Profile_container'>
                        <ScrollView height={"100%"} width={"100%"} >
                            <form onSubmit={handleSubmit} onDragOver={onDragOver}>
                                <div className='row'>
                                    {
                                        (isSmall || isXSmall || isXXSmall || isExSmall) &&
                                        <div className='col-md-4'>
                                            <div className={showSkeleton ? 'w-100 h-100 d-flex align-items-center text-center' : 'w-100 h-100 d-flex align-items-center text-center secoundColumn'}>
                                                <div className='m-auto p-2 w-100'>
                                                    {showSkeleton ?
                                                        <div className='d-flex justify-content-center mt-4'>
                                                            <div className='skeleton-View image-Skeleton'></div>
                                                        </div>
                                                        :
                                                        <img src={CompanyProfile1Logo} alt='Company Profile' width={210} />
                                                    }
                                                    {showSkeleton ?
                                                        <div className="skeleton-View Company-Skeleton my-2">
                                                        </div>
                                                        :
                                                        <>
                                                            <div className='py-2 font-semiBold-18 text-white text-break'>{companyData.companyName}</div>
                                                            <div className='pb-4 font-regular-14 text-white text-break'>{companyData.emailID}</div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    }
                                    <div className='col-md-8 bg-white'>
                                        {showSkeleton ?
                                            <div className="d-flex justify-content-start p-2">
                                                <span className="w-50 skeleton-toolbar-height_common rounded list-toolbar-skeleton_common"></span>
                                            </div>
                                            :
                                            <h3 className='company-title fw-semibold px-4 pt-3 pb-2'>Company</h3>
                                        }
                                        {showSkeleton ?
                                            <div className='p-2'>
                                                <PopupSkeleton />
                                                <PopupSkeleton />
                                                <PopupSkeleton />
                                                <PopupSkeleton />
                                                <PopupSkeleton />
                                                <div className='d-flex justify-content-center pb-2 pe-2'>
                                                    <div className='skeleton-View Skeleton-popup-footer'></div>
                                                </div>
                                            </div>
                                            :
                                            <div className='row px-4'>
                                                <div className='col'>
                                                    <TextBox
                                                        className=""
                                                        label="Company Name"
                                                        labelMode="floating"
                                                        maxLength={50}
                                                        ref={FocusedFeild}
                                                        value={companyData.companyName}
                                                        onValueChange={(e) =>
                                                            setCompanyData({ ...companyData, companyName: e })
                                                        }
                                                    >
                                                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                            <RequiredRule message='Company Name is required' />
                                                        </Validator>
                                                    </TextBox>
                                                    <div className='row'>
                                                        <div className='col-md pe-md-3'>
                                                            <SelectBox
                                                                className="mt-4"
                                                                label="Company Size"
                                                                id="CompanySize_DropDown"
                                                                labelMode="floating"
                                                                displayExpr="Value"
                                                                valueExpr="Id"
                                                                dataSource={CompanySizeDropDown}
                                                                value={companyData.companySize}
                                                                onValueChange={(e) =>
                                                                    setCompanyData({ ...companyData, companySize: e })
                                                                }
                                                                dropDownOptions={{
                                                                    hideOnParentScroll: true,
                                                                    container: "#CompanySize_DropDown"
                                                                }}
                                                            >
                                                                <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                                    <RequiredRule message='Company Size is required' />
                                                                </Validator>
                                                            </SelectBox>
                                                        </div>
                                                        <div className='col-md'>
                                                            <TextBox
                                                                className="mt-4"
                                                                label="Business Type"
                                                                maxLength={50}
                                                                labelMode="floating"
                                                                value={companyData.businessCategory}
                                                                onValueChange={(e) =>
                                                                    setCompanyData({ ...companyData, businessCategory: e })
                                                                }
                                                            >
                                                            </TextBox>
                                                        </div>
                                                    </div>
                                                    <NumberBox
                                                        label='Phone Number'
                                                        labelMode='floating'
                                                        className="mt-4"
                                                        format="#"
                                                        step={0}
                                                        onKeyDown={onKeyDown}
                                                        maxLength={12}
                                                        inputAttr={{ maxLength: 12 }}
                                                        value={companyData.phoneNo && parseInt(companyData.phoneNo)}
                                                        onValueChange={(value) => setCompanyData({ ...companyData, phoneNo: value })}
                                                    >
                                                        <Validator validationGroup={ValidationGroupName} >
                                                            <StringLengthRule message="Phone Number must have 10 digits" min={10} ignoreEmptyValue={true} />
                                                        </Validator>
                                                    </NumberBox>
                                                    <TextArea
                                                        className='mt-4'
                                                        label="Address"
                                                        labelMode="floating"
                                                        maxLength={100}
                                                        value={companyData.address}
                                                        onValueChange={(e) =>
                                                            setCompanyData({ ...companyData, address: e })
                                                        }
                                                    />
                                                    <TextBox
                                                        className="mt-4"
                                                        label="Country"
                                                        maxLength={50}
                                                        labelMode="floating"
                                                        value={companyData.countryName}
                                                        onValueChange={(e) => {
                                                            setCompanyData({ ...companyData, countryName: e?.replace(/\p{Emoji}/gu, '') })
                                                        }}
                                                    >
                                                        <Validator
                                                            validationGroup={ValidationGroupName}
                                                            elementAttr={{ class: "ForValidationAstrikMark" }}
                                                        >
                                                            <RequiredRule message="Country Name is required" />
                                                        </Validator>
                                                    </TextBox>
                                                    <TextBox
                                                        className="mt-4"
                                                        label="State"
                                                        maxLength={50}
                                                        labelMode="floating"
                                                        value={companyData.stateName}
                                                        onValueChange={(e) =>
                                                            setCompanyData({ ...companyData, stateName: e?.replace(/\p{Emoji}/gu, '') })
                                                        }
                                                    >
                                                        <Validator
                                                            validationGroup={ValidationGroupName}
                                                            elementAttr={{ class: "ForValidationAstrikMark" }}
                                                        >
                                                            <RequiredRule message="State Name is required" />
                                                        </Validator>
                                                    </TextBox>
                                                    <TextBox
                                                        className="mt-4"
                                                        label="City"
                                                        maxLength={50}
                                                        labelMode="floating"
                                                        value={companyData.cityName}
                                                        onValueChange={(e) =>
                                                            setCompanyData({ ...companyData, cityName: e?.replace(/\p{Emoji}/gu, '') })
                                                        }
                                                    >
                                                        <Validator
                                                            validationGroup={ValidationGroupName}
                                                            elementAttr={{ class: "ForValidationAstrikMark" }}
                                                        >
                                                            <RequiredRule message="City Name is required" />
                                                        </Validator>
                                                    </TextBox>
                                                    <div className='mt-4 pb-4 text-center justify-content-center'>
                                                        <hr />
                                                        <Button
                                                            text='Save'
                                                            stylingMode='contained'
                                                            type='default'
                                                            useSubmitBehavior={true}
                                                            validationGroup={ValidationGroupName}
                                                            className='mx-2 px-1 rounded-3 '
                                                        >
                                                        </Button>
                                                        <Button
                                                            text='Cancel'
                                                            stylingMode='contained'
                                                            type='danger'
                                                            className='mx-2 px-1 rounded-3 '
                                                            onClick={CancelButton}
                                                        >
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {
                                        (!isSmall && !isXSmall && !isXXSmall && !isExSmall) &&
                                        <div className='col-md-4'>
                                            <div className={showSkeleton ? 'w-100 h-100 d-flex align-items-center text-center' : 'w-100 h-100 d-flex align-items-center text-center secoundColumn'}>
                                                <div className='m-auto p-2 w-100'>
                                                    {showSkeleton ?
                                                        <div className='skeleton-View image-Skeleton'></div>
                                                        :
                                                        <UserUploader newRecord={companyImage} setNewRecord={setCompanyImage} />
                                                    }
                                                    {showSkeleton ?
                                                        <div className="skeleton-View Company-Skeleton my-2" />
                                                        :
                                                        <>
                                                            <div className='py-2 font-semiBold-18 text-white text-break'>{companyData.companyName}</div>
                                                            <div className='pb-4 font-regular-14 text-white text-break '>{companyData.emailID}</div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </form>
                        </ScrollView>
                    </div>
                </Popup>
            }
        </>
    )
}
