import React, { useEffect, useRef, useState } from "react";
import { Header } from "../../components";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Button as DataGridButton,
  ColumnChooser,
  FilterBuilderPopup,
  FilterPanel,
  HeaderFilter,
  Item,
  Pager,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
  Toolbar,
  LoadPanel,
  ColumnChooserSearch,
  Search,
} from "devextreme-react/data-grid";
import { useScreenSize } from "../../utils/media-query";
import { useNavigate } from "react-router-dom";
import ListSkeleton from "../../layouts/templates/ListSkeleton";
import ScheduleView from "./ScheduleView";
import { HideDatagridLoader, onDragOver } from "../../utils/common-methods";

const dsScheduleData = [
  {
    scheduleID: 2,
    scheduleNo: 1,
    candidateInteraction: "Dhawal Sharma",
    scheduleDateAndTime: new Date(),
    meetingLink: "https://www.liveagent.com/templates/zoom-meeting-invitation/",
    description: "lorem ipsome",
    meetingStatus: "Pending",
  },
  {
    scheduleID: 13,
    scheduleNo: 2,
    candidateInteraction: "Dhawal Sharma",
    scheduleDateAndTime: new Date(),
    meetingLink: "https://www.liveagent.com/templates/zoom-meeting-invitation/",
    description: "lorem ipsome",
    meetingStatus: "Pending",
  },
  {
    scheduleID: 43,
    scheduleNo: 3,
    candidateInteraction: "Dhawal Sharma",
    scheduleDateAndTime: new Date(),
    meetingLink: "https://www.liveagent.com/templates/zoom-meeting-invitation/",
    description: "lorem ipsome",
    meetingStatus: "Pending",
  },
];

const ScheduleList = () => {
  const { isExSmall, isXXSmall } = useScreenSize();
  const NavigateTo = useNavigate();
  const [showView, setShowView] = useState(false);
  const [viewData, setViewData] = useState();
  const [filterVisible, setFilterVisible] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [dsSchedule, setDsSchedule] = useState([])


  useEffect(() => {
    GetListData();
  }, [])

  const GetListData = () => {
    // setShowSkeleton(true)
    setTimeout(() => {
      setDsSchedule(dsScheduleData)
      setShowSkeleton(false)
    }, 500)
  }

  const onOptionChange = (e) => {
    if (e.fullName === "filterValue") {
      setFilterVisible(!!e.value);
    }
  };

  const onEditClick = (e) => {
    NavigateTo("/Schedule/Edit", {
      state: {
        ScheduleId: e?.data.scheduleID,
      },
    });
  };

  const onAddClick = (e) => {
    NavigateTo("/Schedule/Edit");
  };

  const handleQuickView = (e) => {
    setViewData(e.row.data);
    setShowView(!showView);
  };

  const ScheduleNo =(cellData)=>{
    return <>
    <div><span className='text-decoration-underline pointer-link' title='Edit' onClick={(e)=>onEditClick(cellData)}>{cellData.data.scheduleNo}</span></div>
    </>
  }


  const meetingLinkCellRender = (e) => {
    return (
      <><a href={e.value} target="_blank" rel="noreferrer" title="Open meeting link">{e.value}</a></>
    )
  }

  const ScheduleDataGridRef = useRef(null);

  const RowDblClick = (e) => {
    NavigateTo("/Schedule/Edit", {
      state: {
        ScheduleId: e?.data.scheduleID,
      },
    })
  }

  return (
    <>
      <Header
        title={"Schedules"}
        handleAdd={onAddClick}
        dataGridRef={ScheduleDataGridRef}
      />
      <div className="list-section" onDragOver={onDragOver}>
        {showSkeleton ? (
          <ListSkeleton />
        ) : (
          <DataGrid
            ref={ScheduleDataGridRef}
            dataSource={dsSchedule}
            showBorders={true}
            showColumnLines={false}
            showRowLines={true}
            focusedRowEnabled={true}
            wordWrapEnabled={true}
            hoverStateEnabled={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            autoNavigateToFocusedRow={true}
            keyExpr="scheduleID"
            height={"100%"}
            filterSyncEnabled={true}
            onOptionChanged={onOptionChange}
            className="List_DataGrid"
            loadPanel={HideDatagridLoader}
            width={"100%"}
            onRowDblClick={RowDblClick}
            noDataText='No Record Found'
          >
            <LoadPanel enabled={false} />
            <Sorting mode={"multiple"} />
            <FilterPanel visible={filterVisible} />
            <FilterBuilderPopup visible={false} />
            <Scrolling mode={"virtual"} />
            <ColumnChooser enabled={true}>
                <ColumnChooserSearch
                  enabled={true}
                  />
              </ColumnChooser>
              <HeaderFilter visible={true} >
                <Search
                  enabled={true}
                />
              </HeaderFilter>
            <SearchPanel
              visible={true}
              width={isExSmall || isXXSmall ? 200 : 400}
            />
            <StateStoring
              enabled={true}
              type="localStorage"
              storageKey="Schedule_Layout"
            />
            <Pager visible={true} showInfo={true} infoText={`{2} Rows`}></Pager>
            <Toolbar>
              <Item location={"after"} name="columnChooserButton" />
              <Item location={"after"} name="searchPanel" />
            </Toolbar>
            <Column type='buttons' name="quickView_button_column" fixed={true} fixedPosition={'left'} allowResizing={false} width={30} allowReordering={false}>
              <DataGridButton
                name="quick view"
                icon="QuickViewIcon"
                visible={true}
                onClick={handleQuickView}
                hint="View"
              />
            </Column>
            <Column
              caption="#"
              dataField="scheduleNo"
              alignment="left"
              width={90}
              cellRender={ScheduleNo}
              allowHiding={false}
              minWidth={70}
              allowHeaderFiltering={false}
            />
            <Column
              caption="Candidate Interaction"
              dataField="candidateInteraction"
              width={250}
              minWidth={100}
              alignment="left"
            />
            <Column
              caption="Schedule Date & Time"
              dataField="scheduleDateAndTime"
              width={200}
              minWidth={120}
              dataType="datetime"
              alignment="left"
            />
            <Column
              caption="Meeting Link"
              dataField="meetingLink"
              minWidth={140}
              alignment="left"
              cellRender={meetingLinkCellRender}
            />
            <Column
              caption="Description"
              dataField="description"
              minWidth={140}
              alignment="left"
            />
            <Column
              caption="Meeting Status"
              dataField="meetingStatus"
              width={160}
              minWidth={80}
              alignment="left"
            />
            <Column
              dataField="createdByUserName"
              caption="Created By"
              visible={false}
              width={125}
              minWidth={80}
              alignment="left"
            />

            <Column
              dataField="updatedByUserName"
              caption="Updated By"
              visible={false}
              width={125}
              minWidth={80}
              alignment="left"
            />
            <Column
              dataField="createdOn"
              caption="Created On"
              visible={false}
              width={135}
              minWidth={80}
              alignment="left"
            />
            <Column
              dataField="updatedOn"
              caption="Updated On"
              visible={false}
              width={137}
              minWidth={80}
              alignment="left"
            />
            <Column type='buttons' name="delete_button_column" fixed={false}  allowResizing={false} width={43} allowReordering={false}>
              <DataGridButton
                name='delete'
                icon='trash'
                hint='Delete'
                visible={true}
                cssClass='text-danger'
              />
            </Column>
          </DataGrid>
        )}
      </div>
      <ScheduleView
        showView={showView}
        setShowView={setShowView}
        viewData={viewData}
      />
    </>
  );
};

export default ScheduleList;
