import { Button, DateBox, NumberBox, Popup, ScrollView, Validator } from "devextreme-react";
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import { Button as NumberBoxButton } from 'devextreme-react/number-box';
import React, { useEffect, useRef, useState } from "react";
import { onKeyDown_Withpoint, ShowAlert } from "../../utils/common-methods";
import { useScreenSize } from "../../utils/media-query";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import { RequiredRule } from "devextreme-react/validator";
import { JobOpeningControlPanelServices } from "../../api/services/JobOpeningControlPanelServices";
import CandidateCard from "./CandidateCard";
import JobApplicationUploadDoc from "./JobApplicationUploadDoc";
import { confirm } from "devextreme/ui/dialog";

const htmlEditorSecondAttributes = {
  class: "noteSecondHtml",
};
const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];

const jobOpeningControlPanelServices = new JobOpeningControlPanelServices();

const FinalizeHiringPopup = (props) => {
  const ValidationGroupName = "statusPopupValidation"
  const [newRecord, setNewRecord] = useState({});
  const { isExSmall, isXXSmall, isXSmall, isMedium, isSmall, isExLarge , isLarge} = useScreenSize();
  let [candidateDocDs, setCandidateDocDs] = useState([]);
  const [offerDetails, setOfferDetails] = useState();
  const [finalizedCTCMonthlyValue, setFinalizedCTCMonthlyValue] = useState();
  const [offeredCTCMonthlyValue, setOfferedCTCMonthlyValue] = useState();

  const htmlEditorNoteRef = useRef(null);

  useEffect(() => {
    if (props?.jobApplicationID) {
      GetOfferDetails()
    }
  }, [])

  const GetOfferDetails = async () => {
    const result = await jobOpeningControlPanelServices.GetOfferDetails(
      props?.jobApplicationID
    );
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      setOfferDetails(result.data);
      if (result.data?.offeredCTCPA != null) {
        setOfferedCTCMonthlyValue((result.data?.offeredCTCPA * 100000) / 12);
      }
    }
  };

  const onClosePopup = () => {
    props.HideAssessmentPopup();
  };

  const PopupTitle = (e) => {
    return (
      <>
        <PopupHeader
          onClosePopup={onClosePopup}
          showCloseButton={"true"}
          title={"Finalize Hiring"}
          hideSaveButton={true}
        />
      </>
    );
  };

  const formatDate = (date, includeTime = false) => {
    if (!date) return null;

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    if (includeTime) {
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return `${year}-${month}-${day}`;
  };

  const onSubmit = async () => {
    const dataToUpdate = {
      jobApplicationID: props?.jobApplicationID,
      onboardDate: formatDate(new Date(newRecord?.finalJoiningDate)),
    };

    if (props?.jobApplicationUpdatedOn !== null && props?.jobApplicationUpdatedOn !== undefined) {
      dataToUpdate.updatedOn = props?.jobApplicationUpdatedOn;
    }

    try {
      const result = await jobOpeningControlPanelServices.FinalizeHiring(dataToUpdate);
      if (result.isOk) {
        props?.GetApplication();
        props?.GetHeader();
        props.HideAssessmentPopup();
      } else {
        ShowAlert(result.data, "Vakency");
      }
    } catch (error) {
      ShowAlert("An error occurred while processing the action.", "Vakency");
    }

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isDocPending = candidateDocDs.some(doc => !doc.isExist);
    if (isDocPending) {

      let res = confirm(
        `
    <div class="align-items-center" style="width:${(isExLarge || isLarge) ? '30vw' : isMedium ? '40vw' : '85vw'}">
    <div class="row align-items-center"><span class="alertText col">${"The following files are missing:"}</span></div>
    <div class="d-flex flex-wrap justify-content-between align-items-center">
      ${candidateDocDs.map(doc => {
          if (!doc.isExist) {
            return `
            <div class="my-1 col-12 col-md-6">
              <i class="dx-icon-crossIcon dx-custom-icon col-auto pe-0"></i>
              <span class="alertText col">${doc.docName}</span>
            </div>`;
          } else {
            return '';
          }
        }).join('')}
      </div>
      <hr>
      <div class="row align-items-center font-semiBold-16"><span class="alertText col">${"Do you want to submit?"}</span></div>
    </div>
  `,
        "File Summary"
      );
      res.then((dialogResult) => (dialogResult ? onSubmit() : ""));
    } else {
      onSubmit();
    }
  }

  return (
    <>
      <Popup
        visible={props.showAssessmentPopup}
        onHiding={props.HideAssessmentPopup}
        width={
          isXSmall || isXXSmall || isExSmall
            ? "95vw"
            : isMedium || isSmall
              ? "70vw"
              : "50vw"
        }
        height={"auto"}
        maxHeight={"92vh"}
        titleRender={PopupTitle}
        wrapperAttr={{ class: "CustomPopup" }}
        deferRendering={false}
      >
        <ScrollView width={"100%"} height={"100%"}>
          <form onSubmit={handleSubmit}>
            <div>
              <CandidateCard
                candidateRating={props?.candidateRating}
                candidateApplicationStatus={props?.candidateApplicationStatus}
                candidateId={props?.candidateId}
                jobProfileName={props?.jobProfileName}
                PopupType={"Finalize Hiring"}
                lastRoundScheduleDate={props.lastRoundScheduleDate}
                dsInteractionRounds={props.dsInteractionRounds}
                lastInteractionRoundName={props.lastInteractionRoundName}
              />
            </div>


            <div className="row m-0">
              <div className="col-xxl col-xl col-lg">
                <div className="row">
                  <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto pt-2 mt-1 editor-label">
                    Finalized CTC:
                  </div>
                  <div className="col">
                    <NumberBox
                      label="Yearly"
                      labelMode="floating"
                      maxLength={15}
                      format="##.##"
                      value={newRecord?.finalizedCTCPA && newRecord?.finalizedCTCPA.toFixed(2)}
                      onValueChange={(e) => {
                        setNewRecord({ ...newRecord, finalizedCTCPA: e })
                        setFinalizedCTCMonthlyValue((e * 100000) / 12)
                      }}
                      onKeyDown={onKeyDown_Withpoint}
                      className="number-box-btn"
                    >
                      <NumberBoxButton
                        location="after"
                        name="finalized_ctc_yearly"
                        options={{
                          text: 'LPA',
                          stylingMode: 'text',
                          hoverStateEnabled: false,
                          focusStateEnabled: false,
                          activeStateEnabled: false,
                        }}
                      />
                      <Validator
                        validationGroup={ValidationGroupName}
                        elementAttr={{ class: "ForValidationAstrikMark" }}
                      >
                        <RequiredRule message="Finalized CTC is required" />
                      </Validator>
                    </NumberBox>
                  </div>
                  <div className="col-auto px-0 pt-3 text-gray font-regular-12">OR</div>
                  <div className="col">
                    <NumberBox
                      label="Monthly"
                      labelMode="floating"
                      value={finalizedCTCMonthlyValue && finalizedCTCMonthlyValue.toFixed(2)}
                      maxLength={15}
                      format="##.##"
                      onValueChange={(e) => {
                        setFinalizedCTCMonthlyValue(e);
                        setNewRecord({ ...newRecord, finalizedCTCPA: ((e * 12) / 100000) })
                      }}
                      onKeyDown={onKeyDown_Withpoint}
                      className="number-box-btn"
                    >
                      <NumberBoxButton
                        location="after"
                        name="finalized_ctc_monthly"
                        options={{
                          text: 'per month',
                          stylingMode: 'text',
                          hoverStateEnabled: false,
                          focusStateEnabled: false,
                          activeStateEnabled: false,
                        }}
                      />
                      <Validator
                        validationGroup={ValidationGroupName}
                        elementAttr={{ class: "ForValidationAstrikMark" }}
                      >
                        <RequiredRule message="Finalized CTC is required" />
                      </Validator>
                    </NumberBox>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-0 my-2">
              <div className="col-xxl col-xl col-lg">
                <div className="row">
                  <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto pt-2 mt-1 editor-label">
                    Offered CTC:
                  </div>
                  <div className="col">
                    <NumberBox
                      label="Yearly"
                      labelMode="floating"
                      maxLength={15}
                      format="##.##"
                      value={offerDetails?.offeredCTCPA && offerDetails?.offeredCTCPA.toFixed(2)}
                      readOnly={true}
                      className="number-box-btn"
                    >
                      <NumberBoxButton
                        location="after"
                        name="offered_ctc_yearly"
                        options={{
                          text: 'LPA',
                          stylingMode: 'text',
                          hoverStateEnabled: false,
                          focusStateEnabled: false,
                          activeStateEnabled: false,
                        }}
                      />
                    </NumberBox>
                  </div>
                  <div className="col-auto px-0 pt-3 text-gray font-regular-12">OR</div>
                  <div className="col">
                    <NumberBox
                      label="Monthly"
                      labelMode="floating"
                      value={offeredCTCMonthlyValue && offeredCTCMonthlyValue.toFixed(2)}
                      maxLength={15}
                      format="##.##"
                      readOnly={true}
                      className="number-box-btn"
                    >
                      <NumberBoxButton
                        location="after"
                        name="accepted_ctc_monthly"
                        options={{
                          text: 'per month',
                          stylingMode: 'text',
                          hoverStateEnabled: false,
                          focusStateEnabled: false,
                          activeStateEnabled: false,
                        }}
                      />
                    </NumberBox>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-xxl col-xl col-lg col-md mt-md-0">
                <DateBox
                  id="JobOpening_FinalJoiningDate"
                  labelMode="floating"
                  label="Final Joining Date"
                  value={newRecord?.finalJoiningDate ? new Date(newRecord?.finalJoiningDate) : null}
                  onValueChange={(e) => setNewRecord({ ...newRecord, finalJoiningDate: e })}
                  type="date"
                  useMaskBehavior={true}
                  // min={minDate}
                  dropDownOptions={{
                    hideOnParentScroll: true,
                    container: "#JobOpening_FinalJoiningDate"
                  }}
                  dateOutOfRangeMessage="Invalid Date"
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Final Joining Date is required" />
                  </Validator>
                </DateBox>
              </div>
            </div>

            <div className="row mt-3">
              <div className="assessment-html-editor-section px-4">
                <HtmlEditor
                  ref={htmlEditorNoteRef}
                  id={"JobDescribtionHtmlEditor"}
                  elementAttr={htmlEditorSecondAttributes}
                  valueType="html"
                  width={"auto"}
                  height={"100px"}
                  className="CustomizeHtmlEditor"
                  placeholder="Description/Notes"
                >
                  <HtmlEditorToolbar multiline={false}>
                    <HtmlEditorItem name="link"></HtmlEditorItem>
                    <HtmlEditorItem
                      name="font"
                      acceptedValues={fontValues}
                    />
                    <HtmlEditorItem
                      name="size"
                      acceptedValues={sizeValues}
                    />
                    <HtmlEditorItem name="bold" />
                    <HtmlEditorItem name="italic" />
                    <HtmlEditorItem name="underline" />
                    <HtmlEditorItem name="background" />
                    <HtmlEditorItem name="color" />
                    <HtmlEditorItem name="orderedList" />
                    <HtmlEditorItem name="bulletList" />
                    <HtmlEditorItem name="clear" />
                  </HtmlEditorToolbar>
                </HtmlEditor>
              </div>
            </div>

            <JobApplicationUploadDoc
              height={'298px'}
              CandidateID={props?.candidateId}
              candidateDocDs={candidateDocDs}
            />
            <div className="d-flex justify-content-end py-2 pe-3">
              <Button
                validationGroup={ValidationGroupName}
                text="Submit"
                type="default"
                height={33}
                width={120}
                className="rounded-3"
                useSubmitBehavior={true}
              />
            </div>

          </form>
        </ScrollView>
      </Popup>
    </>
  );
};

export default FinalizeHiringPopup;
