import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { JobProfileControlName } from "../../contexts/APIurl";
const url = JobProfileControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class JobProfileServices extends VakencyApiServices {
    constructor() {
        super(url);
    }

  CheckDuplicateData= async(params)=> {
        let data = null;
        const noResponseData = true;
        try {
            const CheckUrl = `${url}/CheckDuplicateName?${params}`;
            data = await fetchWrapper.get(baseURL + CheckUrl)
            return this.handleResponse(data, noResponseData);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }
 GetSkillLookupListData=async(SkillControlName)=>{
        const GetListUrl = SkillControlName + "/GetLookupList/"
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

GetSkills_JobProfilePage=async(id)=>{
        const GetListUrl = url + `/GetSkills/${id}`

        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GetJobProfileInteractionRound = async (jobProfileID) => {
        const GetListUrl = `${url}/GetJobProfileInteractionRound/${jobProfileID}`;
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    UpdateJobProfileInteractionRound = async (jobProfileID, dataObj) => {
        const UpdateUrl = `${url}/UpdateJobProfileInteractionRound/${jobProfileID}`
        let data = null;
        try {
            data = await fetchWrapper.put(baseURL + UpdateUrl, dataObj)
            return this.handleResponse(data);
        }

        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

}
