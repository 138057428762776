import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavInnerToolbar as SideNavBarLayout } from './layouts';

export default function Content() {

  return (
    <Routes>
      {routes.map(({ path, element, showSideNavBar }) => (
        <Route
          key={path}
          path={path}
          element={
            showSideNavBar ? (
              <SideNavBarLayout title={appInfo.title}>
                {element}
              </SideNavBarLayout>
            ) : (
              element
            )
          }
        />
      ))}
      <Route
        path='*'
        element={<Navigate to='/Home' />}
      />
    </Routes>
  );
}

