import React, { useEffect, useRef, useState } from 'react'
import { Header } from '../../../components';
import { DataGrid } from 'devextreme-react';
import { Column, Button as DataGridButton, ColumnChooser, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, Pager, Scrolling, SearchPanel, Sorting, StateStoring, Toolbar, ColumnChooserSearch, Search } from 'devextreme-react/data-grid';
import { useScreenSize } from '../../../utils/media-query';
import ListSkeleton from '../../../layouts/templates/ListSkeleton';
import { GetFormattedDateTimeUTCString, HideDatagridLoader, ShowAlert, eCRUDStatus, onDragOver } from '../../../utils/common-methods';
import InteractionRoundPopup from './InteractionRoundPopup';
import { confirm } from 'devextreme/ui/dialog';
import { InteractionRoundServices } from '../../../api/services/InteractionRoundServices';

const interactionRoundServices = new InteractionRoundServices();
export default function InteractionRoundList() {


  const { isExSmall, isXXSmall } = useScreenSize();

  const [filterVisible, setFilterVisible] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true)
  const [dsInteraction, setDsInteraction] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [primaryKey, setPrimaryKey] = useState(null)
  const [focusedRowKey, setfocusedRowKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });

  const GetList = async () => {
    // setShowSkeleton(true)
    const result = await interactionRoundServices.GetListData();
    if (result.statusCode === 400 || result.statusCode === 409) {
      ShowAlert(result.data, "Vakency")
      setShowSkeleton(false);
    }
    else {
      const InteractionRoundData = result?.data?.map(item => {
        const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
        const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
        return {
          ...item,
          createdOn: createdOn ? createdOn: null,
          updatedOn: updatedOn ? updatedOn : null
        };
      });
      setDsInteraction(InteractionRoundData);
      setShowSkeleton(false);
    }
  }

  useEffect(() => {
    GetList();
  }, [])

  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID = status.eStatus === eCRUDStatus.Inserted ? status.primaryKeyID : primaryKey;
      UpdateListByID(PrimaryKeyID);
    }
  }, [status])

  const UpdateListByID = async (primaryKey) => {
      const result = await interactionRoundServices.GetListByIdData(primaryKey);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency")
    } else {
      const InteractionRoundData = result.data?.map(item => {
        const createdOn = item.createdOn ? new Date(new Date(item.createdOn + "Z").toUTCString()).toLocaleString(): null;
        const updatedOn = item.updatedOn ?new Date(new Date(item.updatedOn + "Z").toUTCString()).toLocaleString() : null;
        return {
          ...item,
          createdOn: createdOn ? createdOn: null,
          updatedOn: updatedOn ? updatedOn : null
        };
      });
      if (status.eStatus === eCRUDStatus.Inserted) {
        setDsInteraction([InteractionRoundData[0], ...dsInteraction]);
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setDsInteraction(dsInteraction.map((i) => i.interactionRoundID === primaryKey ? InteractionRoundData[0] : i));
      }
    }
    setfocusedRowKey(primaryKey);
    setPrimaryKey(0);
  };

  const onOptionChange = (e) => {
    if (e.fullName === "filterValue") {
      setFilterVisible(!!e.value);
    }
  };
  const onEditClick = (e) => {
    setPrimaryKey(e.data.interactionRoundID)
    setShowPopup(true)
  }

  const handleAdd = (e) => {
    setPrimaryKey(null)
    setShowPopup(true)
  }

  const DataGridRef = useRef(null);

  const onRowDbClick = (e) =>{
    setPrimaryKey(e.data.interactionRoundID)
    setShowPopup(true)
  }

  const InteractionRoundName=(cellData)=>{
    return <>
    <div><span className='text-decoration-underline pointer-link'  title='Edit' onClick={(e)=>onEditClick(cellData)}>{cellData.data.interactionRoundName}</span></div>
    </>
  }

  const onDeleteButtonClick = (e) => {
    let res = confirm(
      `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
      "Vakency"
    );
    res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
  };

  const handleDelete = async (e) => {
    const Id = e.row.data.interactionRoundID;
    const result = await interactionRoundServices.DeleteData(Id);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency")
    }
    else if (result.statusCode === 200) {
      setDsInteraction(dsInteraction.filter((x) => x.interactionRoundID !== e.row.data.interactionRoundID));
    }
  }

  
  function onFocusedRowChanged(e) {
    setfocusedRowKey(e.component.option("focusedRowKey"));
  }


  return (
    <>
      <Header
        title={"Interaction Rounds"}
        handleAdd={handleAdd}
        dataGridRef={DataGridRef}
        GetRecord={GetList}
      />
      <div className='list-section' onDragOver={onDragOver}>
        {
          showSkeleton ?
            <ListSkeleton />
            :
            <DataGrid
              ref={DataGridRef}
              dataSource={dsInteraction}
              showBorders={true}
              showColumnLines={false}
              showRowLines={true}
              focusedRowEnabled={true}
              wordWrapEnabled={true}
              hoverStateEnabled={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              autoNavigateToFocusedRow={true}
              keyExpr="interactionRoundID"
              height={'100%'}
              width={"100%"}
              filterSyncEnabled={true}
              onOptionChanged={onOptionChange}
              focusedRowKey={focusedRowKey}
              className='List_DataGrid'
              loadPanel={HideDatagridLoader}
              onRowDblClick={onRowDbClick}
              noDataText='No Record Found'
              onFocusedRowChanged={onFocusedRowChanged}
            >
              <Sorting mode={"multiple"} />
              <FilterPanel visible={filterVisible} />
              <FilterBuilderPopup visible={false} />
              <Scrolling mode={"infinite"} preloadEnabled={true} useNative={true}/>
              <ColumnChooser enabled={true}>
                  <ColumnChooserSearch
                    enabled={true}
                  />
                </ColumnChooser>
                <HeaderFilter visible={true} >
                  <Search
                    enabled={true}
                  />
                </HeaderFilter>
              <SearchPanel visible={true} width={(isExSmall || isXXSmall) ? 200 : 400} />
              <StateStoring enabled={true} type="localStorage" storageKey="Interaction_Round_Layout" />
              <Pager
                visible={true}
                showInfo={true}
                infoText={`{2} Rows`}
              ></Pager>
              <Toolbar>
                <Item location={'after'} name="columnChooserButton" />
                <Item location={'after'} name="searchPanel" />
              </Toolbar>
              <Column
                caption='Interaction Round'
                dataField='interactionRoundName'
                alignment='left'
                width={250}
                minWidth={180}
                cellRender={InteractionRoundName}
                allowHiding={false}
              />
              <Column
                caption='Order'
                dataField='sortOrder'
                minWidth={90}
                width={150}
                alignment='left'
              />
              <Column
                caption='Description'
                dataField='assessmentTemplatePlainText'
                minWidth={90}
                alignment='left'
              />
              <Column
                dataField="createdByUserName"
                caption="Created By"
                visible={false}
                width={125}
                minWidth={80}
                alignment="left"
              />

              <Column
                dataField="updatedByUserName"
                caption="Updated By"
                visible={false}
                width={125}
                minWidth={80}
                alignment="left"
              />
              <Column
                dataField="createdOn"
                caption="Created On"
                visible={false}
                width={135}
                minWidth={80}
                alignment="left"
   
              />
              <Column
                dataField="updatedOn"
                caption="Updated On"
                visible={false}
                width={137}
                minWidth={80}
                alignment="left"
             
              />
              <Column type='buttons' fixed={false} allowResizing={false} width={43} allowReordering={false}>
              <DataGridButton
                name='delete'
                icon='trash'
                hint='Delete'
                visible={true}
                cssClass='text-danger'
                onClick={onDeleteButtonClick}
              />
            </Column>
            </DataGrid>
        }
      </div>
     
      {showPopup === true &&
        <InteractionRoundPopup
          openPopup={showPopup}
          setOpenPopup={setShowPopup}
          primaryKey={primaryKey}
          setStatus={setStatus}
        />
      }
    </>
  )
}
