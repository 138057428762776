import React, { useRef, useState } from "react";
import { SelectBox, TagBox, TextBox } from "devextreme-react";
import { Header } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import Editor from "../../layouts/templates/Editor";
import DataGrid, { Column, Editing } from "devextreme-react/data-grid";
import { useEffect } from "react";
import CandidateDropDown from "../candidate/CandidateDropdown";
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import './Interaction.scss';
import { onDragOver } from "../../utils/common-methods";

const dsTechSkills = [
  {
    techSkillId: 1,
    description: "OOps",
    rating: "0 to 5",
    experience: {
      month: null,
      year: null
    },
    obervation: "good",
  },
  {
    techSkillId: 2,
    description: "C# .Net",
    rating: "0 to 5",
    experience: {
      month: null,
      year: null
    },
    obervation: "good",
  },
  {
    techSkillId: 3,
    description: "React",
    rating: "0 to 5",
    experience: {
      month: null,
      year: null
    },
    obervation: "good",
  },
  {
    techSkillId: 4,
    description: "Node js",
    rating: "0 to 5",
    experience: {
      month: null,
      year: null
    },
    obervation: "good",
  },
  {
    techSkillId: 5,
    description: "Next js",
    rating: "0 to 5",
    experience: {
      month: 4,
      year: 2
    },
    obervation: "good",
  },
];

export default function InteractionEditor() {
  const { state } = useLocation();

  const { InteractionID } = state || {};

  const navigate = useNavigate();

  const ValidationGroupName = "InteractionValidation";

  const newRecordData = {
    interactionNo: null,
    candidate: null,
    jobApplicationNo: null,
    jobProfile: null,
    interactionRound: null,
    interviewers: null,
    assessmentNotes: null,
    attitude: null,
    result: null,
  };

  const [newRecord, setNewRecord] = useState(newRecordData);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const OnCancelClick = () => {
    navigate("/Interaction/List");
  };

  useEffect(() => {
    !InteractionID && navigate(`/Interaction/New`);
  }, [InteractionID, navigate]);

  const interviewers = [
    "Aman Verma",
    "Dhawal Sharma",
    "Akash Verma",
    "Mukesh Sharma",
  ];

  const htmlEditorSecondAttributes = {
    class: "noteSecondHtml",
  };

  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
  const fontValues = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];

  const htmlEditorNoteRef = useRef(null);

  const dsResult = ['Pending', 'Passed', 'Failed', 'Candidate did not attend']
  const dsMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const dsYears = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

  const ExperienceCellRender = (e) => {
    return (
      <>
        <div className="row">
          <div className="col pe-0">
            <SelectBox
              items={dsMonths}
              stylingMode="underlined"
              label="months"
              labelMode="floating"
              className="border-0"
              dropDownOptions={{
                height: 200
              }}
              value={e.data.experience.month}
            />
          </div>
          <div className="col">
            <SelectBox
              items={dsYears}
              label="Years"
              labelMode="floating"
              stylingMode="underlined"
              className="border-0"
              dropDownOptions={{
                height: 200
              }}
              value={e.data.experience.year}
            />
          </div>
        </div>

      </>
    )
  }

  return (
    <div>
      <div >
        <Header
          title={[<span key={"header_title"} className="base-accent-text">{InteractionID ? "Edit" : "New"}</span>, " Interaction"]}
          hideDefaultButtons={true}
          validationGroupName={ValidationGroupName}
          onBackButtonClick={OnCancelClick}
          submitEditor={true}
        />
        <Editor
          editorTitle={`${InteractionID ? "Edit" : "New"}`}
          OnCancelClick={OnCancelClick}
          ValidationGroupName={ValidationGroupName}
        >
          <form onSubmit={handleSubmit} className="px-3 pt-2" onDragOver={onDragOver}>
            <div className="row">
              <div className="col-xxl col-xl col-lg col-md">
                <TextBox
                  label="Interaction No."
                  labelMode="floating"
                  value={newRecordData.interactionNo}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, interactionNo: e })
                  }
                  readOnly={true}
                />
              </div>
              <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-3">
                <CandidateDropDown
                  value={newRecordData.candidate}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, candidate: e })
                  }
                  validationRequired={true}
                  validationGroup={ValidationGroupName}
                  dropdownHeight={180}
                />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-xxl col-xl col-lg col-md">
                <TextBox
                  label="Job Appliation No."
                  labelMode="floating"
                  value={newRecordData.interactionNo}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, interactionNo: e })
                  }
                  readOnly={true}
                />
              </div>
              <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-3">
                <TextBox
                  label="Job Profile"
                  labelMode="floating"
                  value={newRecordData.jobProfile}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, jobProfile: e })
                  }
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-xxl col-xl col-lg col-md py-1">
                <SelectBox
                  label="Interaction Round"
                  labelMode="floating"
                  id="InteractionRound_DropDown"
                  value={newRecordData.interactionNo}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, interactionNo: e })
                  }
                  dropDownOptions={{
                    height: 180,
                    hideOnParentScroll: true,
                    container:"#InteractionRound_DropDown"
                  }}
                />
                <div className="pt-3">
                  <TagBox
                    id="Interviewers_DropDown"
                    label="Interviewers"
                    labelMode="floating"
                    value={newRecordData.jobProfile}
                    onValueChange={(e) =>
                      setNewRecord({ ...newRecord, jobProfile: e })
                    }
                    items={interviewers}
                    // showSelectionControls={true}
                    dropDownOptions={{
                      height: 180,
                      hideOnParentScroll: true,
                      container:"#Interviewers_DropDown"
                    }}
                  />
                </div>
                <div className="pt-3">
                  <TagBox
                    label="Attitude"
                    id="Attitude_TagBox_DropDown"
                    labelMode="floating"
                    value={newRecordData.jobProfile}
                    onValueChange={(e) =>
                      setNewRecord({ ...newRecord, jobProfile: e })
                    }
                    dropDownOptions={{
                      height: 180,
                      hideOnParentScroll: true,
                      container: "#Attitude_TagBox_DropDown"
                    }}
                  />
                </div>
              </div>
              <div className="col-xxl col-xl col-lg col-md pt-3 pt-md-0">
                <div className="editor-label">Assessment notes</div>
                <HtmlEditor
                  ref={htmlEditorNoteRef}
                  id={"JobDescribtionHtmlEditor"}
                  elementAttr={htmlEditorSecondAttributes}
                  valueType="html"
                  height={155}
                  className="CustomizeHtmlEditor"
                  tabIndex={-1}
                // defaultValue={}
                // onValueChanged={onValueChanged}
                >
                  <HtmlEditorToolbar multiline={false}>
                    <HtmlEditorItem name="link"></HtmlEditorItem>
                    <HtmlEditorItem name="font" acceptedValues={fontValues} />
                    <HtmlEditorItem name="size" acceptedValues={sizeValues} />
                    <HtmlEditorItem name="bold" />
                    <HtmlEditorItem name="italic" />
                    <HtmlEditorItem name="underline" />
                    <HtmlEditorItem name="background" />
                    <HtmlEditorItem name="color" />
                    <HtmlEditorItem name="orderedList" />
                    <HtmlEditorItem name="bulletList" />
                    <HtmlEditorItem name="clear" />
                  </HtmlEditorToolbar>
                </HtmlEditor>
              </div>
            </div>
            <div className="mt-4">
              <div className="editor-sub-header pb-2">
                Technical Skills Assessment{" "}
              </div>
              <DataGrid
                dataSource={dsTechSkills}
                keyExpr={"techSkillId"}
                showBorders={true}
                className="TechSkillDatagrid"
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                wordWrapEnabled={true}
              >
                <Editing
                  mode="cell"
                  allowUpdating={true}
                />
                <Column caption="Description" dataField="description" width={350} minWidth={100} allowEditing={false} />
                <Column caption="Rating" dataField="rating" width={120} minWidth={80} allowEditing={false} />
                <Column caption="Experience " dataField="Experence" width={300} minWidth={100} cssClass="experience-column" cellRender={ExperienceCellRender} allowEditing={false} />
                <Column caption="Obervation" dataField="obervation" minWidth={100} />

              </DataGrid>
            </div>
            <div className="row pt-3">
              <div className="col-xxl col-xl col-lg col-md py-1">
                <SelectBox
                  id="Result_DropDown"
                  items={dsResult}
                  label="Result"
                  labelMode="floating"
                  dropDownOptions={{
                    hideOnParentScroll: true,
                    container: "#Result_DropDown"
                  }}
                />
              </div>
              <div className="col-xxl col-xl col-lg col-md py-1"></div>
            </div>
          </form>
        </Editor>
      </div>
    </div>
  );
}
