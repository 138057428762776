import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";

const url = `CityManagement/CityState`;
const baseURL = process.env.REACT_APP_BASEURL;

export class StateServices extends VakencyApiServices {
  constructor() {
    super(url);
  }

//   Function to check duplicate state name
  CheckDuplicateStateName = async (params) => {
    const noResponseData = true;
    let data = null;
    try {
      const CheckUrl = `${url}/CheckDuplicateStateName?${params}`;
      data = await fetchWrapper.get(baseURL + CheckUrl);
      return this.handleResponse(data, noResponseData);
    } catch {
      return {
        isOk: false,
        message: "Failed To Load Data",
        statusCode: data?.status,
      };
    }
  };

//   Function to check duplicate short city name
  CheckDuplicateShortName = async (params) => {
    const noResponseData = true;
    let data = null;
    try {
      const CheckUrl = `${url}/CheckDuplicateShortName?${params}`;
      data = await fetchWrapper.get(baseURL + CheckUrl);
      return this.handleResponse(data, noResponseData);
    } catch {
      return {
        isOk: false,
        message: "Failed To Load Data",
        statusCode: data?.status,
      };
    }
  };
}
