import React from 'react'

const PopupSkeletonOneLine = () => {
  return (
    <>
    <div className="">
      <span className="custom-skeleton-two "></span>
    </div>
  </>
  )
}

export default PopupSkeletonOneLine
