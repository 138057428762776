import React from "react";
import ViewMode from "../../layouts/templates/ViewMode";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import { useScreenSize } from "../../utils/media-query";

const dsTechSkills = [
  {
    techSkillId: 1,
    description: "OOps",
    rating: "0 to 5",
    experience: {
      month: 7,
      year: 1,
    },
    obervation: "good",
  },
  {
    techSkillId: 2,
    description: "C# .Net",
    rating: "0 to 5",
    experience: {
      month: 6,
      year: 1,
    },
    obervation: "good",
  },
  {
    techSkillId: 3,
    description: "React",
    rating: "0 to 5",
    experience: {
      month: 4,
      year: 1,
    },
    obervation: "good",
  },
  {
    techSkillId: 4,
    description: "Node js",
    rating: "0 to 5",
    experience: {
      month: 3,
      year: 2,
    },
    obervation: "good",
  },
  {
    techSkillId: 5,
    description: "Next js",
    rating: "0 to 5",
    experience: {
      month: 4,
      year: 2,
    },
    obervation: "good",
  },
];

const InteractionView = (props) => {
  const { isSmall, isXSmall, isXXSmall, isExSmall } = useScreenSize();
  const navigate = useNavigate();

  const viewData = props.viewData;

  const handleEdit = () => {
    navigate("/Interaction/Edit", {
      state: {InteractionID : viewData?.interactionID},
    });
  };

  const ExperienceCellRender = (e) => {
    return (
      e.data.experience.month + " months " + e.data.experience.year + " years"
    );
  };

  return (
    <>
      <ViewMode
        showView={props.showView}
        setShowView={props.setShowView}
        title={"Interaction"}
        handleEdit={handleEdit}
      >
        <div className="row">
          <div className="col">
          <div className="view-text-value">
              {viewData?.interactionNo}
            </div>
            <div className="view-label">Interaction No.</div>
           
          </div>
          <div className="col">
          <div className="view-text-value">{viewData?.candidate}</div>
            <div className="view-label">Candidate</div>
           
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
          <div className="view-text-value">
              {viewData?.jobAppliationNo}
            </div>
            <div className="view-label">Job Appliation No.</div>
          
          </div>
          <div className="col">
          <div className="view-text-value">{viewData?.jobProfile}</div>
            <div className="view-label">Job Profile</div>
           
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
          <div className="view-text-value">
              {viewData?.interactionRound}
            </div>
            <div className="view-label">Interaction Round</div>
           
          </div>
          <div className="col">
          <div className="view-text-value">
              {viewData?.interviewers}
            </div>
            <div className="view-label">Interviewers</div>
           
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
          <div className="view-text-value">{viewData?.assessment}</div>
            <div className="view-label">Assessment</div>
          
          </div>
          <div className="col">
          <div className="view-text-value">{viewData?.attitude}</div>
            <div className="view-label">Attitude</div>
            
          </div>
        </div>

        <div className="mt-4 ">
          <div className="view-label  pb-2">
            Technical Skills Assessment Information
          </div>
        </div>

        <DataGrid
          dataSource={dsTechSkills}
          keyExpr={"techSkillId"}
          showBorders={true}
          className="TechSkillDatagrid"
          showRowLines={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          wordWrapEnabled={true}
          width={isSmall || isXSmall || isXXSmall ? 320 : isExSmall ? 270 : 550}
        >
          <Column
            caption="Description"
            dataField="description"
            width={160}
            minWidth={100}
          />
          <Column
            caption="Rating"
            dataField="rating"
            width={70}
            minWidth={50}
          />
          <Column
            caption="Experience"
            dataField="Experence"
            width={130}
            minWidth={80}
            cellRender={ExperienceCellRender}
          />
          <Column caption="Obervation" dataField="obervation" minWidth={100} />
        </DataGrid>

        <div className="mt-4">
        <div className="view-text-value">{viewData?.result}</div>
          <div className="view-label">Result</div>
         
        </div>
        <div className='row pt-4 px-2'><hr className='mb-1'/></div>
          <div className='row text-end font-regular-12'>
             <div>Created by <span className='Italic-text'><i>Admin</i> </span>at <span className='Italic-text'><i>01:41 PM</i> </span>on <span className='Italic-text'><i>27/12/2023</i> </span></div>
          </div>
      </ViewMode>
    </>
  );
};

export default InteractionView;
