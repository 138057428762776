import React, { useEffect, useState } from "react";
import "./AcceptOffer.scss";
import { Button, LoadPanel } from "devextreme-react";
import { GetFormattedDate, ShowAlert } from "../../utils/common-methods";
import { useScreenSize } from "../../utils/media-query";
import { useNavigate, useParams } from 'react-router-dom';
import { JobManagementOpenServices } from "../../api/services/JobManagementOpenServices";
import ListSkeleton from "../../layouts/templates/ListSkeleton";
import RejectOfferPopup from "./RejectOfferPopup";

const jobManagementOpenServices = new JobManagementOpenServices()

const JobApplicationStatus = {
  "Pending": 0,
  "Shortlisted": 1,
  "Under Review": 2,
  "Selected": 3,
  "Offered": 4,
  "Offer accepted": 5,
  "Hired": 6,
  "On-boarded/joined": 7,
  "Rejected by us": 101,
  "Shortlisted but not selected": 102,
  "Offer rejected by candidate": 103,
  "Cancelled by candidate": 104,
  "Candidate not responding": 105
};

const AcceptOffer = () => {

  const { openaccesscode } = useParams();
  const NavigateTo = useNavigate();
  const [offerDetails, setOfferDetails] = useState(null);
  const [offerLetterFile, setOfferLetterFile] = useState(null);
  const [isOfferRejected, setIsOfferRejected] = useState(false);
  const [isOfferAccepted, setIsOfferAccepted] = useState(false);
  const [showAcceptBtn, setShowAcceptBtn] = useState(false);
  const [validUptoDate, setValidUptoDate] = useState(null);
  const [showLoadPanel, setShowLoadPanel] = useState(false);
  const [showRejectOfferPopup, setShowRejectOfferPopup] = useState(false);

  useEffect(() => {
    GetOfferDetail();
  }, []);

  useEffect(() => {
    if (offerDetails) {
      let currentDateTime = new Date();
      const validUptoDateObject = new Date(offerDetails?.offerValidUpto);

      if (
        offerDetails?.jobApplicationStatus < JobApplicationStatus['Offered'] ||
        offerDetails?.jobApplicationStatus > 100
      ) {
        NavigateTo("/AcceptOffer/error-page", {
          state: {
            errorMessage: "Offer did'nt exists",
          }
        });
      }
      else if (
        offerDetails?.jobApplicationStatus == JobApplicationStatus['Offer accepted'] ||
        offerDetails?.jobApplicationStatus == JobApplicationStatus['Hired'] ||
        offerDetails?.jobApplicationStatus == JobApplicationStatus['On-boarded/joined']
      ) {
        setIsOfferAccepted(true);
        setShowAcceptBtn(false)
      }
      else if (offerDetails?.jobApplicationStatus != JobApplicationStatus['Offered']) {
        setShowAcceptBtn(false);
      }
      else if (currentDateTime > validUptoDateObject) {
        NavigateTo("/AcceptOffer/error-page", {
          state: {
            errorMessage: "Offer is no longer valid. Please contact to your hiring manager",
          }
        });
      }
      else {
        const timeDifference = validUptoDateObject - currentDateTime;

        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60)); // Hours
        const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)); // Minutes
        if (hoursDifference >= 24) {
          setValidUptoDate(validUptoDateObject.toLocaleString());
        } else {
          setValidUptoDate(`${hoursDifference} hrs ${minutesDifference} min`);
        }
        setShowAcceptBtn(true);
        setIsOfferAccepted(false);
      }
    }
  }, [offerDetails]);

  const {
    isSmall,
    isXSmall,
    isExSmall,
    isXXSmall,
    isMedium,
    isLarge,
    isExLarge,
  } = useScreenSize();

  const GetOfferLetterFile = async () => {
    const result = await jobManagementOpenServices.GetOfferLetterFile(openaccesscode);
    if (result.isOk) {
      let response = result.data;
      return response;
    }
    else {
      if (result.statusCode == 204) {
        return result
      }
    }
  }

  const GetOfferDetail = async () => {
    setShowLoadPanel(true);
    const result = await jobManagementOpenServices.GetOfferDetails(openaccesscode);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      setOfferDetails(result?.data);
      setShowLoadPanel(false);
      GetOfferLetterFile().then((res) => {
        if (res?.statusCode == 204) {
          setOfferLetterFile({
            uploadedFile: {
              fileData: 'NoData'
            },
            attachedFile: 'NoData'
          })
        }
        else {
          setOfferLetterFile({
            uploadedFile: {
              fileData: res?.size > 0 ? window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' })) : null
            },
            attachedFile: res?.size > 0 ? res : null,
          });
        }
      });
    }

  };

  const onSubmit = async () => {

    setShowLoadPanel(true);
    const dataToUpdate = {
      offerAcceptedDate: new Date(),
      acceptedCTCPA: offerDetails?.offeredCTCPA,
    };

    try {
      const result = await jobManagementOpenServices.AcceptOffer(openaccesscode, dataToUpdate);
      if (result.isOk) {
        GetOfferDetail();
      } else {
        ShowAlert(result.data, "Vakency");
      }
    } catch (error) {
      ShowAlert("An error occurred while processing the action.", "Vakency");
    } finally {
      setShowLoadPanel(false);
    }

  };

  const onRejectOffer = () => {
    setShowRejectOfferPopup(true);
  }

  const onHidingLoadPanel = () => {
    setShowLoadPanel(false);
  }

  const HideRejectOfferPopup = () => {
    setShowRejectOfferPopup(false);
  }

  const onOfferRejected = () => {
    setIsOfferRejected(true);
    setShowAcceptBtn(false);
  }

  return (
    <>
      <LoadPanel
        visible={showLoadPanel}
        onHiding={onHidingLoadPanel}
      />
      <div className="accept-offer-section w-100">
        <div className="accept-offer-card">
          <div className="row align-items-center px-3 m-0 mt-2">
            <div className={!isExLarge && !isLarge ? "col-12" : "col"}>
              <div
                className={`${!isExLarge ? "font-semiBold-20" : "font-semiBold-23"
                  } align-bottom  d-flex`}
              >
                <div className="me-3">
                  <span title="Edit">
                    {offerDetails?.jobProfileName}
                  </span>
                </div>
              </div>
              <div
                className={`${!isExLarge ? "font-semiBold-20" : "font-semiBold-23"
                  } align-bottom  d-flex text-gray`}
              >
                <div className="me-3">
                  {/* <i className="dx-icon-userIcon dx-custom-icon me-1"></i> */}
                  <span title="Edit">
                    {offerDetails?.candidateName}
                  </span>
                </div>
              </div>
            </div>
            <div className={"col-auto"}>

              <i className="dx-icon-dateIcon dx-custom-icon me-1"></i>
              Offered Date:{" "}
              <b>{GetFormattedDate(offerDetails?.offerDate)}</b>
            </div>
            <div className="col-auto vertical-line d-none d-md-block">|</div>
            <div className={"col-auto"}>
              <i className="dx-icon-dateIcon dx-custom-icon me-1"></i>
              <span>Joining Date:</span>{" "}
              <b>{GetFormattedDate(offerDetails?.expectedJoiningDate)}</b>
            </div>
            <div className="col-auto vertical-line d-none d-md-block">|</div>
            <div className={"col-auto"}>
              <i className="dx-icon-moneyIcon dx-custom-icon me-1"></i>
              <span>Offered CTC:</span>{" "}
              <b>{offerDetails?.offeredCTCPA} LPA</b>
            </div>
            {
              validUptoDate && (
                <>
                  <div className="col-auto vertical-line d-none d-md-block">|</div>
                  <div className={"col-auto"}>
                    <i className="dx-icon-dateIcon dx-custom-icon me-1"></i>
                    <span>Valid up to</span>{" "}
                    <b>{validUptoDate}</b>
                  </div>
                </>
              )
            }

          </div>
        </div>
        <div className="mt-0">
          {
            offerLetterFile?.uploadedFile?.fileData ? (
              offerLetterFile?.uploadedFile?.fileData == "NoData" ? (
                <p className="d-flex justify-content-center">No data for preview.</p>
              ) : (
                <div className="offer-iframe">
                  <iframe
                    src={`${offerLetterFile?.uploadedFile?.fileData}#navpanes=0`}
                    width="100%"
                    className={
                      isExSmall || isXXSmall || isXSmall
                        ? "offerLetter-iframe-sm"
                        : "offerLetter-iframe"
                    }
                    title="Offer Letter"
                  />
                </div>
              )
            ) : (
              <ListSkeleton />
            )
          }
        </div>

        <div className="row justify-content-start align-items-center px-3 pt-1 m-0">
          <div className="col">
            {
              showAcceptBtn && (
                <>
                  <div className="row">
                    <div className="col-auto  ps-2 pe-1">
                      <Button
                        text="Accept Offer"
                        className="my-2 px-3 add-new-candidate-btn"
                        hint="Accept Offer"

                        onClick={onSubmit}
                      />
                    </div>
                    <div className="col-auto pe-2">
                      <Button
                        text="Reject Offer"
                        className="my-2 px-3 ms-2 reject-offer-btn"
                        hint="Accept Offer"

                        onClick={onRejectOffer}
                      />
                    </div>
                  </div>
                </>
              )
            }
            {
              isOfferAccepted && (
                <div className="font-semiBold-18 d-flex align-items-center">
                  <i className="dx-icon-dateIcon dx-custom-icon me-1"></i>
                  <span className="me-1">Offer was accepted by candidate on</span>{" "}
                  <b>{GetFormattedDate(offerDetails?.offerAcceptedDate)}</b>
                </div>
              )
            }
            {
              isOfferRejected && (
                <div className="font-semiBold-18 d-flex align-items-center">
                  <i className="dx-icon-crossIcon dx-custom-icon me-1" />
                  <span>Offer is rejected by candidate</span>{" "}
                </div>
              )
            }
          </div>
        </div>

      </div>
      {showRejectOfferPopup && <RejectOfferPopup
        showPopup={showRejectOfferPopup}
        HideRejectOfferPopup={HideRejectOfferPopup}
        openaccesscode={openaccesscode}
        onOfferRejected={onOfferRejected}
      />}
    </>
  );
};

export default AcceptOffer;
