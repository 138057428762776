import React from 'react';
import { useLocation } from 'react-router-dom';

export default function AcceptOfferErrorPage({
    message
}) {

    const location = useLocation();
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        setErrorMessage(location.state?.errorMessage || 'Invalid Operation');
    },[location]);

    return (
        <>
            <div className="container-fluid">
                <div className="row job-error-page justify-content-center">
                    <div className='col-12 col-md-10 col-lg-7 col-xl-6 d-flex justify-content-center align-items-center'>
                        <div className="w-100">
                            <div className='error-card'>
                                <div className='text-center mb-2'><i className='dx-icon-warning warning-icon'></i></div>
                                <div className='text-center my-2 fw-semibold message'>{errorMessage}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}