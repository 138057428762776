import { Button, Popup, ScrollView, SelectBox, Validator } from "devextreme-react";
import HtmlEditor, {
    Toolbar as HtmlEditorToolbar,
    Item as HtmlEditorItem,
  } from "devextreme-react/html-editor";
import React, { useRef } from "react";
import StarRating, {  ShowAlert } from "../../utils/common-methods";
import { useScreenSize } from "../../utils/media-query";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import { CandidateAssessmentOpenServices } from "../../api/services/CandidateAssessmentOpenServices";
import { RequiredRule } from "devextreme-react/validator";


const candidateAssessmentOpenServices = new CandidateAssessmentOpenServices();
const AssessmentPopup = (props) => {
  const ValidationGroupName = "assessmentPopupValidation"

  const { isExSmall, isXXSmall, isXSmall, isMedium, isSmall,isExLarge } = useScreenSize();
  const htmlEditorSecondAttributes = {
    class: "noteSecondHtml",
  };
  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
  const fontValues = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];
  const htmlEditorNoteRef = useRef(null);

  const onClosePopup = () => {
    props.HideAssessmentPopup();
  };

  const PopupTitle = (e) => {
    return (
      <>
        <PopupHeader
          onClosePopup={onClosePopup}
          showCloseButton={"true"}
          title={"Assessment"}
          hideSaveButton={true}
        />
      </>
    );
  };


  const handleRoundResult=(e)=>{
    props.setRoundResultId(e.value)
  }

  const handleSubmit= async(e)=>{
    e.preventDefault();
    const dataToInsert = {
      assessmentResult: props.roundResultId,
      assessmentNotePlainText: htmlEditorNoteRef.current?.instance.getQuillInstance().getText(),
      assessmentNoteFormattedText:htmlEditorNoteRef.current?.instance.option("value")
    }
    const result = await candidateAssessmentOpenServices.PostAssessmentResult(props?.openaccesscode,dataToInsert);
      if (!result.isOk) {
        if (result.statusCode === 400) {
          ShowAlert(result.data, "Vakency");
        } else if (result.statusCode === 409) {
          ShowAlert(result.data, "Vakency");
        }
    } else {
      props.HideAssessmentPopup();
      props.GetCandidateData()
    }
  }

  return (
    <>
      <Popup
        visible={props.showAssessmentPopup}
        onHiding={props.HideAssessmentPopup}
        width={
          isXSmall || isXXSmall || isExSmall
            ? "95vw"
            : isMedium || isSmall
            ? "70vw"
            : "50vw"
        }
        height={"auto"}
        maxHeight={"95vh"}
        titleRender={PopupTitle}
        wrapperAttr={{ class: "CustomPopup" }}
      >
        <ScrollView width={"100%"} height={"100%"}>
          <form onSubmit={handleSubmit}>
          <div className="row pt-2 mx-1 mb-3">
                      <div className="col pt-2 d-flex align-items-center ">
                        <div className="pt-1 font-medium-14">
                          Over All Rating:
                        </div>

                        <div className=" font-medium-20 ps-2">
                          <StarRating rating={props?.overallRating} />
                        </div>
                      </div>
                      <div className="col-auto ">
                        <div className=" font-medium-14 d-flex align-items-center">
                          <SelectBox
                            dataSource={props.roundResult}
                            displayExpr="type"
                            valueExpr="value"
                            value={props.roundResultId}
                            placeholder="Select a status"
                            label="Round Result"
                            labelMode="floating"
                            searchEnabled={true}
                            className="ms-3"
                            onValueChanged={(e)=>handleRoundResult(e)}
                            validationMessagePosition="left"
                          >
                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                            <RequiredRule message='Round Result is required' />
                                        </Validator>
                          </SelectBox>
                        </div>
                      </div>

                     
                    </div>
                    <div className="row">
                    <div className="assessment-html-editor-section px-4">
                      <HtmlEditor
                        ref={htmlEditorNoteRef}
                        id={"JobDescribtionHtmlEditor"}
                        elementAttr={htmlEditorSecondAttributes}
                        valueType="html"
                        // height={isExLarge ? "670px" :  "790px"
                        //     // : "1100px"
                        // }
                        width={"auto"}
                        height={"300px"}
                        className="CustomizeHtmlEditor"
                        placeholder="Overall Comments"
                      >
                        <HtmlEditorToolbar multiline={false}>
                          <HtmlEditorItem name="link"></HtmlEditorItem>
                          <HtmlEditorItem
                            name="font"
                            acceptedValues={fontValues}
                          />
                          <HtmlEditorItem
                            name="size"
                            acceptedValues={sizeValues}
                          />
                          <HtmlEditorItem name="bold" />
                          <HtmlEditorItem name="italic" />
                          <HtmlEditorItem name="underline" />
                          <HtmlEditorItem name="background" />
                          <HtmlEditorItem name="color" />
                          <HtmlEditorItem name="orderedList" />
                          <HtmlEditorItem name="bulletList" />
                          <HtmlEditorItem name="clear" />
                        </HtmlEditorToolbar>
                      </HtmlEditor>
                    </div>
                    </div>
                     <div className="d-flex justify-content-end py-2 pe-3">
                        <Button
                        validationGroup={ValidationGroupName}
                          text="Submit"
                          type="default"
                          height={33}
                          width={120}
                          className="rounded-3"
                          useSubmitBehavior={true}
                        />
                      </div>
          </form>
        </ScrollView>
      </Popup>
    </>
  );
};

export default AssessmentPopup;
