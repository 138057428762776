import React from 'react';
import './AssessmentViewer.scss'
import { CandidateAssessmentServices } from '../../api/services/CandidateAssessmentServices';
import { BlankProfile, candidateUnavailableIcon, failedIcon, interviewCancelledIcon, notAttendIcon, pendingIcon, rejectedIcon, selectedIcon, shortlistedIcon, userAssessmentIcon } from '../../utils/base-64-Icons';
import { Item } from "devextreme-react/tab-panel";
import TabPanel from "devextreme-react/tab-panel";
import { Button, DataGrid, LoadPanel, ScrollView } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import StarRating, { handleNavigateToMap, ShowAlert } from "../../utils/common-methods";
import { useScreenSize } from "../../utils/media-query";
import { useLocation, useNavigate } from 'react-router-dom';
import FilePreviewer from '../candidate/FilePreviewer';

const colors = [
    "#63439c",
    "#9c9b43",
    "#9c436e",
    "#9c4343",
    "#5f9c43",
    "#439c95",
    "#46439c",
    "#439c9b",
    "#89439c",
    "#436e9c",
    "#436e9c",
    "#7e9c43",
    "#006400",
    "#00008B",
    "#8B008B",
    "#2F4F4F",
    "#8B4513",
    "#4B0082",
    "#556B2F",
    "#FF8C00",
    "#9932CC",
    "#B22222",
];
const candidateAssessmentServices = new CandidateAssessmentServices();

export default function AssessmentViewer() {

    const { state } = useLocation();
    const NavigateTo = useNavigate();
    const [candidateData, setCandidateData] = React.useState({});
    const [dsAttitudeData, setDsAttitudeData] = React.useState(null);
    const [dsTechSkill, setDsTechSkill] = React.useState(null);
    const [overallRating, setOverallRating] = React.useState(null);
    const [profileImg, setProfileImg] = React.useState({
        uploadedFile: null,
        attachedFile: null,
    });
    const [resume, setResume] = React.useState(null);
    const [tabSelectedItemIndex, setTabSelectedItemIndex] = React.useState(null);
    const [interviewers, setInterviewers] = React.useState([]);
    const [assessmentNotes, setAssessmentNotes] = React.useState([]);
    const [dsInteractionResult, setDsInteractionResult] = React.useState({});
    const [showFilePreview, setShowFilePreview] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [showFilePreviewLoad, setShowFilePreviewLoad] = React.useState(false);

    const {
        isMedium,
        isExLarge,
    } = useScreenSize();
    const {
        InteractionID,
    } = state || {};

    React.useEffect(() => {
        if (InteractionID) {
            GetAssessmentData();
        }
    }, []);

    React.useEffect(() => {
        if (InteractionID) {
            GetProfileImage().then((res) => {
                setProfileImg({
                    uploadedFile: {
                        fileData: res?.size > 0 ? window.URL.createObjectURL(res) : null,
                    },
                    attachedFile: res?.size > 0 ? res : null,
                });
            });
        }
    }, []);

    React.useEffect(() => {
        if (InteractionID) {
            GetResume().then((res) => {
                if (res?.statusCode == 204) {
                    setResume({
                        uploadedFile: {
                            fileData: null
                        },
                        attachedFile: null
                    })
                }
                else {
                    setResume({
                        uploadedFile: {
                            fileData: res?.size > 0 ? window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' })) : null
                        },
                        attachedFile: res?.size > 0 ? res : null,
                    });
                }
            });
        }
    }, []);

    React.useEffect(() => {
        if (InteractionID) {
            GetAssessmentNotes();
            GetInteractionResult();
        }
    }, []);

    // API calls
    const GetAssessmentData = async () => {
        const result = await candidateAssessmentServices.GetCandidateAssesmentDetails(InteractionID);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
            } else if (result.statusCode === 409) {
                ShowAlert(result.data, "Vakency");
            }
        } else {
            setCandidateData(result.data);
            setDsAttitudeData(result.data?.attitudes);
            setDsTechSkill(result.data?.technicalSkills);
            setOverallRating(result.data?.interactionRating);
        }
    };

    const GetProfileImage = async () => {
        const result = await candidateAssessmentServices.GetCandidateProfileImage(InteractionID);
        if (result.isOk) {
            return result.data;
        } else {
            return;
        }
    };

    const GetResume = async () => {
        const result = await candidateAssessmentServices.GetCandidateResume(InteractionID);
        if (result.isOk) {
            let response = result.data;
            return response;
        }
        else {
            if (result.statusCode == 204) {
                return result
            }
        }
    }

    const GetInteractionResult = async () => {
        const result = await candidateAssessmentServices.GetInteractionResult(InteractionID);
        if (result.statusCode === 200) {
            setDsInteractionResult(result.data);
        } else {
            return;
        }
    }

    const GetAssessmentNotes = async () => {
        const result = await candidateAssessmentServices.GetAssessmentNotes(InteractionID);
        if (result.statusCode === 200) {
            const response = result.data;
            setAssessmentNotes(response)
            const interviewersData = response.map((res, index) => {
                return {
                    iterviewer: res.interviewerEmailID,
                    bgColor: colors[(index <= 12) ? (index) : (index - 12)]
                }
            })
            setInterviewers(interviewersData)
        } else {
            return;
        }
    };

    // handlers
    const onCandidateItemChange = (e) => {
        setTabSelectedItemIndex(e)
    }

    const RatingCellRender = (e) => {
        return (
            <>
                <div className="font-medium-18">
                    <StarRating rating={e.data.rating} />
                </div>
            </>
        );
    };

    const TechSkillRatingCellRender = (e) => {
        return (
            <>
                <div className="font-medium-18">
                    <StarRating rating={e.data.rating} />
                </div>
            </>
        );
    };

    const ExperienceYearMonthCellRender = (e) => {
        const experience = e.data.experience;
        const integerPart = parseInt(experience);
        const fractionalPart = ((experience - integerPart).toFixed(2));
        //  const   newExperience = value + fractionalPart;
        return (
            <>
                <div className="d-flex gap-2 pt-2">
                    {experience == 0 || experience == null ? "-" : (
                        <>
                            <div>
                                {(integerPart == null || isNaN(integerPart)) ? null : (integerPart + " Years ")}
                            </div>
                            <div>
                                {(fractionalPart == 0) ? "0 Months" : (isNaN(fractionalPart) ? "-" : parseInt(fractionalPart.toString().split('.')[1]) + " Months")}
                            </div>
                        </>
                    )}
                </div>
            </>

        );
    };

    const onEmailButtonClick = () => {
        window.location.href = `mailto:${candidateData?.emailID}`;
    }
    const onPhoneButtonClick = () => {
        window.location.href = `tel:${candidateData?.candidateMobileNo}`;
    }
    const goBackButtonClick = () => {
        NavigateTo(-1);
    }

    const onPictureClick =(fileSrc)=>{
        if(fileSrc){
            setShowFilePreview(true);
            setSelectedFile({
                fileSrc: fileSrc,
            }); 
        }
    }

    const onFilePreviewClose = () => {
    setShowFilePreview(false)
    }

    return (
        <>
          <LoadPanel
                visible={showFilePreviewLoad}
                message='File is loading. We’ll display the preview shortly.'
             />
            <ScrollView height={'100%'} width={'100%'}>
                <div className="container-fluid candidate-assessment-section">
                    <div className="row">
                        <div className="col-xs-12 col-md assessment-card">
                            <div className="row">
                                <div className="col-auto pe-0">
                                    <div className="left-user-img me-3">
                                        <img src={userAssessmentIcon} alt="user" width={36} />
                                    </div>
                                </div>
                                <div className="col ps-0">
                                    {(candidateData?.interactionRoundName) && (candidateData?.jobProfileName) && <div className="font-medium-22">
                                        {candidateData?.interactionRoundName} <span className='text-gray'>for</span> {candidateData?.jobProfileName}
                                    </div>}
                                    <div className="row pt-1">
                                        <div className="col-auto">
                                            <span title="Interview Date ">
                                                Interview Date:{" "}
                                                {(candidateData?.scheduleDateTime) && <span className="font-semiBold-14" title={`${new Date(candidateData?.scheduleDateTime).toLocaleDateString()} - ${new Date(candidateData?.scheduleDateTime).toLocaleTimeString()}`}>
                                                    {`${new Date(candidateData?.scheduleDateTime).toLocaleDateString()} - ${new Date(candidateData?.scheduleDateTime).toLocaleTimeString()}`}
                                                </span>}
                                            </span>
                                        </div>
                                        <div className="col-auto px-0">
                                            <span className="assessment-pipe">|</span>
                                        </div>
                                        <div className="col-auto">
                                            <span title="Application Date">
                                                Application Date:{" "}
                                                {(candidateData?.jobApplicationDate) && <span className="font-semiBold-14" title={new Date(candidateData?.jobApplicationDate).toLocaleDateString()}>
                                                    {new Date(candidateData?.jobApplicationDate).toLocaleDateString()}
                                                </span>}
                                            </span>
                                        </div>
                                        <div className="col-auto px-0">
                                            <span className="assessment-pipe">|</span>
                                        </div>
                                        <div className="col-auto">
                                            <span title="Application Status">
                                                Application Status:{" "}
                                                <img
                                                    src={candidateData?.interactionResultName === "Pending" ? pendingIcon : candidateData?.interactionResultName === "Passed" ? selectedIcon : candidateData?.interactionResultName === "Failed" ? failedIcon : candidateData?.interactionResultName === "Candidate Did Not Attend" ? notAttendIcon : candidateData?.interactionResultName === "Cancelled By Candidate" ? candidateUnavailableIcon : candidateData?.interactionResultName === "Cancelled By Us" ? interviewCancelledIcon : rejectedIcon} //this is the status of interaction, it should be Pending, Passed, Failed, CandidateDidNotAttend,CancelledByCandidate, CancelledByUs   
                                                    title={candidateData?.interactionResultName}
                                                    alt="interaction status"
                                                    width={24}
                                                    className='me-1'
                                                />
                                            </span>
                                            <span className='font-semiBold-14'>{candidateData?.interactionResultName}</span>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Button
                                        text={"Back"}
                                        icon="spinprev"
                                        type="default"
                                        hint="Back"
                                        width={100}
                                        stylingMode="text"
                                        className="border-0 Back__button"
                                        onClick={goBackButtonClick}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md assessment-card ms-xs-0 ms-md-2 mt-md-0 mt-2 pt-3">
                            <div className="row">
                                <div className="col font-medium-22 px-3">
                                    <span className="interviewer-badge">{(candidateData?.interviewerTypeID == 0) ? "Interviewer" : "Co-Interviewer"}</span>
                                    <div className="italic-text">{candidateData?.interviewerEmailID}</div>
                                </div>
                                <div className="col-auto d-flex align-items-end">
                                    {interviewers.map((iterviewer, index) =>
                                        <div key={index} title={iterviewer.iterviewer} className="interviews-tag  ms-1" style={{ backgroundColor: `${iterviewer.bgColor}`, cursor: "pointer" }} >{iterviewer.iterviewer.slice(0, 1).toUpperCase()}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-lg assessment-card mt-2">
                            <div className="assessment-view-details-tab">
                                <div className="font-semiBold-18 pb-2">Candidate Details{" "}
                                    {tabSelectedItemIndex === 1 &&
                                        <span className="candidate-details-top">
                                            <span >- {candidateData?.candidateName}<span className="assessment-pipe mx-1">|</span>{candidateData?.candidateEmailID}{candidateData?.candidateMobileNo && <span className="assessment-pipe mx-1">|</span>} {candidateData?.candidateMobileNo}
                                            </span>
                                        </span>}</div>
                                <TabPanel
                                    animationEnabled={true}
                                    stylingMode={"secondary"}
                                    onSelectedIndexChange={onCandidateItemChange}
                                >
                                    <Item title="Details">
                                        <div className="tab-panel-item">
                                            <div className="row pt-3">
                                                <div className="col-auto">
                                                    <div  onClick={()=>onPictureClick(profileImg.uploadedFile?.fileData)}>
                                                    {(profileImg.uploadedFile?.fileData) ? <img
                                                        src={profileImg.uploadedFile?.fileData}
                                                        width={80}
                                                        height={80}
                                                        alt="candidate"
                                                        className='candidate-image'
                                                    /> :
                                                        <img
                                                            src={BlankProfile}
                                                            width={80}
                                                            height={80}
                                                            alt="candidate"
                                                            className='candidate-image'
                                                        />}
                                                        </div>
                                                </div>
                                                <div className="col mx-0">
                                                    <div className="font-medium-22 d-flex pt-1">
                                                        <div>{candidateData?.candidateName}</div>
                                                        <div className="font-medium-20 ps-3 ">
                                                            <StarRating rating={candidateData?.candidateRating} />
                                                        </div>
                                                    </div>
                                                    <div className="row font-medium-16">
                                                        <div className="col-auto">
                                                            <Button
                                                                icon="email"
                                                                hint="Email"
                                                                stylingMode="text"
                                                                className="resume-edit-btn"
                                                                onClick={onEmailButtonClick}
                                                            />{" "}
                                                            {candidateData?.candidateEmailID ? candidateData?.candidateEmailID : ""}
                                                            {/* <span title="Email ID">{candidateData?.candidateEmailID ? candidateData?.candidateEmailID : ""}</span> */}
                                                        </div>
                                                        {candidateData?.candidateEmailID && <div className="col-auto px-0 assessment-pipe">|</div>}
                                                        <div className="col-auto">
                                                            <span onClick={onPhoneButtonClick} className="cursor-pointer">
                                                                <i className="dx-icon-MobileIcon dx-custom-icon"></i>{" "}
                                                                {candidateData?.candidateMobileNo}
                                                            </span>
                                                        </div>
                                                        <div className="col-auto px-0 assessment-pipe">|</div>
                                                        <div className="col-auto"><span title="Age">{candidateData?.candidateAgeInYears ? (candidateData?.candidateAgeInYears + "yrs") : ""} </span></div>
                                                        {candidateData?.candidateAgeInYears && <div className="col-auto px-0 assessment-pipe">|</div>}
                                                        <div className="col-auto"><span title="Marital Status">{candidateData?.candidateMaritalStatusName}</span></div>
                                                    </div>
                                                    <div className="row pt-1 mt-1 font-medium-16">
                                                        {candidateData?.currentCityName &&
                                                            <>
                                                                <div className="col-auto ">
                                                                    <i className="dx-icon-locationIcon dx-assesment-custom-icon mb-1 cursor-pointer" onClick={()=>handleNavigateToMap(candidateData?.currentCityName)}></i>{" "}
                                                                    {candidateData?.currentCityName}
                                                                </div>
                                                                <div className="col-auto px-0 assessment-pipe">|</div>
                                                            </>
                                                        }
                                                        {candidateData?.permanentCityName &&
                                                            <>
                                                                <div className="col-auto ">
                                                                    <i className="dx-icon-homeTownIcon dx-assesment-custom-icon mb-1 me-1 cursor-pointer"  onClick={()=>handleNavigateToMap(candidateData?.permanentCityName)}></i>{" "}
                                                                    {candidateData?.permanentCityName}
                                                                </div>
                                                                <div className="col-auto px-0 assessment-pipe">|</div>
                                                            </>
                                                        }
                                                        <div className="col-auto ">
                                                            {candidateData?.candidateLinkedIn ? <a
                                                                href={`${candidateData?.candidateLinkedIn}`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <i className="dx-icon-LinkedInIcon dx-assesment-custom-icon-lg"></i>
                                                            </a> : <i className="dx-icon-LinkedInDisabledIcon dx-custom-icon" />}
                                                        </div>
                                                        <div className="col-auto px-0 assessment-pipe">|</div>
                                                        <div className="col-auto ">
                                                            {candidateData?.candidateSkypeID ? <a
                                                                href={`skype:${candidateData?.candidateSkypeID}?chat`}
                                                                rel="noreferrer"
                                                            >
                                                                <i className="dx-icon-skypeIcon dx-assesment-custom-icon-lg"></i>
                                                            </a> : <i className="dx-icon-skypeDisabledIcon dx-custom-icon" />}
                                                        </div>
                                                        <div className="col-auto px-0 assessment-pipe">|</div>
                                                        <div className="col-auto ">
                                                            {candidateData?.candidateMobileNo2 ? <a
                                                                href={`https://wa.me/91${candidateData?.candidateMobileNo2}`}
                                                                rel="noreferrer"
                                                                target='_blank'
                                                            >
                                                                <i className="dx-icon-WhatsappIcon dx-custom-icon"></i>
                                                            </a> : <i className="dx-icon-WhatsappDisabledIcon dx-custom-icon"></i>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="mb-2" />
                                            <span className="font-medium-14 text-decoration-underline">
                                                Professional Details
                                            </span>
                                            <div className="row font-medium-14">
                                                <div className="col ">
                                                    <div className="mt-2">{(candidateData?.lastAcademicQualification) ? (candidateData?.lastAcademicQualification) : "-"}</div>
                                                    <div className="italic-text">Academic Qualification</div>
                                                    <div className="mt-2" title={candidateData?.totalExperience}>{(candidateData?.totalExperience) ? (candidateData?.totalExperience + " " + (candidateData?.totalExperience > 1 ? 'yrs' : 'yr')) : "-"}</div>
                                                    <div className="italic-text" title="Total Experience">Total Experience</div>
                                                    <div className="mt-2" title={candidateData?.relevantExperience}>{(candidateData?.relevantExperience) ? (candidateData?.relevantExperience + " " + (candidateData?.relevantExperience > 1 ? 'yrs' : 'yr')) : "-"}</div>
                                                    <div className="italic-text" title="Relevant Experience">Relevant Experience</div>
                                                    <div className="mt-2" title={candidateData?.requiredExperience}>{(candidateData?.requiredExperience) ? (candidateData?.requiredExperience + " " + (candidateData?.requiredExperience > 1 ? 'yrs' : 'yr')) : "-"}</div>
                                                    <div className="italic-text" title="Relevant Experience">Required Experience</div>
                                                </div>
                                                <div className="col">
                                                    <div className="mt-2" title={candidateData?.currentCTC?.toFixed(2)}>{(candidateData?.currentCTC) ? candidateData?.currentCTC?.toFixed(2) : "-"}</div>
                                                    <div className="italic-text" title="Current CTC(LPA)">Current CTC(LPA)</div>
                                                    <div className="mt-2" title={candidateData?.expectedCTC?.toFixed(2)}>{(candidateData?.expectedCTC) ? candidateData?.expectedCTC?.toFixed(2) : "-"}</div>
                                                    <div className="italic-text" title="Expected CTC(LPA)">Expected CTC(LPA)</div>
                                                    <div className="mt-2">
                                                        {(candidateData?.lastInteractionRoundName) ? (candidateData?.lastInteractionRoundName) : "-"} {candidateData?.lastInteractionDateTime
                                                            ? new Date(candidateData.lastInteractionDateTime).toLocaleDateString()
                                                            : "-"}
                                                    </div>
                                                    <div className="italic-text">Last Interaction</div>
                                                </div>
                                                <div className="col">
                                                    <div className="mt-2">{(candidateData?.currentCompanyName) ? (candidateData?.currentCompanyName) : "-"}</div>
                                                    <div className="italic-text">Current Employer</div>
                                                    <div className="mt-2">{(candidateData?.noticePeriod) ? (candidateData?.noticePeriod + " days") : "-"}</div>
                                                    <div className="italic-text">Notice Period</div>
                                                    <div className="mt-2">{(candidateData?.leavingReason) ? (candidateData?.leavingReason) : "-"}</div>
                                                    <div className="italic-text">Leaving Reason</div>
                                                </div>
                                            </div>
                                            <hr className="mb-2" />
                                            <div className="row pb-1">
                                                <div className="col-auto">
                                                    <div className="font-medium-14 text-decoration-underline">
                                                        Attitude
                                                    </div>
                                                    <div className="custom-card-grid mt-1">
                                                        <DataGrid
                                                            dataSource={dsAttitudeData}
                                                            className="TechSkillDatagrid p-0 mt-0"
                                                            showColumnLines={false}
                                                            showRowLines={true}
                                                            height={320}
                                                            wordWrapEnabled
                                                        >
                                                            <Column dataField="attitudeName" caption="Name" alignment="left" width={(isExLarge) ? '200px' : '100%'} />
                                                            <Column
                                                                alignment="left"
                                                                dataField="rating"
                                                                caption="Rating"
                                                                cellRender={RatingCellRender}
                                                                editCellRender={RatingCellRender}
                                                                width={100}
                                                            />
                                                        </DataGrid>
                                                    </div>
                                                </div>
                                                <div className={`col ${isExLarge ? 'ps-0' : 'pt-2 mt-1'}`}>
                                                    <div className="font-medium-14 text-decoration-underline">
                                                        Technical Skills
                                                    </div>
                                                    <div className="custom-card-grid mt-1">
                                                        <DataGrid
                                                            // keyExpr="skillID"
                                                            dataSource={dsTechSkill}
                                                            className="TechSkillDatagrid"
                                                            showColumnLines={false}
                                                            showRowLines={true}
                                                            height={320}
                                                            noDataText='No Record Found'
                                                            wordWrapEnabled
                                                        >
                                                            <Column
                                                                caption="Skill Name"
                                                                dataField="skillName"
                                                                alignment="left"
                                                                width={140}
                                                                minWidth={100}
                                                            />
                                                            <Column
                                                                caption="Rating"
                                                                dataField="rating"
                                                                width={120}
                                                                minWidth={90}
                                                                alignment="left"
                                                                cellRender={TechSkillRatingCellRender}
                                                            />
                                                            <Column
                                                                caption="Experience "
                                                                dataField="experience"
                                                                width={210}
                                                                minWidth={210}
                                                                cssClass="experience-column"
                                                                cellRender={ExperienceYearMonthCellRender}
                                                                alignment="left"

                                                            >
                                                            </Column>
                                                            <Column
                                                                caption="Observation"
                                                                dataField="observation"
                                                                minWidth={100}
                                                            />
                                                        </DataGrid>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Item>
                                    <Item title="Resume">
                                        <div className={`pt-2 tab-panel-item`}>
                                            {(resume?.uploadedFile?.fileData) ? <iframe
                                                src={`${resume?.uploadedFile?.fileData}#navpanes=0`}
                                                width="100%"
                                                height={"750px"}
                                                title="Resume"
                                            /> :
                                                <p className="d-flex justify-content-center">No data for preview.</p>
                                            }
                                        </div>
                                    </Item>
                                </TabPanel>
                            </div>
                        </div>
                        <div className="col-xs-12 col-lg ms-0 ms-lg-2 assessment-card mt-2">
                            <div className="">
                                <div className="font-semiBold-18 pb-2">Assessment</div>
                                <div className={`row d-flex align-items-center`}>
                                    <div className="col pt-2 d-flex">
                                        <div className="pt-1 font-medium-14">
                                            Over All Rating:
                                        </div>

                                        <div className=" font-medium-20 ps-2">
                                            <StarRating rating={overallRating} />
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="pt-1 font-medium-14">
                                            <span className='text-gray'>Round Result:</span> {candidateData?.interactionResultName}
                                        </div>
                                    </div>
                                </div>
                                <ScrollView
                                    height={tabSelectedItemIndex === 1 ? "762px" : isExLarge ? "705px" : "1060px"}
                                >
                                    <div className='p-0'>
                                        <div className="mt-3 mb-3 assessment-notes-card">
                                            <div className="assessment-notes-header d-flex">
                                                Observation
                                            </div>
                                            <div className='p-3' dangerouslySetInnerHTML={{ __html: dsInteractionResult.assessmentNoteFormattedText || '-' }} />
                                        </div>
                                        {assessmentNotes.map((assessment, index) => (
                                            assessment.assessmentNoteFormattedText && (
                                                <div key={index} className="mt-2 assessment-notes-card">
                                                    <div className="assessment-notes-header d-flex">
                                                        {assessment.interviewerEmailID}
                                                    </div>
                                                    <div className='p-3' dangerouslySetInnerHTML={{ __html: assessment.assessmentNoteFormattedText || '-' }} />
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </ScrollView>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollView>
            {
                showFilePreview && (
                    <FilePreviewer
                        showFilePreview={showFilePreview}
                        selectedFile={selectedFile}
                        setShowFilePreviewLoad={setShowFilePreviewLoad}
                        onFilePreviewClose={onFilePreviewClose}
                        isImageOnly={true}
                    />
                )
            }
        </>
    );

}