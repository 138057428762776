import React, { useEffect, useRef, useState } from "react";
import { Header } from "../../components";
import Editor from "../../layouts/templates/Editor";
import { useLocation, useNavigate } from "react-router-dom";
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import {
  DateBox,
  SelectBox,
  TextBox,
  Validator,
} from "devextreme-react";
import { RequiredRule } from "devextreme-react/validator";
import "./Schedule.scss";
import { useScreenSize } from "../../utils/media-query";
import { minDate, onDragOver } from "../../utils/common-methods";
import { BlankProfile } from "../../utils/base-64-Icons";

const dsCandidate = [
  {
    candidateId: 2,
    candidateName: "Rohit Sharma",
  },
  {
    candidateId: 4,
    candidateName: "Vishal Sharma",
  },
  {
    candidateId: 34,
    candidateName: "Dhawal Sharma",
  },
  {
    candidateId: 334,
    candidateName: "Mukesh Sharma",
  },
];

const ScheduleEditor = () => {
  const NavigateTo = useNavigate();
  const { state } = useLocation();
  const { isLarge, isExLarge } = useScreenSize();
  const { ScheduleId } = state || {};

  const OnCancelClick = () => {
    NavigateTo("/Schedule/List");
  };

  useEffect(() => {
    !ScheduleId && NavigateTo(`/Schedule/New`);
  }, [ScheduleId, NavigateTo]);

  const htmlEditorAttributes = {
    class: "noteHtml",
  };

  const htmlEditorSecondAttributes = {
    class: "noteSecondHtml",
  };

  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
  const fontValues = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];

  const htmlEditorNoteRef = useRef(null);

  const OnSubmitSchedule = (e) => {
    e.preventDefault();
  };

  const ValidationGroupName = "ScheduleValidationGroup";

  const meetingStatus = [
    {
      meetingStatusId: 1,
      meetingStatus: "Pending",
    },
    {
      meetingStatusId: 2,
      meetingStatus: "Completed",
    },
    {
      meetingStatusId: 3,
      meetingStatus: "Candidate did not attend",
    },
    {
      meetingStatusId: 4,
      meetingStatus: "Rescheduled",
    },
    {
      meetingStatusId: 5,
      meetingStatus: "Cancelled",
    },
  ];

  const newRecordData = {
    scheduleID: null,
    scheduleNo: null,
    candidateInteraction: null,
    scheduleDateAndTime: null,
    meetingLink: null,
    description: null,
    meetingStatus: null,
  };

  const [newRecord, setNewRecord] = useState(newRecordData);
  const [showCandidateDetail, setShowCandidateDetail] = useState(false);

  return (
    <>
      <Header
        title={[<span key={"header_title"} className="base-accent-text">{ScheduleId ? "Edit" : "New"}</span>, " Schedule"]}
        hideDefaultButtons={true}
        validationGroupName={ValidationGroupName}
        onBackButtonClick={OnCancelClick}
        submitEditor={true}
      />
      <Editor
        customWidth={true}
      >
        <form onSubmit={OnSubmitSchedule} onDragOver={onDragOver}>
          <div className="row">
            <div className="col-xxl col-xl col-lg col-md">
              <div className="row px-3">
                <div className="col-xxl col-xl  ">
                  <TextBox
                    label="Schedule No."
                    maxLength={50}
                    readOnly={true}
                    labelMode="floating"
                    value={newRecord.scheduleNo}
                    onValueChange={(e) =>
                      setNewRecord({ ...newRecord, scheduleNo: e })
                    }
                  />
                </div>
                <div className="col-xxl col-xl  pt-xxl-0 pt-xl-0  pt-3">
                  <SelectBox
                    id="SchdeuleEditor_CandidateInteraction_DropDown"
                    dataSource={dsCandidate}
                    displayExpr={"candidateName"}
                    valueExpr={"candidateId"}
                    label="Candidate Interaction"
                    onValueChange={(e) => {
                      setNewRecord({ ...newRecord, candidateInteraction: e });
                      setShowCandidateDetail(true);
                    }}
                    labelMode="floating"
                    dropDownOptions={{
                      height: 180,
                      hideOnParentScroll: true,
                      container: "#SchdeuleEditor_CandidateInteraction_DropDown"
                    }}
                  />
                  {showCandidateDetail && (!isLarge && !isExLarge) && (
                    <>
                      <div className="shadow rounded p-3  mt-3">
                        <div className="view-panel-title border-bottom mb-4">
                          Candidate Interaction Information
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="view-label">Candidate Name</div>
                            <div className="candidate-info-value">
                              Dhawal Sharma
                            </div>

                            <div className="view-label mt-4">Candidate No.</div>
                            <div className="candidate-info-value">21</div>
                          </div>
                          <div className="col">
                            <img
                              src={BlankProfile}
                              alt="candidate_picture"
                              width={120}
                              className="rounded"
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col">
                            <div className="view-label ">Job Profile</div>
                            <div className="candidate-info-value">
                              React js Developer
                            </div>
                          </div>
                          <div className="col">
                            <div className="view-label ">Mobile No.</div>
                            <div className="candidate-info-value">9898222211</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="view-label mt-4">Email ID</div>
                            <div className="candidate-info-value">
                              rohit@alittech.com
                            </div>
                          </div>
                          <div className="col">
                            <div className="view-label mt-4">Total Experience</div>
                            <div className="candidate-info-value">
                              2 years 4 months
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col">
                            <div className="view-label ">Resume</div>
                            <div className="candidate-info-value">
                              <a href="">Download Now</a>
                            </div>
                          </div>
                          <div className="col">
                            <div className="view-label ">Linkedin</div>
                            <div className="candidate-info-value">
                              <a href="" className="text-break">
                                https://www.linkedin.com
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="view-label mt-4">Interaction No.</div>
                            <div className="candidate-info-value">544</div>
                          </div>
                          <div className="col">
                            <div className="view-label mt-4">Result</div>
                            <div className="candidate-info-value">Passed</div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="row px-3 pt-3">
                <div className="col-xxl col-xl">
                  <div className="row">
                    <div className="col-auto editor-label pt-2 mt-1 ">
                      Schedule:
                    </div>
                    <div className="col-xxl col-xl col-lg col-md" id="#SchdeuleEditor_Date_DropDown">
                      <DateBox
                        label="Date"
                        labelMode="floating"
                        type="date"
                        useMaskBehavior={true}
                        min={minDate}
                        dateOutOfRangeMessage="Invalid Date"
                        dropDownOptions={{
                          hideOnParentScroll: true,
                          container: "#SchdeuleEditor_Date_DropDown"
                        }}
                      >
                        <Validator
                          validationGroup={ValidationGroupName}
                          elementAttr={{ class: "ForValidationAstrikMark" }}
                        >
                          <RequiredRule message="Date is required" />
                        </Validator>
                      </DateBox>
                    </div>
                    <div className="col-xxl col-xl col-lg col-md mt-3 mt-md-0">
                      <DateBox
                        id="SchdeuleEditor_Time_DropDown"
                        label="Time"
                        labelMode="floating"
                        type="time"
                        dateOutOfRangeMessage="Invalid Time"
                        useMaskBehavior={true}
                        min={minDate}
                        dropDownOptions={{
                          hideOnParentScroll: true,
                          container: "#SchdeuleEditor_Time_DropDown"
                        }}
                      >
                        <Validator
                          validationGroup={ValidationGroupName}
                          elementAttr={{ class: "ForValidationAstrikMark" }}
                        >
                          <RequiredRule message="Time is required" />
                        </Validator>
                      </DateBox>
                    </div>
                  </div>
                </div>
                <div className="col-xxl col-xl col-lg col-md pt-xl-0  pt-3">
                  <SelectBox
                    id="SchdeuleEditor_MeetingStatus_DropDown"
                    labelMode="floating"
                    label="Meeting Status"
                    dataSource={meetingStatus}
                    displayExpr={"meetingStatus"}
                    valueExpr={"meetingStatusId"}
                    dropDownOptions={{
                      hideOnParentScroll: true,
                      container: "#SchdeuleEditor_MeetingStatus_DropDown"
                    }}
                  />
                </div>
              </div>
              <div className="pt-3 px-3">
                <TextBox label="Meeting Link" labelMode="floating">
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Meeting Link is required" />
                  </Validator>
                </TextBox>
              </div>

              <div className="px-3 pt-4 mt-1">
                <HtmlEditor
                  ref={htmlEditorNoteRef}
                  id={"ScheduleHtmlEditor"}
                  elementAttr={htmlEditorSecondAttributes}
                  valueType="html"
                  height={277}
                  className="CustomizeHtmlEditor"
                  tabIndex={-1}
                >
                  <HtmlEditorToolbar multiline={false}>
                    <HtmlEditorItem name="link"></HtmlEditorItem>
                    <HtmlEditorItem name="font" acceptedValues={fontValues} />
                    <HtmlEditorItem name="size" acceptedValues={sizeValues} />
                    <HtmlEditorItem name="bold" />
                    <HtmlEditorItem name="italic" />
                    <HtmlEditorItem name="underline" />
                    <HtmlEditorItem name="background" />
                    <HtmlEditorItem name="color" />
                    <HtmlEditorItem name="orderedList" />
                    <HtmlEditorItem name="bulletList" />
                    <HtmlEditorItem name="clear" />
                  </HtmlEditorToolbar>
                </HtmlEditor>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 ">
              {showCandidateDetail && (isLarge || isExLarge) && (
                <>
                  <div className="shadow rounded p-3 me-3">
                    <div className="view-panel-title border-bottom ">
                      Candidate Interaction Information
                    </div>
                    <div className="row pt-3">
                      <div className="col">
                        <div className="view-label">Candidate Name</div>
                        <div className="candidate-info-value">
                          Dhawal Sharma
                        </div>

                        <div className="view-label mt-4">Candidate No.</div>
                        <div className="candidate-info-value">21</div>
                      </div>
                      <div className="col">
                        <img
                          src={BlankProfile}
                          alt="candidate_picture"
                          width={120}
                          className="rounded"
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <div className="view-label ">Job Profile</div>
                        <div className="candidate-info-value">
                          React js Developer
                        </div>
                      </div>
                      <div className="col">
                        <div className="view-label ">Mobile No.</div>
                        <div className="candidate-info-value">9898222211</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="view-label mt-4">Email ID</div>
                        <div className="candidate-info-value">
                          rohit@alittech.com
                        </div>
                      </div>
                      <div className="col">
                        <div className="view-label mt-4">Total Experience</div>
                        <div className="candidate-info-value">
                          2 years 4 months
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <div className="view-label ">Resume</div>
                        <div className="candidate-info-value">
                          <a href="">Download Now</a>
                        </div>
                      </div>
                      <div className="col">
                        <div className="view-label ">Linkedin</div>
                        <div className="candidate-info-value">
                          <a href="" className="text-break">
                            https://www.linkedin.com
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="view-label mt-4">Interaction No.</div>
                        <div className="candidate-info-value">544</div>
                      </div>
                      <div className="col">
                        <div className="view-label mt-4">Result</div>
                        <div className="candidate-info-value">Passed</div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </Editor>
    </>
  );
};

export default ScheduleEditor;
