import DataGrid, {
  Column,
  Button as DataGridButton,
  FilterPanel,
  Scrolling,
  ColumnChooser,
  HeaderFilter,
  SearchPanel,
  FilterBuilderPopup,
  Pager,
  StateStoring,
  Toolbar,
  Item,
  ColumnChooserSearch,
  Search,
} from "devextreme-react/data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Sorting } from "devextreme-react/gantt";
import { useScreenSize } from "../../../utils/media-query";
import { Header } from "../../../components";
import ListSkeleton from "../../../layouts/templates/ListSkeleton";
import {
  ConflictPopup,
  GetFormattedDateTimeUTCString,
  HideDatagridLoader,
  ShowAlert,
  eCRUDStatus,
  onDragOver,
} from "../../../utils/common-methods";
import CountryPopup from "./CountryPopup";
import { confirm } from "devextreme/ui/dialog";
import { CountryServices } from "../../../api/services/CountryServices";

const countryServices = new CountryServices();

export default function CountryList() {
  const { isExSmall, isXXSmall } = useScreenSize();
  const [dsCountry, setDsCountry] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);

  const [showCountryPopup, setShowCountryPopup] = useState(false);
  const [primaryKey, setPrimaryKey] = useState(null);
  const [focusedRowKey, setfocusedRowKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });

  const DataGridRef = useRef(null);

  const GetList = async () => {
    // setShowSkeleton(true)
    const result = await countryServices.GetListData();
    if (result?.statusCode === 400) {
      ShowAlert(result?.data, "Vakency");
      setShowSkeleton(false);
    } else {
      const countryData = result?.data?.map((item) => {
        const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
        const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
        return {
          ...item,
          createdOn: createdOn ? createdOn : null,
          updatedOn: updatedOn ? updatedOn : null,
        };
      });
      setDsCountry(countryData);
      setShowSkeleton(false);
    }
  };

  // Get all countries records
  useEffect(() => {
    GetList();
  }, []);

  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID =
        status.eStatus === eCRUDStatus.Inserted
          ? status.primaryKeyID
          : primaryKey;
      UpdateListByID(PrimaryKeyID);
    }
  }, [status]);

  const UpdateListByID = async (primaryKey) => {
    const result = await countryServices.GetListByIdData(primaryKey);
    if (result?.statusCode === 400) {
      ShowAlert(result.data, "Vakency");
    } else {
      const countryData = result?.data?.map((item) => {
        const createdOn = item.createdOn
          ? new Date(
              new Date(item.createdOn + "Z").toUTCString()
            ).toLocaleString()
          : null;
        const updatedOn = item.updatedOn
          ? new Date(
              new Date(item.updatedOn + "Z").toUTCString()
            ).toLocaleString()
          : null;
        return {
          ...item,
          createdOn: createdOn ? createdOn : null,
          updatedOn: updatedOn ? updatedOn : null,
        };
      });
      if (status.eStatus === eCRUDStatus.Inserted) {
        setDsCountry([countryData[0], ...dsCountry]);
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setDsCountry(
          dsCountry.map((i) =>
            i.countryID === primaryKey ? countryData[0] : i
          )
        );
      }
    }
    setfocusedRowKey(primaryKey);
    setPrimaryKey(0);
  };

  const onOptionChange = (e) => {
    if (e.fullName === "filterValue") {
      setFilterVisible(!!e.value);
    }
  };

  const handleAdd = () => {
    setPrimaryKey(null);
    setShowCountryPopup(true);
  };

  const handleEdit = (e) => {
    setPrimaryKey(e.data.countryID);
    setShowCountryPopup(true);
  };

  const onDeleteButtonClick = (e) => {
    let res = confirm(
      `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
      "Vakency"
    );
    res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
  };

  const handleDelete = async (e) => {
    const Id = e.row.data.countryID;
    const result = await countryServices.DeleteData(Id);
    if (result?.statusCode === 400) {
      if (result.data === "Deleting record exists in states(city)") {
        ShowAlert("Deleting record exists in states.", "Vakency");
      } else {
        ShowAlert(result.data, "Vakency");
      }
    } else if (result?.statusCode === 200) {
      setDsCountry(
        dsCountry.filter((x) => x.countryID !== e.row.data.countryID)
      );
    }
  };

  const onRowDbClick = (e) => {
    setPrimaryKey(e.data.countryID);
    setShowCountryPopup(true);
  };

  const CountryName = (cellData) => {
    return (
      <>
        <div>
          <span
            className="text-decoration-underline pointer-link"
            title="Edit"
            onClick={(e) => handleEdit(cellData)}
          >
            {cellData.data.countryName}
          </span>
        </div>
      </>
    );
  };

  function onFocusedRowChanged(e) {
    setfocusedRowKey(e.component.option("focusedRowKey"));
  }

  return (
    <div className="Country" onDragOver={onDragOver}>
      <Header
        title={"Countries"}
        handleAdd={handleAdd}
        dataGridRef={DataGridRef}
        GetRecord={GetList}
      />
      <div className="list-section">
        {showSkeleton ? (
          <ListSkeleton />
        ) : (
          <DataGrid
            ref={DataGridRef}
            height={"100%"}
            width={"100%"}
            className="List_DataGrid"
            dataSource={dsCountry}
            keyExpr="countryID"
            showBorders={true}
            showColumnLines={false}
            showRowLines={true}
            focusedRowKey={focusedRowKey}
            focusedRowEnabled={true}
            wordWrapEnabled={true}
            hoverStateEnabled={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            autoNavigateToFocusedRow={true}
            loadPanel={HideDatagridLoader}
            filterSyncEnabled={true}
            onOptionChanged={onOptionChange}
            onRowDblClick={onRowDbClick}
            noDataText="No Record Found"
            onFocusedRowChanged={onFocusedRowChanged}
          >
            <Sorting mode={"multiple"} />
            <FilterPanel visible={filterVisible} />
            <FilterBuilderPopup visible={false} />
            <Scrolling mode={"infinite"} preloadEnabled={true} useNative={true}/>
            <ColumnChooser enabled={true} width={!isExSmall ? 320 : "auto"}>
              <ColumnChooserSearch enabled={true} />
            </ColumnChooser>
            <HeaderFilter visible={true}>
              <Search enabled={true} />
            </HeaderFilter>
            <SearchPanel
              visible={true}
              width={isExSmall || isXXSmall ? 200 : 400}
            />
            <StateStoring
              enabled={true}
              type="localStorage"
              storageKey="Country_Layout"
            />
            <Pager visible={true} showInfo={true} infoText={`{2} Rows`} showNavigationButtons={false}></Pager>
            <Toolbar>
              <Item location={"after"} name="columnChooserButton" />
              <Item location={"after"} name="searchPanel" />
            </Toolbar>
            <Column
              dataField="countryName"
              caption="Country"
              cellRender={CountryName}
              minWidth={120}
              allowHiding={false}
            />
            <Column
              dataField="createdByUserName"
              caption="Created By"
              visible={false}
              width={125}
              minWidth={80}
              alignment="left"
            />
            <Column
              dataField="updatedByUserName"
              caption="Updated By"
              visible={false}
              width={125}
              minWidth={80}
              alignment="left"
            />
            <Column
              dataField="createdOn"
              caption="Created On"
              visible={false}
              width={135}
              minWidth={80}
              alignment="left"
              // dataType='datetime'
              // format="dd/MM/yyyy hh:mm a"
            />
            <Column
              dataField="updatedOn"
              caption="Updated On"
              visible={false}
              width={137}
              minWidth={80}
              alignment="left"
              // dataType='datetime'
              // format="dd/MM/yyyy hh:mm a"
            />
            <Column
              type="buttons"
              fixed={false}
              allowResizing={false}
              width={43}
              allowReordering={false}
            >
              <DataGridButton
                name="delete"
                icon="trash"
                hint="Delete"
                visible={true}
                cssClass="text-danger"
                onClick={onDeleteButtonClick}
              />
            </Column>
          </DataGrid>
        )}
      </div>
      {showCountryPopup && (
        <CountryPopup
          openPopup={showCountryPopup}
          setOpenPopup={setShowCountryPopup}
          primaryKey={primaryKey}
          setStatus={setStatus}
          status={status}
        />
      )}
    </div>
  );
}
